import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {
    MuiModal,
    MuiPrimaryButton,
    MuiSecondaryButton,
} from '../material-ui';
import Icon from '../icon/Icon';
import getContent from '../../services/content/getContent';
import BadgeIcon from '../../assets/images/icons/privacy-badge-icon.svg';

const MemberConsentNotice = ({
    classes,
    open,
    onClose,
    onConfirm,
    name,
    consent,
    resolving,
}) => (
    <MuiModal
        open={open}
        onClose={(event, reason) => onClose(reason, consent)}
        size="medium"
        disableClose
        overflow
        classes={{ paper: classes.paper }}
    >
        <Box className={classes.root}>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Icon
                        src={BadgeIcon}
                        height={130}
                        classes={{ box: classes.badge }}
                        alt={getContent(['components', 'memberConsentNotice', 'title', 1])}
                    />
                    <Typography variant="h2" align="center">
                        {`${getContent(['components', 'memberConsentNotice', 'title', 0])} ${name}.`}
                        <br />
                        {getContent(['components', 'memberConsentNotice', 'title', 1])}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Typography variant="body1" gutterBottom>
                        {getContent(['components', 'memberConsentNotice', 'subTitle'])}
                    </Typography>
                    <List>
                        {getContent(['components', 'memberConsentNotice', 'listItems']).map(item => (
                            <ListItem className={classes.listItem} key={item.replace(/ /g, '')}>
                                <ListItemIcon className={classes.icon}><CheckCircle /></ListItemIcon>
                                <ListItemText
                                    className={classes.text}
                                    primary={item}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={10}>
                    <Box className={classes.box}>
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={10} md={12} lg={12}>
                                <Typography variant="h3" gutterBottom align="center">
                                    {getContent(['components', 'memberConsentNotice', 'questionLabel'])}
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {getContent(['components', 'memberConsentNotice', 'note'])}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPrimaryButton fullWidth size="large" onClick={() => onConfirm(consent)} disabled={resolving}>
                                    {getContent(['components', 'memberConsentNotice', 'confirmBtnLabel'])}
                                </MuiPrimaryButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiSecondaryButton fullWidth size="large" onClick={(event, reason) => onClose(reason, consent)} disabled={resolving}>
                                    {getContent(['components', 'memberConsentNotice', 'rejectBtnLabel'])}
                                </MuiSecondaryButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </MuiModal>
);

const styles = theme => ({
    paper: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down('lg')]: {
            width: '75%',
        },
        [theme.breakpoints.down('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '75%',
        },
    },
    root: {
        background: theme.palette.background.greyFadePinTop,
        backgroundPosition: '0 80px',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    icon: {
        color: theme.palette.common.green,
        marginRight: 0,
    },
    badge: {
        marginBottom: theme.spacing(1),
    },
    text: {
        marginTop: 0,
        '& span': {
            fontWeight: 'bold',
            color: theme.palette.common.black,
        },
    },
    listItem: {
        alignItems: 'flex-start',
    },
    box: {
        backgroundColor: theme.palette.common.greyLight,
        padding: theme.spacing(4),
        paddingTop: theme.spacing(2),
    },
});

MemberConsentNotice.defaultProps = {
    open: false,
    name: null,
    consent: null,
    resolving: false,
};

MemberConsentNotice.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    name: PropTypes.string,
    consent: PropTypes.shape({
        marketing: PropTypes.bool,
        profiling: PropTypes.bool,
    }),
    resolving: PropTypes.bool,
};

export default withStyles(styles)(MemberConsentNotice);
