import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const PageTitle = ({
    classes, className, text, align,
}) => (
    <Typography
        variant="h1"
        gutterBottom
        align={align}
        className={classes.title}
        classes={{ root: className }}
    >
        {text}
    </Typography>
);

const styles = () => ({
    title: props => (props.lg ? {
        fontSize: 45,
    } : null),
});

styles.defaultProps = {
    lg: false,
};

styles.propTypes = {
    lg: PropTypes.bool,
};

PageTitle.defaultProps = {
    className: '',
    align: 'left',
};

PageTitle.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    align: PropTypes.string,
};

export default withStyles(styles)(PageTitle);
