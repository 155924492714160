import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '../../icon/Icon';
import SummaryOutput from '../SummaryOutput/SummaryOutput';
import SummaryThemeOutput from '../SummaryThemeOutput/SummaryThemeOutput';
import QuestionnaireService from '../../../services/questionnaire/questionnaire';
import getContent from '../../../services/content/getContent';

const QuestionnaireResultsSummaryPanel = ({ classes, priority, color }) => (
    <Card elevation={0} className={classes.card}>
        <CardHeader
            className={classes.header}
            title={priority.title}
            titleTypographyProps={{
                variant: 'h2',
                align: 'center',
                className: classes.header,
            }}
        />
        <CardContent className={classes.content}>
            <List dense className={classes.list}>
                {priority.items.length > 0 && priority.items.map(item => (
                    <ListItem className={classes.listItem} key={item.title.replace(' ', '')}>
                        <ListItemIcon className={classes.listIcon}>
                            <Icon
                                src={QuestionnaireService.getResultSummaryIcon(item.icon)}
                                alt={item.title}
                                xs
                                verticalAlign="top"
                                height={80}
                            />
                        </ListItemIcon>
                        {item.theme
                            ? <SummaryThemeOutput title={item.title} theme={item.theme} color={color} />
                            : <SummaryOutput title={item.title} description={item.description} />
                        }
                    </ListItem>
                ))}
                {priority.items.length === 0 && (
                    <ListItem className={`${classes.listItem} ${classes.listItemEmpty}`}>
                        <ListItemText
                            secondary={getContent(['components', 'QuestionnaireResultsSummaryPanel', 'emptyPanel', priority.title.toLowerCase().replace(/ /g, '')])}
                            secondaryTypographyProps={{
                                variant: 'body2',
                                align: 'center',
                            }}
                        />
                    </ListItem>
                )}
            </List>
        </CardContent>
    </Card>
);

const styles = theme => ({
    card: {
        borderRadius: 15,
        display: 'flex',
        flexDirection: 'column',
    },
    header: ({ color }) => ({
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        color,
    }),
    iconColor: ({ color }) => ({
        color,
    }),
    headerFont: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        display: 'block',
        marginBottom: 6,
    },
    content: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: theme.palette.common.greyLight,
        flex: 1,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    list: ({ inline }) => ({
        display: inline ? 'flex' : 'block',
        flexWrap: 'wrap',
    }),
    listItem: ({ inline }) => ({
        borderBottom: inline ? 'none' : `1px solid ${theme.palette.common.grey}`,
        paddingBottom: theme.spacing(2),
        alignItems: 'flex-start',
        '&:last-child': {
            borderBottom: 'none',
        },
        width: inline ? '33%' : '100%',
        [theme.breakpoints.up('lg')]: {
            minHeight: 157,
        },
        [theme.breakpoints.down('md')]: {
            width: inline ? '50%' : '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }),
    listItemEmpty: {
        width: '100%',
        paddingBottom: 0,
        minHeight: 0,
        [theme.breakpoints.up('lg')]: {
            minHeight: '0 !important',
        },
    },
    listIcon: {
        minWidth: 85,
        marginTop: 10,
        '& div': {
            height: 'auto',
            paddingBottom: 20,
        },
        '& img': {
            maxWidth: 65,
            maxHeight: 65,
        },
    },
});

styles.defaultProps = {
    inline: false,
};

styles.propTypes = {
    color: PropTypes.string.isRequired,
    inline: PropTypes.bool,
};

QuestionnaireResultsSummaryPanel.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    priority: PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            theme: PropTypes.shape,
        })),
    }).isRequired,
    color: PropTypes.string.isRequired,
};

export default withStyles(styles)(QuestionnaireResultsSummaryPanel);
