import ApolloClient from 'apollo-client-preset';
import { createHttpLink } from 'apollo-link-http';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import authMiddleware from './authMiddleware';
import { getEnvironmentVariable } from '../environment/environment-service';
/**
 * Creates a new Apollo Client using any middleware or afterware required
 */

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
            if (message === 'Token not found' || message === 'Not Authorised!') {
                window.location.reload();
            }
        });
    }
    // if (networkError) console.log(`[Network error]: ${networkError}`);
});

const graphQLClient = () => {
    const httpLink = createHttpLink({
        uri: getEnvironmentVariable('REACT_APP_GRAPHQL_SERVER'),
    });
    return new ApolloClient({
        link: from([
            errorLink,
            authMiddleware,
            httpLink,
        ]),
    });
};

export default graphQLClient;
