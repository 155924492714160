import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireIntro from '../questionnaire/QuestionnaireIntro/QuestionnaireIntro';
import QuestionnaireComplete from './LifestyleAssessmentComplete/LifestyleAssessmentComplete';

const LifestyleAssessmentWrapper = (Questionnaire) => {
    // const pageImages = [resilienceSVG, perceivedStressSVG];

    const Wrapper = ({ questionnaireId, step }) => (
        <Questionnaire
            questionnaireId={questionnaireId}
            introPage={QuestionnaireIntro}
            completePage={QuestionnaireComplete}
            step={step}
        />
    );

    Wrapper.defaultProps = {
        step: null,
    };

    Wrapper.propTypes = {
        questionnaireId: PropTypes.string.isRequired,
        step: PropTypes.string,
    };

    return Wrapper;
};

export default LifestyleAssessmentWrapper;
