export const PERKS_OFFERS_REQUESTED = 'PERKS_OFFERS_REQUESTED';
export const PERKS_OFFERS_RESOLVED = 'PERKS_OFFERS_RESOLVED';
export const PERKS_OFFERS_ERROR = 'PERKS_OFFERS_ERROR';

export const PERKS_OFFERS_NEXT_REQUESTED = 'PERKS_OFFERS_NEXT_REQUESTED';
export const PERKS_OFFERS_NEXT_RESOLVED = 'PERKS_OFFERS_NEXT_RESOLVED';
export const PERKS_OFFERS_NEXT_ERROR = 'PERKS_OFFERS_NEXT_ERROR';

export const PERKS_CATEGORIES_REQUESTED = 'PERKS_CATEGORIES_REQUESTED';
export const PERKS_CATEGORIES_RESOLVED = 'PERKS_CATEGORIES_RESOLVED';
export const PERKS_CATEGORIES_ERROR = 'PERKS_CATEGORIES_ERROR';

export const PERKS_SUBCATEGORIES_REQUESTED = 'PERKS_SUBCATEGORIES_REQUESTED';
export const PERKS_SUBCATEGORIES_RESOLVED = 'PERKS_SUBCATEGORIES_RESOLVED';
export const PERKS_SUBCATEGORIES_ERROR = 'PERKS_SUBCATEGORIES_ERROR';
