import getContent from '../services/content/getContent';
import bodyIcon from '../assets/images/events/pillar-body-icon.svg';
import cultureIcon from '../assets/images/events/pillar-culture-icon.svg';
import mindIcon from '../assets/images/events/pillar-mind-icon.svg';
import moneyIcon from '../assets/images/events/pillar-money-icon.svg';

const PILLARS = [
    {
        id: 'body', label: getContent(['components', 'events', 'pillars', 'body']), value: 'body', src: bodyIcon,
    },
    {
        id: 'mind', label: getContent(['components', 'events', 'pillars', 'mind']), value: 'mind', src: mindIcon,
    },
    {
        id: 'money', label: getContent(['components', 'events', 'pillars', 'money']), value: 'money', src: moneyIcon,
    },
    {
        id: 'culture', label: getContent(['components', 'events', 'pillars', 'culture']), value: 'culture', src: cultureIcon,
    },
];

export default PILLARS;
