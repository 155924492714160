import { createTheme } from '@material-ui/core/styles';
import palette from './palette/palette';
import typography from './typography/typography';
import overrides from './overrides/overrides';
import shape from './shape/shape';

const defaultTheme = createTheme();

const theme = createTheme({
    palette,
    overrides,
    typography,
    shape,
    shadows: [
        ...defaultTheme.shadows,
        '0px 1px 7px 0px rgba(0,0,0,0.2)',
    ],
});

export default theme;
