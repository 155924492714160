import { gql } from '@apollo/client';
import getWebSocketClient from '../getWebSocketClient';
import onStatusCheck from '../../../actions/status/onStatusCheck';
import store from '../../store';

export const handleSubscription = ({ data }) => {
    if (!data.onStatusCheck) return;
    store.dispatch(onStatusCheck(data.onStatusCheck));
};

const onStatusCheckSubscription = async () => {
    const client = await getWebSocketClient({ resetInactivityTimer: false });

    const subscription = gql`subscription OnStatusCheck {
        onStatusCheck {
            component
            plannedStatus
            unPlannedStatus
            message
        }
    }`;

    const observable = await client.subscribe({
        query: gql`${subscription}`,
    });

    observable.subscribe({
        next: handleSubscription,
        // complete: console.log,
        // error: console.log,
    });
};

export default onStatusCheckSubscription;
