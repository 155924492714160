import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: ({ gutterBottom }) => ({
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 1,
        marginBottom: gutterBottom ? theme.spacing(3) : theme.spacing(1),
    }),
});

const SectionHeader = withStyles(styles)(Typography);

SectionHeader.defaultProps = {
    align: 'center',
    variant: 'h6',
    color: 'textSecondary',
    gutterBottom: false,
};

SectionHeader.propTypes = {
    align: PropTypes.string,
};

export default SectionHeader;
