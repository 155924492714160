import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const StaticLoader = ({ classes }) => (
    <LinearProgress color="secondary" className={classes.root} />
);

const styles = () => ({
    root: {
        position: 'fixed',
        top: 0,
        width: '100%',
        left: 0,
        zIndex: 2000,
    },
});

StaticLoader.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(StaticLoader);
