import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import getContent from '../../../services/content/getContent';

const RadialDial = ({
    classes,
    resultLabel,
    description,
}) => (
    <Box className={classes.circle}>
        <Box className={classes.circleInner}>
            <Box className={classes.pointer} />
            <Typography variant="h5" className={classes.result}>
                {resultLabel}
            </Typography>
            {typeof description === 'object' ? description : (
                <Typography variant="body2" className={classes.description} color="textPrimary">
                    {description}
                </Typography>
            )}
        </Box>
        <div className={classes.labels}>
            <Typography variant="overline">{getContent(['components', 'RadialDial', 'labels', 'low'])}</Typography>
            <Typography variant="overline">{getContent(['components', 'RadialDial', 'labels', 'medium'])}</Typography>
            <Typography variant="overline">{getContent(['components', 'RadialDial', 'labels', 'high'])}</Typography>
        </div>
    </Box>
);

const styles = theme => ({
    circleInner: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '50%',
        width: 190,
        height: 190,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[25],
    },
    circle: ({ rangeReverse }) => ({
        height: 132,
        borderTopLeftRadius: 264,
        borderTopRightRadius: 264,
        background: rangeReverse ? theme.palette.background.gradientRangeReverse : theme.palette.background.gradientRange,
        padding: 36,
        display: 'inline-block',
        boxSizing: 'border-box',
        position: 'relative',
    }),
    result: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: 30,
        lineHeight: 1,
        marginBottom: theme.spacing(1),
    },
    description: {
        fontSize: 13,
    },
    pointer: props => ({
        position: 'absolute',
        top: 0,
        left: 'calc(50% - 18px)',
        transition: 'all 2s',
        transform: `rotate(${((180 / 100) * props.percent) - 90}deg)`,
        width: 36,
        height: 190,
        '&:after': {
            content: '""',
            position: 'absolute',
            top: -25,
            left: 0,
            width: 0,
            height: 0,
            borderLeft: '18px solid transparent',
            borderRight: '18px solid transparent',
            borderBottom: `36px solid ${theme.palette.common.white}`,
            backgroundColor: theme.palette.common.transparent,
        },
    }),
    labels: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '& > span': {
            position: 'absolute',
            top: -30,
            left: 'calc(50% - 24px)',
            '&:first-child': {
                left: -25,
                transform: 'rotate(-90deg)',
                top: 85,
            },
            '&:last-child': {
                left: 'auto',
                right: -25,
                transform: 'rotate(90deg)',
                top: 85,
            },
        },
    },
});

styles.defaultProps = {
    rangeReverse: false,
};

styles.propTypes = {
    rangeReverse: PropTypes.bool,
};

RadialDial.defaultProps = {
    description: null,
};

RadialDial.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    resultLabel: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withStyles(styles)(RadialDial);
