import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

const MuiInfoExpansionPanel = ({ classes, summary, children }) => (
    <Accordion className={classes.panel} elevation={0}>
        <AccordionSummary
            classes={{ content: classes.summary, expanded: classes.summary }}
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
            <InfoIcon className={classes.summaryIcon} />
            <Typography
                variant="body2"
                className={classes.content}
            >
                {summary}
            </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
            {children}
        </AccordionDetails>
    </Accordion>
);

const styles = theme => ({
    summary: {
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.primary.main,
    },
    summaryIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
    content: {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    details: {
        paddingTop: 0,
    },
});

MuiInfoExpansionPanel.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    summary: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(MuiInfoExpansionPanel);
