import { gql } from '@apollo/client';
import getContent from '../../content/getContent';
import graphQLCLient from '../client';

const favouriteArticleQuery = async (articleId) => {
    const client = graphQLCLient();

    const query = gql`query GetArticleFavourites {
        getArticleFavourites {
            favourites {
                articleId
                authorIds
            }
        }
    }`;

    return new Promise((resolve, reject) => {
        client.query({
            query,
            variables: {
                articleId,
            },
        })
            .then((result) => {
                if (!result.data || !Object.prototype.hasOwnProperty.call(result.data, 'getArticleFavourites')) {
                    throw new Error(getContent(['errors', 'invalid-graphQL-response']));
                }
                resolve(result.data.getArticleFavourites);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default favouriteArticleQuery;
