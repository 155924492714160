let config = {};

export const setEnvironmentConfig = (env) => {
    config = env;
};

export const getEnvironmentConfig = () => config;

export const getEnvironmentVariable = (key) => {
    if (config[key]) {
        return config[key];
    }
    return null;
};

export default {
    setEnvironmentConfig,
    getEnvironmentConfig,
    getEnvironmentVariable,
};
