import variables from '../variables';

const MuiTableRow = {
    root: {
        borderBottom: `1px solid ${variables.colors.grey}`,
        '&:nth-of-type(odd)': {
            backgroundColor: variables.colors.greyLight,
            '&:hover': {
                backgroundColor: variables.colors.greyLight,
            },
        },
        '&:nth-of-type(even)': {
            backgroundColor: variables.colors.white,
            '&:hover': {
                backgroundColor: variables.colors.white,
            },
        },
    },
    head: {
        backgroundColor: `${variables.colors.white} !important`,
        borderBottom: `1px solid ${variables.colors.grey}`,
    },
};

const MuiTableCell = {
    root: {
        paddingLeft: '16px',
        paddingRight: '16px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '0.8125rem',
        color: variables.colors.greyDark,
    },
    body: {
        color: variables.colors.black,
        '& span strong': {
            color: variables.colors.orange,
        },
    },
};

const MuiTableSortLabel = {
    active: {
        fontWeight: 'bold',
        '& svg': {
            color: variables.colors.orange,
        },
    },
};

const MuiTablePagination = {
    select: {
        paddingRight: '35px',
    },
    input: {
        marginTop: '3px',
    },
    toolbar: {
        backgroundColor: variables.colors.white,
    },
};

const MuiTable = {
    MuiTableRow,
    MuiTableCell,
    MuiTableSortLabel,
    MuiTablePagination,
};

export default MuiTable;
