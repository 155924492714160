
export const EMPLOYER_DASHBOARD_REQUESTED = 'EMPLOYER_DASHBOARD_REQUESTED';
export const EMPLOYER_DASHBOARD_RESOLVED = 'EMPLOYER_DASHBOARD_RESOLVED';
export const EMPLOYER_DASHBOARD_ERROR = 'EMPLOYER_DASHBOARD_ERROR';

export const EMPLOYER_TRACKER_REQUESTED = 'EMPLOYER_TRACKER_REQUESTED';
export const EMPLOYER_TRACKER_RESOLVED = 'EMPLOYER_TRACKER_RESOLVED';
export const EMPLOYER_TRACKER_ERROR = 'EMPLOYER_TRACKER_ERROR';

export const EMPLOYER_TRACKER_ASSESSMENT_REQUESTED = 'EMPLOYER_TRACKER_ASSESSMENT_REQUESTED';
export const EMPLOYER_TRACKER_ASSESSMENT_RESOLVED = 'EMPLOYER_TRACKER_ASSESSMENT_RESOLVED';
export const EMPLOYER_TRACKER_ASSESSMENT_ERROR = 'EMPLOYER_TRACKER_ASSESSMENT_ERROR';

export const EMPLOYER_ASSESSMENT_THEME_TREND_REQUESTED = 'EMPLOYER_ASSESSMENT_THEME_TREND_REQUESTED';
export const EMPLOYER_ASSESSMENT_THEME_TREND_RESOLVED = 'EMPLOYER_ASSESSMENT_THEME_TREND_RESOLVED';
export const EMPLOYER_ASSESSMENT_THEME_TREND_ERROR = 'EMPLOYER_ASSESSMENT_THEME_TREND_ERROR';

export const EMPLOYER_ASSESSMENT_CLEAR_THEME_TREND = 'EMPLOYER_ASSESSMENT_CLEAR_THEME_TREND';

export const EMPLOYER_LOGIN_TREND_REQUESTED = 'EMPLOYER_LOGIN_TREND_REQUESTED';
export const EMPLOYER_LOGIN_TREND_RESOLVED = 'EMPLOYER_LOGIN_TREND_RESOLVED';
export const EMPLOYER_LOGIN_TREND_ERROR = 'EMPLOYER_LOGIN_TREND_ERROR';

export const EMPLOYER_LOGIN_CLEAR_TREND = 'EMPLOYER_LOGIN_CLEAR_TREND';
