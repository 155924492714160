import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import shortid from 'shortid';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SectionContainer from '../../layout/SectionContainer/SectionContainer';
import QuestionnaireResultsSummaries from '../../questionnaire/QuestionnaireResultsSummaries/QuestionnaireResultsSummaries';
import QuestionnaireResultsPrioritiesAnswers from '../../questionnaire/QuestionnaireResultsPrioritiesAnswers/QuestionnaireResultsPrioritiesAnswers';
import QuestionnaireResultsAnswers from '../../questionnaire/QuestionnaireResultsAnswers/QuestionnaireResultsAnswers';
import QuestionnaireMessage from '../../questionnaire/QuestionnaireMessage/QuestionnaireMessage';
import QuestionnaireResultsRecommendations from '../../questionnaire/QuestionnaireResultsRecommendations/QuestionnaireResultsRecommendations';
import MuiRoundedButton from '../../material-ui/MuiRoundedButton/MuiRoundedButton';
import SectionHeader from '../../layout/SectionHeader/SectionHeader';
import RetakeAssessmentReminder from '../../retake-assessment-reminder/RetakeAssessmentReminder';
import ServiceQuoteCard from '../../questionnaire/QuestionnaireResultsServices/QuestionnaireResultsServices';
import HelpfulResources from '../../helpful-resources/HelpfulResources';
import getContent from '../../../services/content/getContent';
import CounsellingIcon from '../../../assets/images/icons/counselling-icon.svg';

const FinancialWellbeingAssessmentComplete = ({
    classes,
    results: {
        summary: {
            priorities,
            answers,
        },
        results,
        message,
        recommendations,
        latestResultDate,
    },
}) => {
    const [tabSelected, setTabSelected] = React.useState(0);
    const icons = [CounsellingIcon];
    return (
        <>
            <SectionContainer
                title={getContent(['components', 'questionnaires', 'FinancialWellbeing', 'complete', 'description'])}
                description={getContent(['components', 'questionnaires', 'FinancialWellbeing', 'complete', 'title'])}
                className={classes.titleContainer}
                subDescription={(
                    <Typography variant="body2" align="center" className={classes.date}>{`Last taken: ${moment(latestResultDate).format('DD/MM/YYYY')}`}</Typography>
                )}
            >
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={6} sm={6} md={3}>
                        <MuiRoundedButton
                            fullWidth
                            size="large"
                            onClick={() => setTabSelected(0)}
                            selected={tabSelected === 0}
                        >
                            {getContent(['components', 'questionnaires', 'complete', 'optionButtons', 'summary'])}
                        </MuiRoundedButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <MuiRoundedButton
                            fullWidth
                            size="large"
                            onClick={() => setTabSelected(1)}
                            selected={tabSelected === 1}
                        >
                            {getContent(['components', 'questionnaires', 'complete', 'optionButtons', 'answers'])}
                        </MuiRoundedButton>
                    </Grid>
                </Grid>
            </SectionContainer>
            <SectionContainer
                backgroundColor="gradient"
                className={classes.messageContainer}
            >
                {tabSelected === 0
                    ? <QuestionnaireResultsSummaries priorities={priorities} panelResize /> : (
                        <>
                            <QuestionnaireResultsPrioritiesAnswers answers={answers} panelResize />
                            <Divider className={classes.divider} />
                            <SectionHeader>{getContent(['components', 'questionnaires', 'FinancialWellbeing', 'complete', 'sectionAnswersHeader'])}</SectionHeader>
                            {results.map((result, index) => (
                                <QuestionnaireResultsAnswers
                                    key={`result${shortid.generate()}`}
                                    title={getContent(['components', 'questionnaires', 'FinancialWellbeing', 'complete', 'answerGroups', index, 'title'])}
                                    result={result}
                                />
                            ))}
                        </>
                    )
                }
                {message && (
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={10}>
                            <QuestionnaireMessage
                                message={message}
                                title={getContent(['components', 'questionnaires', 'FinancialWellbeing', 'complete', 'questionnaireMessage'])}
                            />
                        </Grid>
                    </Grid>
                )}
            </SectionContainer>
            {recommendations && recommendations.length > 0 && (
                <Box className={classes.recommendationsContainer}>
                    <QuestionnaireResultsRecommendations recommendations={recommendations} />
                    <ServiceQuoteCard
                        services={getContent(['components', 'questionnaires', 'FinancialWellbeing', 'complete', 'services']).toJS()
                            .map((service, index) => ({ ...service, icon: icons[index] }))}
                    />
                </Box>
            )}
            <SectionContainer
                backgroundColor="white"
            >
                <RetakeAssessmentReminder />
            </SectionContainer>
            <SectionContainer
                className={classes.resourcesContainer}
                backgroundColor="grey"
                title={getContent(['components', 'questionnaires', 'FinancialWellbeing', 'complete', 'otherResources'])}
            >
                <HelpfulResources items={getContent(['components', 'helpfulResources', 'FinancialWellbeing', 'items']).toJS()} />
            </SectionContainer>
        </>
    );
};

const styles = theme => ({
    titleContainer: {
        '& h2': {
            fontSize: 55,
        },
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.common.green,
    },
    messageContainer: ({ results: { message } }) => ({
        marginBottom: message ? 130 : theme.spacing(5),
    }),
    recommendationsContainer: {
        backgroundColor: theme.palette.common.greyLight,
    },
    resourcesContainer: {
        marginBottom: -48,
    },
    date: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
});

FinancialWellbeingAssessmentComplete.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    results: PropTypes.shape({
        summary: PropTypes.shape({
            priorities: PropTypes.arrayOf(PropTypes.shape),
            answers: PropTypes.arrayOf(PropTypes.shape),
        }),
        results: PropTypes.arrayOf(PropTypes.shape),
        message: PropTypes.string,
        recommendations: PropTypes.arrayOf(PropTypes.shape),
        moreArticles: PropTypes.arrayOf(PropTypes.shape),
        latestResultDate: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(FinancialWellbeingAssessmentComplete);
