import Container from './Container/Container';
import FormPage from './FormPage/FormPage';
import Page from './Page/Page';
import PageHeader from './PageHeader/PageHeader';
import PageTitle from './PageTitle/PageTitle';
import SectionContainer from './SectionContainer/SectionContainer';
import SectionDescription from './SectionDescription/SectionDescription';
import SectionHeader from './SectionHeader/SectionHeader';
import SubPage from './SubPage/SubPage';
import SubPageHeader from './SubPageHeader/SubPageHeader';

export {
    Container,
    FormPage,
    Page,
    PageHeader,
    PageTitle,
    SectionContainer,
    SectionDescription,
    SectionHeader,
    SubPage,
    SubPageHeader,
};
