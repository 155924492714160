import { history } from '../store';

const WebViewService = {};
let locationListenerSet = false;

WebViewService.isWebView = () => window.ReactNativeWebView !== undefined;

WebViewService.postMessage = (message) => {
    if (WebViewService.isWebView()) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
};

/**
 * Listen for messages from the React native webview
 * And change the route if valid page route found
 */
WebViewService.setLocationListener = () => {
    if (locationListenerSet === false) {
        window.addEventListener('message', (event) => {
            if (event.data && event.data.indexOf('/') === 0) {
                history.push(event.data);
            }
        });
        document.addEventListener('message', (event) => {
            if (event.data && event.data.indexOf('/') === 0) {
                history.push(event.data);
            }
        });
        locationListenerSet = true;
    }
};

export default WebViewService;
