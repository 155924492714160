import * as Immutable from 'immutable';
import {
    // NOTIFICATION_REQUESTED,
    // NOTIFICATION_RESOLVED,
    // NOTIFICATION_ERROR,
    NOTIFICATIONS_REQUESTED,
    NOTIFICATIONS_RESOLVED,
    NOTIFICATIONS_ERROR,
    // NOTIFICATION_UPDATE_REQUESTED,
    NOTIFICATION_UPDATE_RESOLVED,
    // NOTIFICATION_UPDATE_ERROR,
    // NOTIFICATION_DELETE_REQUESTED,
    // NOTIFICATION_DELETE_RESOLVED,
    // NOTIFICATION_DELETE_ERROR,
    // NOTIFICATION_CREATE_REQUESTED,
    // NOTIFICATION_CREATE_RESOLVED,
    // NOTIFICATION_CREATE_ERROR,
    NOTIFICATION_CLOSE_RESOLVED,
} from '../actions/notifications/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    notifications: [],
};

const initialState = Immutable.Map(schema);
/**
 * The notifications reducer - responsible for managing the notifications in the state
 * @param {Object} state
 * @param {Object} action
 */
const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
    case NOTIFICATIONS_REQUESTED:
        return state.set('resolving', true);

    case NOTIFICATIONS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('notifications', action.payload.filter(notification => notification.status !== 'read'));

    case NOTIFICATIONS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case NOTIFICATION_UPDATE_RESOLVED:
        return state
            .update('notifications', notifications => notifications.map((notification) => {
                if (notification.id === action.payload.id) {
                    return action.payload;
                }
                return notification;
            }));

    case NOTIFICATION_CLOSE_RESOLVED:
        return state
            .update('notifications', notifications => notifications.map((notification) => {
                if (notification.id === action.payload.id) {
                    return action.payload;
                }
                return notification;
            }));

    default:
        return state;
    }
};

export { initialState };

export default notificationsReducer;
