import * as Immutable from 'immutable';
import {
    QUESTIONNAIRE_REQUESTED,
    QUESTIONNAIRE_RESOLVED,
    QUESTIONNAIRE_ERROR,
    QUESTIONNAIRE_UPDATE_REQUESTED,
    QUESTIONNAIRE_UPDATE_RESOLVED,
    QUESTIONNAIRE_UPDATE_ERROR,
    QUESTIONNAIRE_RESULTS_REQUESTED,
    QUESTIONNAIRE_RESULTS_RESOLVED,
    QUESTIONNAIRE_RESULTS_ERROR,
    QUESTIONNAIRE_SET_META,
} from '../actions/questionnaire/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    questionnaire: null,
    results: null,
    meta: {},
};

const initialState = Immutable.Map(schema);

/**
 * The questionnaire reducer - responsible for managing a questionnaire and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const questionnaireReducer = (state = initialState, action) => {
    switch (action.type) {
    case QUESTIONNAIRE_UPDATE_REQUESTED:
    case QUESTIONNAIRE_REQUESTED:
    case QUESTIONNAIRE_RESULTS_REQUESTED:
        return state
            .set('resolving', true)
            .set('results', null);

    case QUESTIONNAIRE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('questionnaire', action.payload)
            .set('results', null)
            .set('meta', {});

    case QUESTIONNAIRE_UPDATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .updateIn(['questionnaire', 'questions'], questions => questions.map((question) => {
                const questionAnswer = action.payload.answers.find(answer => answer.id === question.id);
                if (questionAnswer) {
                    return { ...question, value: questionAnswer.value };
                }
                return question;
            }));

    case QUESTIONNAIRE_RESULTS_ERROR:
    case QUESTIONNAIRE_UPDATE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case QUESTIONNAIRE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('questionnaire', null)
            .set('results', null);

    case QUESTIONNAIRE_RESULTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('results', action.payload);

    case QUESTIONNAIRE_SET_META:
        return state
            .setIn(['meta', action.payload.name], action.payload.value);

    default:
        return state;
    }
};

export { initialState };

export default questionnaireReducer;
