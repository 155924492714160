import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireIntro from './MentalHealthAssessmentIntro/MentalHealthAssessmentIntro';
import QuestionnaireComplete from './MentalHealthAssessmentComplete/MentalHealthAssessmentComplete';
import resilienceSVG from '../../assets/images/services/mental-health/resilience.svg';
import perceivedStressSVG from '../../assets/images/services/mental-health/perceived-stress.svg';

const MentlHealthAssessmentWrapper = (Questionnaire) => {
    const pageImages = [resilienceSVG, perceivedStressSVG];

    const Wrapper = ({ questionnaireId, step }) => (
        <Questionnaire
            questionnaireId={questionnaireId}
            introPage={QuestionnaireIntro}
            completePage={QuestionnaireComplete}
            pageImages={pageImages}
            step={step}
        />
    );

    Wrapper.defaultProps = {
        step: null,
    };

    Wrapper.propTypes = {
        questionnaireId: PropTypes.string.isRequired,
        step: PropTypes.string,
    };

    return Wrapper;
};

export default MentlHealthAssessmentWrapper;
