import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import DeviceContext from '../../context/device-context';
import getStatusAction from '../../actions/status/getStatus';
import StatusService from '../../services/status/status';
import CacheBuster from '../cache-buster/CacheBuster';
import Loader from '../loader/Loader';
import StaticLoader from '../loader/StaticLoader/StaticLoader';
import MaintenancePage from '../maintenance-page/MaintenancePage';
import withTracker from '../with-tracker/withTracker';
import Routes from './Routes/Routes';
import MaintenancePoller from '../maintenance-poller/container';
import getArticlesAction from '../../actions/whats-new/getArticles';
import MobileAppService from '../../services/mobile-app/mobile-app';

const Instructions = lazy(() => import('../instructions/component'));

class App extends React.Component {
    static contextType = DeviceContext; // eslint-disable-line react/sort-comp

    constructor(props, context) {
        super(props, context);
        const device = this.context;
        const { cookies } = props;
        this.state = {
            showGuide: MobileAppService.canShowGuide(device, cookies),
        };
    }

    componentDidMount = async () => {
        const { getStatus, getArticles } = this.props;
        await getStatus();
        getArticles(); // get what's new articles
    }

    /**
     * If the isUndermaintenance flag or status changes then update
     *
     * @param  {Object} nextProps
     * @return {Bool}
     */
    shouldComponentUpdate = (nextProps, nextState) => {
        const { isUnderMaintenance, status } = this.props;
        const { showGuide } = this.state;
        const { showGuide: showGuideNext } = nextState;
        const { isUnderMaintenance: isUnderMaintenanceNext, status: statusNext } = nextProps;
        return (isUnderMaintenance !== isUnderMaintenanceNext || status !== statusNext || showGuide !== showGuideNext);
    }

    closeGuide = () => {
        const { cookies } = this.props;
        MobileAppService.setGuideViewed(cookies);
        this.setState({ showGuide: false });
    };

    render = () => {
        const { showGuide } = this.state;

        if (showGuide) {
            return (<Suspense fallback={<StaticLoader />}><Instructions onClose={this.closeGuide} /></Suspense>);
        }

        const { resolving, status, isUnderMaintenance } = this.props;
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading || (resolving && status === 'INIT') || (!resolving && status === 'INIT')) return <Loader show />;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                        return null;
                    }
                    if ((!resolving && status === 'OK' && isUnderMaintenance) || (!resolving && status === 'ERROR')) {
                        return (
                            <>
                                <MaintenancePoller />
                                <MaintenancePage />
                            </>
                        );
                    }
                    return (
                        <>
                            <MaintenancePoller />
                            <Loader show={resolving} />
                            <Route path="/" component={withTracker(Routes)} />
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getStatus: async () => dispatch(await getStatusAction()),
    getArticles: async () => dispatch(await getArticlesAction()),
});

const mapStateToProps = state => ({
    resolving: state.status.get('resolving'),
    status: state.status.get('status'),
    isUnderMaintenance: !StatusService.isAPIAvailable(
        state.member.get('status'),
        state.companyContact.get('status'),
    ),
});

App.defaultProps = {
    isUnderMaintenance: false,
};

App.propTypes = {
    resolving: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    isUnderMaintenance: PropTypes.bool,
    getStatus: PropTypes.func.isRequired,
    getArticles: PropTypes.func.isRequired,
    cookies: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func,
    }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App));
