import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import variables from '../../../theme/variables';

const MuiStepper = ({
    width, steps, classes, activeStep,
}) => {
    const isSmallScreen = /xs|sm/.test(width);

    const showLabel = label => (!isSmallScreen ? label : null);

    const renderStep = (step, index) => {
        const label = step.displayLabel || step.label;
        if (index === activeStep) {
            return (
                <StepLabel
                    StepIconProps={{
                        icon: (
                            <SvgIcon>
                                <circle cx="13" cy="12" r="6" strokeWidth="7" stroke={variables.stepper.colorActive} fill={variables.colors.white} />
                            </SvgIcon>
                        ),
                    }}
                >
                    <div className={classes.activeStep}>{label}</div>
                </StepLabel>
            );
        }
        return (<StepLabel>{showLabel(label)}</StepLabel>);
    };

    return (
        <Stepper activeStep={activeStep} className={classes.root} orientation={isSmallScreen ? 'horizontal' : 'vertical'}>
            {steps.map((step, index) => (
                <Step key={step.label}>
                    {renderStep(step, index)}
                    ;
                </Step>
            ))}
        </Stepper>
    );
};

const styles = theme => ({
    root: {
        position: 'relative',
        padding: theme.spacing(3),
        background: 'transparent',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 0,
        },
    },
    activeStep: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: -10,
            left: 0,
            right: 0,
            textAlign: 'center',
        },
    },
});

MuiStepper.defaultProps = {
    activeStep: null,
};

MuiStepper.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    steps: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
    })).isRequired,
    width: PropTypes.string.isRequired,
    activeStep: PropTypes.number,
};

export default withStyles(styles)(withWidth()(MuiStepper));
