import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { URLS } from '../../config/config';
import { openSnackbarError, openSnackbarSuccess, closeSnackbar } from '../notifier/Notifier';
import getContent from '../../services/content/getContent';

const UploadNotification = ({
    updateCompanyMembersUploadViewed,
    uploadStatus: {
        errors,
        isComplete,
    },
}) => {
    const linkError = () => (
        <Typography variant="body1" display="inline" gutterBottom>
            <Link
                component={RouterLink}
                to={`${URLS.CONTACT.EMPLOYEES}/upload`}
                onClick={closeSnackbar}
            >
                {getContent(['components', 'uploadNotification', 'errorActionMessage'])}
            </Link>
        </Typography>
    );

    const linkSuccess = () => (
        <Typography variant="body1" display="inline" gutterBottom>
            <Link
                component={RouterLink}
                to={URLS.CONTACT.EMPLOYEES}
                onClick={() => {
                    closeSnackbar();
                }}
            >
                {getContent(['components', 'uploadNotification', 'successActionMessage'])}
            </Link>
        </Typography>
    );
    // if complete with errors
    if (isComplete && errors.length > 0) {
        openSnackbarError(
            getContent(['components', 'uploadNotification', 'errorMessage']),
            [],
            linkError(),
            true,
        );
    }
    // if complete with no errors
    if (isComplete && errors.length === 0) {
        openSnackbarSuccess(
            getContent(['components', 'uploadNotification', 'successMessage']),
            linkSuccess(),
            true,
        );
        updateCompanyMembersUploadViewed();
    }
    return null;
};

UploadNotification.defaultProps = {
    resolving: false,
    status: null,
    error: null,
    uploadStatus: null,
};

UploadNotification.propTypes = {
    getCompanyMembersUploadStatus: PropTypes.func.isRequired,
    updateCompanyMembersUploadViewed: PropTypes.func.isRequired,
    resolving: PropTypes.bool,
    status: PropTypes.string,
    error: PropTypes.string,
    uploadStatus: PropTypes.shape({
        stage: PropTypes.string,
        errors: PropTypes.arrayOf(PropTypes.object),
        isComplete: PropTypes.bool,
    }),
};

export default UploadNotification;
