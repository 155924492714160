import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SectionContainer from '../../layout/SectionContainer/SectionContainer';
import QuestionnaireResultsSummaries from '../../questionnaire/QuestionnaireResultsSummaries/QuestionnaireResultsSummaries';
import QuestionnaireMessage from '../../questionnaire/QuestionnaireMessage/QuestionnaireMessage';
import QuestionnaireResultsRecommendations from '../../questionnaire/QuestionnaireResultsRecommendations/QuestionnaireResultsRecommendations';
import RetakeAssessmentReminder from '../../retake-assessment-reminder/RetakeAssessmentReminder';
import HelpfulResources from '../../helpful-resources/HelpfulResources';
import getContent from '../../../services/content/getContent';

const LifestyleAssessmentComplete = ({
    classes,
    results: {
        summary: {
            priorities,
        },
        message,
        recommendations,
        moreArticles,
        latestResultDate,
    },
}) => (
    <>
        <SectionContainer
            title="Lifestyle Assessment"
            description="Your Results."
            className={classes.titleContainer}
            subDescription={(
                <Typography variant="body2" align="center" className={classes.date}>{`Last taken: ${moment(latestResultDate).format('DD/MM/YYYY')}`}</Typography>
            )}
        />
        <SectionContainer
            backgroundColor="gradient"
            className={classes.messageContainer}
        >
            <QuestionnaireResultsSummaries priorities={priorities} panelResize />
            {message && (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={12} md={10}>
                        <QuestionnaireMessage message={message} title="Result summary" />
                    </Grid>
                </Grid>
            )}
        </SectionContainer>
        {recommendations && recommendations.length > 0 && (
            <Box className={classes.recommendationsContainer}>
                <QuestionnaireResultsRecommendations recommendations={recommendations} moreRecommendations={moreArticles} />
            </Box>
        )}
        <SectionContainer
            backgroundColor="white"
        >
            <RetakeAssessmentReminder />
        </SectionContainer>
        <SectionContainer
            className={classes.resourcesContainer}
            backgroundColor="grey"
            title={getContent(['components', 'questionnaires', 'HealthQuiz', 'complete', 'otherResources'])}
        >
            <HelpfulResources items={getContent(['components', 'helpfulResources', 'HealthQuiz', 'items']).toJS()} />
        </SectionContainer>
    </>
);

const styles = theme => ({
    titleContainer: {
        '& h2': {
            fontSize: 55,
        },
    },
    messageContainer: ({ results: { message } }) => ({
        marginBottom: message ? 130 : theme.spacing(5),
    }),
    recommendationsContainer: {
        backgroundColor: theme.palette.common.greyLight,
    },
    resourcesContainer: {
        marginBottom: -48,
    },
    date: {
        marginTop: theme.spacing(2),
    },
});

LifestyleAssessmentComplete.defaultProps = {

};

LifestyleAssessmentComplete.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    results: PropTypes.shape({
        summary: PropTypes.shape({
            priorities: PropTypes.arrayOf(PropTypes.object),
            answers: PropTypes.arrayOf(PropTypes.object),
        }),
        results: PropTypes.arrayOf(PropTypes.object),
        message: PropTypes.string,
        recommendations: PropTypes.arrayOf(PropTypes.shape),
        moreArticles: PropTypes.arrayOf(PropTypes.object),
        latestResultDate: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(LifestyleAssessmentComplete);
