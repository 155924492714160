import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Radial from './Radial';

const RadialChart = ({
    classes, result, background, color, grey,
}) => {
    const parseResultLabel = () => {
        if (typeof result !== 'string') {
            return result;
        }
        const labels = result.split(' ');
        if (labels.length > 1) {
            return (
                <>
                    <span className={classes.subTitle}>{labels.shift()}</span>
                    <span className={labels.length > 1 ? classes.rangeTarget : ''}>{labels.join(' ')}</span>
                </>
            );
        }
        return result;
    };

    return (
        <Box>
            <Typography variant="h5" className={classes.label} align="center">
                {grey ? 'Target' : 'Result'}
            </Typography>
            <Radial grey={grey} background={background} color={color} size={grey ? 'medium' : 'large'}>
                <Typography variant="h6">
                    {parseResultLabel()}
                </Typography>
            </Radial>
        </Box>
    );
};

const styles = theme => ({
    label: ({ color, grey }) => ({
        color,
        marginBottom: grey ? theme.spacing(2) : 0,
    }),
    subTitle: {
        display: 'block',
        fontSize: 14,
    },
    rangeTarget: {
        fontSize: 15,
        lineHeight: 1.3,
        display: 'block',
        textAlign: 'center',
    },
});
RadialChart.defaultProps = {
    grey: false,
    background: 'white',
    color: 'black',
};

RadialChart.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    result: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    grey: PropTypes.bool,
    color: PropTypes.string,
    background: PropTypes.string,
};

export default withStyles(styles)(RadialChart);
