import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ShadowSVG from '../../assets/images/global/icon-shadow.svg';
import ShadowLargeSVG from '../../assets/images/global/icon-shadow-large.svg';

const Icon = ({ classes, src, alt }) => (
    <Box className={classes.box}>
        <img
            src={src}
            alt={alt}
            className={classes.image}
        />
    </Box>
);

const styles = () => ({
    box: ({
        sm, height, xs, verticalAlign,
    }) => {
        let backgroundImage = `url(${ShadowLargeSVG})`;
        let backgroundSize = 95;
        let h = 170;
        let minWidth: 185;

        if (sm) {
            h = 130;
            backgroundImage = `url(${ShadowSVG})`;
            backgroundSize = '100%';
            minWidth = 130;
        } else if (xs) {
            h = 90;
            backgroundImage = `url(${ShadowSVG})`;
            backgroundSize = '100%';
            minWidth = 'auto';
        }

        if (height) {
            h = height;
        }

        return {
            backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize,
            display: 'flex',
            height: h,
            alignItems: verticalAlign === 'top' ? 'flex-start' : 'center',
            justifyContent: 'center',
            minWidth,
        };
    },
    image: props => ({
        maxWidth: props.xs ? 100 : 147,
        maxHeight: props.xs ? 100 : 112,
    }),
});

styles.defaultProps = {
    height: 170,
    sm: false,
    xs: false,
};

styles.propTypes = {
    height: PropTypes.number,
    sm: PropTypes.bool,
    xs: PropTypes.bool,
    verticalAlign: 'center',
};

Icon.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default withStyles(styles)(Icon);
