import progressDashesSVG from '../../assets/images/progress/progress-dashes.svg';
import progressDashesVerticalSVG from '../../assets/images/progress/progress-dashes-vertical.svg';

const MuiStepConnector = {
    vertical: {
        padding: 0,
        marginLeft: 11,
    },
    lineVertical: {
        minHeight: 32,
        borderLeftStyle: 'none',
        borderLeftWidth: 0,
        backgroundImage: `url(${progressDashesSVG})`,
        backgroundRepeat: 'repeat-y',
    },
    lineHorizontal: {
        minHeight: 3,
        borderTopStyle: 'none',
        borderTopWidth: 0,
        backgroundImage: `url(${progressDashesVerticalSVG})`,
        backgroundRepeat: 'repeat-x',
    },
};

export default MuiStepConnector;
