import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
    Column,
    Table,
} from 'react-virtualized';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import MuiTable from '../MuiTable/MuiTable';

class MuiVirtualisedTable extends MuiTable {
    /**
     * Render the checkbox in the cell
     *
     * @param  {String} id
     * @return {node}
     */
    renderBodyCheckbox = id => (<Checkbox checked={this.isSelected(id)} />)

    checkResolving = () => {
        const { resolving } = this.props;
        return resolving;
    }

    /**
     * Render the table cell
     *
     * @param  {String} options.cellData
     * @param  {Number} options.columnIndex
     * @param  {Array} columns
     * @return {Node}
     */
    cellRenderer = ({ cellData, columnIndex = null }, columns) => {
        const { classes, rowHeight } = this.props;
        return (
            <TableCell
                component="div"
                className={classes.tableCell}
                variant="body"
                style={{ height: rowHeight }}
            >
                {this.renderCell(cellData, columnIndex, columns, '')}
            </TableCell>
        );
    };

    /**
     * Render the header cell
     *
     * @param  {String} options.label
     * @param  {Number} options.columnIndex
     * @param  {String} options.dataKey
     * @param  {String} options.sortBy
     * @param  {String} options.sortDirection
     * @param  {Array} columns
     * @return {Node}
     */
    headerRenderer = ({
        columnIndex,
    }, columns) => {
        const { headerHeight, classes } = this.props;
        const column = columns[columnIndex];
        return (
            <TableCell
                component="div"
                className={classes.tableCell}
                variant="head"
                style={{ height: headerHeight }}
            >
                {this.state.selectableRows && columnIndex === 0 ? this.renderHeaderCheckbox() : this.renderHeaderCell(column)}
            </TableCell>
        );
    };

    render() {
        const {
            classes,
            data,
            columns,
            resolving,
        } = this.props;
        const { selectableRows, selectedRows: { selected, uidIndex } } = this.state;
        // Clone the rows to stop props updating with array unshift
        // Process the rows and hide the cells where the column is set to hidden
        const rows = this.processRows(_.cloneDeep(data), columns).map((row) => {
            // If the state is selectableRows then add the checkbox cell
            if (selectableRows) {
                row.unshift(row[uidIndex]);
            }
            return row.filter((cell, cellIndex) => !_.hasIn(columns[cellIndex], 'options.hide'));
        });
        // Remove the columns where set to hide
        const visibleColumns = columns.filter(column => !_.hasIn(column, 'options.hide'));
        // If the state is selectableRows then add the checkbox cell to column header
        if (selectableRows) {
            visibleColumns.unshift({
                name: 'checkbox',
                checkbox: true,
                label: '',
                options: { width: 70, customBodyRender: this.renderBodyCheckbox },
            });
        }
        // Set the default row height
        const rowHeight = 48;
        // Set the width based on the column width
        const tableWidth = _.sumBy(columns, column => column.options.width || 145);
        // Set the height based on the amount of rows or default height
        const tableHeight = rowHeight * 8;

        return (
            <div className={this.props.overflowClass}>
                {!resolving
                    && (
                        <Table
                            className={classes.table}
                            height={tableHeight}
                            width={tableWidth}
                            headerHeight={rowHeight}
                            headerClassName={classes.header}
                            rowHeight={rowHeight}
                            rowCount={rows.length}
                            rowGetter={(rowProps => rows[rowProps.index])}
                            rowClassName={
                                ({ index }) => {
                                // If this is the header row
                                    if (index === -1) {
                                        return classes.headerRow;
                                    }
                                    // If the row is selected then highlight row
                                    return (selected.indexOf(rows[index][uidIndex]) !== -1 ? [classes.rowSelected, classes.row] : classes.row);
                                }
                            }
                            onRowClick={rowEvent => (selectableRows ? this.handleRowSelectClick(rowEvent.event, rowEvent.rowData[uidIndex]) : null)}
                        >
                            {visibleColumns
                                .map(({
                                    className, ...other
                                }, index) => (
                                    <Column
                                        key={other.name}
                                        headerRenderer={headerProps => this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        }, visibleColumns)
                                        }
                                        flexGrow={1}
                                        width={other.options.width}
                                        className={classes.flexContainer}
                                        cellDataGetter={({ rowData, dataKey }) => rowData[dataKey]}
                                        cellRenderer={bodyProps => this.cellRenderer(bodyProps, visibleColumns)}
                                        dataKey={index}
                                        {...other}
                                    />
                                ))
                            }
                        </Table>
                    )
                }
                {resolving && <CircularProgress color="secondary" className={classes.loader} />}
            </div>
        );
    }
}

const styles = (theme) => {
    const tableOverides = theme.overrides.MuiTableRow ? theme.overrides.MuiTableRow : { root: {}, head: {} };
    delete tableOverides['&$selected'];
    return {
        table: {
            fontFamily: theme.typography.fontFamily,
            marginTop: theme.spacing(2),
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            ...tableOverides.root,
            '&.ReactVirtualized__Table__headerRow': {
                backgroundColor: theme.palette.common.white,
                '&:hover': {
                    backgroundColor: theme.palette.common.white,
                },
            },
            cursor: 'pointer',
            outline: 'none',
        },
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
        },
        headerRow: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            ...tableOverides.head,
            outline: 'none',
        },
        tableCell: {
            flex: 1,
        },
        header: {
            overflow: 'hidden',
        },
        rowSelected: {
            backgroundColor: `${theme.palette.common.blueLight} !important`,
        },
        loader: {
            display: 'block',
            margin: `${theme.spacing(4)}px auto`,
            textAlign: 'center',
        },
    };
};

export default withStyles(styles)(MuiVirtualisedTable);
