import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

let toggleLoaderFn;

class Loader extends React.Component {
    state = {
        visible: false,
    };

    componentDidMount() {
        toggleLoaderFn = this.toggleLoader;
    }

    toggleLoader = (visible) => {
        this.setState({
            visible,
        });
    };

    render() {
        const { classes, show } = this.props;
        const { visible } = this.state;

        return visible || show ? (
            <LinearProgress color="secondary" className={classes.root} />
        ) : null;
    }
}

const styles = () => ({
    root: {
        position: 'fixed',
        top: 0,
        width: '100%',
        left: 0,
    },
});

export function toggleLoader(visible) {
    toggleLoaderFn(visible);
}

Loader.defaultProps = {
    show: false,
};

Loader.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    show: PropTypes.bool,
};

export default withStyles(styles)(Loader);
