import * as Immutable from 'immutable';
import {
    EMPLOYER_DASHBOARD_REQUESTED,
    EMPLOYER_DASHBOARD_RESOLVED,
    EMPLOYER_DASHBOARD_ERROR,
} from '../actions/employer/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    dashboard: null,
};

const initialState = Immutable.Map(schema);
/**
 * The employer dashboard reducer - responsible for managing the state of the employer data
 * @param {Object} state
 * @param {Object} action
 */
const employerDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
    case EMPLOYER_DASHBOARD_REQUESTED:
        return state.set('resolving', true);

    case EMPLOYER_DASHBOARD_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('dashboard', action.payload);

    case EMPLOYER_DASHBOARD_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('dashboard', null);

    default:
        return state;
    }
};

export { initialState };

export default employerDashboardReducer;
