import getStatusQuery from '../../services/graphQL/status/getStatusQuery';
import {
    STATUS_REQUESTED,
    STATUS_RESOLVED,
    STATUS_ERROR,
} from './types';

/**
 * Action helper which resolves the status of the api and components to the redux store
 */

const getStatusAction = async () => async (dispatch, getState) => {
    const { status } = getState();

    if (status.get('resolving')) return false;

    dispatch({
        type: STATUS_REQUESTED,
    });

    try {
        const payload = await getStatusQuery();
        return dispatch({
            type: STATUS_RESOLVED,
            payload,
        });
    } catch (error) {
        return dispatch({
            type: STATUS_ERROR,
        });
    }
};

export default getStatusAction;
