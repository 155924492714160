export const ADD_COMPANY_MEMBERS_REQUESTED = 'ADD_COMPANY_MEMBERS_REQUESTED';
export const ADD_COMPANY_MEMBERS_RESOLVED = 'ADD_COMPANY_MEMBERS_RESOLVED';
export const ADD_COMPANY_MEMBERS_ERROR = 'ADD_COMPANY_MEMBERS_ERROR';
export const ADD_COMPANY_MEMBERS_RESET = 'ADD_COMPANY_MEMBERS_RESET';

export const ADD_COMPANY_MEMBERS_UPLOAD_STATUS_REQUESTED = 'ADD_COMPANY_MEMBERS_UPLOAD_STATUS_REQUESTED';
export const ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED = 'ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED';
export const ADD_COMPANY_MEMBERS_UPLOAD_STATUS_ERROR = 'ADD_COMPANY_MEMBERS_UPLOAD_STATUS_ERROR';

export const ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_REQUESTED = 'ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_REQUESTED';
export const ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_RESOLVED = 'ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_RESOLVED';
export const ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_ERROR = 'ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_ERROR';
