// import variables from '../variables';

const MuiPickers = {
    // MuiPickersToolbar: {
    //     toolbar: {
    //         backgroundColor: variables.colors.black,
    //     },
    // },
    // MuiPickersYear: {
    //     root: {
    //         '&$selected': {
    //             color: variables.colors.blue,
    //         },
    //     },
    // },
    // MuiPickersMonth: {
    //     root: {
    //         '&$selected': {
    //             color: variables.colors.blue,
    //         },
    //     },
    // },
    // MuiPickersDay: {
    //     isSelected: {
    //         backgroundColor: variables.colors.blue,
    //         '&:hover': {
    //             backgroundColor: variables.colors.blue,
    //         },
    //     },
    //     current: {
    //         color: variables.colors.grey,
    //     },
    // },
    // MuiPickersModal: {
    //     dialogAction: {
    //         color: variables.colors.greyDark,
    //         '&:last-child': {
    //             color: variables.colors.orange,
    //         },
    //     },
    // },
};

export default MuiPickers;
