import { connect } from 'react-redux';
import MenuBar from './MenuBar';
import whatsNewService from '../../services/whats-new/whats-new';

const getTitleByLocation = (path) => {
    switch (path) {
    case '/register': return 'Joining the HealthyHub';
    default: return null;
    }
};

const mapStateToProps = state => ({
    title: getTitleByLocation(state.router.location.pathname),
    isAuthenticated: state.auth.get('authenticated'),
    isRegistering: state.router.location.pathname && state.router.location.pathname.match(/\/register\/account/) !== null,
    isRegistered: state.member.getIn(['member', 'status']) === 'enabled' || state.companyContact.getIn(['contact', 'status']) === 'enabled',
    role: state.auth.getIn(['authentication', 'role']),
    member: state.member.get('member'),
    companyContact: state.companyContact.get('contact'),
    resolving: state.member.get('resolving'),
    highlightWhatsNew: !whatsNewService.hasReadLatest(state.whatsNew.get('articles')),
});

export default connect(mapStateToProps)(MenuBar);
