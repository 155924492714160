import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/lab/Slider';

const MuiSlider = ({
    classes,
    value,
    min,
    max,
    step,
    onChange,
    marks,
    hideThumb,
    tooltip,
    animate,
    showValue,
    defaultValue,
    answers,
}) => {
    const handleChange = (e, sliderValue) => {
        if (value !== sliderValue) {
            onChange(sliderValue);
        }
    };

    const valueDisplay = (valueNow) => {
        const answer = answers[valueNow];
        if (showValue) {
            return (
                <div className={classes.valueDisplay}>
                    {answer ? answer.label : valueNow}
                </div>
            );
        }
        return null;
    };

    return (
        <div className={`${classes.root} ${hideThumb ? classes.hideThumb : null}`}>
            <Slider
                className={`${classes.slider} aut-muiSlider_slider`}
                classes={animate ? { track: classes.track } : {}}
                min={min}
                max={max}
                step={step}
                onChangeCommitted={(e, sliderValue) => handleChange(e, sliderValue)}
                marks={marks}
                value={value}
                defaultValue={defaultValue}
                ThumbComponent={thumbProps => (
                    <>
                        {valueDisplay(thumbProps['aria-valuenow'])}
                        <span {...thumbProps}>
                            <span />
                            <span />
                            <span />
                        </span>
                    </>
                )}
            />
            {tooltip ? (
                <div className={`${classes.tooltip} ${animate ? classes.tooltipAnimation : ''}`} style={{ left: `${((value / max) * 100)}%` }}>
                    {tooltip}
                </div>
            ) : null}
        </div>
    );
};

const styles = theme => ({
    root: ({ showValue }) => ({
        width: '100%',
        position: 'relative',
        paddingTop: showValue ? 50 : 0,
    }),
    slider: {
        width: '100%',
    },
    track: {
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    tooltip: {
        position: 'absolute',
        backgroundColor: theme.palette.common.greyDark,
        fontSize: 14,
        borderRadius: 20,
        padding: '3px 10px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        width: 90,
        marginLeft: -57,
        '&:before': {
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            position: 'absolute',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: `5px solid ${theme.palette.common.greyDark}`,
            left: 'calc(50% - 2.5px)',
            top: -5,
            textTransform: 'uppercase',
        },
    },
    tooltipAnimation: {
        transition: theme.transitions.create('left', {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    hideThumb: {
        '& [role="slider"]': {
            display: 'none',
        },
        '& > span > span:first-child': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'inset 1px 1px 7px -3px rgba(0,0,0,0.36)',
        },
        '& > span > span:nth-child(2)': {
            background: theme.palette.background.gradient,
        },
    },
    valueDisplay: {
        position: 'absolute',
        top: -50,
        fontFamily: theme.typography.h1.fontFamily,
        fontWeight: 400,
        fontSize: 30,
        color: theme.palette.common.black,
        width: '100%',
        textAlign: 'center',
    },
});

MuiSlider.defaultProps = {
    value: null,
    min: null,
    max: null,
    step: null,
    hideThumb: false,
    marks: [],
    tooltip: null,
    animate: false,
    showValue: false,
    defaultValue: null,
    answers: [],
};

MuiSlider.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    hideThumb: PropTypes.bool,
    marks: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    tooltip: PropTypes.string,
    animate: PropTypes.bool,
    showValue: PropTypes.bool,
    answers: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
    })),
};

export default withStyles(styles)(MuiSlider);
