import store from '../store';
import { getEnvironmentVariable } from '../environment/environment-service';

const session = {};
/**
 * Get a token from the state
 *
 * @param  {String} key
 * @return {String}
 */
session.getStoreToken = key => store.getState().auth.getIn(['authentication', key]);

/**
 * Get a token from local storage
 *
 * @param  {String} key
 * @return {String}
 */
session.getLocalStorageToken = key => localStorage.getItem(key);

/**
 * Set a token in localstorage
 *
 * @param  {String} key
 * @param  {String} token
 */
session.setLocalStorageToken = (key, token) => {
    localStorage.setItem(
        key,
        token,
    );
};

/**
 * Remove a token from localstorage
 *
 * @param  {String} key
 * @return {Bool}
 */
session.removeLocalStorageToken = key => localStorage.removeItem(key);

/**
 * Get the auth token from the store or local storage
 *
 * @return {String}
 */
session.getAuthToken = () => {
    const storeToken = session.getStoreToken(getEnvironmentVariable('REACT_APP_AUTH_TOKEN_STORAGE_KEY'));
    if (!storeToken) {
        return session.getLocalStorageToken(getEnvironmentVariable('REACT_APP_AUTH_TOKEN_STORAGE_KEY'));
    }
    return storeToken;
};

export default session;
