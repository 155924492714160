import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
    root: {
        border: `1px solid ${theme.palette.common.grey}`,
        height: 10,
        borderRadius: 15,
    },
    colorPrimary: {
        backgroundColor: theme.palette.common.white,
    },
    barColorPrimary: {
        background: theme.palette.background.gradient,
    },
});

export default withStyles(styles)(LinearProgress);
