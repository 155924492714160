import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import helpPersonSVG from '../../../assets/images/questionnaires/help-person.svg';

const QuestionnaireMessage = ({ classes, message, title }) => (
    <Card elevation={0} className={classes.card}>
        <CardContent className={classes.content}>
            <div
                dangerouslySetInnerHTML={{ __html: message }} // eslint-disable-line react/no-danger
            />
            {' '}
            <img src={helpPersonSVG} alt={title} className={classes.image} />
        </CardContent>
    </Card>
);

const styles = theme => ({
    box: {
        marginBottom: 80,
    },
    card: {
        marginTop: theme.spacing(8),
        position: 'relative',
        border: `1px solid ${theme.palette.common.green}`,
        boxShadow: theme.shadows[25],
        borderRadius: 8,
        overflow: 'visible',
        marginBottom: -80,
        zIndex: 10,
        '&:after': {
            content: '""',
            position: 'absolute',
            left: 'calc(50% - 12px)',
            bottom: -10,
            width: 20,
            height: 20,
            backgroundColor: theme.palette.common.green,
            borderRadius: '50%',
        },
    },
    content: {
        fontSize: 20,
        paddingLeft: theme.spacing(6),
        paddingRight: 250,
        fontFamily: theme.typography.fontFamily,
        minHeight: 150,
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            fontSize: 14,
            '& > div > *:first-child': {
                paddingRight: 80,
                fontSize: 18,
            },
        },
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
    },
    image: {
        position: 'absolute',
        top: -50,
        right: 0,
        [theme.breakpoints.down('xs')]: {
            width: 100,
        },
    },
});

QuestionnaireMessage.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    message: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default withStyles(styles)(QuestionnaireMessage);
