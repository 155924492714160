export const EVENTS_REQUESTED = 'EVENTS_REQUESTED';
export const EVENTS_RESOLVED = 'EVENTS_RESOLVED';
export const EVENTS_ERROR = 'EVENTS_ERROR';

export const EVENT_REQUESTED = 'EVENT_REQUESTED';
export const EVENT_RESOLVED = 'EVENT_RESOLVED';
export const EVENT_ERROR = 'EVENT_ERROR';

export const EVENT_DELETE_REQUESTED = 'EVENT_DELETE_REQUESTED';
export const EVENT_DELETE_RESOLVED = 'EVENT_DELETE_RESOLVED';
export const EVENT_DELETE_ERROR = 'EVENT_DELETE_ERROR';

export const EVENT_CREATE_REQUESTED = 'EVENT_CREATE_REQUESTED';
export const EVENT_CREATE_RESOLVED = 'EVENT_CREATE_RESOLVED';
export const EVENT_CREATE_ERROR = 'EVENT_CREATE_ERROR';

export const EVENT_UPDATE_REQUESTED = 'EVENT_UPDATE_REQUESTED';
export const EVENT_UPDATE_RESOLVED = 'EVENT_UPDATE_RESOLVED';
export const EVENT_UPDATE_ERROR = 'EVENT_UPDATE_ERROR';

export const EVENT_RESEND_INVITE_REQUESTED = 'EVENT_RESEND_INVITE_REQUESTED';
export const EVENT_RESEND_INVITE_RESOLVED = 'EVENT_RESEND_INVITE_RESOLVED';
export const EVENT_RESEND_INVITE_ERROR = 'EVENT_RESEND_INVITE_ERROR';

export const MEMBER_EVENTS_REGISTER_INTEREST_REQUESTED = 'MEMBER_EVENTS_REGISTER_INTEREST_REQUESTED';
export const MEMBER_EVENTS_REGISTER_INTEREST_RESOLVED = 'MEMBER_EVENTS_REGISTER_INTEREST_RESOLVED';
export const MEMBER_EVENTS_REGISTER_INTEREST_ERROR = 'MEMBER_EVENTS_REGISTER_INTEREST_ERROR';

export const MEMBER_EVENTS_REGISTER_ATTENDING_REQUESTED = 'MEMBER_EVENTS_REGISTER_ATTENDING_REQUESTED';
export const MEMBER_EVENTS_REGISTER_ATTENDING_RESOLVED = 'MEMBER_EVENTS_REGISTER_ATTENDING_RESOLVED';
export const MEMBER_EVENTS_REGISTER_ATTENDING_ERROR = 'MEMBER_EVENTS_REGISTER_ATTENDING_ERROR';

export const MEMBER_EVENTS_UNREGISTER_INTEREST_REQUESTED = 'MEMBER_EVENTS_UNREGISTER_INTEREST_REQUESTED';
export const MEMBER_EVENTS_UNREGISTER_INTEREST_RESOLVED = 'MEMBER_EVENTS_UNREGISTER_INTEREST_RESOLVED';
export const MEMBER_EVENTS_UNREGISTER_INTEREST_ERROR = 'MEMBER_EVENTS_UNREGISTER_INTEREST_ERROR';

export const MEMBER_EVENTS_UNREGISTER_ATTENDING_REQUESTED = 'MEMBER_EVENTS_UNREGISTER_ATTENDING_REQUESTED';
export const MEMBER_EVENTS_UNREGISTER_ATTENDING_RESOLVED = 'MEMBER_EVENTS_UNREGISTER_ATTENDING_RESOLVED';
export const MEMBER_EVENTS_UNREGISTER_ATTENDING_ERROR = 'MEMBER_EVENTS_UNREGISTER_ATTENDING_ERROR';

export const MEMBER_EVENTS_RESET = 'MEMBER_EVENTS_RESET';
export const EVENT_RESET = 'EVENT_RESET';

export const EVENT_REGISTRATIONS_REQUESTED = 'EVENT_REGISTRATIONS_REQUESTED';
export const EVENT_REGISTRATIONS_RESOLVED = 'EVENT_REGISTRATIONS_RESOLVED';
export const EVENT_REGISTRATIONS_ERROR = 'EVENT_REGISTRATIONS_ERROR';
