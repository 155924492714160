import 'typeface-muli';
import './fonts/fonts.css';

const colors = {
    black: '#000000',
    blue: '#1EB5D8',
    blueLight: '#F5FBFD',
    blueDark: '#12bddb',
    green: '#5BECB8',
    orange: '#FF520B',
    red: '#BE002D',
    white: '#FFFFFF',
    greyLight: '#F8F8F8',
    grey: '#D9D9D9',
    greyDark: '#575756',
    yellow: '#F3D43B',
    yellowDark: '#FFA500', // '#EED235',
    pink: '#DC10C5',
    purple: '#A34D94',
    greyBlueDark: '#333745',
};

const themeColors = {
    primary: colors.orange,
    secondary: colors.blue,
    error: colors.red,
};

const text = {
    primary: colors.greyDark,
    secondary: colors.black,
    disabled: colors.grey,
    hint: colors.orange,
    headerColor: colors.black,
};

const font = {
    fontFamily: ['Muli', 'Open-Sans', 'sans-serif'],
    fontFamilyHeading: ['Cocon'],
};

const border = {
    borderRadius: '4px',
    borderWidth: '1px',
    borderColor: colors.grey,
    borderColorSelected: colors.blue,
    borderColorDisabled: colors.grey,
};

const button = {
    borderWidth: '2px',
    borderColor: colors.greyDark,
    boxShadow: 'none',
    backgroundColorActive: colors.greyDark,
    backgroundColorDisabled: colors.grey,
    colorActive: colors.grey,
};

const background = {
    backgroundColor: colors.white,
    backgroundColorDisabled: colors.grey,
    boxShadow: 'none',
};

const input = {
    borderRadius: border.borderRadius,
    borderWidth: border.borderWidth,
    borderWidthDisabled: border.borderWidth + 1,
    borderColor: border.borderColor,
    borderColorSelected: border.borderColorSelected,
    borderColorDisabled: border.borderColorDisabled,
    backgroundColor: colors.white,
    backgroundColorDisabled: colors.grey,
    colorDisabled: colors.white,
};

const stepper = {
    color: colors.grey,
    colorActive: colors.green,
    colorComplete: colors.green,
};

const variables = {
    background,
    border,
    button,
    colors,
    font,
    input,
    stepper,
    text,
    themeColors,
};

export default variables;
