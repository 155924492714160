import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireIntro from './FinancialWellbeingAssessmentIntro/FinancialWellbeingAssessmentIntro';
import QuestionnaireComplete from './FinancialWellbeingAssessmentComplete/FinancialWellbeingAssessmentComplete';
import piggyBankSVG from '../../assets/images/icons/piggy-bank-icon.svg';

const FinancialWellbeingAssessmentWrapper = (Questionnaire) => {
    const pageImages = [piggyBankSVG, piggyBankSVG];

    const Wrapper = ({ questionnaireId, step }) => (
        <Questionnaire
            questionnaireId={questionnaireId}
            introPage={QuestionnaireIntro}
            completePage={QuestionnaireComplete}
            pageImages={pageImages}
            step={step}
        />
    );

    Wrapper.defaultProps = {
        step: null,
    };

    Wrapper.propTypes = {
        questionnaireId: PropTypes.string.isRequired,
        step: PropTypes.string,
    };

    return Wrapper;
};

export default FinancialWellbeingAssessmentWrapper;
