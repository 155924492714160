const MOBILE_APP = {
    DEVICE: {
        WEB: 'DEVICE_WEB',
        APP: 'DEVICE_APP',
    },
    EVENTS: {
        MENU_SHOW: 'MENU_SHOW',
        BREEZE_GUIDE_READ: 'BREEZE_GUIDE_READ',
    },
    WINDOW_VARS: {
        IS_APP: 'isBreezeApp',
        SHOW_GUIDE: 'showBreezeGuide',
        REACT_NATIVE_WEB_VIEW: 'ReactNativeWebView',
    },
    COOKIES: {
        BREEZE_GUIDE_READ: 'BREEZE_GUIDE_READ',
    },
};

export default MOBILE_APP;
