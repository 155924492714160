import variables from '../variables';

const MuiAppBar = {
    root: {
        boxShadow: 'none',
        borderBottom: `1px solid ${variables.colors.grey}`,
        '& h6': {
            fontFamily: variables.font.fontFamilyHeading,
            fontSize: '30px',
            fontWeight: 'bold',
        },
    },
    colorDefault: {
        backgroundColor: variables.colors.white,
    },
};

export default MuiAppBar;
