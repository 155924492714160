export const SERVICES_REQUESTED = 'SERVICES_REQUESTED';
export const SERVICES_RESOLVED = 'SERVICES_RESOLVED';
export const SERVICES_ERROR = 'SERVICES_ERROR';

export const SERVICES_CREATE_ENQUIRY_REQUESTED = 'SERVICES_CREATE_ENQUIRY_REQUESTED';
export const SERVICES_CREATE_ENQUIRY_RESOLVED = 'SERVICES_CREATE_ENQUIRY_RESOLVED';
export const SERVICES_CREATE_ENQUIRY_ERROR = 'SERVICES_CREATE_ENQUIRY_ERROR';

export const SERVICES_CREATE_HISTORY_REQUESTED = 'SERVICES_CREATE_HISTORY_REQUESTED';
export const SERVICES_CREATE_HISTORY_RESOLVED = 'SERVICES_CREATE_HISTORY_RESOLVED';
export const SERVICES_CREATE_HISTORY_ERROR = 'SERVICES_CREATE_HISTORY_ERROR';

export const SERVICES_HISTORY_REQUESTED = 'SERVICES_HISTORY_REQUESTED';
export const SERVICES_HISTORY_RESOLVED = 'SERVICES_HISTORY_RESOLVED';
export const SERVICES_HISTORY_ERROR = 'SERVICES_HISTORY_ERROR';

export const SERVICES_COMPANY_PRODUCTS_REQUESTED = 'SERVICES_COMPANY_PRODUCTS_REQUESTED';
export const SERVICES_COMPANY_PRODUCTS_RESOLVED = 'SERVICES_COMPANY_PRODUCTS_RESOLVED';
export const SERVICES_COMPANY_PRODUCTS_ERROR = 'SERVICES_COMPANY_PRODUCTS_ERROR';
