import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MuiSecondaryButton = (props) => {
    const { children, classes, shouldFloatRight, ...other } = props;
    return (
        <Button
            className={shouldFloatRight ? classes.floatButtonRight : classes.root}
            variant="outlined"
            color="primary"
            {...other}
        >
            {children}
        </Button>
    );
};

const styles = theme => ({
    root: {
        height: 45,
        marginTop: 10,
        marginBottom: 10,
    },
    floatButtonRight: {
        height: 45,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.up('lg')]: {
            width: 350,
            marginTop: 25,
            marginLeft: 250,
            position: 'absolute',
        },
    }
});

MuiSecondaryButton.defaultProps = {
    children: null,
    shouldFloatRight: false,
};

MuiSecondaryButton.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node,
    shouldFloatRight: PropTypes.bool,
};

export default withStyles(styles)(MuiSecondaryButton);
