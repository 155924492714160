import { gql } from '@apollo/client';
import getContent from '../../content/getContent';
import graphQLCLient from '../client';
import errorHandler from '../errorHandler';

const getStatusQuery = async () => {
    const client = graphQLCLient();

    const query = gql`{
        getStatus {
            component
            plannedStatus
            unPlannedStatus
            message
        }
    }`;

    return new Promise((resolve, reject) => {
        client.query({ query })
            .then((result) => {
                if (!result.data || !Object.prototype.hasOwnProperty.call(result.data, 'getStatus')) {
                    throw new Error(getContent(['errors', 'invalid-graphQL-response']));
                }

                resolve(result.data.getStatus);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};

export default getStatusQuery;
