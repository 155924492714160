import variables from '../variables';

const MuiOutlinedInput = {
    root: {
        backgroundColor: variables.input.backgroundColor,
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderWidth: variables.input.borderWidth,
            borderColor: variables.input.borderColor,
        },
        '&$focused $notchedOutline': {
            borderWidth: variables.input.borderWidth,
            borderColor: variables.input.borderColorSelected,
        },
        '&$disabled $notchedOutline': {
            borderColor: variables.input.borderColorDisabled,
        },
        '&$disabled': {
            backgroundColor: variables.input.backgroundColorDisabled,
            color: variables.input.colorDisabled,
        },
        '&$error $notchedOutline': {
            borderWidth: '2px',
        },
    },
};

export default MuiOutlinedInput;
