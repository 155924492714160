import * as Immutable from 'immutable';
import {
    MEMBER_THEME_TREND_REQUESTED,
    MEMBER_THEME_TREND_RESOLVED,
    MEMBER_THEME_TREND_ERROR,
    MEMBER_CLEAR_THEME_TREND,
} from '../actions/member/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    themeIds: null,
    trends: null,
    title: null,
    assessment: null,
};

const initialState = Immutable.Map(schema);
/**
 * The member tracker theme trend reducer - responsible for managing a member theme trend and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const memberThemeTrendReducer = (state = initialState, action) => {
    switch (action.type) {
    case MEMBER_THEME_TREND_REQUESTED:
        return state.set('resolving', true);

    case MEMBER_THEME_TREND_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('themeIds', action.payload.themeIds)
            .set('title', action.payload.title)
            .set('trends', action.payload.trends)
            .set('assessment', action.payload.assessment);

    case MEMBER_THEME_TREND_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('themeIds', action.payload.themeIds)
            .set('title', action.payload.title)
            .set('trends', null)
            .set('assessment', action.payload.assessment);

    case MEMBER_CLEAR_THEME_TREND:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('themeIds', null)
            .set('title', null)
            .set('trends', null)
            .set('assessment', null);

    default:
        return state;
    }
};

export { initialState };

export default memberThemeTrendReducer;
