import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import variables from '../../../theme/variables';

const MuiSwitch = ({
    classes, checked, className, disabled, labelOn, labelOff, onChange, name,
}) => (
    <div
        role="button"
        tabIndex={0}
        className={`${classes.switch} ${className} ${(checked ? classes.switchOn : '')} ${(disabled ? classes.switchDisabled : '')}`}
        onClick={e => (!disabled ? onChange(e) : null)}
        onKeyDown={e => (!disabled && e.key === 'Enter' ? onChange(e) : null)}
    >
        <svg
            height="25"
            width="25"
            className={`${classes.switchToggle} ${(checked ? classes.switchToggleOn : '')} ${(disabled ? classes.switchToggleDisabled : '')}`}
        >
            <circle cx="12.5" cy="12.5" r="12.5" fill={`${disabled ? variables.colors.grey : variables.colors.white}`} />
        </svg>
        {labelOn || labelOff
            ? (
                <Typography
                    variant="body2"
                    align={checked ? 'left' : 'right'}
                    className={`${classes.switchLabel} ${(checked ? classes.switchLabelOn : classes.switchLabelOff)}`}
                >
                    {checked ? labelOn : labelOff}
                </Typography>
            ) : null
        }
        <input
            type="checkbox"
            name={name}
            id={name}
            className={classes.switchCheckbox}
            defaultChecked={!!checked}
            disabled={disabled}
        />
    </div>
);

const styles = theme => ({
    switch: {
        backgroundColor: theme.palette.common.greyDark,
        borderRadius: 20,
        height: 33,
        width: 80,
        minWidth: 80,
        position: 'relative',
        cursor: 'pointer',
        transition: theme.transitions.create('background', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
        margin: theme.spacing(1.5),
    },
    switchOn: {
        backgroundColor: theme.palette.common.blue,
    },
    switchDisabled: {
        border: `solid 2px ${theme.palette.common.grey}`,
        backgroundColor: 'transparent',
        cursor: 'default',
    },
    switchToggle: {
        position: 'absolute',
        top: '50%',
        marginTop: -12.5,
        left: 'calc(0% + 5px)',
        transition: theme.transitions.create('left', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
        filter: 'drop-shadow(-3px 0 1px rgba(0, 0, 0, .1))',
    },
    switchToggleOn: {
        left: 'calc(100% - 30px)',
    },
    switchToggleDisabled: {
        filter: 'none',
    },
    switchLabel: {
        fontWeight: 'bold',
        paddingTop: 6,
    },
    switchLabelOn: {
        color: theme.palette.common.white,
        marginLeft: 10,
    },
    switchLabelOff: {
        color: theme.palette.common.white,
        marginRight: 10,
    },
    switchCheckbox: {
        top: 0,
        left: 0,
        width: '100%',
        cursor: 'inherit',
        height: '100%',
        margin: 0,
        opacity: 0,
        padding: 0,
        position: 'absolute',
    },
});

MuiSwitch.defaultProps = {
    checked: false,
    className: '',
    disabled: false,
};

MuiSwitch.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    checked: PropTypes.bool,
    labelOn: PropTypes.string.isRequired,
    labelOff: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default withStyles(styles)(MuiSwitch);
