import _ from 'lodash';
import updateMemberNotificationPreferencesQuery from '../../services/graphQL/member/updateMemberNotificationPreferencesQuery';
import {
    MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED,
    MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED,
    MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR,
} from './types';

/**
 * Action helper which updated and resolves the member to the redux store
 */
const updateMemberNotificationPreferencesAction = async notifications => async (dispatch, getState) => {
    const { member } = getState();

    if (member.get('resolving')) return false;

    dispatch({
        type: MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED,
    });

    try {
        const payload = await updateMemberNotificationPreferencesQuery(_.omit(notifications, ['marketingAll']));
        return dispatch({
            type: MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED,
            payload,
        });
    } catch (error) {
        dispatch({
            type: MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR,
            payload: error,
        });
        throw error;
    }
};

export default updateMemberNotificationPreferencesAction;
