import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';

export const initialState = fromJS({
    currentLocation: '',
    previousLocation: '',
});

const locationHistoryReducer = (state = initialState, action = {}) => {
    if (!action.type) return state;
    switch (action.type) {
    case LOCATION_CHANGE:
        return state.set('previousLocation', state.get('currentLocation')).set('currentLocation', action.payload.location.pathname);
    default:
        return state;
    }
};

export default locationHistoryReducer;
