export const GP_BOOKING_APPOINTMENT_SLOTS_REQUESTED = 'GP_BOOKING_APPOINTMENT_SLOTS_REQUESTED';
export const GP_BOOKING_APPOINTMENT_SLOTS_RESOLVED = 'GP_BOOKING_APPOINTMENT_SLOTS_RESOLVED';
export const GP_BOOKING_APPOINTMENT_SLOTS_ERROR = 'GP_BOOKING_APPOINTMENT_SLOTS_ERROR';

export const GP_FUTURE_APPOINTMENTS_REQUESTED = 'GP_FUTURE_APPOINTMENTS_REQUESTED';
export const GP_FUTURE_APPOINTMENTS_RESOLVED = 'GP_FUTURE_APPOINTMENTS_RESOLVED';
export const GP_FUTURE_APPOINTMENTS_ERROR = 'GP_FUTURE_APPOINTMENTS_ERROR';

export const GP_BOOKING_CONSULTATION_DETAILS_SET = 'GP_BOOKING_CONSULTATION_DETAILS_SET';
export const GP_BOOKING_RESET = 'GP_BOOKING_RESET';

export const GP_BOOKING_HOLD_APPOINTMENT_SLOT_REQUESTED = 'GP_BOOKING_HOLD_APPOINTMENT_SLOT_REQUESTED';
export const GP_BOOKING_HOLD_APPOINTMENT_SLOT_RESOLVED = 'GP_BOOKING_HOLD_APPOINTMENT_SLOT_RESOLVED';
export const GP_BOOKING_HOLD_APPOINTMENT_SLOT_ERROR = 'GP_BOOKING_HOLD_APPOINTMENT_SLOT_ERROR';

export const GP_BOOKING_BOOK_APPOINTMENT_REQUESTED = 'GP_BOOKING_BOOK_APPOINTMENT_REQUESTED';
export const GP_BOOKING_BOOK_APPOINTMENT_RESOLVED = 'GP_BOOKING_BOOK_APPOINTMENT_RESOLVED';
export const GP_BOOKING_BOOK_APPOINTMENT_ERROR = 'GP_BOOKING_BOOK_APPOINTMENT_ERROR';

export const GP_BOOKING_REMOVE_APPOINTMENT_SLOT = 'GP_BOOKING_REMOVE_APPOINTMENT_SLOT';

export const GP_BOOKING_CANCEL_BOOKING_REQUESTED = 'GP_BOOKING_CANCEL_BOOKING_REQUESTED';
export const GP_BOOKING_CANCEL_BOOKING_RESOLVED = 'GP_BOOKING_CANCEL_BOOKING_RESOLVED';
export const GP_BOOKING_CANCEL_BOOKING_ERROR = 'GP_BOOKING_CANCEL_BOOKING_ERROR';
