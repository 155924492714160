import whatsNewService from '../../services/whats-new/whats-new';
import {
    WHATSNEW_ARTICLES_REQUESTED,
    WHATSNEW_ARTICLES_RESOLVED,
    WHATSNEW_ARTICLES_ERROR,
} from './types';

/**
 * Action helper which resolves the status of the api and components to the redux store
 */

const getArticlesAction = async () => async (dispatch, getState) => {
    const { status } = getState();

    if (status.get('resolving')) return false;

    dispatch({
        type: WHATSNEW_ARTICLES_REQUESTED,
    });

    try {
        const payload = await whatsNewService.getArticles('/static/whats-new/articles.json');
        return dispatch({
            type: WHATSNEW_ARTICLES_RESOLVED,
            payload,
        });
    } catch (error) {
        dispatch({
            type: WHATSNEW_ARTICLES_ERROR,
        });
        throw error;
    }
};

export default getArticlesAction;
