import * as Immutable from 'immutable';
import {
    MEMBER_DASHBOARD_REQUESTED,
    MEMBER_DASHBOARD_RESOLVED,
    MEMBER_DASHBOARD_ERROR,
    MEMBER_DASHBOARD_HIDE_CONSENT,
} from '../actions/member/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    dashboard: null,
    hideConsent: false,
};

const initialState = Immutable.Map(schema);
/**
 * The member dasboard reducer - responsible for managing a member's dashboard and the state of the dashboard data
 * @param {Object} state
 * @param {Object} action
 */
const memberDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
    case MEMBER_DASHBOARD_REQUESTED:
        return state.set('resolving', true);

    case MEMBER_DASHBOARD_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('dashboard', action.payload);

    case MEMBER_DASHBOARD_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('dashboard', null);

    case MEMBER_DASHBOARD_HIDE_CONSENT:
        return state
            .set('hideConsent', true);

    default:
        return state;
    }
};

export { initialState };

export default memberDashboardReducer;
