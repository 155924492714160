import {
    ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED,
} from './types';

const onCompanyMembersUploadStatus = payload => ({
    type: ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED,
    payload,
});

export default onCompanyMembersUploadStatus;
