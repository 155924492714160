import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import getContent from '../../../services/content/getContent';
import { URLS } from '../../../config/config';

const AuthorLink = ({
    classes,
    author,
}) => {
    const { location } = useHistory();
    const articleLinkPrefix = location.pathname.includes('admin') ? URLS.CONTACT.HOME : '';
    return (
        <Typography
            variant="caption"
            color="textSecondary"
            className={classes.root}
            component="p"
        >
            {getContent(['components', 'healthyBites', 'author', 'label'])}
            {' '}
            <Link
                component="a"
                href={`${articleLinkPrefix}${URLS.MEMBER.ARTICLES}/authors/${author.id}`}
                color="textSecondary"
            >
                <strong>{author.name}</strong>
            </Link>
        </Typography>
    );
};

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
});

AuthorLink.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    author: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(AuthorLink);
