import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import MenuBar from '../../menu-bar/container';

const Page = ({ classes, children, logout }) => (
    <Box className={classes.root}>
        <MenuBar logout={logout} />
        {children}
    </Box>
);

const styles = theme => ({
    root: {
        paddingTop: 64,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 48,
        },
    },
});

Page.defaultProps = {
    logout: null,
};

Page.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node.isRequired,
    logout: PropTypes.func,
};

export default withStyles(styles)(Page);
