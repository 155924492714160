import React, {
    lazy,
    Suspense,
    useState,
    useContext,
} from 'react';

import { Route, Switch } from 'react-router-dom';
import { URLS } from '../../../config/config';
import ContactFooter from '../../footer/ContactFooter/ContactFooter';
import StaticLoader from '../../loader/StaticLoader/StaticLoader';
import UploadNotification from '../../upload-notification/container';
import Notifier from '../../notifier/Notifier';
import Favourites from '../../favourites/Favourites';
import IDMContext from '../IDMContext';
import UserMsal from '../../azure/UserMsal';
import ContactWrapper from '../../azure/Contact/ContactWrapper';
import {
    getMsalSignInConfig,
    getMsalPasswordResetConfig,
    getMsalRequest,
} from '../../azure/Contact/contact-msal-auth-config';
import DeviceContext from '../../../context/device-context';
import MobileAppService from '../../../services/mobile-app/mobile-app';

const CompanyMembers = lazy(() => import('../../company-members/CompanyMembers'));
const CompanyContactAccount = lazy(() => import('../../company-contact-account/CompanyContactAccount'));
const EmployerDashboard = lazy(() => import('../../employer-dashboard/container'));
const EmployerTracker = lazy(() => import('../../employer-tracker/container'));
const Events = lazy(() => import('../../events/Events'));
const Register = lazy(() => import('../ContactRegistrationRoutes/ContactRegistrationRoutes'));
const CompanyServices = lazy(() => import('../../company-services/container'));
const HelpSupport = lazy(() => import('../../help-support/HelpSupport'));
const TermsConditions = lazy(() => import('../../terms-conditions/TermsConditions'));
const PageNotFound = lazy(() => import('../../page-not-found/PageNotFound'));
const System = lazy(() => import('../../system/System'));
const AccountLocked = lazy(() => import('../../account-locked/AccountLocked'));
const HealthyBites = lazy(() => import('../../healthy-bites/ContactHealthyBites/ContactHealthyBites'));
const CookiePolicy = lazy(() => import('../../cookie-policy/CookiePolicy'));
const WhatsNew = lazy(() => import('../../whats-new/container'));
const HereToHelp = lazy(() => import('../../here-to-help/HereToHelp'));

const ContactRoutes = () => {
    const [authProviderConfig, setAuthProviderConfig] = useState(null);
    const device = useContext(DeviceContext);
    const platform = MobileAppService.isMobileApp(device) ? 'App' : 'Browser';

    return (
        <IDMContext.Provider value={{ authProviderConfig, setAuthProviderConfig }}>
            <Suspense fallback={<StaticLoader />}>
                <Notifier />
                <Switch>
                    <Route path={URLS.CONTACT.REGISTER.ROOT} render={() => <Register />} />
                    <Route exact path={URLS.CONTACT.ACCOUNT_LOCKED} render={() => <AccountLocked />} />
                    <IDMContext.Consumer>
                        {authProvider => (
                            <UserMsal
                                authProvider={authProvider}
                                config={getMsalSignInConfig()}
                                resetConfig={getMsalPasswordResetConfig()}
                                request={getMsalRequest(platform)}
                                UserWrapper={ContactWrapper}
                            >
                                <Favourites />
                                <UploadNotification />
                                <Switch>
                                    <Route exact path={URLS.CONTACT.HOME} render={() => <EmployerDashboard />} />
                                    <Route path={URLS.CONTACT.EMPLOYEES} render={() => <CompanyMembers />} />
                                    <Route path={URLS.CONTACT.ARTICLES} render={() => <HealthyBites />} />
                                    <Route path={URLS.CONTACT.EVENTS} render={() => <Events />} />
                                    <Route exact path={URLS.CONTACT.DASHBOARD} render={() => <EmployerTracker />} />
                                    <Route
                                        path={URLS.CONTACT.ADMIN.ROOT}
                                        render={(routeProps) => {
                                            const { location: { pathname } } = routeProps;
                                            return <System route={pathname} />;
                                        }}
                                    />
                                    <Route
                                        path={URLS.CONTACT.ACCOUNT.ROOT}
                                        render={(routeProps) => {
                                            const { location: { pathname } } = routeProps;
                                            return <CompanyContactAccount route={pathname} />;
                                        }}
                                    />
                                    <Route path={URLS.CONTACT.SERVICES} render={() => <CompanyServices />} />
                                    <Route exact path={URLS.CONTACT.HERETOHELP} render={() => <HereToHelp />} />
                                    <Route exact path={URLS.CONTACT.HELP} render={() => <HelpSupport isAdmin />} />
                                    <Route exact path={URLS.CONTACT.WHATS_NEW} render={() => <WhatsNew />} />
                                    <Route exact path={URLS.CONTACT.TERMS} render={() => <TermsConditions isAdmin />} />
                                    <Route exact path={URLS.CONTACT.COOKIES} render={() => <CookiePolicy />} />
                                    <Route component={PageNotFound} />
                                </Switch>
                                <ContactFooter />
                            </UserMsal>
                        )}
                    </IDMContext.Consumer>
                </Switch>
            </Suspense>
        </IDMContext.Provider>
    );
};

export default ContactRoutes;
