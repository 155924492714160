import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';

let openSnackbarFn;
let closeSnackbarFn;

class Notifier extends React.Component {
    state = {
        open: false,
        message: '',
        action: null,
        autoHideDuration: null,
    };

    componentDidMount() {
        openSnackbarFn = this.openSnackbar;
        closeSnackbarFn = this.handleSnackbarClose;
    }

    openSnackbar = ({
        message, variant, action, stopHide,
    }) => {
        this.setState({
            open: true,
            message,
            variant,
            action,
            autoHideDuration: stopHide ? null : 5000,
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            open: false,
            message: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            message,
            open,
            variant,
            action,
            autoHideDuration,
        } = this.state;

        const messageText = (
            <span id="snackbar-message-id">
                {message}
            </span>
        );

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={autoHideDuration}
                onClose={this.handleSnackbarClose}
                open={open}
                ContentProps={{
                    'aria-describedby': 'snackbar-message-id',
                }}
            >
                <SnackbarContent
                    classes={{ action: classes.action }}
                    className={`${classes[variant]} ${classes.root}`}
                    message={messageText}
                    action={action}
                />
            </Snackbar>
        );
    }
}

const styles = theme => ({
    root: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        '& p': {
            display: 'inline-flex',
            verticalAlign: 'middle',
        },
        '& svg': {
            color: theme.palette.common.white,
            marginRight: theme.spacing(2),
        },
        '& ul': {
            margin: 0,
        },
    },
    success: {
        backgroundColor: theme.palette.common.green,
        color: theme.palette.common.black,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.common.white,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    warning: {
        backgroundColor: theme.palette.common.blue,
        color: theme.palette.common.white,
    },
    action: {
        marginRight: theme.spacing(1),
        '& a': {
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    },
});

export function openSnackbar({
    message, variant, action, stopHide,
}) {
    openSnackbarFn({
        message, variant, action, stopHide,
    });
}

export function closeSnackbar() {
    closeSnackbarFn();
}

export function openSnackbarSuccess(message, action, stopHide) {
    openSnackbar({
        message: (
            <Typography variant="body1" style={{ color: 'inherit' }} gutterBottom>
                <CheckCircleIcon />
                {' '}
                {message}
            </Typography>
        ),
        variant: 'success',
        action,
        stopHide,
    });
}

export function openSnackbarError(message, errors = [], action, stopHide) {
    const errorList = () => (
        <ul>
            {errors.map(error => (<li key={error.replace(/ /g, '')}>{error}</li>))}
        </ul>
    );

    openSnackbar({
        message: (
            <>
                <Typography variant="body1" style={{ color: 'inherit' }} gutterBottom>
                    <ErrorIcon />
                    {' '}
                    {message}
                </Typography>
                {errors.length > 0 && errorList()}
            </>
        ),
        variant: 'error',
        action,
        stopHide,
    });
}

Notifier.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Notifier);
