import { ApolloLink } from 'apollo-link';
import store from '../store';

const authMiddleware = new ApolloLink((operation, forward) => {
    let bearerToken;
    if (!bearerToken) {
        bearerToken = `Bearer ${store.getState().auth.getIn(['authentication', 'idToken'])}`;
    }
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: bearerToken,
        },
    }));
    return forward(operation);
});

export default authMiddleware;
