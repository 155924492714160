import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NotFoundIllustrationLarge from '../../assets/images/not-found/404-illustration-lg.svg';
import NotFoundIllustrationSmall from '../../assets/images/not-found/404-illustration-sm.svg';
import NotFoundIllustrationExtraSmall from '../../assets/images/not-found/404-illustration-xs.svg';

const StaticMessagePage = ({
    classes,
    title,
    subTitle,
    children,
}) => (
    <div className={classes.container}>
        <div className={classes.copyContainer}>
            {!!title && (
                <Typography variant="h1" className={classes.title}>
                    {title}
                </Typography>
            )}
            {!!subTitle && <Typography className={classes.subtitle}>{subTitle}</Typography>}
            {children}
        </div>
        <div className={classes.illustrationOuterContainer}>
            <div className={classes.illustrationContainer}>
                <picture>
                    <source media="(max-width: 599px)" srcSet={NotFoundIllustrationExtraSmall} alt="not found" />
                    <source media="(max-width: 967px)" srcSet={NotFoundIllustrationSmall} alt="not found" />
                    <source media="(min-width: 968px)" srcSet={NotFoundIllustrationLarge} alt="not found" />
                    <img className={classes.illustration} src={NotFoundIllustrationLarge} alt="not found" />
                </picture>
            </div>
            <div className={classes.illustrationBackground} />
        </div>
    </div>
);

const styles = theme => ({
    container: {
        position: 'relative',
        left: -theme.spacing(3),
        width: `calc(100% + ${theme.spacing(6)}px)`,
        paddingTop: theme.spacing(6),
        textAlign: 'center',
        marginBottom: -theme.spacing(12),
        [theme.breakpoints.up('md')]: {
            left: -theme.spacing(8),
            width: `calc(100% + ${theme.spacing(16)}px)`,
        },
    },
    copyContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    title: {
        lineHeight: 1.375,
        fontSize: 40,
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            fontSize: 64,
            paddingBottom: 0,
        },
    },
    subtitle: {
        fontSize: 20,
        color: theme.palette.common.black,
    },
    illustrationOuterContainer: {
        textAlign: 'center',
        position: 'relative',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(8),
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(9.5),
            paddingBottom: theme.spacing(9.5),
        },
    },
    illustrationContainer: {
        display: 'inline-block',
        maxWidth: '100%',
    },
    illustrationBackground: {
        position: 'absolute',
        backgroundImage: theme.palette.background.gradientVerticalGrey,
        width: '100%',
        height: '50%',
        bottom: 0,
        left: 0,
        zIndex: -1,
    },
    illustration: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 'initial',
        },
    },
});

StaticMessagePage.defaultProps = {
    subTitle: null,
    children: null,
};

StaticMessagePage.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    children: PropTypes.node,
};

export default withStyles(styles)(StaticMessagePage);
