import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: ({ gutterBottom, variant }) => ({
        fontSize: variant === 'h1' ? 45 : 30,
        fontWeight: 400,
        marginBottom: gutterBottom ? theme.spacing(5) : theme.spacing(0.5),
        fontFamily: theme.typography.h1.fontFamily,
    }),
});

const SectionDescription = withStyles(styles)(Typography);

SectionDescription.defaultProps = {
    variant: 'h2',
    align: 'center',
    color: 'textSecondary',
    gutterBottom: false,
};

SectionDescription.propTypes = {
    align: PropTypes.string,
};

export default SectionDescription;
