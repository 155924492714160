import moment from 'moment';
import getContent from '../content/getContent';
import HealthIcon from '../../assets/images/icons/health-icon.svg';
import YinYangIcon from '../../assets/images/icons/yin-yang-icon.svg';
import MoneyIcon from '../../assets/images/icons/money-question-icon.svg';
import WaterIcon from '../../assets/images/icons/water-icon.svg';
import TrainerIcon from '../../assets/images/icons/trainer-icon.svg';
import CoffeeIcon from '../../assets/images/icons/coffee-icon.svg';
import ScalesIcon from '../../assets/images/icons/scales-icon.svg';
import SleepingMaskIcon from '../../assets/images/icons/sleeping-mask-icon.svg';
import PlateIcon from '../../assets/images/icons/plate-icon.svg';
import ShieldIcon from '../../assets/images/icons/shield-icon.svg';
import DialIcon from '../../assets/images/icons/dial-icon.svg';
import AlcoholIcon from '../../assets/images/icons/alcohol-icon.svg';
import WeightIcon from '../../assets/images/icons/weight-icon.svg';
import ConeIcon from '../../assets/images/icons/cone-icon.svg';
import TargetIcon from '../../assets/images/icons/target-icon.svg';
import WalletIcon from '../../assets/images/icons/wallet-icon.svg';
import palette from '../../theme/palette/palette';
import HealthQuizChartData from './data/HealthQuiz.json';
import MentalWellbeingChartData from './data/MentalWellbeing.json';
import FinancialWellbeingChartData from './data/FinancialWellbeing.json';

const assessmentProps = {
    FinancialWellbeing: { icon: MoneyIcon, value: 30 },
    MentalHealth: { icon: YinYangIcon, value: 30 },
    HealthQuiz: { icon: HealthIcon, value: 30 },
};

const themeIcons = {
    'bodymassindex(bmi)': ScalesIcon,
    bmi: ScalesIcon,
    minutesofexerciseperweek: TrainerIcon,
    minutesofcardioperweek: TrainerIcon,
    hoursofexerciseperweek: TrainerIcon,
    waterintakeperday: WaterIcon,
    teaandcoffeeintakeperday: CoffeeIcon,
    caffeineintakeperday: CoffeeIcon,
    fruitandvegetablesperday: PlateIcon,
    'fruit&veg': PlateIcon,
    'fruit&vegportionsperday': PlateIcon,
    sleep: SleepingMaskIcon,
    exercise: TrainerIcon,
    oldexercise: TrainerIcon,
    fruitandveg: PlateIcon,
    hoursofsleeppernight: SleepingMaskIcon,
    resilience: ShieldIcon,
    perceivedstress: DialIcon,
    stress: DialIcon,
    financialwellbeing: MoneyIcon,
    'mentalhealth-resilience': ShieldIcon,
    'mentalhealth-perceivedstress': DialIcon,
    mentalhealth: DialIcon,
    alcoholunitsperweek: AlcoholIcon,
    daysofstrengthtrainingperweek: WeightIcon,
    dailystrengthactivitiesperweek: WeightIcon,
    daytodayfinances: WalletIcon,
    'day-to-dayfinances': WalletIcon,
    longtermgoals: TargetIcon,
    'long-termgoals': TargetIcon,
    unexpectedevents: ConeIcon,
    exercisestrength: WeightIcon,
    water: WaterIcon,
    exercisecardio: TrainerIcon,
    caffeine: CoffeeIcon,
    alcohol: AlcoholIcon,
};

export const baseCompletnessPercentage = 10;

/**
 * Calculate the percentage complete from the status counts
 * @return {Number}
 */
export const getTrackerProgress = (assessments) => {
    let completePercentage = baseCompletnessPercentage;
    let overduePercentage = 0;

    assessments.forEach((assessment) => {
        if (assessment.status === 'complete') {
            completePercentage += assessment.value;
        }
        if (assessment.status === 'overdue') {
            overduePercentage += assessment.value;
        }
    });

    return [completePercentage, overduePercentage];
};

/**
 * Generate the status text based on the status counts
 * @return {String}
 */
export const generateStatusText = (complete, incomplete, overdue) => {
    let statusText;
    if (incomplete > 0) {
        statusText = `
            ${getContent(['components', 'wellbeingTracker', 'status', 'youHave'])}
            ${incomplete} ${getContent(['components', 'wellbeingTracker', 'status', 'incomplete'])}
            ${incomplete > 0 ? getContent(['components', 'wellbeingTracker', 'status', 'assessment', 1])
        : getContent(['components', 'wellbeingTracker', 'status', 'assessment', 0])}`;
        if (overdue > 0) {
            statusText = statusText.concat(`
                ${getContent(['components', 'wellbeingTracker', 'status', 'and'])}
                ${overdue} ${getContent(['components', 'wellbeingTracker', 'status', 'overdue'])}
                ${overdue > 0 ? getContent(['components', 'wellbeingTracker', 'status', 'retake', 1])
        : getContent(['components', 'wellbeingTracker', 'status', 'retake', 0])}`);
        }
    } else if (overdue > 0) {
        statusText = `
            ${getContent(['components', 'wellbeingTracker', 'status', 'youHave'])}
            ${overdue} ${getContent(['components', 'wellbeingTracker', 'status', 'overdue'])}
            ${overdue > 0 ? getContent(['components', 'wellbeingTracker', 'status', 'retake', 1])
        : getContent(['components', 'wellbeingTracker', 'status', 'retake', 0])}`;
    }
    return statusText;
};

export const addAssessmentProps = assessments => assessments.map((assessment) => {
    const { code } = assessment;
    return { ...assessment, ...assessmentProps[code] };
});

/**
 * Get Wellbeing Theme Id
 * @param  {Object} theme
 * @return {String}
 */
export const getWellbeingThemeId = (theme) => {
    if (!theme) return null;
    const { name } = theme;
    return name.replace(/ /g, '').toLowerCase();
};

/**
 * Get Wellbeing Category Id
 * @param  {Object} category
 * @return {String}
 */
export const getWellbeingCategoryId = (category) => {
    if (!category) return null;
    const { name } = category;
    return name.replace(/ /g, '').toLowerCase();
};


/**
 * Get tracker theme icon
 * @param  {String} themeId
 * @return {String}
 */
export const getTrackerThemeIcon = themeId => themeIcons[themeId];

/**
 * Map icon to pending url
 * @param  {String} name
 * @return {String}
 */
export const fuzzyMapIconPendingIconUrl = (name) => {
    const nameToLower = name.toLowerCase();
    if (nameToLower.indexOf('fruit') !== -1) { return themeIcons.fruitandvegetablesperday; }
    if (nameToLower.indexOf('coffee') !== -1) { return themeIcons.teaandcoffeeintakeperday; }
    if (nameToLower.indexOf('caffeine') !== -1) { return themeIcons.teaandcoffeeintakeperday; }
    if (nameToLower.indexOf('bodymassindex(bmi)') !== -1) { return themeIcons.bmi; }
    if (nameToLower.indexOf('water') !== -1) { return themeIcons.waterintakeperday; }
    if (nameToLower.indexOf('exercise') !== -1) { return themeIcons.hoursofexerciseperweek; }
    if (nameToLower.indexOf('alcohol') !== -1) { return themeIcons.alcoholunitsperweek; }
    if (nameToLower.indexOf('strength') !== -1) { return themeIcons.strength; }
    return null;
};

/**
 * Get the status of an assessment
 *
 * @param  {Object} assessment
 * @return {String}
 */
const getStatus = (assessment) => {
    // If last complete is null then the user hasn't completed
    if (assessment.lastCompleted === null) {
        return 'incomplete';
    // If there's no due date or it's been completed and the due date is in the future
    } if (assessment.dueDate === null || (assessment.lastCompleted !== null && moment(assessment.dueDate).isAfter(moment()))) {
        return 'complete';
    // If it's been completed but the due date is before today
    } if (assessment.lastCompleted !== null && moment(assessment.dueDate).isBefore(moment())) {
        return 'overdue';
    }
    return null;
};

/**
 * Set the status object
 *
 * @param  {array} assessments
 * @return {object}
 */
export const setStatus = (assessments) => {
    if (assessments) {
        const status = {
            incomplete: assessments.filter(assessment => getStatus(assessment) === 'incomplete').length,
            complete: assessments.filter(assessment => getStatus(assessment) === 'complete').length,
            overdue: assessments.filter(assessment => getStatus(assessment) === 'overdue').length,
        };
        return status;
    }
    return null;
};

/**
 * Get the assessment link
 *
 * @param  {Object} assessment
 * @return {String}
 */
export const getQuestionnaireIdFromName = (name) => {
    switch (name) {
    case 'Lifestyle': return 'HealthQuiz';
    case 'Mental health': return 'MentalHealth';
    case 'Financial Wellbeing': return 'FinancialWellbeing';
    default: return null;
    }
};

/**
 * Get the assessment link
 *
 * @param  {Object} assessment
 * @return {String}
 */
export const getAssessmentLink = (assessment) => {
    const { code } = assessment;
    switch (code) {
    case 'Lifestyle':
    case 'HealthQuiz': return '/questionnaires/HealthQuiz';
    case 'Mentalhealth':
    case 'MentalHealth': return '/questionnaires/MentalHealth';
    case 'FinancialWellbeing': return '/questionnaires/FinancialWellbeing';
    default: return null;
    }
};

/**
 * Get the category link
 *
 * @param  {Object} category
 * @return {String}
 */
const getCategoryLink = (category) => {
    const { name } = category;

    switch (name.toLowerCase()) {
    case 'lifestyle': return '/questionnaires/HealthQuiz';
    case 'food and drink': return '/questionnaires/HealthQuiz';
    case 'exercise and physical fitness': return '/questionnaires/HealthQuiz';
    case 'mental health': return '/questionnaires/MentalHealth';
    case 'financial wellbeing': return '/questionnaires/FinancialWellbeing';
    default: return null;
    }
};

/**
 * Add the category link to the object
 *
 * @param  {Array} categories
 * @return {Array}
 */
export const setCategoryLink = (categories) => {
    if (categories) {
        return categories.map(category => ({
            ...category,
            link: getCategoryLink(category),
        }));
    }
    return null;
};

/**
 * Add the status property to the assessment object
 * and link to the assessment
 *
 * @param  {Array} assessments
 * @return {Array}
 */
export const addAssessmentStatus = (assessments) => {
    if (assessments) {
        return assessments.map(assessment => ({
            ...assessment,
            status: getStatus(assessment),
            link: getAssessmentLink(assessment),
        }));
    }
    return assessments;
};

/**
 * Get the status of an assessment
 * @param  {Object} assessment
 * @return {String}
 */
export const getAssessmentStatus = (assessment) => {
    // If last complete is null then the user hasn't completed
    if (assessment.lastCompleted === null) {
        return 'incomplete';
    // If there's no due date or it's been completed and the due date is in the future
    } if (assessment.dueDate === null || (assessment.lastCompleted !== null && moment(assessment.dueDate).isAfter(moment()))) {
        return 'complete';
    // If it's been completed but the due date is before today
    } if (assessment.lastCompleted !== null && moment(assessment.dueDate).isBefore(moment())) {
        return 'overdue';
    }
    return null;
};

/**
 * Get the break down of status types
 * @param  {List} assessments
 * @return {Object}
 */
export const getTrackerStatus = (assessments) => {
    if (assessments) {
        const status = {
            incomplete: assessments.filter(assessment => getAssessmentStatus(assessment) === 'incomplete').length,
            complete: assessments.filter(assessment => getAssessmentStatus(assessment) === 'complete').length,
            overdue: assessments.filter(assessment => getAssessmentStatus(assessment) === 'overdue').length,
        };
        return status;
    }
    return null;
};

/**
 * Get the tracker progress percentage
 *
 * @param  {Object} status
 * @return {Number}
 */
export const getTrackerProgressPercent = (status) => {
    if (status) {
        return ((status.complete + status.overdue) / (status.complete + status.incomplete + status.overdue)) * 100;
    }
    return 0;
};

export const getThemeTarget = (theme) => {
    const { targetLabel } = theme;
    return {
        result: targetLabel,
    };
};

export const getColorBanding = (latestResultBand) => {
    switch (latestResultBand) {
    case 'LG': return palette.performance[0];
    case 'NW': return palette.performance[1];
    case 'AF': return palette.performance[2];
    default: return palette.common.greyDark;
    }
};

export const getBgColorBanding = (latestResultBand) => {
    switch (latestResultBand) {
    case 'LG': return palette.background.gradientVerticalReverse;
    case 'NW': return palette.background.gradientYellow;
    case 'AF': return palette.background.gradientRed;
    default: return palette.background.gradientDarkGrey;
    }
};

export const mapGraphColorBanding = (color) => {
    switch (color) {
    case '#f5d1d2':
    case '#f1cdce': return getColorBanding('AF');
    case '#f7e2d4':
    case '#f3ded0': return getColorBanding('NW');
    case '#ecfbf5': return getColorBanding('LG');
    default: return getColorBanding(null);
    }
};

export const getThemeResult = (theme, inViewport = false) => {
    const {
        max,
        latestResultLabel: resultLabel,
        latestResult: result,
        latestResultBand,
    } = theme;

    const percent = inViewport ? (parseFloat(result) / max) * 100 : 0;

    return {
        result: inViewport ? Math.round(result) : 0,
        resultLabel,
        percent,
        color: getColorBanding(latestResultBand),
        background: getBgColorBanding(latestResultBand),
    };
};

export const isRadialChartType = theme => !Number.isNaN(parseFloat(theme.recommendedMin));

/**
 * Is a lifestyle assessment
 * @param  {String} string
 * @return {Bool}
 */
export const isLifeStyle = title => (title.replace(/ /g, '').toLowerCase().indexOf('mentalwellbeing') === -1
    && title.replace(/ /g, '').toLowerCase().indexOf('financial') === -1);

export const getMockChartData = (code, type) => {
    try {
        switch (code) {
        case 'HealthQuiz': return HealthQuizChartData[type];
        case 'MentalHealth': return MentalWellbeingChartData[type];
        case 'FinancialWellbeing': return FinancialWellbeingChartData[type];
        default: return null;
        }
    } catch (error) {
        return null;
    }
};
