import * as Immutable from 'immutable';
import {
    SERVICES_REQUESTED,
    SERVICES_RESOLVED,
    SERVICES_ERROR,
    SERVICES_CREATE_ENQUIRY_REQUESTED,
    SERVICES_CREATE_ENQUIRY_RESOLVED,
    SERVICES_CREATE_ENQUIRY_ERROR,
    SERVICES_CREATE_HISTORY_REQUESTED,
    SERVICES_CREATE_HISTORY_RESOLVED,
    SERVICES_CREATE_HISTORY_ERROR,
    SERVICES_HISTORY_REQUESTED,
    SERVICES_HISTORY_RESOLVED,
    SERVICES_HISTORY_ERROR,
    SERVICES_COMPANY_PRODUCTS_REQUESTED,
    SERVICES_COMPANY_PRODUCTS_RESOLVED,
    SERVICES_COMPANY_PRODUCTS_ERROR,
} from '../actions/services/types';

const deliverablesHistory = {
    status: 'INIT',
    resolving: false,
    history: [],
};

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    deliverables: [],
    deliverablesHistory,
};

const initialState = Immutable.Map(schema);

/**
 * The perks reducer - responsible for managing the perks service and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
    case SERVICES_REQUESTED:
    case SERVICES_CREATE_ENQUIRY_REQUESTED:
    case SERVICES_CREATE_HISTORY_REQUESTED:
    case SERVICES_COMPANY_PRODUCTS_REQUESTED:
        return state.set('resolving', true);

    case SERVICES_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('deliverables', action.payload);

    case SERVICES_CREATE_ENQUIRY_RESOLVED:
    case SERVICES_CREATE_HISTORY_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK');

    case SERVICES_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('deliverables', []);

    case SERVICES_CREATE_ENQUIRY_ERROR:
    case SERVICES_CREATE_HISTORY_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case SERVICES_HISTORY_REQUESTED:
        return state
            .set('deliverablesHistory', {
                status: 'OK',
                resolving: true,
                history: [],
            });

    case SERVICES_HISTORY_RESOLVED:
        return state
            .set('deliverablesHistory', {
                status: 'OK',
                resolving: false,
                history: action.payload.history,
            });

    case SERVICES_HISTORY_ERROR:
        return state
            .set('deliverablesHistory', {
                status: 'ERROR',
                resolving: false,
                history: [],
            });

    case SERVICES_COMPANY_PRODUCTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('deliverables', action.payload);

    case SERVICES_COMPANY_PRODUCTS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('deliverables', []);

    default:
        return state;
    }
};

export { initialState };

export default servicesReducer;
