import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MentalHealthSlider from '../MentalHealthSlider/MentalHealthSlider';
import MuiButtonLink from '../../material-ui/MuiButtonLink/MuiButtonLink';
import MuiRoundedButton from '../../material-ui/MuiRoundedButton/MuiRoundedButton';
import MuiCard from '../../material-ui/MuiCard/MuiCard';
import MuiCardContent from '../../material-ui/MuiCardContent/MuiCardContent';
import getContent from '../../../services/content/getContent';
import resilienceSVG from '../../../assets/images/services/mental-health/resilience.svg';
import perceivedStressSVG from '../../../assets/images/services/mental-health/perceived-stress.svg';
import thriveLogoSVG from '../../../assets/images/icons/thrive-app-icon.svg';
import ServiceProviderCard from '../../service-provider-card/ServiceProviderCard';

const MentalHealthAssessmentIntro = ({
    classes,
    onStartQuiz,
    onCancelQuiz,
}) => {
    const [option, setOption] = useState('low');
    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} md={10} lg={8}>
                    <Typography variant="h1" align="center" gutterBottom>
                        {getContent(['components', 'mentalHealthAssessment', 'title'])}
                    </Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                        {getContent(['components', 'mentalHealthAssessment', 'description'])}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="center" gutterBottom className={classes.intro}>
                        {getContent(['components', 'mentalHealthAssessment', 'intro', 0])}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="center" gutterBottom className={classes.intro}>
                        {getContent(['components', 'mentalHealthAssessment', 'intro', 1])}
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={11}>
                    <MuiCard variant="gradient">
                        <MuiCardContent className={classes.infoContent}>
                            <Grid
                                container
                                spacing={4}
                                justifyContent="center"
                                alignItems="baseline"
                                className={classes.infoContentPrimary}
                            >
                                <Grid item xs={12} sm={12} md={5} align="center">
                                    <img
                                        src={resilienceSVG}
                                        className={classes.infoContentLogo}
                                        alt={getContent(['components', 'mentalHealthAssessment', 'resilience', 'title'])}
                                    />
                                    <Typography variant="h3" className={classes.infoContentTitle}>
                                        {getContent(['components', 'mentalHealthAssessment', 'resilience', 'title'])}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {getContent(['components', 'mentalHealthAssessment', 'resilience', 'description'])}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} />
                                <Grid item xs={12} sm={12} md={5} align="center">
                                    <img
                                        src={perceivedStressSVG}
                                        className={classes.infoContentLogo}
                                        alt={getContent(['components', 'mentalHealthAssessment', 'perceivedStress', 'title'])}
                                    />
                                    <Typography variant="h3" className={classes.infoContentTitle}>
                                        {getContent(['components', 'mentalHealthAssessment', 'perceivedStress', 'title'])}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {getContent(['components', 'mentalHealthAssessment', 'perceivedStress', 'description'])}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <div className={classes.infoSecondaryContent}>
                                <Grid
                                    container
                                    spacing={4}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} align="center">
                                        <MuiRoundedButton
                                            variant="outlined"
                                            selected={option === 'low'}
                                            className={classes.infoButton}
                                            onClick={() => setOption('low')}
                                        >
                                            {getContent(['components', 'mentalHealthAssessment', 'infoButtons', 0])}
                                        </MuiRoundedButton>
                                        <MuiRoundedButton
                                            variant="outlined"
                                            selected={option === 'high'}
                                            className={classes.infoButton}
                                            onClick={() => setOption('high')}
                                        >
                                            {getContent(['components', 'mentalHealthAssessment', 'infoButtons', 1])}
                                        </MuiRoundedButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} align="center">
                                        <MentalHealthSlider
                                            title={getContent(['components', 'mentalHealthAssessment', 'resilience', 'title'])}
                                            image={resilienceSVG}
                                            value={(option === 'low' ? 4 : 1)}
                                            min={0}
                                            max={4}
                                            tooltip={(option === 'low' ? 'High' : 'Low')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} align="center">
                                        <MentalHealthSlider
                                            title={getContent(['components', 'mentalHealthAssessment', 'perceivedStress', 'title'])}
                                            image={perceivedStressSVG}
                                            value={(option === 'low' ? 1 : 4)}
                                            min={0}
                                            max={4}
                                            tooltip={(option === 'low' ? 'Low' : 'High')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={11}>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            {getContent(['components', 'mentalHealthAssessment', 'infoText', 0])}
                                            {' '}
                                            <strong>{getContent(['components', 'mentalHealthAssessment', 'infoText', 1])}</strong>
                                            {getContent(['components', 'mentalHealthAssessment', 'infoText', 2])}
                                            {' '}
                                            <strong>{getContent(['components', 'mentalHealthAssessment', 'infoText', 3])}</strong>
                                            {getContent(['components', 'mentalHealthAssessment', 'infoText', 4])}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </MuiCardContent>
                    </MuiCard>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                align="center"
            >
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Button color="primary" variant="contained" size="large" fullWidth onClick={onStartQuiz} className={classes.questionnaireBtn}>
                        {getContent(['components', 'mentalHealthAssessment', 'startQuestionnaireBtnLabel'])}
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
                align="center"
            >
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <MuiButtonLink onClick={onCancelQuiz}>
                        {getContent(['components', 'mentalHealthAssessment', 'tryAgainBtnLabel'])}
                    </MuiButtonLink>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
                align="center"
                className={classes.providerCard}
            >
                <Grid item xs={12} lg={11}>
                    <ServiceProviderCard
                        logo={thriveLogoSVG}
                        title={getContent(['components', 'mentalHealthAssessment', 'serviceProvider', 'serviceMessageTitle'])}
                        description={getContent(['components', 'mentalHealthAssessment', 'serviceProvider', 'serviceMessageDescription'])}
                    />
                </Grid>

            </Grid>
        </>
    );
};

const styles = theme => ({
    intro: {
        marginTop: theme.spacing(4),
        '&:last-child': {
            marginTop: 0,
            paddingBottom: theme.spacing(8),
        },
    },
    infoContent: {
        padding: 0,
        marginBottom: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    infoContentPrimary: {
        padding: theme.spacing(6, 3),
    },
    infoContentLogo: {
        marginBottom: theme.spacing(3),
    },
    infoContentTitle: {
        marginBottom: theme.spacing(3),
    },
    infoSecondaryContent: {
        backgroundColor: theme.palette.common.greyLight,
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    infoButton: {
        textTransform: 'uppercase',
        margin: `0 ${theme.spacing(1)}px`,
    },
    questionnaireBtn: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1),
    },
    providerCard: {
        marginTop: theme.spacing(4),
    },
    backButton: {
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
});

MentalHealthAssessmentIntro.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onCancelQuiz: PropTypes.func.isRequired,
    onStartQuiz: PropTypes.func.isRequired,
};

export default withStyles(styles)(MentalHealthAssessmentIntro);
