import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const ExpertQuote = ({
    classes,
    avatar,
    name,
    description,
    quote,
}) => (
    <Card className={`${classes.card} aut-expertQuote`}>
        <CardHeader
            classes={{ root: classes.header, avatar: classes.avatar }}
            avatar={<img src={avatar} alt={name} />}
            title={<Typography variant="h4" className={`${classes.avatarTitle} aut-expertQuote-card_title`}>{name}</Typography>}
            subheader={<Typography variant="overline" className={`${classes.avatarRole} aut-expertQuote-card_role`}>{description}</Typography>}
        />
        <CardContent
            className={classes.content}
        >
            <Typography variant="body1" color="textSecondary" className={`${classes.quote} aut-expertQuote-card_quote`}>
                &quot;
                {quote}
                &quot;
            </Typography>
        </CardContent>
    </Card>
);

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: theme.shadows[25],
        borderRadius: 15,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    header: {
        minWidth: 495,
        padding: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
            flexDirection: 'column',
            textAlign: 'center',
        },
    },
    avatar: {
        maxWidth: 125,
        maxHeight: 125,
        border: `1px solid ${theme.palette.common.green}`,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: theme.spacing(3),
        '& img': {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1),
        },
    },
    avatarTitle: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: 30,
    },
    avatarRole: {
        fontSize: 14,
        letterSpacing: 1,
        lineHeight: 1,
    },
    content: {
        background: theme.palette.background.gradient,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    quote: {
        fontStyle: 'italic',
        fontSize: 18,
    },
});

ExpertQuote.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
};

export default withStyles(styles)(ExpertQuote);
