import variables from '../variables';

const MuiInputLabel = {
    root: {
        fontWeight: 'bold',
        fontSize: 15,
        color: variables.colors.black,
    },
    outlined: {
        position: 'relative',
        transform: 'none',
        marginBottom: 15,
        '&$disabled': {
            color: variables.colors.black,
        },
        '&$focused': {
            color: variables.colors.black,
        },
    },
};

export default MuiInputLabel;
