import cookies from 'js-cookie';

const COOKIE_NAME = 'Breeze_whatsNew';

const whatsNewService = {};

whatsNewService.getArticles = async (url) => {
    try {
        const articles = await fetch(url);
        return await articles.json();
    } catch (error) {
        throw error;
    }
};

whatsNewService.hasReadLatest = (articles) => {
    if (articles.length > 0) {
        if (cookies.get(COOKIE_NAME) === articles[0].id) {
            return true;
        }
        return false;
    }
    return true; // no articles
};

whatsNewService.saveReadLatest = (article) => {
    cookies.set(COOKIE_NAME, article.id, {
        expires: 365,
    });
};

export default whatsNewService;
