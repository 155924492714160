import * as Immutable from 'immutable';
import {
    GP_BOOKING_APPOINTMENT_SLOTS_REQUESTED,
    GP_BOOKING_APPOINTMENT_SLOTS_RESOLVED,
    GP_BOOKING_APPOINTMENT_SLOTS_ERROR,
    GP_BOOKING_CONSULTATION_DETAILS_SET,
    GP_BOOKING_RESET,
    GP_BOOKING_HOLD_APPOINTMENT_SLOT_REQUESTED,
    GP_BOOKING_HOLD_APPOINTMENT_SLOT_RESOLVED,
    GP_BOOKING_HOLD_APPOINTMENT_SLOT_ERROR,
    GP_BOOKING_BOOK_APPOINTMENT_REQUESTED,
    GP_BOOKING_BOOK_APPOINTMENT_RESOLVED,
    GP_BOOKING_BOOK_APPOINTMENT_ERROR,
    GP_BOOKING_REMOVE_APPOINTMENT_SLOT,
    GP_FUTURE_APPOINTMENTS_REQUESTED,
    GP_FUTURE_APPOINTMENTS_RESOLVED,
    GP_BOOKING_CANCEL_BOOKING_REQUESTED,
    GP_BOOKING_CANCEL_BOOKING_RESOLVED,
    GP_BOOKING_CANCEL_BOOKING_ERROR,
} from '../actions/gp-booking/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    appointmentSlots: null,
    consultationDetails: {},
    confirmedBooking: null,
    bookingSlotId: null,
    bookingSlotIdUnavailable: false,
    appointmentDetails: {},
    futureAppointments: [],
};

const initialState = Immutable.Map(schema);

/**
 * The gp booking reducer - responsible for managing a gp booking service and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const gpBookingReducer = (state = initialState, action) => {
    switch (action.type) {
    case GP_BOOKING_APPOINTMENT_SLOTS_REQUESTED:
    case GP_BOOKING_HOLD_APPOINTMENT_SLOT_REQUESTED:
    case GP_BOOKING_BOOK_APPOINTMENT_REQUESTED:
    case GP_FUTURE_APPOINTMENTS_REQUESTED:
    case GP_BOOKING_CANCEL_BOOKING_REQUESTED:
        return state.set('resolving', true);

    case GP_BOOKING_APPOINTMENT_SLOTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('appointmentSlots', action.payload.slots)
            .set('appointmentDetails', action.payload.appointmentDetails);

    case GP_BOOKING_APPOINTMENT_SLOTS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('appointmentSlots', null);

    case GP_BOOKING_CONSULTATION_DETAILS_SET:
        return state
            .set('consultationDetails', action.payload);

    case GP_BOOKING_HOLD_APPOINTMENT_SLOT_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('bookingSlotId', action.payload.slotId)
            .set('bookingSlotIdUnavailable', action.payload.isUnavailable);

    case GP_BOOKING_HOLD_APPOINTMENT_SLOT_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('bookingSlotId', null);

    case GP_BOOKING_BOOK_APPOINTMENT_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('confirmedBooking', action.payload);

    case GP_BOOKING_BOOK_APPOINTMENT_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('confirmedBooking', null);

    case GP_BOOKING_REMOVE_APPOINTMENT_SLOT:
        return state
            .set('bookingSlotId', null)
            .set('bookingSlotIdUnavailable', false);

    case GP_FUTURE_APPOINTMENTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('futureAppointments', action.payload);

    case GP_BOOKING_CANCEL_BOOKING_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('futureAppointments', []);

    case GP_BOOKING_CANCEL_BOOKING_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case GP_BOOKING_RESET:
        return initialState;

    default:
        return state;
    }
};

export { initialState };

export default gpBookingReducer;
