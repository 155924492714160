import variables from '../variables';

const MuiFormControlLabel = {
    root: {
        color: variables.colors.black,
    },
    label: {
        color: variables.colors.black,
        fontWeight: 'bold',
        '& span': {
            fontWeight: 'normal',
        },
        // marginLeft: 16,
    },
};

export default MuiFormControlLabel;
