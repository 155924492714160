import variables from '../variables';

const typograhpy = {
    fontFamily: variables.font.fontFamily.join(','),
    h1: {
        fontFamily: variables.font.fontFamilyHeading.join(','),
        color: variables.text.headerColor,
        fontSize: 36,
        fontWeight: 500,
    },
    h2: {
        fontSize: 24,
        fontWeight: 500,
        fontFamily: variables.font.fontFamilyHeading.join(','),
        color: variables.text.headerColor,
    },
    h3: {
        fontSize: 20,
        fontWeight: 500,
        fontFamily: variables.font.fontFamilyHeading.join(','),
        color: variables.text.headerColor,
    },
    h4: {
        fontSize: 18,
        fontWeight: 700,
        color: variables.text.headerColor,
    },
    h5: {
        fontSize: 16,
        fontWeight: 700,
        color: variables.text.headerColor,
    },
    subtitle1: {
        fontSize: 20,
        fontWeight: 500,
        color: variables.text.headerColor,
        lineHeight: 1.3,
    },
    useNextVariants: true,
};

export default typograhpy;
