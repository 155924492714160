import variables from '../variables';

const text = {
    primary: variables.text.primary,
    textPrimary: variables.text.primary,
    textSecondary: variables.text.secondary,
    secondary: variables.text.secondary,
};

export default text;
