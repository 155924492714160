import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import lockIcon from '../../assets/images/icons/privacy-promise-icon.svg';
import getContent from '../../services/content/getContent';
import Container from '../layout/Container/Container';

const PrivacyPanel = ({
    classes,
    isAdmin,
}) => (
    <div className={classes.container}>
        <Container displayPrint="none">
            <div className={classes.privacyPanel}>
                <img
                    src={lockIcon}
                    alt={getContent(['components', 'dashboard', 'privacyPromise', 'iconAlt'])}
                    className={classes.icon}
                />
                <Typography variant="h3" align="center" className={classes.title}>
                    {getContent(['components', 'dashboard', 'privacyPromise', 'title'])}
                </Typography>
                <Typography variant="body1" align="center" className={classes.promise}>
                    {getContent(['components', 'dashboard', 'privacyPromise', (isAdmin ? 'adminPromise' : 'promise')])}
                </Typography>
            </div>
        </Container>
    </div>
);

const styles = theme => ({
    container: props => ({
        backgroundColor: props.isBackgroundWhite ? theme.palette.common.white : theme.palette.common.greyLight,
    }),
    privacyPanel: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: 320,
        marginTop: theme.spacing(6),
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: 420,
        },
    },
    promise: {
        maxWidth: 900,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginBottom: theme.spacing(2),
    },
    link: {
        fontSize: 16,
    },
});

styles.defaultProps = {
    isBackgroundWhite: false,
};

styles.propTypes = {
    isBackgroundWhite: PropTypes.bool,
};

const mapStateToProps = state => ({
    isBackgroundWhite: state.router.location.pathname.indexOf('/account') !== -1,
});

PrivacyPanel.defaultProps = {
    isAdmin: false,
};

PrivacyPanel.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isAdmin: PropTypes.bool,
};

export default connect(mapStateToProps)(withStyles(styles)(PrivacyPanel));
