import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const MuiButtonWithLoader = (props) => {
    const {
        classes,
        loading,
        disabled,
        size,
        children,
    } = props;

    const getSize = () => {
        switch (size) {
        case 'small': return 20;
        case 'medium': return 20;
        case 'large': return 30;
        default: return 20;
        }
    };

    return (
        <Button
            {..._.omit(props, ['classes', 'loading', 'children'])}
            disabled={disabled || loading}
        >
            {loading && <CircularProgress className={classes.buttonProgress} size={getSize()} />}
            {children}
        </Button>
    );
};

const styles = theme => ({
    buttonProgress: {
        position: 'absolute',
        color: theme.palette.common.green,
        marginRight: theme.spacing(1),
    },
});

MuiButtonWithLoader.defaultProps = {
    fullWidth: false,
    disabled: false,
    loading: false,
    variant: 'contained',
    color: 'primary',
    size: 'medium',
    type: 'button',
    onClick: null,
};

MuiButtonWithLoader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func,
};

export default withStyles(styles)(MuiButtonWithLoader);
