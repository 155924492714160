import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import session from '../auth/session';
import store from '../store';
import { getEnvironmentVariable } from '../environment/environment-service';

const getWebSocketClient = async () => {
    // Create a WebSocket link:
    const wsLink = new WebSocketLink({
        uri: getEnvironmentVariable('REACT_APP_GRAPHQL_SERVER_WEBSOCKET'),
        options: {
            reconnect: true,
            connectionParams: () => {
                let bearerToken = session.getAuthToken() !== null ? session.getAuthToken() : null;
                if (bearerToken === null) {
                    bearerToken = store.getState().auth.getIn(['authentication', 'idToken']);
                }
                return {
                    authorization: bearerToken,
                };
            },
        },
    });

    return new ApolloClient({
        link: from([
            wsLink,
        ]),
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });
};

export default getWebSocketClient;
