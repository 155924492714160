import _ from 'lodash';
import getContent from '../content/getContent';

const errorHandler = (error) => {
    if (error.graphQLErrors && error.graphQLErrors[0]) {
        try {
            if (error.graphQLErrors.length > 0) {
                const formError = error.graphQLErrors[0].message;
                try {
                    const parsedErrors = JSON.parse(formError);
                    if (parsedErrors.title && parsedErrors.errors) {
                        return {
                            message: parsedErrors.title,
                            errors: Object.values(parsedErrors.errors).map(e => e[0]),
                        };
                    } if (typeof parsedErrors === 'string') {
                        return {
                            message: parsedErrors,
                            errors: [],
                        };
                    }
                    return {
                        message: 'Field validation error(s)',
                        errors: _.values(parsedErrors).length && _.values(parsedErrors)[0],
                    };
                } catch (e) {
                    if (typeof formError === 'string') {
                        return {
                            message: formError,
                        };
                    }
                    return error;
                }
            }
            return {
                message: getContent(['errors', 'invalid-graphQL-response']),
            };
        } catch (e) {
            return error;
        }
    }
    return error;
};

export default errorHandler;
