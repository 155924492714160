import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MuiPrimaryButton = (props) => {
    const { children } = props;
    return (
        <Button
            color="primary"
            variant="contained"
            {...props}
        >
            {children}
        </Button>
    );
};

const styles = () => ({
    root: {
        height: 45,
    },
});

MuiPrimaryButton.defaultProps = {
    children: null,
};

MuiPrimaryButton.propTypes = {
    children: PropTypes.node,
};

export default withStyles(styles)(MuiPrimaryButton);
