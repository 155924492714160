import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import ClearIcon from '@material-ui/icons/Clear';
import isHtml from 'is-html';
import MuiButtonLink from '../../material-ui/MuiButtonLink/MuiButtonLink';

const renderList = (list, listKey) => (
    <ul key={listKey}>
        {list && list.map((listItem, index) => {
            const key = index;
            return (
                <li key={key}>
                    {isHtml(listItem)
                        ? <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: listItem }} />
                        : <Typography variant="body1" gutterBottom>{listItem}</Typography>
                    }
                </li>
            );
        })}
    </ul>
);

const InformationModal = ({
    classes, open, onClose, title, content = [],
}) => (
    <Modal open={open} size="medium" onClose={onClose} className={classes.modal}>
        <Card className={classes.card} elevation={0}>
            <CardContent className={classes.content}>
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        {title
                            && (
                                <Typography variant="h3" className={classes.title}>
                                    {title}
                                </Typography>
                            )
                        }
                        {content && content.map(
                            (line, index) => {
                                const key = index;
                                if (typeof line !== 'string' && line.length) {
                                    return renderList(line, key);
                                }
                                if (isHtml(line)) {
                                    return (<Typography variant="body1" gutterBottom key={key} dangerouslySetInnerHTML={{ __html: line }} />);
                                }
                                return (<Typography variant="body1" gutterBottom key={key}>{line}</Typography>);
                            },
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions className={classes.actions}>
                <MuiButtonLink onClick={onClose} color="primary">
                    <ClearIcon />
                    {' '}
                    Close
                </MuiButtonLink>
            </CardActions>
        </Card>
    </Modal>
);

const styles = theme => ({
    card: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 'none',
        top: '20%',
        left: '25%',
        width: '50%',
        borderRadius: 16,
        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(3),
            bottom: theme.spacing(3),
            width: `calc(100% - ${theme.spacing(6)}px)`,
            left: theme.spacing(3),
        },
    },
    content: {
        padding: theme.spacing(4),
        '& a': {
            color: theme.palette.common.orange,
        },
        '& strong': {
            color: theme.palette.common.black,
        },
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100% - 52px)',
            overflow: 'scroll',
        },
    },
    title: {
        paddingBottom: theme.spacing(2),
    },
    actions: {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.greyLight,
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > button': {
            marginLeft: -theme.spacing(1),
            fontWeight: 'bold',
        },
        '& svg': {
            color: theme.palette.common.black,
            marginRight: theme.spacing(0.5),
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

InformationModal.defaultProps = {
    open: false,
    title: null,
};

InformationModal.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    content: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]).isRequired,
    ).isRequired,
};

export default withStyles(styles)(InformationModal);
