import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import getCompanyContactAction from '../../../actions/company-contact/getCompanyContact';
import { URLS } from '../../../config/config';

const ContactWrapper = ({ children, resolving, getToken }) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.getIn(['authentication', 'idToken']));
    const contact = useSelector(state => state.companyContact.get('contact'));

    const getContact = async () => {
        dispatch(await getCompanyContactAction());
    };

    useEffect(() => {
        if (token && !resolving) {
            getContact();
        } else if (!token && !resolving) {
            getToken();
        }
    }, [token, resolving]);

    if (!token || !contact) return null;

    // If contact status is pending or disabled then redirect to locked / register pages
    if (contact && token && (contact.status === 'pending' || contact.status === 'disabled')) {
        return contact.status === 'pending' ? (
            <Redirect to={{ pathname: URLS.CONTACT.REGISTER.ACCOUNT, state: { signIn: true } }} />)
            : (<Redirect to={URLS.CONTACT.ACCOUNT_LOCKED} />);
    }

    return children;
};

ContactWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    resolving: PropTypes.bool.isRequired,
    getToken: PropTypes.func.isRequired,
};

export default ContactWrapper;
