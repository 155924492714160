import getContent from '../../services/content/getContent';
import { URLS } from '../../config/config';

export const memberFooterLinkGroups = [
    {
        label: getContent(['components', 'footer', 'member', 'menuLinksLabel']),
        links: [
            {
                label: getContent(['components', 'footer', 'member', 'dashboardLinkLabel']),
                to: URLS.MEMBER.HOME,
            },
            {
                label: getContent(['components', 'footer', 'member', 'wellbeingTrackerLinkLabel']),
                to: URLS.MEMBER.DASHBOARD,
            },
            {
                label: getContent(['components', 'footer', 'member', 'healthServicesLinkLabel']),
                to: URLS.MEMBER.SERVICES,
            },
            {
                label: getContent(['components', 'footer', 'member', 'healthyBitesLinkLabel']),
                to: URLS.MEMBER.ARTICLES,
            },
            {
                label: getContent(['components', 'footer', 'member', 'memberEventsLinkLabel']),
                to: URLS.MEMBER.EVENTS,
            },
            {
                label: getContent(['components', 'footer', 'member', 'helpAndSupportLinkLabel']),
                to: URLS.MEMBER.HELP,
            },
        ],
    },
    {
        label: getContent(['components', 'footer', 'member', 'legalsLinksLabel']),
        links: [
            {
                label: getContent(['components', 'footer', 'member', 'termsAndConditionsLinkLabel']),
                to: URLS.MEMBER.TERMS,
            },
            {
                label: getContent(['components', 'footer', 'member', 'breezePrivacyLinkLabel']),
                to: URLS.MEMBER.PRIVACY_EXTERNAL,
                target: '_blank',
            },
            {
                label: getContent(['components', 'footer', 'member', 'cookiePolicyLinkLabel']),
                to: URLS.MEMBER.COOKIES,
                target: '_blank',
            },
        ],
    },
];
export const contactFooterLinkGroups = [
    {
        label: getContent(['components', 'footer', 'contact', 'exploreBreezeLabel']),
        links: [
            {
                label: getContent(['components', 'footer', 'contact', 'dashboardLinkLabel']),
                to: URLS.CONTACT.HOME,
            },
            {
                label: getContent(['components', 'footer', 'contact', 'wellbeingTrackerLinkLabel']),
                to: URLS.CONTACT.DASHBOARD,
            },
            {
                label: getContent(['components', 'footer', 'contact', 'healthServicesLinkLabel']),
                to: URLS.CONTACT.SERVICES,
            },
            {
                label: getContent(['components', 'footer', 'contact', 'healthyBitesLinkLabel']),
                to: URLS.CONTACT.ARTICLES,
            },
            {
                label: getContent(['components', 'footer', 'contact', 'membersLinkLabel']),
                to: URLS.CONTACT.EMPLOYEES,
            },
            {
                label: getContent(['components', 'footer', 'contact', 'eventsLinkLabel']),
                to: URLS.CONTACT.EVENTS,
            },
            {
                label: getContent(['components', 'footer', 'contact', 'adminLinkLabel']),
                to: URLS.CONTACT.ADMIN.ADMINISTRATORS,
            },
            {
                label: getContent(['components', 'footer', 'contact', 'helpAndSupportLinkLabel']),
                to: URLS.CONTACT.HELP,
            },
        ],
    },
    {
        label: getContent(['components', 'footer', 'member', 'legalsLinksLabel']),
        links: [
            {
                label: getContent(['components', 'footer', 'member', 'termsAndConditionsLinkLabel']),
                to: URLS.CONTACT.TERMS,
            },
            {
                label: getContent(['components', 'footer', 'member', 'breezePrivacyLinkLabel']),
                to: URLS.CONTACT.PRIVACY_EXTERNAL,
                target: '_blank',
            },
            {
                label: getContent(['components', 'footer', 'member', 'cookiePolicyLinkLabel']),
                to: URLS.CONTACT.COOKIES,
                target: '_blank',
            },
        ],
    },
    {
        label: getContent(['components', 'footer', 'contact', 'companyWellbeingLabel']),
        links: [
            {
                label: getContent(['components', 'footer', 'contact', 'visitHealthShieldLinkLabel']),
                to: URLS.CONTACT.HEALTH_SHIELD_EXTERNAL,
            },
        ],
    },
];

export const guestFooterLinkGroups = [
    {
        label: getContent(['components', 'footer', 'guest', 'legalsLinksLabel']),
        links: [
            {
                label: getContent(['components', 'footer', 'guest', 'termsAndConditionsLinkLabel']),
                to: URLS.MEMBER.REGISTER.TERMS,
                target: '_blank',
            },
            {
                label: getContent(['components', 'footer', 'guest', 'breezePrivacyLinkLabel']),
                to: URLS.MEMBER.PRIVACY_EXTERNAL,
                target: '_blank',
            },
            {
                label: getContent(['components', 'footer', 'guest', 'cookiePolicyLinkLabel']),
                to: URLS.MEMBER.REGISTER.COOKIES,
                target: '_blank',
            },
        ],
    },
];

export const guestContactFooterLinkGroups = [
    {
        label: getContent(['components', 'footer', 'guest', 'legalsLinksLabel']),
        links: [
            {
                label: getContent(['components', 'footer', 'guest', 'termsAndConditionsLinkLabel']),
                to: URLS.CONTACT.REGISTER.TERMS,
                target: '_blank',
            },
            {
                label: getContent(['components', 'footer', 'guest', 'breezePrivacyLinkLabel']),
                to: URLS.CONTACT.PRIVACY_EXTERNAL,
                target: '_blank',
            },
            {
                label: getContent(['components', 'footer', 'guest', 'cookiePolicyLinkLabel']),
                to: URLS.CONTACT.REGISTER.COOKIES,
                target: '_blank',
            },
        ],
    },
];
