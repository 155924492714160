import MuiAppBar from './mui-app-bar';
import MuiButton from './mui-button';
import MuiCheckbox from './mui-checkbox';
import MuiAccordion from './mui-accordion';
import MuiFormControlLabel from './mui-form-control-label';
import MuiInputAdornment from './mui-input-adornment';
import MuiInputLabel from './mui-input-label';
import MuiLink from './mui-link';
import MuiOutlinedInput from './mui-outlined-input';
import MuiPickers from './mui-pickers';
import MuiSelect from './mui-select';
import MuiSlider from './mui-slider';
import MuiStepIcon from './mui-step-icon';
import MuiStepConnector from './mui-step-connector';
import MuiStepLabel from './mui-step-label';
import MuiTable from './mui-table';
import MuiTypography from './mui-typography';

export default {
    MuiAppBar,
    MuiButton,
    MuiCheckbox,
    MuiFormControlLabel,
    MuiInputAdornment,
    MuiInputLabel,
    MuiLink,
    MuiOutlinedInput,
    MuiSelect,
    MuiSlider,
    MuiStepConnector,
    MuiStepIcon,
    MuiStepLabel,
    MuiTypography,
    ...MuiAccordion,
    ...MuiTable,
    ...MuiPickers,
};
