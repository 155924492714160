import {
    ADD_COMPANY_MEMBERS_RESET,
} from './types';

/**
 * Action helper which resets the add members of a company to the redux store
 */

const resetAddCompanyMembersAction = () => (dispatch) => {
    dispatch({
        type: ADD_COMPANY_MEMBERS_RESET,
    });
};

export default resetAddCompanyMembersAction;
