import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Icon from '../../icon/Icon';

const MetricCard = ({
    classes,
    title,
    description,
    icon,
    iconSize,
}) => (
    <Card className={`${classes.card} aut-metricCard`} elevation={0}>
        <CardContent className={`${classes.content} aut-metricCard-content`}>
            {!description && (
                <Typography variant="h4" className={`${classes.title} aut-metricCard-content_title`}>
                    {title}
                </Typography>
            )}
            <div className={`${classes.icon} aut-metricCard-content_icon`}>
                {iconSize === 'large' ? (
                    <img src={icon} alt={title} />
                ) : (
                    <Icon
                        src={icon}
                        alt={title}
                        height={120}
                    />
                )}
            </div>
            {description && (
                <Box className={`${classes.description} aut-metricCard-content-desription`}>
                    <Typography variant="h4" className={`${classes.title} aut-metricCard-content-description_title`}>
                        {title}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        className="aut-metricCard-description_subTitle"
                    >
                        {description}
                    </Typography>
                </Box>
            )}
        </CardContent>
    </Card>
);

const styles = theme => ({
    card: {
        height: '100%',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.shadows[25],
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: '1 1 auto',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0,
    },
    title: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flex: 1,
    },
    icon: ({ iconSize }) => ({
        background: theme.palette.background.greyFade,
        backgroundPosition: '0 20px',
        width: '100%',
        height: 120,
        '& img': {
            minHeight: iconSize === 'large' ? 110 : 80,
        },
    }),
    description: {
        flex: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        '& h4': {
            marginBottom: theme.spacing(1),
        },
    },
});

MetricCard.defaultProps = {
    description: null,
    iconSize: 'small',
};

MetricCard.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string.isRequired,
    iconSize: PropTypes.oneOf(['small', 'large']),
};

export default withStyles(styles)(MetricCard);
