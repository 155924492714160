import updateCompanyMembersUploadViewedQuery from '../../services/graphQL/company-members/updateCompanyMembersUploadViewedQuery';
import {
    ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_REQUESTED,
    ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_RESOLVED,
    ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_ERROR,
} from './types';

/**
 * Action helper which resolves the members upload viewed of a company to the redux store
 */

const updateCompanyMembersUploadViewedAction = async () => async (dispatch, getState) => {
    const { companyMembers } = getState();

    if (companyMembers.getIn(['membersUploadStatus', 'resolving'])) return false;

    dispatch({
        type: ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_REQUESTED,
    });

    try {
        const payload = await updateCompanyMembersUploadViewedQuery();
        return dispatch({
            type: ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_RESOLVED,
            payload,
        });
    } catch (error) {
        return dispatch({
            type: ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_ERROR,
        });
    }
};

export default updateCompanyMembersUploadViewedAction;
