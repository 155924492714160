import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const MuiFileInput = ({
    input: {
        value: omitValue, onChange, // eslint-disable-line no-unused-vars
    },
    classes: {
        textField,
        fileInputOverlay,
        fileInput,
        fileInputBtn,
    },
    input,
    accept,
}) => {
    const displayFileName = () => (input.value ? input.value.name : 'e.g. myemployees.csv');

    return (
        <div className={fileInput}>
            <div className={fileInputOverlay}>{displayFileName()}</div>
            <Button color="primary" variant="contained" size="large" className={fileInputBtn}>Browse</Button>
            <input
                type="file"
                id={input.name}
                name={input.name}
                accept={accept}
                variant="outlined"
                className={textField}
                onChange={adaptFileEventToValue(onChange)}
            />
        </div>
    );
};

const styles = theme => ({
    fileInput: {
        display: 'flex',
        cursor: 'pointer',
        position: 'relative',
        '& input': {
            top: 0,
            left: 0,
            width: '100%',
            cursor: 'inherit',
            height: '100%',
            margin: 0,
            opacity: 0,
            padding: 0,
            position: 'absolute',
        },
    },
    fileInputOverlay: {
        display: 'inline-block',
        width: '265px',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.palette.common.greyDark,
        padding: '16px 14px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        pointerEvents: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        overflow: 'unset',
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.common.white,
    },
    fileInputBtn: {
        height: '52px',
        lineHeight: 1,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
});

MuiFileInput.defaultProps = {
    input: null,
    accept: null,
};

MuiFileInput.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    input: fieldPropTypes.input,
    accept: PropTypes.string,
};

export default withStyles(styles)(MuiFileInput);
