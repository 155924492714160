import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import variables from '../../../theme/variables';

const MuiTickBox = props => (
    <Checkbox
        {...props}
        icon={(
            <svg height="28" width="28">
                <circle cx="14" cy="14" r="13" strokeWidth="1" stroke={variables.colors.grey} fill={variables.colors.white} />
            </svg>
        )}
        checkedIcon={(
            <svg height="28" width="28">
                <circle cx="14" cy="14" r="13" strokeWidth="1" stroke={variables.colors.green} fill={variables.colors.green} />
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(2.000000, 1.000000)" fill={variables.colors.white}>
                        <path
                            d="M9.61442533,18.5843872
                                L6.34776704,14.9454366
                                C5.8677837,14.4108864
                                5.88668068,13.5649607
                                6.39186,13.0558016
                                C6.89325992,12.5466426
                                7.69071256,12.5666882
                                8.1732155,13.1025748
                                L10.4584905,15.6470338
                                L16.7650433,7.48846119
                                C17.2059729,6.92050159
                                18.0009059,6.83363718
                                18.53884,7.30270499
                                C19.0780339,7.7717728
                                19.1548816,8.61368938
                                18.7139521,9.18432173
                                L11.5016039,18.5108866
                                C11.2735803,18.8075619
                                10.9359543,18.9852999
                                10.574392,19
                                C10.5592744,19
                                10.5454166,19
                                10.5277795,19
                                C10.1838544,19
                                9.8537871,18.8503259
                                9.61442533,18.5843872 Z"
                            id="Fill-1-Copy-3"
                        />
                    </g>
                </g>
            </svg>
        )}
    />
);

MuiTickBox.defaultProps = {
    checked: false,
};

MuiTickBox.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
};

export default MuiTickBox;
