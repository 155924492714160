import * as Immutable from 'immutable';
import {
    ADDRESS_SEARCH_TERM_SET,
    ADDRESS_SEARCH_RESULTS_CLEAR,
    ADDRESS_SEARCH_RESULT_SELECTED,
    ADDRESS_SEARCH_REQUESTED,
    ADDRESS_SEARCH_RESOLVED,
    ADDRESS_SEARCH_ERROR,
} from '../actions/address-search/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    addressSearchTerm: '',
    addressSearchResults: [],
    addressSelected: null,
};

const initialState = Immutable.Map(schema);
/**
 * The member reducer - responsible for managing a member and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const addressSearchReducer = (state = initialState, action) => {
    switch (action.type) {
    case ADDRESS_SEARCH_TERM_SET:
        return state.set('addressSearchTerm', action.payload);

    case ADDRESS_SEARCH_RESULTS_CLEAR:
        return state.set('addressSearchResults', []);

    case ADDRESS_SEARCH_REQUESTED:
        return state.set('resolving', true);

    case ADDRESS_SEARCH_RESOLVED:
        return state
            .set('status', 'OK')
            .set('addressSearchResults', action.payload)
            .set('resolving', false);

    case ADDRESS_SEARCH_RESULT_SELECTED:
        return state.set('addressSelected', action.payload);

    case ADDRESS_SEARCH_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('addressSearchTerm', '')
            .set('addressSearchResults', []);

    default:
        return state;
    }
};

export { initialState };

export default addressSearchReducer;
