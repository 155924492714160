import * as Immutable from 'immutable';
import {
    PERKS_OFFERS_REQUESTED,
    PERKS_OFFERS_RESOLVED,
    PERKS_OFFERS_ERROR,
    PERKS_OFFERS_NEXT_REQUESTED,
    PERKS_OFFERS_NEXT_RESOLVED,
    PERKS_OFFERS_NEXT_ERROR,
    PERKS_CATEGORIES_RESOLVED,
    PERKS_CATEGORIES_ERROR,
    PERKS_SUBCATEGORIES_RESOLVED,
    PERKS_SUBCATEGORIES_ERROR,
} from '../actions/perks/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    resolvingMore: false,
    offers: [],
    categories: [],
    subCategories: [],
    panelCategories: [],
    pageInfo: {
        page: 1,
        totalCount: null,
        hasNextPage: false,
    },
};

const initialState = Immutable.Map(schema);

/**
 * The perks reducer - responsible for managing the perks service and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const perksReducer = (state = initialState, action) => {
    switch (action.type) {
    case PERKS_OFFERS_REQUESTED:
        return state.set('resolving', true);
    case PERKS_OFFERS_NEXT_REQUESTED:
        return state.set('resolvingMore', true);

    case PERKS_OFFERS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('offers', action.payload.perks.offers)
            .set('pageInfo', action.payload.perks.pageInfo);

    case PERKS_OFFERS_NEXT_RESOLVED:
        return state
            .set('resolving', false)
            .set('resolvingMore', false)
            .set('status', 'OK')
            .update('offers', offers => offers.concat(action.payload.perks.offers))
            .set('pageInfo', action.payload.perks.pageInfo);

    case PERKS_CATEGORIES_RESOLVED:
        return state
            .set('status', 'OK')
            .set('categories', action.payload.categories)
            .set('panelCategories', action.payload.panelCategories);

    case PERKS_SUBCATEGORIES_RESOLVED:
        return state
            .set('status', 'OK')
            .set('subCategories', action.payload.categories);

    case PERKS_OFFERS_ERROR:
    case PERKS_OFFERS_NEXT_ERROR:
    case PERKS_CATEGORIES_ERROR:
    case PERKS_SUBCATEGORIES_ERROR:
        return state
            .set('resolving', false)
            .set('resolvingMore', false)
            .set('status', 'ERROR')
            .set('offers', [])
            .set('categories', [])
            .set('subCategories', [])
            .set('pageInfo', schema.pageInfo);

    default:
        return state;
    }
};

export { initialState };

export default perksReducer;
