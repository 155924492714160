import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Icon from '../../icon/Icon';
import { URLS } from '../../../config/config';

const ServiceQuoteCard = ({
    classes,
    title,
    description,
    link,
    icon,
    quote,
    source,
}) => {
    const { location } = useHistory();
    const articleLinkPrefix = location.pathname.includes('admin') ? URLS.CONTACT.HOME : '';

    return (
        <Card className={classes.card}>
            <CardHeader
                classes={{ root: classes.header, avatar: classes.avatar }}
                avatar={<Icon src={icon} alt={title} height={120} />}
            />
            <CardContent
                className={classes.content}
            >
                <Typography variant="caption" gutterBottom>Service</Typography>
                <Typography variant="h2" color="textSecondary" gutterBottom>{title}</Typography>
                <Typography variant="body1" className={classes.description}>{description}</Typography>
                <Typography variant="body2" color="primary">
                    <Link
                        component={RouterLink}
                        to={`${articleLinkPrefix}${link}`}
                        color="textSecondary"
                        underline="none"
                        className={classes.link}
                    >
                        Learn more
                    </Link>
                </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
                <Typography variant="body1" color="textSecondary" className={classes.quote}>
                    &quot;
                    {quote}
                    &quot;
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>{source}</Typography>
            </CardActions>
        </Card>
    );
};

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: theme.shadows[25],
        borderRadius: 15,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    header: {
        padding: theme.spacing(4),
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
        minWidth: 120,
        borderRight: 'none',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'none',
        },
    },
    avatar: {
        flex: 0,
        display: 'flex',
        flexDirection: 'column-reverse',
        marginRight: 0,
        '& img': {
            width: 100,
            height: 100,
            overflow: 'hidden',
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& span': {
            fontWeight: 'bold',
        },
        '& h2': {
            fontFamily: theme.typography.h1.fontFamily,
            fontWeight: 400,
        },
    },
    actions: {
        background: theme.palette.background.gradient,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: theme.spacing(3),
        width: 295,
        '& > :not(:first-child)': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
    description: {
        marginBottom: theme.spacing(3),
    },
    quote: {
        fontStyle: 'italic',
        fontSize: 18,
    },
    link: {
        color: theme.palette.primary.main,
        textAlign: 'left',
        textDecoration: 'underline',
        padding: 0,
        fontWeight: 'bold',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

ServiceQuoteCard.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
};

export default withStyles(styles)(ServiceQuoteCard);
