import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = (page, opts = {}) => {
        ReactGA.set({
            page,
            options: { ...options, ...opts },
        });
        ReactGA.pageview(page);
    };

    const HOC = class extends Component {
        componentDidMount() {
            const { location: { pathname } } = this.props;
            // If we're in the reg process then track page
            if (pathname.indexOf('/register') !== -1) {
                trackPage(pathname);
            }
        }

        componentWillReceiveProps(nextProps) {
            const { location: { pathname }, contactId } = this.props;
            const { contactId: nextContactId } = nextProps;
            const nextPage = nextProps.location.pathname;
            // If we have a contact Id then set ga with
            // contact id referenced
            if (contactId !== nextContactId) {
                ReactGA.set({ userId: nextContactId });
                trackPage(pathname);
            }

            if (pathname !== nextPage) {
                // Scroll to the top of the new page
                window.scrollTo(0, 0);
                // Track page in Google Analytics
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    HOC.defaultProps = {
        contactId: null,
    };

    HOC.propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        contactId: PropTypes.string,
    };

    const mapStateToProps = state => ({
        contactId: state.member.getIn(['member', 'contactId']),
    });

    return connect(mapStateToProps)(HOC);
};

export default withTracker;
