import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { getEnvironmentVariable } from '../../services/environment/environment-service';

class AutomaticLogout extends React.Component {
    state = {
        inactivityTimer: 0,
    };

    /**
     * Inactivity timer id
     * @type {Object}
     */
    sessionInactivityTimerId = null;

    /**
     * Inactivity time limit
     * @type {Number}
     */
    sessionInactivityTimerLimit = getEnvironmentVariable('REACT_APP_MAX_INACTIVTY_TIME') || 900;

    /**
     * Events to track
     * @type {Array}
     */
    events = [
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress',
    ];

    componentDidMount() {
        // Add the window evetns to reset the timer
        // for (const i in this.events) {
        this.events.forEach((event) => {
            window.addEventListener(event, this.resetInactivityTimer);
        });
        // Start inactivity timer
        this.inactivityTick();
    }

    componentDidUpdate(prevProps) {
        const { authenticated, route, enabled } = this.props;
        const { authenticated: prevAuthenticated, route: prevRoute, enabled: prevEnabled } = prevProps;
        // If the auth state has changed or route has changed
        // reset the timer
        if ((prevAuthenticated !== authenticated) || (prevRoute !== route) || (enabled && !prevEnabled)) {
            // reset timer
            this.resetInactivityTimer();
        }
    }

    componentWillUnmount() {
        // Remove the window events
        this.events.forEach((event) => {
            window.removeEventListener(event, this.resetInactivityTimer);
        });
        // Clear inactivity timer
        clearInterval(this.sessionInactivityTimerId);
    }

    /**
     * Check if the auth token has expired
     * @return {Bool}
     */
    hasTokenExpired = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            if (decodedToken && moment().unix() < decodedToken.exp) {
                return false;
            }
            return true;
        } catch (error) {
            return true;
        }
    }

    /**
     * Has the inactivity limit been reached?
     * @return {Bool}
     */
    sessionInactivityTimerLimitReached = () => {
        const { inactivityTimer } = this.state;
        return this.sessionInactivityTimerLimit <= inactivityTimer;
    };

    /**
     * Perform the tick to increment the inactity timer
     */
    inactivityTick = () => {
        this.sessionInactivityTimerId = setInterval(() => {
            const { inactivityTimer } = this.state;
            const { authenticated, token, enabled } = this.props;
            // Has the inactivity timeout been reached?
            if ((authenticated && enabled && this.sessionInactivityTimerLimitReached()) || this.hasTokenExpired(token)) {
                this.expireSession();
            } else {
                // Update the state timer
                this.setState({
                    inactivityTimer: inactivityTimer + 1,
                });
            }
        }, 1000);
    };

    /**
     * Reset the inactivity timer
     */
    resetInactivityTimer = () => {
        this.setState({
            inactivityTimer: 0,
        });
    };

    /**
     * Expire the session and logout
     */
    expireSession = () => {
        const { logout } = this.props;
        clearInterval(this.sessionInactivityTimerId);
        // Clear inactivity timer
        this.resetInactivityTimer();
        // Logout
        logout();
    };

    render() {
        const { children } = this.props;
        return children;
    }
}

const mapStateToProps = state => ({
    authenticated: state.auth.get('authenticated'),
    route: state.router.location.pathname,
    token: state.auth.getIn(['authentication', 'idToken']),
    enabled: state.auth.get('autologout'),
});

AutomaticLogout.defaultProps = {
    authenticated: false,
    route: null,
    token: null,
    enabled: true,
};

AutomaticLogout.propTypes = {
    authenticated: PropTypes.bool,
    route: PropTypes.string,
    logout: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    token: PropTypes.string,
    enabled: PropTypes.bool,
};

export default connect(mapStateToProps)(AutomaticLogout);
