import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '../../layout/Container/Container';
import SectionContainer from '../../layout/SectionContainer/SectionContainer';
import getContent from '../../../services/content/getContent';
import HealthQuizExpert from '../../../assets/images/questionnaires/experts/DarrylEdwards.jpg';
import FinancialWellbeingExpert from '../../../assets/images/questionnaires/experts/IonaBain.jpg';
import MentalHealthExpert from '../../../assets/images/questionnaires/experts/AndresPhoto.png';
import MetricCard from './MetricCard';
import ExpertQuote from './ExpertQuote';
import AssessmentSteps from './AssessmentSteps';
import MuiPrimaryButton from '../../material-ui/MuiPrimaryButton/MuiPrimaryButton';
import AssessmentIcon from '../../../assets/images/icons/assessment-icon.svg';
import TrafficLightIcon from '../../../assets/images/icons/traffic-light-icon.svg';
import RecommendationsIcon from '../../../assets/images/icons/recommendations-icon.svg';
import { getTrackerThemeIcon, getWellbeingThemeId, fuzzyMapIconPendingIconUrl } from '../../../services/tracker/tracker';

const icons = [AssessmentIcon, TrafficLightIcon, RecommendationsIcon];

const QuestionnaireIntro = ({
    classes,
    code,
    onStartQuiz,
}) => {
    const metricCards = getContent([
        'components',
        'questionnaires',
        'intro',
        code,
        'Metrics',
        'cards',
    ]).toJS();

    const expertAvatars = {
        HealthQuiz: HealthQuizExpert,
        FinancialWellbeing: FinancialWellbeingExpert,
        MentalHealth: MentalHealthExpert,
    };

    return (
        <div className="aut-questionnaireIntro">
            <Container>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                >
                    <Grid item xs={12} sm={12} md={11} lg={11}>
                        <Typography className={`${classes.title} aut-questionnaireIntro-title`} variant="h1" align="center">
                            {getContent(['components', 'questionnaires', 'intro', code, 'title'])}
                        </Typography>
                        <Typography
                            className={`${classes.subtitle} aut-questionnaireIntro-subTitle`}
                            variant="subtitle1"
                            align="center"
                            color="textPrimary"
                        >
                            {getContent(['components', 'questionnaires', 'intro', code, 'description'])}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <div className="aut-questionnaireIntro-midSection">
                <SectionContainer
                    className="aut-questionnaireIntro-midSection_title"
                    description={getContent(['components', 'questionnaires', 'intro', code, 'Metrics', 'title'])}
                    subDescription={(
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            align="center"
                            className={`${classes.subDescription} aut-questionnaireIntro-midsection_subTitle`}
                        >
                            {getContent(['components', 'questionnaires', 'intro', code, 'Metrics', 'description'])}
                        </Typography>
                    )}
                    backgroundColor="gradient"
                >
                    <Grid
                        className="aut-questionnaireIntro-midSection-grids"
                        container
                        spacing={4}
                        justifyContent="center"
                    >
                        {metricCards.map(card => (
                            <Grid
                                item
                                xs={12}
                                sm={metricCards.length > 3 ? 4 : (12 / metricCards.length)}
                                md={metricCards.length > 3 ? 3 : (12 / metricCards.length)}
                                key={card.title.replace(/ /g, '')}
                            >
                                <MetricCard
                                    title={card.title}
                                    description={card.description}
                                    icon={getTrackerThemeIcon(getWellbeingThemeId({ name: card.title })) || fuzzyMapIconPendingIconUrl(card.title)}
                                    iconSize={metricCards.length === 2 ? 'large' : 'small'}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={6} md={4}>
                            <MuiPrimaryButton
                                size="large"
                                fullWidth
                                onClick={onStartQuiz}
                                className={`${classes.button} aut-questionnaireIntro-midSection-grids_button`}
                            >
                                {getContent(['components', 'questionnaires', 'startQuestionnaireBtnLabel'])}
                            </MuiPrimaryButton>
                        </Grid>
                    </Grid>
                </SectionContainer>
            </div>
            <div className="aut-questionnaireIntro-expertSection">
                <SectionContainer
                    backgroundColor="grey"
                >
                    <ExpertQuote
                        avatar={expertAvatars[code]}
                        name={getContent(['components', 'questionnaires', 'intro', code, 'ExpertQuote', 'name'])}
                        description={getContent(['components', 'questionnaires', 'intro', code, 'ExpertQuote', 'description'])}
                        quote={getContent(['components', 'questionnaires', 'intro', code, 'ExpertQuote', 'quote'])}
                    />
                </SectionContainer>
            </div>
            <div className="aut-questionnaireIntro-stepSection">
                <SectionContainer
                    description={getContent(['components', 'questionnaires', 'intro', 'Steps', 'title'])}
                    backgroundColor="white"
                    className={classes.container}
                >
                    <AssessmentSteps
                        steps={getContent([
                            'components',
                            'questionnaires',
                            'intro',
                            'Steps',
                            'steps',
                        ]).toJS().map((step, index) => ({ ...step, icon: icons[index] }))}
                    />
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                align="center"
                                className="aut-questionnaireIntro-stepSection_intro"
                                dangerouslySetInnerHTML={{ __html: getContent(['components', 'questionnaires', 'intro', 'Steps', 'footer']) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <MuiPrimaryButton
                                size="large"
                                fullWidth
                                onClick={onStartQuiz}
                                className={`${classes.button} aut-questionnaireIntro-stepSection_button`}
                            >
                                {getContent(['components', 'questionnaires', 'startQuestionnaireBtnLabel'])}
                            </MuiPrimaryButton>
                        </Grid>
                    </Grid>
                </SectionContainer>
            </div>
        </div>
    );
};

const styles = theme => ({
    title: {
        marginTop: theme.spacing(8),
        fontSize: 55,
    },
    subtitle: {
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(8),
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
    },
    subDescription: {
        fontSize: 18,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(6),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 760,
    },
    container: {
        background: theme.palette.background.greyFade,
        backgroundPosition: '0 0',
    },
    button: {
        marginTop: theme.spacing(2),
    },
});

QuestionnaireIntro.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    code: PropTypes.string.isRequired,
    onStartQuiz: PropTypes.func.isRequired,
};

export default withStyles(styles)(QuestionnaireIntro);
