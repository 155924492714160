import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getArticleFavouritesAction from '../../actions/healthy-bites/getArticleFavourites';

/**
 * Get a users article favourites and save them in the redux store
 */
class Favourites extends React.Component {
    componentDidMount = () => {
        const { getArticleFavourites } = this.props;
        getArticleFavourites();
    };

    render = () => null;
}

const mapDispatchToProps = dispatch => ({
    getArticleFavourites: async () => {
        await dispatch(await getArticleFavouritesAction());
    },
});

Favourites.propTypes = {
    getArticleFavourites: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Favourites);
