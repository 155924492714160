import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import QuestionnaireResultsPriorityAnswerPanel from '../QuestionnaireResultsPriorityAnswerPanel/QuestionnaireResultsPriorityAnswerPanel';

const QuestionnaireResultsPrioritiesAnswers = ({ answers, panelResize }) => {
    const theme = useTheme();

    const getItemCount = () => {
        let count = 0;
        answers.forEach((answer) => {
            count += answer.items.length;
        });
        return count;
    };
    const itemCount = getItemCount();
    const fullPanelLimit = itemCount < 5 ? itemCount : 5;

    /**
     * Make column full size if the other columns have no entries
     * or if the current column has more than or equal to 5 items
     * @param {Number} key
     * @return {Boolean}
     */
    const isFullColumnSize = key => answers
        .filter((answer, index) => index !== key && answer.items && answer.items.length > 0).length === 0
        || answers[key].items.length >= fullPanelLimit;

    const hasFullColumnSize = () => {
        let found = false;
        answers.forEach((answer, key) => {
            if (isFullColumnSize(key)) {
                found = true;
            }
        });
        return found;
    };

    const getColumnSize = (key, defaultSize) => {
        if (!panelResize) {
            return defaultSize;
        }
        if (isFullColumnSize(key)) {
            return 12;
        }
        if (hasFullColumnSize()) {
            return 6;
        }
        return defaultSize;
    };

    return (
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="stretch"
        >
            {answers.map((answer, key) => (
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={getColumnSize(key, 4)}
                    lg={getColumnSize(key, 4)}
                    key={answer.title.replace(' ', '')}
                    style={{ order: panelResize && isFullColumnSize(key) ? -1 : 0 }}
                >
                    <QuestionnaireResultsPriorityAnswerPanel answer={answer} color={theme.palette.performance[key]} />
                </Grid>
            ))}
        </Grid>
    );
};

QuestionnaireResultsPrioritiesAnswers.defaultProps = {
    panelResize: false,
};

QuestionnaireResultsPrioritiesAnswers.propTypes = {
    answers: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
    panelResize: PropTypes.bool,
};

export default QuestionnaireResultsPrioritiesAnswers;
