import * as Immutable from 'immutable';
import {
    COMPANY_CONTACT_REQUESTED,
    COMPANY_CONTACT_RESOLVED,
    COMPANY_CONTACT_ERROR,
    COMPANY_CONTACT_UPDATE_REQUESTED,
    COMPANY_CONTACT_UPDATE_RESOLVED,
    COMPANY_CONTACT_UPDATE_ERROR,
    COMPANY_CONTACT_UNREGISTERED_REQUESTED,
    COMPANY_CONTACT_UNREGISTERED_RESOLVED,
    COMPANY_CONTACT_UNREGISTERED_ERROR,
    COMPANY_CONTACT_NOTIFICATION_PREFERENCES_REQUESTED,
    COMPANY_CONTACT_NOTIFICATION_PREFERENCES_RESOLVED,
    COMPANY_CONTACT_NOTIFICATION_PREFERENCES_ERROR,
    COMPANY_CONTACT_REGISTRATION_COMPLETE_REQUESTED,
    COMPANY_CONTACT_REGISTRATION_COMPLETE_RESOLVED,
    COMPANY_CONTACT_REGISTRATION_COMPLETE_ERROR,
    COMPANY_CONTACT_COMPANYPAYMENT_REQUESTED,
    COMPANY_CONTACT_COMPANYPAYMENT_RESOLVED,
    COMPANY_CONTACT_COMPANYPAYMENT_ERROR,
    COMPANY_CONTACT_COMPANYPAYMENT_CREATE_REQUESTED,
    COMPANY_CONTACT_COMPANYPAYMENT_CREATE_RESOLVED,
    COMPANY_CONTACT_COMPANYPAYMENT_CREATE_ERROR,
    COMPANY_CONTACT_CHECK_INITIALCONTACT_REQUESTED,
    COMPANY_CONTACT_CHECK_INITIALCONTACT_RESOLVED,
    COMPANY_CONTACT_CHECK_INITIALCONTACT_ERROR,
    COMPANY_CONTACT_COMPANYDETAILS_REQUESTED,
    COMPANY_CONTACT_COMPANYDETAILS_RESOLVED,
    COMPANY_CONTACT_COMPANYDETAILS_ERROR,
    COMPANY_CONTACT_COMPANYDETAILS_UPDATE_RESOLVED,
    COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_REQUESTED,
    COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_RESOLVED,
    COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_ERROR,
} from '../actions/company-contact/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    contact: null,
    unregisteredContact: null,
    notificationPreferences: null,
    paymentDetails: null,
    companyDetails: null,
    initialUser: null,
};

const initialState = Immutable.Map(schema);
/**
 * The company contact reducer - responsible for managing an contact of a company and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const companyContactReducer = (state = initialState, action) => {
    switch (action.type) {
    case COMPANY_CONTACT_COMPANYPAYMENT_REQUESTED:
    case COMPANY_CONTACT_UPDATE_REQUESTED:
    case COMPANY_CONTACT_REQUESTED:
    case COMPANY_CONTACT_UNREGISTERED_REQUESTED:
    case COMPANY_CONTACT_NOTIFICATION_PREFERENCES_REQUESTED:
    case COMPANY_CONTACT_REGISTRATION_COMPLETE_REQUESTED:
    case COMPANY_CONTACT_CHECK_INITIALCONTACT_REQUESTED:
    case COMPANY_CONTACT_COMPANYDETAILS_REQUESTED:
    case COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_REQUESTED:
    case COMPANY_CONTACT_COMPANYPAYMENT_CREATE_REQUESTED:
        return state.set('resolving', true);

    case COMPANY_CONTACT_UPDATE_RESOLVED:
    case COMPANY_CONTACT_RESOLVED:
    case COMPANY_CONTACT_REGISTRATION_COMPLETE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('contact', action.payload);

    case COMPANY_CONTACT_UNREGISTERED_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('unregisteredContact', action.payload);

    case COMPANY_CONTACT_NOTIFICATION_PREFERENCES_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('notificationPreferences', action.payload);

    case COMPANY_CONTACT_COMPANYPAYMENT_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('paymentDetails', action.payload);

    case COMPANY_CONTACT_COMPANYPAYMENT_CREATE_RESOLVED:
    case COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('paymentDetails', action.payload);

    case COMPANY_CONTACT_CHECK_INITIALCONTACT_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('initialUser', action.payload.initialCompanyContact);

    case COMPANY_CONTACT_COMPANYDETAILS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('companyDetails', action.payload);

    case COMPANY_CONTACT_COMPANYDETAILS_UPDATE_RESOLVED:
        return state
            .set('companyDetails', action.payload);

    case COMPANY_CONTACT_ERROR:
    case COMPANY_CONTACT_UNREGISTERED_ERROR:
    case COMPANY_CONTACT_NOTIFICATION_PREFERENCES_ERROR:
    case COMPANY_CONTACT_COMPANYPAYMENT_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('contact', null)
            .set('unregisteredContact', null)
            .set('notificationPreferences', null);


    case COMPANY_CONTACT_UPDATE_ERROR:
    case COMPANY_CONTACT_REGISTRATION_COMPLETE_ERROR:
    case COMPANY_CONTACT_COMPANYDETAILS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_ERROR:
    case COMPANY_CONTACT_COMPANYPAYMENT_CREATE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case COMPANY_CONTACT_CHECK_INITIALCONTACT_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('initialUser', null);

    default:
        return state;
    }
};

export { initialState };

export default companyContactReducer;
