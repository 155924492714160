import React, { useContext } from 'react';
import DeviceContext from '../../../context/device-context';
import Footer from '../Footer';
import { URLS } from '../../../config/config';
import { memberFooterLinkGroups } from '../footer.config';
import PrivacyPanel from '../../privacy-panel/PrivacyPanel';
import MobileAppService from '../../../services/mobile-app/mobile-app';

const MemberFooter = () => {
    const device = useContext(DeviceContext);
    return (
        <>
            <PrivacyPanel link={URLS.MEMBER.PRIVACY_EXTERNAL} />
            {!MobileAppService.isMobileApp(device) && <Footer linkGroups={memberFooterLinkGroups} />}
        </>
    );
};

export default MemberFooter;
