import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiPrimaryButton from '../../material-ui/MuiPrimaryButton/MuiPrimaryButton';
import Container from '../Container/Container';
import SectionHeader from '../SectionHeader/SectionHeader';
import SectionDescription from '../SectionDescription/SectionDescription';

const getBackgroundColor = (backgroundColor, theme) => {
    switch (backgroundColor) {
    case 'grey': return theme.palette.common.greyLight;
    case 'gradient': return theme.palette.background.gradientRadial;
    case 'white': return theme.palette.common.white;
    default: return 'transparent';
    }
};

const SectionContainer = ({
    classes,
    title,
    description,
    subDescription,
    children,
    footerButtonLink,
    footerButtonLinkLabel,
    className,
}) => (
    <Box className={`${classes.box} ${className}`}>
        <Container>
            <Box className={classes.container}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={4}
                >
                    <Grid item xs={12}>
                        {title && <SectionHeader gutterBottom={description === null}>{title}</SectionHeader>}
                        {description && <SectionDescription gutterBottom={subDescription === null}>{description}</SectionDescription>}
                        {subDescription}
                        {children}
                    </Grid>
                    {footerButtonLink && footerButtonLinkLabel && (
                        <Grid item xs={12} sm={6} md={4}>
                            <MuiPrimaryButton
                                size="large"
                                fullWidth
                                component={RouterLink}
                                to={footerButtonLink}
                                className={classes.button}
                            >
                                {footerButtonLinkLabel}
                            </MuiPrimaryButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Container>
    </Box>
);

const styles = theme => ({
    box: ({ backgroundColor, title }) => ({
        paddingTop: title ? theme.spacing(3) : 0,
        background: getBackgroundColor(backgroundColor, theme),
    }),
    container: ({ panelBackgroundColor }) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        background: getBackgroundColor(panelBackgroundColor, theme),
        borderRadius: 10,
        marginLeft: -theme.spacing(2.5),
        marginRight: -theme.spacing(2.5),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        marginBottom: panelBackgroundColor === 'gradient' ? theme.spacing(4) : 0,
        position: 'relative',
    }),
    button: {
        marginTop: theme.spacing(2),
    },
});

styles.defaultProps = {
    backgroundColor: 'transparent',
    panelBackgroundColor: 'transparent',
};

styles.propTypes = {
    backgroundColor: PropTypes.string,
    panelBackgroundColor: PropTypes.string,
};

SectionContainer.defaultProps = {
    title: null,
    description: null,
    subDescription: null,
    children: null,
    footerButtonLink: null,
    footerButtonLinkLabel: null,
    className: '',
};

SectionContainer.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    subDescription: PropTypes.node,
    footerButtonLink: PropTypes.string,
    footerButtonLinkLabel: PropTypes.string,
};

export default withStyles(styles)(SectionContainer);
