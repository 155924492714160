import { gql } from '@apollo/client';
import getContent from '../../content/getContent';
import graphQLCLient from '../client';
import errorHandler from '../errorHandler';

const updateCompanyMembersUploadViewedQuery = async () => {
    const client = graphQLCLient();

    const mutation = gql`mutation UpdateCompanyMembersUploadViewed {
        updateCompanyMembersUploadViewed {
            isViewed
        }
    }`;

    return new Promise((resolve, reject) => {
        client.mutate({
            mutation,
        })
            .then((result) => {
                if (!result.data || !Object.prototype.hasOwnProperty.call(result.data, 'updateCompanyMembersUploadViewed')) {
                    throw new Error(getContent(['errors', 'invalid-graphQL-response']));
                }
                resolve(result.data.updateCompanyMembersUploadViewed);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};

export default updateCompanyMembersUploadViewedQuery;
