import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import HealthyBitesVideo from '../../healthy-bites/Video/Video';
import AuthorLink from '../../healthy-bites/AuthorLink/AuthorLink';
import { URLS } from '../../../config/config';

const QuestionnaireResultsRecommendation = ({
    classes,
    recommendation: {
        article: {
            id,
            title,
            videoUrl,
            thumbnailImage,
            authors,
        },
    },
}) => {
    const { location } = useHistory();
    const articleLinkPrefix = location.pathname.includes('admin') ? URLS.CONTACT.HOME : '';

    const displayMedia = () => {
        if (videoUrl) {
            return <div className={classes.video}><HealthyBitesVideo url={videoUrl} width="265" height="135" /></div>;
        }
        if (thumbnailImage) {
            return (
                <Link component={RouterLink} to={`${articleLinkPrefix}${URLS.MEMBER.ARTICLES}/${id}`}>
                    <CardMedia image={thumbnailImage} title={title} className={classes.media} />
                </Link>
            );
        }
        return <div />;
    };

    return (
        <Card className={classes.card}>
            {displayMedia()}
            <CardContent
                className={classes.content}
            >
                <Typography variant="h3" align="left" className={classes.title}>
                    <Link
                        component={RouterLink}
                        to={`${articleLinkPrefix}${URLS.MEMBER.ARTICLES}/${id}`}
                        color="primary"
                        underline="none"
                        className={classes.link}
                    >
                        {title}
                    </Link>
                </Typography>
                {authors && authors.map(author => (
                    <AuthorLink key={author.id} author={author} />
                ))}
            </CardContent>
        </Card>
    );
};

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row-reverse',
        boxShadow: theme.shadows[25],
        borderRadius: 15,
        minHeight: 160,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: theme.spacing(4),
    },
    media: {
        width: 265,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    video: {
        width: 265,
        height: '100%',
        backgroundColor: theme.palette.common.black,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

QuestionnaireResultsRecommendation.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    recommendation: PropTypes.shape({
        article: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            introduction: PropTypes.string,
            thumbnailImage: PropTypes.string,
            videoUrl: PropTypes.string,
            authors: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string,
            })),
        }),
        category: PropTypes.string,
        banding: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(QuestionnaireResultsRecommendation);
