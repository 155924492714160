import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { MuiSecondaryButton } from '../../material-ui';
import HealthyBitesSVG from '../../../assets/images/healthy-bites/healthy-bites-hero.svg';
import { URLS } from '../../../config/config';

const QuestionnaireArticlesLink = ({ classes }) => (
    <Card className={classes.card} elevation={0}>
        <CardHeader
            classes={{ root: classes.header, avatar: classes.avatar }}
            avatar={<img src={HealthyBitesSVG} alt="Healthy Bites" />}
        />
        <CardContent
            className={classes.content}
        >
            <Typography variant="h2" color="textSecondary" gutterBottom>Healthy Bites.</Typography>
            <Typography variant="body1">
                Our content hub is regularly updated with expert content, bringing the different areas of wellbeing together to help you stay healthy and happy.
            </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
            <MuiSecondaryButton fullWidth elementType="a" href={`${URLS.MEMBER.ARTICLES}?categories=MIND`}>View content</MuiSecondaryButton>
        </CardActions>
    </Card>
);

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: 'transparent',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    avatar: {
        '& img': {
            width: 265,
        },
    },
    content: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(4),
    },
    actions: {
        width: 265,
    },
});

QuestionnaireArticlesLink.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(QuestionnaireArticlesLink);
