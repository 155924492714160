import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const MuiSelect = ({
    menuItems, placeholder, value, fullWidth, input, input: { name },
}) => (
    <Select
        value={value}
        displayEmpty
        IconComponent={props => (<KeyboardArrowDownIcon {...props} />)}
        input={(
            <OutlinedInput
                inputProps={{
                    id: name,
                }}
                name={name}
                fullWidth={fullWidth}
                labelWidth={0}
            />
        )}
        {...input}
    >
        {placeholder
            ? (
                <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>
            ) : null
        }
        {menuItems && menuItems.map(menuItem => (<MenuItem key={menuItem.value} value={menuItem.value}>{menuItem.label}</MenuItem>))}
    </Select>
);

MuiSelect.defaultProps = {
    value: null,
    fullWidth: false,
    placeholder: null,
    input: null,
};

MuiSelect.propTypes = {
    input: fieldPropTypes.input,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    fullWidth: PropTypes.bool,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.mixed,
        label: PropTypes.string,
    })).isRequired,
};

export default MuiSelect;
