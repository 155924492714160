const MEMBER = {
    HOME: '/',
    DASHBOARD: '/dashboard',
    SERVICES: '/your-services',
    REWARDS: '/your-perks',
    PERKS: '/your-perks-0',
    ARTICLES: '/healthy-bites',
    EVENTS: '/events',
    HELP: '/help-and-support',
    WHATS_NEW: '/whats-new',
    HERETOHELP: '/here-to-help',
    TERMS: '/terms',
    PRIVACY: '/privacy-policy',
    COOKIES: 'https://www.healthshield.co.uk/cookie-policy/',
    QUESTIONNAIRES: '/questionnaires',
    ACCOUNT: {
        ROOT: '/account',
        PERSONAL_DETAILS: '/account/my-details',
        INTERESTS: '/account/interests',
        SECURITY: '/account/security',
        MARKETING: '/account/marketing-preferences',
        COMPANY: '/account/your-company',
    },
    REGISTER: {
        ROOT: '/register',
        WELCOME: '/register/welcome',
        ACCOUNT: '/register/account',
        TERMS: '/register/terms',
        COOKIES: 'https://www.healthshield.co.uk/cookie-policy/',
    },
    ACCOUNT_LOCKED: '/account-locked',
    PRIVACY_EXTERNAL: 'https://www.healthshield.co.uk/privacy-policy/',
    HEALTH_SHIELD_EXTERNAL: 'https://www.healthshield.co.uk',
    APPLE_STORE: 'https://apps.apple.com/gb/app/breeze-health-wellbeing/id1536127005',
    GOOGLE_STORE: 'https://play.google.com/store/apps/details?id=com.healthshield.breezedev',
};

const CONTACT = {
    HOME: '/admin',
    DASHBOARD: '/admin/company-dashboard',
    SERVICES: '/admin/your-services',
    SERVICES_EMPLOYEES: '/admin/your-services/employee',
    ARTICLES: '/admin/healthy-bites',
    HELP: '/admin/help-and-support',
    WHATS_NEW: '/admin/whats-new',
    EMPLOYEES: '/admin/your-employees',
    HERETOHELP: '/admin/here-to-help',
    EVENTS: '/admin/events',
    TERMS: '/admin/terms',
    PRIVACY: '/admin/privacy-policy',
    COOKIES: 'https://www.healthshield.co.uk/cookie-policy/',
    ADMIN: {
        ROOT: '/admin/system',
        ADMINISTRATORS: '/admin/system/system-administrators',
        PAYMENT_DETAILS: '/admin/system/payment-details',
        COMPANY: '/admin/system/company-information',
    },
    ACCOUNT: {
        ROOT: '/admin/account',
        PERSONAL_DETAILS: '/admin/account/my-details',
        SECURITY: '/admin/account/security',
        MARKETING: '/admin/account/marketing-preferences',
    },
    REGISTER: {
        ROOT: '/admin/register',
        WELCOME: '/admin/register/welcome',
        ACCOUNT: '/admin/register/account',
        TERMS: '/admin/register/terms',
        COOKIES: 'https://www.healthshield.co.uk/cookie-policy/',
    },
    ACCOUNT_LOCKED: '/admin/account-locked',
    PRIVACY_EXTERNAL: 'https://www.healthshield.co.uk/privacy-policy/',
    HEALTH_SHIELD_EXTERNAL: 'https://www.healthshield.co.uk',
    OCCUPATIONAL_HEALTH_EXTERNAL: 'https://www.healthshield.co.uk/businesses/our-solutions/occupational-health/',
    HEALTH_CHECKS_EXTERNAL: 'https://www.healthshield.co.uk/our-solutions/health-checks/',
    HEALTH_CASH_PLANS_EXTERNAL: 'https://www.healthshield.co.uk/businesses/our-solutions/health-cash-plans/',
};

export default {
    MEMBER,
    CONTACT,
};
