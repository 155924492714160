import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';

const MuiCardActions = ({ classes, children, className }) => (
    <CardActions className={`${classes.root} ${className}`}>
        {children}
    </CardActions>
);

const styles = theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(3),
        alignItems: 'flex-end',
    },
});

MuiCardActions.defaultProps = {
    className: '',
};

MuiCardActions.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default withStyles(styles)(MuiCardActions);
