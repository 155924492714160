import CookieBot from 'react-cookiebot';
import React, { useContext } from 'react';
import DeviceContext from '../../context/device-context';
import MobileAppService from '../../services/mobile-app/mobile-app';

const domainGroupId = '03b1ec0e-d445-4ba1-ae2a-43259c94a903';
function Cookiebot() {
    const device = useContext(DeviceContext);
    if (MobileAppService.isMobileApp(device)) return null;
    return <CookieBot domainGroupId={domainGroupId} />;
}
export default Cookiebot;
