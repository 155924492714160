import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import LifestyleAssessment from '../../lifestyle-assessment/LifestyleAssessment';
import MentalHealthAssessment from '../../mental-health-assessment/MentalHealthAssessment';
import FinancialWellbeingAssessment from '../../financial-wellbeing-assessment/FinancialWellbeingAssessment';

const Questionnaire = lazy(() => import('../container'));

const QuestionnaireRoute = (props) => {
    const { match: { params: { questionnaireId, step } } } = props;
    if (questionnaireId === 'MentalHealth') {
        const MHQuestionnaire = MentalHealthAssessment(Questionnaire);
        return <MHQuestionnaire questionnaireId={questionnaireId} step={step} />;
    }
    if (questionnaireId === 'FinancialWellbeing') {
        const FWQuestionnaire = FinancialWellbeingAssessment(Questionnaire);
        return <FWQuestionnaire questionnaireId={questionnaireId} step={step} />;
    }
    if (questionnaireId === 'HealthQuiz') {
        const LFQuestionnaire = LifestyleAssessment(Questionnaire);
        return <LFQuestionnaire questionnaireId={questionnaireId} step={step} />;
    }

    const Wrapper = () => (
        <Questionnaire
            questionnaireId={questionnaireId}
            step={step}
        />
    );
    return (<Wrapper questionnaireId={questionnaireId} step={step} />);
};

QuestionnaireRoute.defaultProps = {
    match: null,
};

QuestionnaireRoute.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            questionnaireId: PropTypes.string,
            step: PropTypes.string,
        }),
    }),
};

export default QuestionnaireRoute;
