import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AssessmentStep from './AssessmentStep';
import Arrow from '../../../assets/images/questionnaires/arrow.svg';

const AssessmentSteps = ({
    classes,
    steps,
}) => (
    <Box className={`${classes.root} aut-assessmentSteps`}>
        <Grid
            container
            spacing={4}
            justifyContent="center"
        >
            {steps.map(step => (
                <Grid item xs={12} sm={12} md={4} className={classes.steps} key={step.title.replace(/ /g, '')}>
                    <AssessmentStep
                        header={step.header}
                        title={step.title}
                        description={step.description}
                        icon={step.icon}
                        className="aut-assessmentSteps-step"
                    />
                </Grid>
            ))}
        </Grid>
        <Box className={classes.background}>
            <Box className={classes.arrowContainer}>
                <Box className={classes.arrow} />
                <Box className={classes.arrow} />
                <Box className={classes.arrow} />
                <Box className={classes.arrow} />
            </Box>
        </Box>
    </Box>
);

const styles = theme => ({
    root: {
        position: 'relative',
        marginBottom: theme.spacing(2),
    },
    steps: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(8),
        },
    },
    background: {
        position: 'absolute',
        border: `2px solid ${theme.palette.common.green}`,
        borderRadius: 25,
        bottom: 0,
        left: 0,
        right: 0,
        height: '55%',
        zIndex: 0,
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            height: 'auto',
            bottom: '20%',
            top: '15%',
        },
    },
    arrowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        top: -11,
        left: 25,
        right: 25,
        height: 17,
        backgroundColor: theme.palette.common.white,
        '& > div:first-child': {
            backgroundPosition: '-40px 0',
        },
        '& > div:last-child': {
            backgroundPosition: '25px 0',
        },
        [theme.breakpoints.down('sm')]: {
            top: -11,
            bottom: -8,
            right: 0,
            left: 30,
            height: 'auto',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            '& > div': {
                position: 'relative',
                transform: 'rotate(90deg)',
                left: -15,
            },
            '& > div:first-child': {
                position: 'absolute',
                backgroundPosition: '-40px 0',
                top: 0,
                left: 0,
                transform: 'rotate(0deg)',
            },
            '& > div:last-child': {
                position: 'absolute',
                backgroundPosition: '25px 0',
                bottom: 0,
                left: 0,
                transform: 'scaleX(-1)',
            },
        },
    },
    arrow: {
        width: 67,
        height: 17,
        backgroundImage: `url(${Arrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
    },
});

AssessmentSteps.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    steps: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
    })).isRequired,
};

export default withStyles(styles)(AssessmentSteps);
