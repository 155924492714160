export const NOTIFICATION_REQUESTED = 'NOTIFICATION_REQUESTED';
export const NOTIFICATION_RESOLVED = 'NOTIFICATION_RESOLVED';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';

export const NOTIFICATIONS_REQUESTED = 'NOTIFICATIONS_REQUESTED';
export const NOTIFICATIONS_RESOLVED = 'NOTIFICATIONS_RESOLVED';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';

export const NOTIFICATION_UPDATE_REQUESTED = 'NOTIFICATION_UPDATE_REQUESTED';
export const NOTIFICATION_UPDATE_RESOLVED = 'NOTIFICATION_UPDATE_RESOLVED';
export const NOTIFICATION_UPDATE_ERROR = 'NOTIFICATION_UPDATE_ERROR';

export const NOTIFICATION_DELETE_REQUESTED = 'NOTIFICATION_DELETE_REQUESTED';
export const NOTIFICATION_DELETE_RESOLVED = 'NOTIFICATION_DELETE_RESOLVED';
export const NOTIFICATION_DELETE_ERROR = 'NOTIFICATION_DELETE_ERROR';

export const NOTIFICATION_CREATE_REQUESTED = 'NOTIFICATION_CREATE_REQUESTED';
export const NOTIFICATION_CREATE_RESOLVED = 'NOTIFICATION_CREATE_RESOLVED';
export const NOTIFICATION_CREATE_ERROR = 'NOTIFICATION_CREATE_ERROR';

export const NOTIFICATION_CLOSE_REQUESTED = 'NOTIFICATION_CLOSE_REQUESTED';
export const NOTIFICATION_CLOSE_RESOLVED = 'NOTIFICATION_CLOSE_RESOLVED';
export const NOTIFICATION_CLOSE_ERROR = 'NOTIFICATION_CLOSE_ERROR';
