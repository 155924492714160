import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Modal from '@material-ui/core/Modal';
import getContent from '../../../services/content/getContent';

const getSizeClass = (size, classes) => {
    switch (size) {
    case 'small':
        return classes.paperSm;
    case 'medium':
        return classes.paperMd;
    default:
        return '';
    }
};

const MuiModal = ({
    open,
    children,
    classes,
    size,
    onClose,
    disableClose,
}) => (
    <Modal open={open} onClose={onClose} className={classes.root} disableEscapeKeyDown={disableClose}>
        <div className={`${classes.paper} ${getSizeClass(size, classes)}`}>
            {!disableClose && (
                <IconButton
                    className={classes.closeBtn}
                    aria-label={getContent(['components', 'modal', 'close'])}
                    onClick={onClose}
                >
                    <ClearIcon />
                </IconButton>
            )}
            {children}
        </div>
    </Modal>
);

MuiModal.defaultProps = {
    open: false,
    size: 'large',
    pinTop: false,
    disableClose: false,
};

MuiModal.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    open: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    pinTop: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    disableClose: PropTypes.bool,
};

const styles = theme => ({
    paper: (props) => {
        const overflow = props.overflow ? {
            maxHeight: 750,
            '@media (max-height: 750px)': {
                overflowY: 'scroll',
            },
        } : {};

        const stretch = !props.fixedHeight ? {
            [theme.breakpoints.down('sm')]: {
                top: theme.spacing(1),
                bottom: theme.spacing(1),
                overflowY: 'scroll',
            },
        } : {};

        return {
            position: 'absolute',
            left: '10%',
            right: '10%',
            // borderRadius: 15,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: 'none',
            margin: 'auto',
            top: props.pinTop ? theme.spacing(2) : 'auto',
            ...overflow,
            '@media (max-height: 740px)': {
                top: props.autoHeight ? theme.spacing(1) : 'auto',
                bottom: props.autoHeight ? theme.spacing(1) : 'auto',
                overflowY: 'scroll',
            },
            ...stretch,
        };
    },
    paperSm: {
        width: theme.spacing(50),
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            left: '5% !important',
            right: '5% !important',
        },
    },
    paperMd: {
        width: '50%',
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeBtn: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.common.orange,
        zIndex: 300,
    },
});

export default withStyles(styles)(MuiModal);
