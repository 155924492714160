import React from 'react';
import PropTypes from 'prop-types';
import handleViewport from 'react-in-viewport';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ThemeResult from '../../wellbeing-tracker-theme/ThemeResult/ThemeResult';
import InformationModal from '../../wellbeing-tracker-theme/InformationModal/InformationModal';

const QuestionnaireResultsSummaryChart = ({
    classes, item, title, color, icon, helpContent,
}) => {
    const [isHelpModalOpen, openHelpModal] = React.useState(false);
    const ViewportBlock = handleViewport(ThemeResult, {}, { disconnectOnLeave: false });
    return (
        <Box>
            <Card elevation={0} key={item.title.replace(' ', '')} className={classes.card}>
                <CardHeader
                    className={classes.cardHeader}
                    disableTypography
                    title={(
                        <img src={icon} alt={item.title} height="100" />
                    )}
                />
                <CardContent className={classes.content}>
                    <Typography variant="h2" align="center" gutterBottom className={classes.contentHeader}>
                        {item.title}
                        {helpContent && <HelpOutlineIcon onClick={() => openHelpModal(true)} />}
                    </Typography>
                    <Typography variant="body1" align="center">{item.description}</Typography>
                    <Box className={classes.chartContainer}>
                        <Box className={classes.chart}><ViewportBlock theme={item.theme} description={title} color={color} /></Box>
                    </Box>
                </CardContent>
            </Card>
            {helpContent
                && (
                    <InformationModal
                        open={isHelpModalOpen}
                        onClose={() => openHelpModal(false)}
                        content={helpContent}
                    />
                )}

        </Box>
    );
};

const styles = theme => ({
    card: {
        height: '100%',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.shadows[25],
        background: theme.palette.background.greyFadeTop,
        backgroundPosition: '0 0',
    },
    header: props => ({
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: 30,
        color: props.color,
        marginBottom: theme.spacing(4),
    }),
    cardHeader: {
        textAlign: 'center',
    },
    contentHeader: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: 30,
        marginBottom: theme.spacing(4),
        '& svg': {
            marginTop: 0,
            color: theme.palette.primary.main,
            fontSize: 22,
            marginLeft: theme.spacing(0.5),
            cursor: 'pointer',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: '1 1 auto',
        textAlign: 'center',
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    chartContainer: {
        width: '100%',
        display: 'flex',
        height: 285,
        paddingTop: 90,
    },
    chart: {
        width: '100%',
        height: 135,
        marginTop: 64,
        background: theme.palette.background.gradientRadial,
        backgroundPosition: 'center bottom',
    },
});

styles.propTypes = {
    color: PropTypes.string.isRequired,
};

QuestionnaireResultsSummaryChart.defaultProps = {
    title: null,
    icon: null,
    helpContent: null,
};

QuestionnaireResultsSummaryChart.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.string.isRequired,
    icon: PropTypes.string,
    helpContent: PropTypes.arrayOf(PropTypes.string),
    item: PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        theme: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
            step: PropTypes.number,
            change: PropTypes.string,
            companyLatest: PropTypes.string,
            companyLatestLabel: PropTypes.string,
            companyLatestDate: PropTypes.string,
            companyPrevious: PropTypes.string,
            companyPreviousLabel: PropTypes.string,
            companyPreviousDate: PropTypes.string,
            latestResult: PropTypes.string,
            latestResultLabel: PropTypes.string,
            latestResultDate: PropTypes.string,
            name: PropTypes.string,
            previousResult: PropTypes.string,
            previousResultLabel: PropTypes.string,
            previousResultDate: PropTypes.string,
            recommendedMin: PropTypes.string,
            recommendedMax: PropTypes.string,
            recommendedLabel: PropTypes.string,
        }),
    }).isRequired,
};

export default withStyles(styles)(QuestionnaireResultsSummaryChart);
