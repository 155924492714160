import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fieldPropTypes } from 'redux-form';
import CreatableSelect from 'react-select/creatable';


const options = [
    { value: '00:00', label: '00:00', option: '00:00' },
    { value: '00:30', label: '00:30', option: '00:30' },
    { value: '01:00', label: '01:00', option: '01:00' },
    { value: '01:30', label: '01:30', option: '01:30' },
    { value: '02:00', label: '02:00', option: '02:00' },
    { value: '02:30', label: '02:30', option: '02:30' },
    { value: '03:00', label: '03:00', option: '03:00' },
    { value: '03:30', label: '03:30', option: '03:30' },
    { value: '04:00', label: '04:00', option: '04:00' },
    { value: '04:30', label: '04:30', option: '04:30' },
    { value: '05:00', label: '05:00', option: '05:00' },
    { value: '05:30', label: '05:30', option: '05:30' },
    { value: '06:00', label: '06:00', option: '06:00' },
    { value: '06:30', label: '06:30', option: '06:30' },
    { value: '07:00', label: '07:00', option: '07:00' },
    { value: '07:30', label: '07:30', option: '07:30' },
    { value: '08:00', label: '08:00', option: '08:00' },
    { value: '08:30', label: '08:30', option: '08:30' },
    { value: '09:00', label: '09:00', option: '09:00' },
    { value: '09:30', label: '09:30', option: '09:30' },
    { value: '10:00', label: '10:00', option: '10:00' },
    { value: '10:30', label: '10:30', option: '10:30' },
    { value: '11:00', label: '11:00', option: '11:00' },
    { value: '11:30', label: '11:30', option: '11:30' },
    { value: '12:00', label: '12:00', option: '12:00' },
    { value: '12:30', label: '12:30', option: '12:30' },
    { value: '13:00', label: '13:00', option: '13:00' },
    { value: '13:30', label: '13:30', option: '13:30' },
    { value: '14:00', label: '14:00', option: '14:00' },
    { value: '14:30', label: '14:30', option: '14:30' },
    { value: '15:00', label: '15:00', option: '15:00' },
    { value: '15:30', label: '15:30', option: '15:30' },
    { value: '16:00', label: '16:00', option: '16:00' },
    { value: '16:30', label: '16:30', option: '16:30' },
    { value: '17:00', label: '17:00', option: '17:00' },
    { value: '17:30', label: '17:30', option: '17:30' },
    { value: '18:00', label: '18:00', option: '18:00' },
    { value: '18:30', label: '18:30', option: '18:30' },
    { value: '19:00', label: '19:00', option: '19:00' },
    { value: '19:30', label: '19:30', option: '19:30' },
    { value: '20:00', label: '20:00', option: '20:00' },
    { value: '20:30', label: '20:30', option: '20:30' },
    { value: '21:00', label: '21:00', option: '21:00' },
    { value: '21:30', label: '21:30', option: '21:30' },
    { value: '22:00', label: '22:00', option: '22:00' },
    { value: '22:30', label: '22:30', option: '22:30' },
    { value: '23:00', label: '23:00', option: '23:00' },
    { value: '23:30', label: '23:30', option: '23:30' },
];
const MuiTimePicker = ({
    input: {
        value, name, onBlur, ...inputProps
    },
}) => {
    // set value for default selection
    const [selectedValue, setSelectedValue] = useState(3);
    // handle onChange event of the dropdown and get value
    // React-select get value on change
    const handleChange = (e) => {
        if (e.value.trim().match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
            const dt = new Date();
            dt.setHours(e.value.substring(0, 2), e.value.substring(3, 5));
            setSelectedValue(e.value);
            return inputProps.onChange(dt.toISOString());
        }
        return inputProps.onChange(null);
    };
    const isValidNewOption = (inputValue, e, selectOptions) => {
        if (
            inputValue.trim().length === 0
          || selectOptions.find(option => option.name === inputValue)
          || !inputValue.trim().match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
            return false;
        }
        return true;
    };

    const handleInputChange = () => {
        function insertColon(element, key) {
            if (element.value.trim().length === 2 && key !== 8) {
                // eslint-disable-next-line no-param-reassign
                element.value += ':';
            }
        } 
        function keyUpHandler(e) {
            const evt = e || window.event;
            const target = evt.target || evt.srcElement;
            const key = e.keyCode || e.which;

            // Assign handler to class
            if (target.className.indexOf('timeSelect__input') > -1) {
                insertColon(target, key);
            }
        }
        window.addEventListener('keyup', keyUpHandler, false);
    };


    return (
        <Fragment>
            <CreatableSelect
                {...inputProps}
                classNamePrefix="timeSelect"
                InputProps={{
                    id: name,
                }}
                defaultValue={{ label: moment(value).format('HH:mm'), value: moment(value).format('HH:mm') }}
                value={value ? options.find(obj => obj.value === selectedValue) : null}
                onChange={handleChange}
                name={name}
                isValidNewOption={isValidNewOption}
                hideSelectedOptions
                blurInputOnSelect
                onInputChange={handleInputChange}
                options={options}
                ampm={false}
                format="HH:mm"
            />
        </Fragment>
    );
};

MuiTimePicker.defaultProps = {
    input: null,
    disableFuture: false,
    disablePast: false,
    variant: 'dialog',
    value: null,
};

MuiTimePicker.propTypes = {
    input: fieldPropTypes.input,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
    value: PropTypes.string,
};

export default MuiTimePicker;
