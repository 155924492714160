import * as Immutable from 'immutable';
import {
    ADD_COMPANY_MEMBERS_REQUESTED,
    ADD_COMPANY_MEMBERS_RESOLVED,
    ADD_COMPANY_MEMBERS_ERROR,
    ADD_COMPANY_MEMBERS_RESET,
    ADD_COMPANY_MEMBERS_UPLOAD_STATUS_REQUESTED,
    ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED,
    ADD_COMPANY_MEMBERS_UPLOAD_STATUS_ERROR,
    ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_RESOLVED,
} from '../actions/add-company-members/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    errors: null,
    userCount: 0,
    membersUploadStatus: {
        resolving: false,
        status: 'INIT',
        error: null,
        uploadStatus: {
            stage: null,
            processed: null,
            total: null,
            errors: [],
            isComplete: false,
        },
    },
};

const initialState = Immutable.Map(schema);

/**
 * The Add Company Members reducer - responsible for adding members of a company and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const addCompanyMembersReducer = (state = initialState, action) => {
    switch (action.type) {
    case ADD_COMPANY_MEMBERS_REQUESTED:
        return state.set('resolving', true);

    case ADD_COMPANY_MEMBERS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('errors', null)
            .set('userCount', action.payload.userCount)
            .setIn(['membersUploadStatus', 'uploadStatus', 'stage'], 'Pre-Validation');

    case ADD_COMPANY_MEMBERS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('errors', action.payload)
            .set('userCount', 0)
            .setIn(['membersUploadStatus', 'uploadStatus', 'stage'], null);

    case ADD_COMPANY_MEMBERS_UPLOAD_VIEWED_RESOLVED:
    case ADD_COMPANY_MEMBERS_RESET:
        return initialState;

    case ADD_COMPANY_MEMBERS_UPLOAD_STATUS_REQUESTED:
        return state.setIn(['membersUploadStatus', 'resolving'], true);

    case ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED: {
        if (action.payload) {
            return state
                .setIn(['membersUploadStatus', 'resolving'], false)
                .setIn(['membersUploadStatus', 'status'], 'OK')
                .setIn(['membersUploadStatus', 'uploadStatus'], action.payload);
        }
        return state
            .setIn(['membersUploadStatus', 'resolving'], false)
            .setIn(['membersUploadStatus', 'status'], 'OK')
            .setIn(['membersUploadStatus', 'uploadStatus'], initialState.getIn(['membersUploadStatus', 'uploadStatus']));
    }

    case ADD_COMPANY_MEMBERS_UPLOAD_STATUS_ERROR:
        return state
            .setIn(['membersUploadStatus', 'resolving'], false)
            .setIn(['membersUploadStatus', 'error'], action.payload)
            .setIn(['membersUploadStatus', 'status'], 'ERROR');

    default:
        return state;
    }
};

export { initialState };

export default addCompanyMembersReducer;
