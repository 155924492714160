import { connectWithLifecycle } from 'react-lifecycle-component';
import getCompanyMembersUploadStatus from '../../actions/add-company-members/getCompanyMembersUploadStatus';
import updateCompanyMembersUploadViewed from '../../actions/add-company-members/updateCompanyMembersUploadViewed';
import resetAddCompanyMembers from '../../actions/add-company-members/resetAddCompanyMembers';
import onCompanyMembersUploadStatusSubscription from '../../services/graphQL/company-members/onCompanyMembersUploadStatusSubscription';
import UploadNotification from './UploadNotification';

const mapDispatchToProps = dispatch => ({
    componentDidMount: async () => {
        await dispatch(await getCompanyMembersUploadStatus());
        onCompanyMembersUploadStatusSubscription();
    },
    getCompanyMembersUploadStatus: async () => dispatch(await getCompanyMembersUploadStatus()),
    updateCompanyMembersUploadViewed: async () => {
        dispatch(await updateCompanyMembersUploadViewed());
        dispatch(resetAddCompanyMembers());
    },
});

const mapStateToProps = state => ({
    resolving: state.addCompanyMembers.getIn(['membersUploadStatus', 'resolving']),
    status: state.addCompanyMembers.getIn(['membersUploadStatus', 'status']),
    error: state.addCompanyMembers.getIn(['membersUploadStatus', 'error']),
    uploadStatus: state.addCompanyMembers.getIn(['membersUploadStatus', 'uploadStatus']),
});


export default connectWithLifecycle(mapStateToProps, mapDispatchToProps)(UploadNotification);
