import variables from '../variables';

const MuiStepIcon = {
    root: {
        color: variables.colors.grey,
        fontSize: '12px',
        marginLeft: '7px',
        '&$completed': {
            color: variables.colors.green,
        },
        '&:not($completed) text': {
            display: 'none',
        },
    },
};

export default MuiStepIcon;
