import variables from '../variables';

const MuiStepLabel = {
    label: {
        color: variables.colors.grey,
        '&$active': {
            fontWeight: 'bold',
        },
    },
    iconContainer: {
        fontSize: '7px',
    },
};

export default MuiStepLabel;
