import * as Immutable from 'immutable';
import {
    COMPANY_MEMBERS_REQUESTED,
    COMPANY_MEMBERS_RESOLVED,
    COMPANY_MEMBERS_ERROR,
    COMPANY_MEMBERS_FILTERED_REQUESTED,
    COMPANY_MEMBERS_FILTERED_RESOLVED,
    COMPANY_MEMBERS_FILTERED_ERROR,
    COMPANY_MEMBERS_STATUS_RESOLVED,
    COMPANY_MEMBERS_STATUS_UPDATE_RESOLVED,
} from '../actions/company-members/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    membersPage: {},
    membersFiltered: {
        status: 'INIT',
        resolving: false,
        members: [],
    },
};

const initialState = Immutable.Map(schema);

/**
 * The Company Members reducer - responsible for managing members of a company and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const companyMembersReducer = (state = initialState, action) => {
    switch (action.type) {
    case COMPANY_MEMBERS_REQUESTED:
        return state.set('resolving', true);

    case COMPANY_MEMBERS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('membersPage', action.payload);

    case COMPANY_MEMBERS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('membersPage', {});

    case COMPANY_MEMBERS_STATUS_UPDATE_RESOLVED:
        return state
            .updateIn(['membersPage', 'members'], members => members.map((m) => {
                const updatedMember = m;
                const foundMember = action.payload.find(payloadMember => payloadMember.contactId === updatedMember.contactId);
                if (foundMember) {
                    updatedMember.status = foundMember.status;
                }
                return updatedMember;
            }));

    case COMPANY_MEMBERS_STATUS_RESOLVED:
        return state
            .setIn(['membersPage', 'status'], action.payload);

    case COMPANY_MEMBERS_FILTERED_REQUESTED:
        return state.setIn(['membersFiltered', 'resolving'], true);

    case COMPANY_MEMBERS_FILTERED_RESOLVED:
        return state
            .setIn(['membersFiltered', 'resolving'], false)
            .setIn(['membersFiltered', 'status'], 'OK')
            .setIn(['membersFiltered', 'members'], action.payload.members);

    case COMPANY_MEMBERS_FILTERED_ERROR:
        return state
            .setIn(['membersFiltered', 'resolving'], false)
            .setIn(['membersFiltered', 'status'], 'ERROR')
            .setIn(['membersFiltered', 'members'], []);

    default:
        return state;
    }
};

export { initialState };

export default companyMembersReducer;
