import React, {
    lazy,
    Suspense,
    useState,
    useContext,
} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { URLS } from '../../../config/config';
import MemberFooter from '../../footer/MemberFooter/MemberFooter';
import MemberConsentNotice from '../../member-consent-notice/container';
import StaticLoader from '../../loader/StaticLoader/StaticLoader';
import QuestionnaireRoute from '../../questionnaire/QuestionnaireRoute/QuestionnaireRoute';
import Notifier from '../../notifier/Notifier';
import Favourites from '../../favourites/Favourites';
import IDMContext from '../IDMContext';
import UserMsal from '../../azure/UserMsal';
import MemberWrapper from '../../azure/Member/MemberWrapper';
import {
    getMsalSignInConfig,
    getMsalPasswordResetConfig,
    getMsalRequest,
} from '../../azure/Member/member-msal-auth-config';
import DeviceContext from '../../../context/device-context';
import MobileAppService from '../../../services/mobile-app/mobile-app';

const Dashboard = lazy(() => import('../../dashboard/container'));
const WellbeingTracker = lazy(() => import('../../wellbeing-tracker/container'));
const CompanyMemberAccount = lazy(() => import('../../company-member-account/container'));
const HealthyBites = lazy(() => import('../../healthy-bites/HealthyBites'));
const Services = lazy(() => import('../../services/container'));
const Perks = lazy(() => import('../../perks/container'));
const Register = lazy(() => import('../MemberRegistrationRoutes/MemberRegistrationRoutes'));
const HelpSupport = lazy(() => import('../../help-support/HelpSupport'));
const TermsConditions = lazy(() => import('../../terms-conditions/TermsConditions'));
const PageNotFound = lazy(() => import('../../page-not-found/PageNotFound'));
const MemberEvents = lazy(() => import('../../events/MemberEvents/MemberEvents'));
const AccountLocked = lazy(() => import('../../account-locked/AccountLocked'));
const CookiePolicy = lazy(() => import('../../cookie-policy/CookiePolicy'));
const WhatsNew = lazy(() => import('../../whats-new/container'));

const MemberRoutes = () => {
    const [authProviderConfig, setAuthProviderConfig] = useState(null);
    const device = useContext(DeviceContext);
    const platform = MobileAppService.isMobileApp(device) ? 'App' : 'Browser';

    return (
        <IDMContext.Provider value={{ authProviderConfig, setAuthProviderConfig }}>
            <Suspense fallback={<StaticLoader />}>
                <Notifier />
                <Switch>
                    <Route path={URLS.MEMBER.REGISTER.ROOT} render={() => <Register />} />
                    <Route exact path={URLS.MEMBER.ACCOUNT_LOCKED} render={() => <AccountLocked />} />
                    <IDMContext.Consumer>
                        {authProvider => (
                            <UserMsal
                                authProvider={authProvider}
                                config={getMsalSignInConfig()}
                                resetConfig={getMsalPasswordResetConfig()}
                                request={getMsalRequest(platform)}
                                UserWrapper={MemberWrapper}
                            >
                                <Favourites />
                                <Switch>
                                    <Route exact path={URLS.MEMBER.HOME} render={() => <Dashboard />} />
                                    <Route exact path={URLS.MEMBER.DASHBOARD} render={() => <WellbeingTracker />} />
                                    <Route
                                        path={URLS.MEMBER.ACCOUNT.ROOT}
                                        render={(routeProps) => {
                                            const { location: { pathname } } = routeProps;
                                            return <CompanyMemberAccount route={pathname} />;
                                        }}
                                    />
                                    <Route exact path={`${URLS.MEMBER.QUESTIONNAIRES}/:questionnaireId/:step?`} render={QuestionnaireRoute} />
                                    <Route path={URLS.MEMBER.SERVICES} render={() => <Services />} />
                                    <Route path={URLS.MEMBER.ARTICLES} render={() => <HealthyBites />} />
                                    <Route path={URLS.MEMBER.EVENTS} render={() => <MemberEvents />} />
                                    <Route exact path={URLS.MEMBER.REWARDS}>
                                        <Redirect to={URLS.MEMBER.SERVICES} />
                                    </Route>
                                    <Route path={URLS.MEMBER.PERKS} render={() => <Perks />} />
                                    <Route exact path={URLS.MEMBER.HELP} render={() => <HelpSupport />} />
                                    <Route exact path={URLS.MEMBER.WHATS_NEW} render={() => <WhatsNew />} />
                                    <Route exact path={URLS.MEMBER.TERMS} render={() => <TermsConditions />} />
                                    <Route exact path={URLS.MEMBER.COOKIES} render={() => <CookiePolicy />} />
                                    <Route component={PageNotFound} />
                                </Switch>
                                <MemberFooter />
                                <MemberConsentNotice />
                            </UserMsal>
                        )}
                    </IDMContext.Consumer>
                </Switch>
            </Suspense>
        </IDMContext.Provider>
    );
};

export default MemberRoutes;
