import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import getMemberAction from '../../../actions/member/getMember';
import { URLS } from '../../../config/config';
import MobileAppService from '../../../services/mobile-app/mobile-app';
import WebViewService from '../../../services/webview/webview';
import DeviceContext from '../../../context/device-context';

const MemberWrapper = ({ children, resolving, getToken }) => {
    const dispatch = useDispatch();
    const device = useContext(DeviceContext);
    const token = useSelector(state => state.auth.getIn(['authentication', 'idToken']));
    const member = useSelector(state => state.member.get('member'));

    const getMember = async () => {
        // check if mobile app
        if (WebViewService.isWebView()) {
            WebViewService.setLocationListener();
        }
        // post message to app to show menu
        if (MobileAppService.isMobileApp(device)) {
            MobileAppService.setShowMenu();
        }
        dispatch(await getMemberAction());
    };

    useEffect(() => {
        if (token && !resolving) {
            getMember();
        } else if (!token && !resolving) {
            getToken();
        }
    }, [token, resolving]);

    useEffect(() => {
        if (member && token) {
            const { contactId } = member;
            WebViewService.postMessage({
                type: 'LOGIN',
                contactId,
                token,
            });
        }
    }, [member, token]);

    if (!token || !member) return null;

    // If member status is pending or disabled then redirect to locked / register pages
    if (member && token && (member.status === 'pending' || member.status === 'disabled')) {
        return member.status === 'pending' ? (
            <Redirect to={{ pathname: URLS.MEMBER.REGISTER.ACCOUNT, state: { signIn: true } }} />)
            : (<Redirect to={URLS.MEMBER.ACCOUNT_LOCKED} />);
    }

    return children;
};

MemberWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    resolving: PropTypes.bool.isRequired,
    getToken: PropTypes.func.isRequired,
};

export default MemberWrapper;
