import * as Immutable from 'immutable';
import {
    addAssessmentProps,
    addAssessmentStatus,
    setCategoryLink,
    setStatus,
    getTrackerProgressPercent,
} from '../services/tracker/tracker';
import {
    MEMBER_TRACKER_REQUESTED,
    MEMBER_TRACKER_RESOLVED,
    MEMBER_TRACKER_ERROR,
} from '../actions/member/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    tracker: null,
};

const initialState = Immutable.Map(schema);
/**
 * The member tracker reducer - responsible for managing a member tracker and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const memberTrackerReducer = (state = initialState, action) => {
    switch (action.type) {
    case MEMBER_TRACKER_REQUESTED:
        return state.set('resolving', true);

    case MEMBER_TRACKER_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('tracker', action.payload)
            .updateIn(['tracker', 'assessments'], assessments => addAssessmentProps(addAssessmentStatus(assessments) || []))
            .updateIn(['tracker', 'categories'], categories => setCategoryLink(categories))
            .setIn(['tracker', 'status'], setStatus(action.payload.assessments))
            .setIn(['tracker', 'progress'], getTrackerProgressPercent(setStatus(action.payload.assessments)));

    case MEMBER_TRACKER_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('tracker', null);

    default:
        return state;
    }
};

export { initialState };

export default memberTrackerReducer;
