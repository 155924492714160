import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const styles = () => ({
    root: {
        pointerEvents: 'all',
    },
});

InputLabel.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default withStyles(styles)(InputLabel);
