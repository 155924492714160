import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiSlider from '../../material-ui/MuiSlider/MuiSlider';

const MentalHealthSlider = ({
    classes,
    title,
    image,
    min,
    max,
    value,
    tooltip,
    subTitle,
}) => (
    <div className={classes.root}>
        <img src={image} alt={title} className={classes.image} />
        <div className={classes.slider}>
            <Typography variant="caption" color="textSecondary" className={classes.title}>
                <strong>{title}</strong>
            </Typography>
            <Typography variant="caption" className={classes.subTitle}>
                {subTitle}
            </Typography>
            <MuiSlider
                defaultValue={value}
                value={value}
                onChange={() => {}}
                min={min}
                max={max}
                step={1}
                tooltip={tooltip}
                hideThumb
                animate
            />
        </div>
    </div>
);

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    slider: {
        width: '70%',
        textAlign: 'left',
    },
    title: {
        bottom: -7,
        position: 'relative',
    },
    subTitle: {
        bottom: -7,
        position: 'relative',
        float: 'right',
    },
    image: {
        width: 85,
        marginRight: theme.spacing(1),
    },
});

MentalHealthSlider.defaultProps = {
    subTitle: null,
};

MentalHealthSlider.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    tooltip: PropTypes.string.isRequired,
};

export default withStyles(styles)(MentalHealthSlider);
