import variables from '../variables';
/* eslint-disable */
const background = {
    paper: variables.colors.white,
    default: variables.colors.white,
    gradient: `linear-gradient(to right, ${variables.colors.green} 0%, ${variables.colors.blue} 100%)`,
    gradientReverse: `linear-gradient(to right, ${variables.colors.blue} 0%, ${variables.colors.green} 100%)`,
    gradientVertical: `linear-gradient(to bottom, ${variables.colors.blue} 0%, ${variables.colors.green} 100%)`,
    gradientVerticalReverse: `linear-gradient(to bottom, ${variables.colors.green} 0%, ${variables.colors.blue} 100%)`,
    gradientGrey: `linear-gradient(to right, ${variables.colors.green} 0%, ${variables.colors.grey} 100%)`,
    gradientVerticalGrey: `linear-gradient(to bottom, rgba(216, 216, 216, 0.4) 0%, ${variables.colors.greyLight} 25%)`,
    gradientRadial: `radial-gradient(circle at top center, ${variables.colors.green}, ${variables.colors.blue} 70%)`,
    transparentWhite: 'rgba(255, 255, 255, 0.7)',
    greyFade: `linear-gradient(to bottom, ${variables.colors.white} 0, ${variables.colors.white} 45%, ${variables.colors.greyLight} 45%, ${variables.colors.white} 55%)`,
    greyFadeTop: `linear-gradient(to bottom, ${variables.colors.white} 0, ${variables.colors.white} 20%, ${variables.colors.greyLight} 20%, ${variables.colors.white} 55%)`,
    greyFadePinTop: `linear-gradient(to bottom, ${variables.colors.white} 0, ${variables.colors.white} 0%, ${variables.colors.greyLight} 0%, ${variables.colors.white} 55%)`,
    greyDarkFadePinTop: `linear-gradient(to bottom,  ${variables.colors.greyLight} 0%, ${variables.colors.greyLight} 0%, #f1f1f1 0%, ${variables.colors.greyLight} 55%)`,
    greyLightFadePinTop: `linear-gradient(to bottom,  ${variables.colors.greyLight} 0%, ${variables.colors.greyLight} 0%, #f1f1f1 0%, ${variables.colors.greyLight} 15%)`,
    gradientRange: 'linear-gradient(90deg, rgba(255,82,11,1) 0%, rgba(244,209,65,1) 50%, rgba(105,234,180,1) 100%)',
    gradientRangeReverse: 'linear-gradient(90deg, rgba(105,234,180,1) 0%, rgba(244,209,65,1) 50%, rgba(255,82,11,1) 100%)',
    gradientDarkGrey: 'linear-gradient(to bottom, #575756 0%, #828282 100%)',
    gradientRed: 'linear-gradient(to bottom, #e00434 0%, #fb4c0d 100%)',
    gradientYellow: `linear-gradient(to bottom, ${variables.colors.yellowDark} 0%, #fb8045 100%)`,
    gradientDiagonal: `linear-gradient(-45deg, ${variables.colors.blue} 0%, ${variables.colors.green} 100%)`,
    greyFade: `linear-gradient(to bottom, ${variables.colors.white} 0, ${variables.colors.white} 45%, ${variables.colors.greyLight} 45%, ${variables.colors.white} 55%)`,
    greyFadeTransparent: `linear-gradient(to bottom, transparent 0, transparent 45%, ${variables.colors.greyLight} 45%, transparent 55%)`,
};

export default background;
