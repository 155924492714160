import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
});

export default withStyles(styles)(Divider);
