import variables from '../variables';

const MuiAccordion = {
    root: {
        marginBottom: 8,
        '&:before': {
            backgroundColor: 'transparent',
        },
        '&$expanded': {
            margin: 0,
        },
    },
};

const MuiAccordionSummary = {
    root: {
        backgroundColor: variables.colors.greyLight,
        paddingLeft: 16,
        paddingRight: 16,
        '&$expanded': {
            margin: '0',
            minHeight: 48,
        },
    },
    content: {
        '&$expanded': {
            backgroundColor: 'transparent',
            margin: 0,
        },
    },
    expandIcon: {
        color: variables.colors.orange,
        '&$expanded': {
            backgroundColor: 'transparent',
        },
    },
};

const MuiAccordionDetails = {
    root: {
        padding: 0,
        marginBottom: 16,
    },
};

const MuiExpansion = {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAccordionDetails,
};

export default MuiExpansion;
