import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const SummaryThemeOutput = ({
    classes, title, theme,
}) => (
    <ListItemText
        disableTypography
        primary={<Typography color="textSecondary" variant="h4" className={classes.headerFont}>{title}</Typography>}
        secondary={(
            <>
                <Typography color="textSecondary" variant="body2" className={classes.item}>
                    <strong>Results</strong>
                    {' '}
                    <span className={classes.result}>{theme.latestResultLabel}</span>
                </Typography>
                <Typography color="textPrimary" variant="body2" className={classes.item}>
                    <strong>Target</strong>
                    {' '}
                    <span className={classes.target}>{theme.recommendedLabel}</span>
                </Typography>
            </>
        )}
    />
);

const styles = theme => ({
    headerFont: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        display: 'block',
        marginBottom: 6,
    },
    result: props => ({
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: theme.typography.h2.fontSize,
        color: props.color,
        marginLeft: theme.spacing(1),
    }),
    item: {
        display: 'flex',
        alignItems: 'baseline',
    },
    target: {
        marginLeft: 12,
        fontWeight: 'bold',
    },
});

styles.propTypes = {
    color: PropTypes.string.isRequired,
};

SummaryThemeOutput.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    theme: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        change: PropTypes.string,
        companyLatest: PropTypes.string,
        companyLatestLabel: PropTypes.string,
        companyLatestDate: PropTypes.string,
        companyPrevious: PropTypes.string,
        companyPreviousLabel: PropTypes.string,
        companyPreviousDate: PropTypes.string,
        latestResult: PropTypes.string,
        latestResultLabel: PropTypes.string,
        latestResultDate: PropTypes.string,
        name: PropTypes.string,
        previousResult: PropTypes.string,
        previousResultLabel: PropTypes.string,
        previousResultDate: PropTypes.string,
        recommendedMin: PropTypes.string,
        recommendedMax: PropTypes.string,
        recommendedLabel: PropTypes.string,
        relevantContentId: PropTypes.number,
        relevantServiceId: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(SummaryThemeOutput);
