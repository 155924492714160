export const COMPANY_CONTACT_REQUESTED = 'COMPANY_CONTACT_REQUESTED';
export const COMPANY_CONTACT_RESOLVED = 'COMPANY_CONTACT_RESOLVED';
export const COMPANY_CONTACT_ERROR = 'COMPANY_CONTACT_ERROR';

export const COMPANY_CONTACT_CREATE_REQUESTED = 'COMPANY_CONTACT_CREATE_REQUESTED';
export const COMPANY_CONTACT_CREATE_RESOLVED = 'COMPANY_CONTACT_CREATE_RESOLVED';
export const COMPANY_CONTACT_CREATE_ERROR = 'COMPANY_CONTACT_CREATE_ERROR';
export const COMPANY_CONTACT_CREATE_RESET = 'COMPANY_CONTACT_CREATE_RESET';

export const COMPANY_CONTACT_UPDATE_REQUESTED = 'COMPANY_CONTACT_UPDATE_REQUESTED';
export const COMPANY_CONTACT_UPDATE_RESOLVED = 'COMPANY_CONTACT_UPDATE_RESOLVED';
export const COMPANY_CONTACT_UPDATE_ERROR = 'COMPANY_CONTACT_UPDATE_ERROR';

export const COMPANY_CONTACT_NOTIFICATION_PREFERENCES_REQUESTED = 'COMPANY_CONTACT_NOTIFICATION_PREFERENCES_REQUESTED';
export const COMPANY_CONTACT_NOTIFICATION_PREFERENCES_RESOLVED = 'COMPANY_CONTACT_NOTIFICATION_PREFERENCES_RESOLVED';
export const COMPANY_CONTACT_NOTIFICATION_PREFERENCES_ERROR = 'COMPANY_CONTACT_NOTIFICATION_PREFERENCES_ERROR';

export const COMPANY_CONTACT_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED = 'COMPANY_CONTACT_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED';
export const COMPANY_CONTACT_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED = 'COMPANY_CONTACT_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED';
export const COMPANY_CONTACT_NOTIFICATION_PREFERENCES_UPDATE_ERROR = 'COMPANY_CONTACT_NOTIFICATION_PREFERENCES_UPDATE_ERROR';

export const COMPANY_CONTACT_UNREGISTERED_REQUESTED = 'COMPANY_CONTACT_UNREGISTERED_REQUESTED';
export const COMPANY_CONTACT_UNREGISTERED_RESOLVED = 'COMPANY_CONTACT_UNREGISTERED_RESOLVED';
export const COMPANY_CONTACT_UNREGISTERED_ERROR = 'COMPANY_CONTACT_UNREGISTERED_ERROR';

export const COMPANY_CONTACT_COMPANYDETAILS_REQUESTED = 'COMPANY_CONTACT_COMPANYDETAILS_REQUESTED';
export const COMPANY_CONTACT_COMPANYDETAILS_RESOLVED = 'COMPANY_CONTACT_COMPANYDETAILS_RESOLVED';
export const COMPANY_CONTACT_COMPANYDETAILS_ERROR = 'COMPANY_CONTACT_COMPANYDETAILS_ERROR';

export const COMPANY_CONTACT_COMPANYDETAILS_UPDATE_REQUESTED = 'COMPANY_CONTACT_COMPANYDETAILS_UPDATE_REQUESTED';
export const COMPANY_CONTACT_COMPANYDETAILS_UPDATE_RESOLVED = 'COMPANY_CONTACT_COMPANYDETAILS_UPDATE_RESOLVED';
export const COMPANY_CONTACT_COMPANYDETAILS_UPDATE_ERROR = 'COMPANY_CONTACT_COMPANYDETAILS_UPDATE_ERROR';

export const COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_REQUESTED = 'COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_REQUESTED';
export const COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_RESOLVED = 'COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_RESOLVED';
export const COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_ERROR = 'COMPANY_CONTACT_COMPANYPAYMENT_UPDATE_ERROR';

export const COMPANY_CONTACT_REGISTRATION_COMPLETE_REQUESTED = 'COMPANY_CONTACT_REGISTRATION_COMPLETE_REQUESTED';
export const COMPANY_CONTACT_REGISTRATION_COMPLETE_RESOLVED = 'COMPANY_CONTACT_REGISTRATION_COMPLETE_RESOLVED';
export const COMPANY_CONTACT_REGISTRATION_COMPLETE_ERROR = 'COMPANY_CONTACT_REGISTRATION_COMPLETE_ERROR';

export const COMPANY_CONTACT_COMPANYPAYMENT_REQUESTED = 'COMPANY_CONTACT_COMPANYPAYMENT_REQUESTED';
export const COMPANY_CONTACT_COMPANYPAYMENT_RESOLVED = 'COMPANY_CONTACT_COMPANYPAYMENT_RESOLVED';
export const COMPANY_CONTACT_COMPANYPAYMENT_ERROR = 'COMPANY_CONTACT_COMPANYPAYMENT_ERROR';

export const COMPANY_CONTACT_CHECK_INITIALCONTACT_REQUESTED = 'COMPANY_CONTACT_CHECK_INITIALCONTACT_REQUESTED';
export const COMPANY_CONTACT_CHECK_INITIALCONTACT_RESOLVED = 'COMPANY_CONTACT_CHECK_INITIALCONTACT_RESOLVED';
export const COMPANY_CONTACT_CHECK_INITIALCONTACT_ERROR = 'COMPANY_CONTACT_CHECK_INITIALCONTACT_ERROR';

export const COMPANY_CONTACT_COMPANYPAYMENT_CREATE_REQUESTED = 'COMPANY_CONTACT_COMPANYPAYMENT_CREATE_REQUESTED';
export const COMPANY_CONTACT_COMPANYPAYMENT_CREATE_RESOLVED = 'COMPANY_CONTACT_COMPANYPAYMENT_CREATE_RESOLVED';
export const COMPANY_CONTACT_COMPANYPAYMENT_CREATE_ERROR = 'COMPANY_CONTACT_COMPANYPAYMENT_CREATE_ERROR';
