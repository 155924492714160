import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const Container = ({
    classes,
    children,
    displayPrint,
}) => (
    <Box className={classes.root} displayPrint={displayPrint}>
        {children}
    </Box>
);

const styles = theme => ({
    root: (props) => {
        const gutterTopExtended = props.gutterTopExtended ? theme.spacing(4) : 0;
        const gutterTop = props.gutterTop ? (theme.spacing(4) + gutterTopExtended) : 0;
        const smallerHeight = props.smallerHeight ? 180 : 'auto';
        return {
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            maxWidth: props.fullWidth ? '100%' : 1282,
            paddingTop: gutterTop,
            paddingBottom: props.gutterBottom ? theme.spacing(4) : 0,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
                height: smallerHeight,
            },
        };
    },
});

styles.defaultProps = {
    gutterTop: false,
    gutterBottom: false,
    fullWidth: false,
    gutterTopExtended: false,
};

styles.propTypes = {
    gutterTop: PropTypes.bool,
    gutterTopExtended: PropTypes.bool,
    gutterBottom: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

Container.defaultProps = {
    displayPrint: null,
};

Container.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node.isRequired,
    displayPrint: PropTypes.string,
};

export default withStyles(styles)(Container);
