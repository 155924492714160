import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const MuiChip = ({
    classes,
    label,
}) => (
    <Chip
        className={classes.root}
        label={label}
        variant="outlined"
    />
);

const styles = theme => ({
    root: ({ lg }) => ({
        textTransform: lg ? 'auto' : 'uppercase',
        color: theme.palette.common.black,
        fontSize: lg ? 12 : 8,
        fontWeight: 'bold',
        height: 28,
        border: 'none',
        background: theme.palette.background.gradient,
        borderRadius: 15,
        padding: lg ? 2 : 1,
        '& > span': {
            backgroundColor: theme.palette.common.white,
            width: '100%',
            height: '100%',
            borderRadius: 13,
            display: 'flex',
            alignItems: 'center',
        },
    }),
});

styles.defaultProps = {
    lg: false,
};

styles.propTypes = {
    lg: PropTypes.bool,
};

MuiChip.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    label: PropTypes.string.isRequired,
};

export default withStyles(styles)(MuiChip);
