import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MuiDivider from '../../material-ui/MuiDivider/MuiDivider';
import Container from '../Container/Container';
import PageTitle from '../PageTitle/PageTitle';

const FormPage = ({
    title,
    subTitle,
    children,
}) => (
    <Container gutterTop gutterTopExtended>
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid item xs={12} sm={12} md={8}>
                <PageTitle text={title} align="center" />
                {subTitle && (
                    <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                        {subTitle}
                    </Typography>
                )}
                <MuiDivider />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
                {children}
            </Grid>
        </Grid>
    </Container>
);

FormPage.defaultProps = {
    subTitle: null,
};

FormPage.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default FormPage;
