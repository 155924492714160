/**
 * A helper service for logging Google Analytics
 * @type {Object}
 */
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { getEnvironmentVariable } from '../environment/environment-service';
import { toTitleCase } from '../utils/strings';

/**
 * Initialist the GA and hot jar tracking
 */
export const initTracking = () => {
    if (process.env.NODE_ENV !== 'test') {
        ReactGA.initialize(
            getEnvironmentVariable('REACT_APP_GA_ACCOUNT'),
            {
                debug: getEnvironmentVariable('REACT_APP_DEBUG_GA') && getEnvironmentVariable('REACT_APP_DEBUG_GA') === 'true',
                titleCase: false,
            },
        );
    }

    if (getEnvironmentVariable('REACT_APP_HOTJAR_ENABLED') === 'true') {
        hotjar.initialize(getEnvironmentVariable('REACT_APP_HOTJAR_HJID'), getEnvironmentVariable('REACT_APP_HOTJAR_HJSV'));
    }
};

const analyticsService = {};
/**
 * Helper method to format the event in Google Analytics
 * @param string - The string to format
 */
analyticsService.format = string => toTitleCase(string.replace(/-/g, ' '));
/**
 * Method to log a general event in Google Analytics
 * @param event Object - The event for Google Analytics with category, action and label properties
 */
analyticsService.logEvent = (event) => {
    ReactGA.event(event);
};

export default analyticsService;
