import * as Immutable from 'immutable';
import {
    WHATSNEW_ARTICLES_REQUESTED,
    WHATSNEW_ARTICLES_RESOLVED,
    WHATSNEW_ARTICLES_ERROR,
} from '../actions/whats-new/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    articles: [],
};

const initialState = Immutable.Map(schema);
/**
 * The whatsnew reducer - responsible for managing the whats new articles of the api and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const whatsNewReducer = (state = initialState, action) => {
    switch (action.type) {
    case WHATSNEW_ARTICLES_REQUESTED:
        return state.set('resolving', true);

    case WHATSNEW_ARTICLES_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('articles', action.payload);

    case WHATSNEW_ARTICLES_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('articles', []);

    default:
        return state;
    }
};

export { initialState };

export default whatsNewReducer;
