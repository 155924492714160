import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { URLS } from '../../../config/config';
import MemberRoutes from '../MemberRoutes/MemberRoutes';
import ContactRoutes from '../ContactRoutes/ContactRoutes';

const Routes = () => (
    <Switch>
        <Route
            path={URLS.CONTACT.HOME}
            component={ContactRoutes}
        />
        <Route
            path={URLS.MEMBER.HOME}
            component={MemberRoutes}
        />
    </Switch>
);


export default Routes;
