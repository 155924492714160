import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const MuiAlert = (props) => {
    const {
        classes, classes: { alert, alertIcon, messageeWithIcon }, className, type, icon, message,
    } = props;

    return (
        <div className={`${alert} ${classes[type]} ${className}`}>
            {icon ? <span className={`${alertIcon} ${classes[`${type}Icon`]}`}>{icon}</span> : null}
            <div className={icon ? messageeWithIcon : null}>{message}</div>
        </div>
    );
};

const styles = theme => ({
    alert: {
        position: 'relative',
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        justifyContent: 'center',
        borderRadius: '4px',
        borderWidth: '2px',
        borderStyle: 'solid',
        alignItems: 'inherit',
        padding: theme.spacing(2),
        '& svg': {
            verticalAlign: 'bottom',
        },
    },
    alertIcon: {
        position: 'absolute',
        left: theme.spacing(2),
        top: theme.spacing(2),
    },
    error: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
    messageeWithIcon: {
        marginLeft: theme.spacing(4),
    },
    success: {
        borderColor: theme.palette.common.green,
        color: theme.palette.common.green,
    },
    successIcon: {
        color: theme.palette.common.green,
    },
    warning: {
        borderColor: theme.palette.common.blue,
        color: theme.palette.common.blue,
    },
    warningIcon: {
        color: theme.palette.common.blue,
    },
    info: {
        borderColor: theme.palette.common.grey,
        color: theme.palette.common.grey,
    },
    infoIcon: {
        color: theme.palette.common.grey,
    },
});

MuiAlert.defaultProps = {
    icon: null,
    className: '',
    type: 'info',
};

MuiAlert.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    type: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
    icon: PropTypes.node,
    message: PropTypes.node.isRequired,
};

export default withStyles(styles)(MuiAlert);
