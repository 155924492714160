import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';

const ResponsiveButton = (props) => {
    const { width, children } = props;

    const isSmallScreen = /xs|sm|md/.test(width);
    const buttonProps = {
        fullWidth: isSmallScreen,
    };

    return (
        <Button {...buttonProps} {...props}>
            {children}
        </Button>
    );
};

ResponsiveButton.propTypes = {
    width: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default withWidth()(ResponsiveButton);
