import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import shortid from 'shortid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import QuestionnaireResultsAnswer from '../QuestionnaireResultsAnswer/QuestionnaireResultsAnswer';
import resilienceSVG from '../../../assets/images/services/mental-health/resilience.svg';
import perceivedStressSVG from '../../../assets/images/services/mental-health/perceived-stress.svg';
import getContent from '../../../services/content/getContent';

const icons = {
    Resilience: resilienceSVG,
    'Perceived Stress': perceivedStressSVG,
};

const QuestionnaireResultsAnswers = ({
    classes, title, result, result: { groups },
}) => (
    <Card className={classes.card}>
        <CardHeader
            title={title || result.title}
            className={classes.cardHeader}
            classes={{ avatar: classes.avatar }}
            titleTypographyProps={{
                variant: 'h3',
                color: 'textSecondary',
                className: classes.header,
            }}
            subheader={getContent(['components', 'QuestionnaireResultsAnswers', result.title, 'subheader'])}
            avatar={icons[result.title] && (
                <img
                    src={icons[result.title]}
                    alt={result.title}
                />
            )}
        />
        <CardContent className={classes.cardContent}>
            {groups && groups.map((group, index) => (
                <Box className={classes.category} key={`group${shortid.generate()}`}>
                    <Typography variant="body2" color="textSecondary"><strong>{group.name}</strong></Typography>
                    {group.questions.map((question, questionIndex) => (
                        <QuestionnaireResultsAnswer key={question.title.replace(' ', '')} question={question} showLabels={index === 0 && questionIndex === 0} />))}
                </Box>
            ))}
        </CardContent>
    </Card>
);

const styles = theme => ({
    card: {
        marginBottom: theme.spacing(4),
        borderRadius: 15,
    },
    cardHeader: {
        paddingBottom: 0,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        '& > div': {
            paddingBottom: theme.spacing(1.5),
            borderBottom: `1px solid ${theme.palette.common.grey}`,
        },
    },
    cardContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(4),
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
    },
    header: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        paddingBottom: theme.spacing(0.5),
    },
    category: {
        marginBottom: theme.spacing(2),
    },
    avatar: {
        marginRight: 0,
        paddingRight: theme.spacing(2),
        '& > img': {
            maxHeight: 42,
        },
    },
});

QuestionnaireResultsAnswers.defaultProps = {
    title: null,
};

QuestionnaireResultsAnswers.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    result: PropTypes.shape({
        title: PropTypes.string,
        groups: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            questions: PropTypes.arrayOf(PropTypes.shape),
        })),
    }).isRequired,
    title: PropTypes.string,
};

export default withStyles(styles)(QuestionnaireResultsAnswers);
