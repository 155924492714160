import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import shortid from 'shortid';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SectionContainer from '../../layout/SectionContainer/SectionContainer';
import MentalHealthQuestionnaireResultsSummaryCharts from '../MentalHealthQuestionnaireResultsSummaryCharts/MentalHealthQuestionnaireResultsSummaryCharts';
import QuestionnaireResultsAnswers from '../../questionnaire/QuestionnaireResultsAnswers/QuestionnaireResultsAnswers';
import QuestionnaireMessage from '../../questionnaire/QuestionnaireMessage/QuestionnaireMessage';
import QuestionnaireResultsRecommendations from '../../questionnaire/QuestionnaireResultsRecommendations/QuestionnaireResultsRecommendations';
import MuiRoundedButton from '../../material-ui/MuiRoundedButton/MuiRoundedButton';
import RetakeAssessmentReminder from '../../retake-assessment-reminder/RetakeAssessmentReminder';
import HelpfulResources from '../../helpful-resources/HelpfulResources';
import ServiceQuoteCard from '../../questionnaire/QuestionnaireResultsServices/QuestionnaireResultsServices';
import getContent from '../../../services/content/getContent';
import ThriveIcon from '../../../assets/images/icons/thrive-app-icon.svg';
import CounsellingIcon from '../../../assets/images/icons/counselling-icon.svg';

const MentalHealthWellbeingAssessmentComplete = ({
    classes,
    results: {
        summary: {
            priorities,
        },
        results,
        message,
        recommendations,
        latestResultDate,
    },
}) => {
    const [tabSelected, setTabSelected] = React.useState(0);
    const icons = [ThriveIcon, CounsellingIcon];
    return (
        <>
            <SectionContainer
                title="Mental Wellbeing Assessment"
                description="Your Results."
                className={classes.titleContainer}
                subDescription={(
                    <Typography variant="body2" align="center" className={classes.date}>{`Last taken: ${moment(latestResultDate).format('DD/MM/YYYY')}`}</Typography>
                )}
            >
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={6} sm={6} md={3}>
                        <MuiRoundedButton
                            fullWidth
                            size="large"
                            onClick={() => setTabSelected(0)}
                            selected={tabSelected === 0}
                        >
                            Summary
                        </MuiRoundedButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <MuiRoundedButton
                            fullWidth
                            size="large"
                            onClick={() => setTabSelected(1)}
                            selected={tabSelected === 1}
                        >
                            Answers
                        </MuiRoundedButton>
                    </Grid>
                </Grid>
            </SectionContainer>
            <SectionContainer
                backgroundColor="grey"
                className={classes.messageContainer}
            >
                {tabSelected === 0
                    ? <MentalHealthQuestionnaireResultsSummaryCharts priorities={priorities} /> : (
                        <>
                            {results.map(result => <QuestionnaireResultsAnswers key={`result${shortid.generate()}`} result={result} />)}
                        </>
                    )
                }
                {message && (
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={10}>
                            <QuestionnaireMessage message={message} title="Result summary" />
                        </Grid>
                    </Grid>
                )}
            </SectionContainer>
            <Box className={classes.recommendationsContainer}>
                <QuestionnaireResultsRecommendations recommendations={recommendations} />
                <ServiceQuoteCard
                    services={getContent(['components', 'mentalHealthAssessment', 'complete', 'services']).toJS()
                        .map((service, index) => ({ ...service, icon: icons[index] }))}
                />
            </Box>
            <SectionContainer
                backgroundColor="white"
            >
                <RetakeAssessmentReminder />
            </SectionContainer>
            <SectionContainer
                backgroundColor="grey"
                title="Other helpful resources"
                className={classes.resourcesContainer}
            >
                <HelpfulResources items={getContent(['components', 'helpfulResources', 'MentalHealth', 'items']).toJS()} />
            </SectionContainer>
        </>
    );
};

const styles = theme => ({
    titleContainer: {
        '& h2': {
            fontSize: 55,
        },
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.common.green,
    },
    messageContainer: ({ results: { message } }) => ({
        marginBottom: message ? 130 : 0,
    }),
    recommendationsContainer: {
        backgroundColor: theme.palette.common.greyLight,
    },
    resourcesContainer: {
        marginBottom: -48,
    },
    date: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
});

MentalHealthWellbeingAssessmentComplete.defaultProps = {

};

MentalHealthWellbeingAssessmentComplete.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    results: PropTypes.shape({
        summary: PropTypes.shape({
            priorities: PropTypes.arrayOf(PropTypes.shape),
            answers: PropTypes.arrayOf(PropTypes.shape),
        }),
        results: PropTypes.arrayOf(PropTypes.shape),
        recommendations: PropTypes.arrayOf(PropTypes.shape),
        message: PropTypes.string,
        moreArticles: PropTypes.arrayOf(PropTypes.shape),
        latestResultDate: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(MentalHealthWellbeingAssessmentComplete);
