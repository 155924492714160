import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const QuestionnaireResultsPriorityAnswer = ({
    classes, question, question: { answers }, showLabels,
}) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid container spacing={0} className={classes.root}>
            <Grid item xs={12} sm={12} md={7}>
                <Typography variant="body2" color="textSecondary" className={classes.title}>{question.title}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
                {(showLabels || sm) && answers.length > 0 && (
                    <Box className={classes.labels}>
                        <span>{answers[0].label}</span>
                        <span>{answers[Math.floor(answers.length / 2)].label}</span>
                        <span>{answers[answers.length - 1].label}</span>
                    </Box>
                )}
                <Box className={classes.answerChecks}>
                    {answers && answers.map(answer => (answer.value === question.value
                        ? <CheckCircleIcon className={classes.checked} key={answer.id} />
                        : <FiberManualRecordTwoToneIcon className={classes.unchecked} key={answer.id} />
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
};

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    answerChecks: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 35,
        marginRight: 35,
    },
    checked: {
        fontSize: 20,
        color: theme.palette.common.green,
        width: 24,
    },
    unchecked: {
        fontSize: 24,
        color: theme.palette.common.grey,
    },
    labels: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: -24,
        paddingBottom: 6,
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        '& > span': {
            width: 100,
            textAlign: 'center',
        },
    },
});

QuestionnaireResultsPriorityAnswer.defaultProps = {
    showLabels: false,
};

QuestionnaireResultsPriorityAnswer.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    question: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        answers: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })),
    }).isRequired,
    showLabels: PropTypes.bool,
};

export default withStyles(styles)(QuestionnaireResultsPriorityAnswer);
