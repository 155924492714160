import * as Immutable from 'immutable';
import {
    HEALTHY_BITES_ARTICLES_REQUESTED,
    HEALTHY_BITES_ARTICLES_RESOLVED,
    HEALTHY_BITES_ARTICLES_ERROR,
    HEALTHY_BITES_ARTICLES_NEXT_REQUESTED,
    HEALTHY_BITES_ARTICLES_NEXT_RESOLVED,
    HEALTHY_BITES_ARTICLES_NEXT_ERROR,
    HEALTHY_BITES_ARTICLE_REQUESTED,
    HEALTHY_BITES_ARTICLE_RESOLVED,
    HEALTHY_BITES_ARTICLE_ERROR,
    HEALTHY_BITES_CATEGORIES_RESOLVED,
    HEALTHY_BITES_CATEGORIES_ERROR,
    HEALTHY_BITES_ARTICLES_FEATURED_REQUESTED,
    HEALTHY_BITES_ARTICLES_FEATURED_RESOLVED,
    HEALTHY_BITES_ARTICLES_FEATURED_ERROR,
    HEALTHY_BITES_ARTICLES_RELATED_REQUESTED,
    HEALTHY_BITES_ARTICLES_RELATED_RESOLVED,
    HEALTHY_BITES_ARTICLES_RELATED_ERROR,
    HEALTHY_BITES_FAVOURITE_REQUESTED,
    HEALTHY_BITES_FAVOURITE_RESOLVED,
    HEALTHY_BITES_UNFAVOURITE_REQUESTED,
    HEALTHY_BITES_UNFAVOURITE_RESOLVED,
    HEALTHY_BITES_FAVOURITES_RESOLVED,
    HEALTHY_BITES_SET_FAVOURITE_FILTER_RESOLVED,
    HEALTHY_BITES_SPOTLIGHT_REQUESTED,
    HEALTHY_BITES_SPOTLIGHT_RESOLVED,
    HEALTHY_BITES_SPOTLIGHT_ERROR,
    HEALTHY_BITES_AUTHOR_ARTICLES_REQUESTED,
    HEALTHY_BITES_AUTHOR_ARTICLES_RESOLVED,
    HEALTHY_BITES_AUTHOR_ARTICLES_ERROR,
    HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_REQUESTED,
    HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_RESOLVED,
    HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_ERROR,
} from '../actions/healthy-bites/types';

const relatedArticles = {
    status: 'INIT',
    resolving: false,
    articles: [],
};

const featuredArticles = {
    status: 'INIT',
    resolving: false,
    articles: [],
};

const favouriteArticles = {
    status: 'INIT',
    resolving: false,
    favouriteIdsResolving: [], // ids of requests resolving
    favourites: [],
};

const spotlight = {
    status: 'INIT',
    resolving: false,
    spotlight: null,
};

const pageInfo = {
    hasNextPage: false,
    resolving: false,
    page: null,
    pageSize: null,
    total: 0,
    categories: [],
};

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    articles: [],
    pageInfo,
    article: null,
    categories: [],
    featuredArticles,
    relatedArticles,
    favouriteArticles,
    favouriteFilter: false,
    spotlight,
    author: null,
};

const initialState = Immutable.Map(schema);

/**
 * The healthy bites reducer - responsible for managing the healthy bites and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const healthyBitesReducer = (state = initialState, action) => {
    switch (action.type) {
    case HEALTHY_BITES_ARTICLES_REQUESTED:
    case HEALTHY_BITES_ARTICLE_REQUESTED:
    case HEALTHY_BITES_AUTHOR_ARTICLES_REQUESTED:
        return state
            .set('resolving', true)
            .setIn(['pageInfo', 'resolving'], false);

    case HEALTHY_BITES_ARTICLES_NEXT_REQUESTED:
    case HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_REQUESTED:
        return state.setIn(['pageInfo', 'resolving'], true);

    case HEALTHY_BITES_ARTICLES_FEATURED_REQUESTED:
        return state.setIn(['featuredArticles', 'resolving'], true);

    case HEALTHY_BITES_ARTICLES_RELATED_REQUESTED:
        return state.setIn(['relatedArticles', 'resolving'], true);

    case HEALTHY_BITES_ARTICLES_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('articles', action.payload.articles)
            .setIn(['pageInfo', 'hasNextPage'], action.payload.hasNextPage)
            .setIn(['pageInfo', 'page'], action.payload.page)
            .setIn(['pageInfo', 'pageSize'], action.payload.pageSize)
            .setIn(['pageInfo', 'categories'], action.payload.categories)
            .setIn(['pageInfo', 'total'], action.payload.total)
            .setIn(['pageInfo', 'resolving'], false);

    case HEALTHY_BITES_AUTHOR_ARTICLES_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('articles', action.payload.articles)
            .setIn(['pageInfo', 'hasNextPage'], action.payload.hasNextPage)
            .setIn(['pageInfo', 'page'], action.payload.page)
            .setIn(['pageInfo', 'pageSize'], action.payload.pageSize)
            .setIn(['pageInfo', 'categories'], action.payload.categories)
            .setIn(['pageInfo', 'total'], action.payload.total)
            .setIn(['pageInfo', 'resolving'], false)
            .set('author', action.payload.author);

    case HEALTHY_BITES_ARTICLES_NEXT_RESOLVED:
    case HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_RESOLVED:
        return state
            .set('status', 'OK')
            .update('articles', articles => articles.concat(action.payload.articles))
            .setIn(['pageInfo', 'page'], action.payload.page)
            .setIn(['pageInfo', 'pageSize'], action.payload.pageSize)
            .setIn(['pageInfo', 'hasNextPage'], action.payload.hasNextPage)
            .setIn(['pageInfo', 'categories'], action.payload.categories)
            .setIn(['pageInfo', 'total'], action.payload.total)
            .setIn(['pageInfo', 'resolving'], false);

    case HEALTHY_BITES_ARTICLES_FEATURED_RESOLVED:
        return state
            .setIn(['featuredArticles', 'status'], 'OK')
            .setIn(['featuredArticles', 'articles'], action.payload.articles)
            .setIn(['featuredArticles', 'resolving'], false);

    case HEALTHY_BITES_ARTICLES_RELATED_RESOLVED:
        return state
            .setIn(['relatedArticles', 'status'], 'OK')
            .setIn(['relatedArticles', 'articles'], action.payload.articles)
            .setIn(['relatedArticles', 'resolving'], false);

    case HEALTHY_BITES_ARTICLE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('article', action.payload);

    case HEALTHY_BITES_CATEGORIES_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('categories', action.payload);

    case HEALTHY_BITES_ARTICLE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('article', null);

    case HEALTHY_BITES_ARTICLES_ERROR:
    case HEALTHY_BITES_AUTHOR_ARTICLES_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('articles', [])
            .set('pageInfo', pageInfo)
            .set('author', null);

    case HEALTHY_BITES_ARTICLES_NEXT_ERROR:
    case HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_ERROR:
        return state
            .set('status', 'ERROR')
            .set('pageInfo', pageInfo);

    case HEALTHY_BITES_ARTICLES_FEATURED_ERROR:
        return state
            .setIn(['featuredArticles', 'status'], 'ERROR')
            .setIn(['featuredArticles', 'articles'], [])
            .setIn(['featuredArticles', 'resolving'], false);

    case HEALTHY_BITES_ARTICLES_RELATED_ERROR:
        return state
            .setIn(['relatedArticles', 'status'], 'ERROR')
            .setIn(['relatedArticles', 'articles'], [])
            .setIn(['relatedArticles', 'resolving'], false);

    case HEALTHY_BITES_CATEGORIES_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('categories', []);

    case HEALTHY_BITES_FAVOURITE_REQUESTED:
        return state
            .updateIn(['favouriteArticles', 'favouriteIdsResolving'], (favouriteIdsResolving) => {
                if (favouriteIdsResolving.indexOf(action.payload.articleId) === -1) {
                    favouriteIdsResolving.push(action.payload.articleId);
                    return favouriteIdsResolving;
                }
                return favouriteIdsResolving;
            });

    case HEALTHY_BITES_FAVOURITE_RESOLVED:
        return state
            .updateIn(['favouriteArticles', 'favouriteIdsResolving'], (favouriteIdsResolving) => {
                const index = favouriteIdsResolving.indexOf(action.payload.articleId);
                if (index !== -1) {
                    favouriteIdsResolving.splice(index, 1);
                    return favouriteIdsResolving;
                }
                return favouriteIdsResolving;
            })
            .setIn(['favouriteArticles', 'favourites'], action.payload.favourites);

    case HEALTHY_BITES_UNFAVOURITE_REQUESTED:
        return state
            .updateIn(['favouriteArticles', 'favouriteIdsResolving'], (favouriteIdsResolving) => {
                if (favouriteIdsResolving.indexOf(action.payload.articleId) === -1) {
                    favouriteIdsResolving.push(action.payload.articleId);
                    return favouriteIdsResolving;
                }
                return favouriteIdsResolving;
            });

    case HEALTHY_BITES_UNFAVOURITE_RESOLVED:
        return state
            .updateIn(['favouriteArticles', 'favouriteIdsResolving'], (favouriteIdsResolving) => {
                const index = favouriteIdsResolving.indexOf(action.payload.articleId);
                if (index !== -1) {
                    favouriteIdsResolving.splice(index, 1);
                    return favouriteIdsResolving;
                }
                return favouriteIdsResolving;
            })
            .setIn(['favouriteArticles', 'favourites'], action.payload.favourites)
            .update('articles', (articles) => {
                if (state.get('favouriteFilter') === true) {
                    return articles.filter(article => action.payload.favourites.findIndex(favourite => favourite.articleId === article.id) !== -1);
                }
                return articles;
            });

    case HEALTHY_BITES_FAVOURITES_RESOLVED:
        return state
            .setIn(['favouriteArticles', 'resolving'], false)
            .setIn(['favouriteArticles', 'status'], 'OK')
            .setIn(['favouriteArticles', 'favourites'], action.payload.favourites);

    case HEALTHY_BITES_SET_FAVOURITE_FILTER_RESOLVED:
        return state
            .set('favouriteFilter', action.payload.active);

    case HEALTHY_BITES_SPOTLIGHT_REQUESTED:
        return state.setIn(['spotlight', 'resolving'], true);

    case HEALTHY_BITES_SPOTLIGHT_RESOLVED:
        return state
            .setIn(['spotlight', 'resolving'], false)
            .setIn(['spotlight', 'status'], 'OK')
            .setIn(['spotlight', 'spotlight'], action.payload);

    case HEALTHY_BITES_SPOTLIGHT_ERROR:
        return state
            .setIn(['spotlight', 'resolving'], false)
            .setIn(['spotlight', 'status'], 'ERROR')
            .setIn(['spotlight', 'spotlight'], null);

    default:
        return state;
    }
};

export { initialState };

export default healthyBitesReducer;
