export const QUESTIONNAIRE_REQUESTED = 'QUESTIONNAIRE_REQUESTED';
export const QUESTIONNAIRE_RESOLVED = 'QUESTIONNAIRE_RESOLVED';
export const QUESTIONNAIRE_ERROR = 'QUESTIONNAIRE_ERROR';

export const QUESTIONNAIRE_UPDATE_REQUESTED = 'QUESTIONNAIRE_UPDATE_REQUESTED';
export const QUESTIONNAIRE_UPDATE_RESOLVED = 'QUESTIONNAIRE_UPDATE_RESOLVED';
export const QUESTIONNAIRE_UPDATE_ERROR = 'QUESTIONNAIRE_UPDATE_ERROR';

export const QUESTIONNAIRE_RESULTS_REQUESTED = 'QUESTIONNAIRE_RESULTS_REQUESTED';
export const QUESTIONNAIRE_RESULTS_RESOLVED = 'QUESTIONNAIRE_RESULTS_RESOLVED';
export const QUESTIONNAIRE_RESULTS_ERROR = 'QUESTIONNAIRE_RESULTS_ERROR';

export const QUESTIONNAIRE_SET_META = 'QUESTIONNAIRE_SET_META';
