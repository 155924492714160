import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';

const SummaryOutput = ({ classes, title, description }) => (
    <ListItemText
        primary={title}
        secondary={description}
        primaryTypographyProps={{
            variant: 'h4',
            color: 'textSecondary',
            className: classes.headerFont,
        }}
        secondaryTypographyProps={{
            variant: 'body2',
        }}
    />
);


const styles = theme => ({
    headerFont: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        display: 'block',
        marginBottom: 6,
    },
});

SummaryOutput.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default withStyles(styles)(SummaryOutput);
