import getMemberQuery from '../../services/graphQL/member/getMemberQuery';
import {
    MEMBER_REQUESTED,
    MEMBER_RESOLVED,
    MEMBER_ERROR,
} from './types';

/**
 * Action helper which resolves the member to the redux store
 */

const getMemberAction = async () => async (dispatch, getState) => {
    const { member } = getState();

    if (member.get('resolving')) return false;

    dispatch({
        type: MEMBER_REQUESTED,
    });

    try {
        const payload = await getMemberQuery();
        return dispatch({
            type: MEMBER_RESOLVED,
            payload,
        });
    } catch (error) {
        return dispatch({
            type: MEMBER_ERROR,
        });
    }
};

export default getMemberAction;
