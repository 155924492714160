import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Container } from '../../layout';
import ServiceQuoteCard from '../../services/ServiceQuoteCard/ServiceQuoteCard';

const QuestionnaireResultsServices = ({
    classes,
    services,
}) => services.map(service => (
    <Box key={service.title.replace(/ /g, '')} className={classes.container}>
        <Container>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <ServiceQuoteCard {...service} />
                </Grid>
            </Grid>
        </Container>
    </Box>
));

const styles = theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        background: theme.palette.background.greyLightFadePinTop,
        backgroundPosition: '0 0',
    },
    box: {
        alignItems: 'center',
    },
});

QuestionnaireResultsServices.defaultProps = {

};

QuestionnaireResultsServices.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    services: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        quote: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
    })).isRequired,
};

export default withStyles(styles)(QuestionnaireResultsServices);
