import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import QuestionnaireResultsSummaryPanel from '../QuestionnaireResultsSummaryPanel/QuestionnaireResultsSummaryPanel';

const QuestionnaireResultsSummaries = ({ priorities, panelResize }) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const getItemCount = () => {
        let count = 0;
        priorities.forEach((priority) => {
            count += priority.items.length;
        });
        return count;
    };
    const itemCount = getItemCount();
    const fullPanelLimit = itemCount < 5 ? itemCount : 5;

    /**
     * Make column full size if the other columns have no entries
     * or if the current column has more than or equal to 5 items
     * @param {Number} key
     * @return {Boolean}
     */
    const isFullColumnSize = key => priorities
        .filter((priority, index) => index !== key && priority.items && priority.items.length > 0).length === 0
        || priorities[key].items.length >= fullPanelLimit;

    const hasFullColumnSize = () => {
        let found = false;
        priorities.forEach((priority, key) => {
            if (isFullColumnSize(key)) {
                found = true;
            }
        });
        return found;
    };

    const getColumnSize = (key, defaultSize) => {
        if (!panelResize) {
            return defaultSize;
        }
        if (isFullColumnSize(key)) {
            return 12;
        }
        if (hasFullColumnSize()) {
            return 6;
        }
        return defaultSize;
    };

    return (
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="stretch"
        >
            {priorities.map((priority, key) => (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={getColumnSize(key, 4)}
                    lg={getColumnSize(key, 4)}
                    key={priority.title.replace(' ', '')}
                    style={{ order: panelResize && isFullColumnSize(key) ? -1 : 0 }}
                >
                    <QuestionnaireResultsSummaryPanel
                        priority={priority}
                        color={theme.palette.performance[key]}
                        inline={!sm && panelResize && (getColumnSize(key, 4) === 12)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

QuestionnaireResultsSummaries.defaultProps = {
    panelResize: false,
};

QuestionnaireResultsSummaries.propTypes = {
    priorities: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.object),
    })).isRequired,
    panelResize: PropTypes.bool,
};

export default QuestionnaireResultsSummaries;
