import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import shortid from 'shortid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import MuiTable from '../MuiTable/MuiTable';
import MuiTablePagination from '../MuiTablePagination/MuiTablePagination';

class MuiStateTable extends MuiTable {
    constructor(props) {
        super(props);
        this.immediate = false;
        this.tableEl = React.createRef();
    }

    shouldComponentUpdate = nextProps => nextProps.actionMenuOpen !== true;

    checkResolving = () => {
        const { resolving } = this.props;
        return resolving;
    }

    handleRefetch = async () => {
        if (!this.checkResolving()) {
            const { onSearchChange } = this.props;
            const { filters } = this.state;
            const status = filters.status && filters.status[0];
            await onSearchChange({ ..._.pick(this.state, ['order', 'orderBy', 'page', 'searchText', 'rowsPerPage', 'status']), ...{ status } });
        }
    }

    /**
     * Handle the search term input
     *
     * @param  {String} searchText
     */
    handleSearchChange = async (searchText) => {
        if (!this.checkResolving()) {
            const { onSearchChange } = this.props;
            const { filters } = this.state;
            try {
                this.setState({ page: 0, searchText });
                const status = filters.status && filters.status[0];
                await onSearchChange({ ..._.pick(this.state, ['order', 'orderBy', 'page', 'searchText', 'rowsPerPage']), ...{ searchText, page: 0, status } });
            } catch (error) {
                throw error;
            }
        }
    }

    /**
     * Handle the page change
     *
     * @param  {Event} event
     * @param  {Number} page
     */
    handleChangePage = async (event, page) => {
        if (!this.checkResolving()) {
            const { onPageChange } = this.props;
            const { filters } = this.state;
            try {
                this.setState({ page });
                const status = filters.status && filters.status[0];
                await onPageChange({
                    ..._.pick(this.state, ['order', 'orderBy', 'page', 'searchText', 'rowsPerPage']),
                    page,
                    status,
                    filters,
                });
            } catch (error) {
                throw error;
            }
        }
    };

    /**
     * Handle the change of number of rows
     *
     * @param  {Event} event
     */
    handleChangeRowsPerPage = async (event) => {
        if (!this.checkResolving()) {
            const { onRowsPerPageChange } = this.props;
            const { filters } = this.state;
            const { target: { value } } = event;
            try {
                this.setState({ rowsPerPage: value, page: 0 });
                const status = filters.status && filters.status[0];
                await onRowsPerPageChange({
                    ..._.pick(this.state, ['order', 'orderBy', 'searchText', 'rowsPerPage']),
                    ...{
                        rowsPerPage: value,
                        status,
                        page: 0,
                        filters,
                    },
                });
                window.scrollTo(0, this.tableEl.current.offsetTop);
            } catch (error) {
                throw error;
            }
        }
    };

    /**
     * Handle the change of sort column
     *
     * @param  {Event} event
     * @param  {String} nextOrderBy
     */
    handleSortChange = async (event, setOrderBy) => {
        if (!this.checkResolving()) {
            const { onSortChange } = this.props;
            const { orderBy, order, filters } = this.state;
            let nextOrderBy = order;
            if (orderBy === setOrderBy) {
                nextOrderBy = order === 'asc' ? 'desc' : 'asc';
            }
            try {
                this.setState({
                    order: nextOrderBy,
                    orderBy: setOrderBy,
                    page: 0,
                });
                const status = filters.status && filters.status[0];
                await onSortChange({
                    ..._.pick(this.state, ['order', 'orderBy', 'page', 'searchText', 'rowsPerPage']),
                    ...{
                        order: nextOrderBy, orderBy: setOrderBy, status, page: 0,
                    },
                });
            } catch (error) {
                throw error;
            }
        }
    };

    /**
     * Handle the filter change
     *
     * @param  {String} property
     * @param  {String} value
     * @param  {Bool} reset
     * @return {Object}
     */
    handleFilterChange = async (property, value, reset) => {
        const { selectedRows, filters } = this.state;
        const { onFilterChange } = this.props;
        let status;
        let nextFilters = filters;
        // Check if the filter already exists and remove it
        if (nextFilters[property] && nextFilters[property].indexOf(value) !== -1) {
            nextFilters[property].splice(nextFilters[property].indexOf(value), 1);
            if (_.isEmpty(nextFilters[property])) {
                nextFilters = _.omit(nextFilters, property);
            }
        } else {
            status = value;
            // If the reset flag is set then clear nextFilters
            if (reset) {
                nextFilters = {};
            }
            // If the filter property exists then push the new filter into its array
            // Else add the new propery and push the initial filter
            if (nextFilters[property]) {
                nextFilters[property].push(value);
            } else {
                nextFilters[property] = [value];
            }
        }

        try {
            this.setState({ filters: nextFilters, selectedRows: { ...selectedRows, selected: [] }, page: 0 });
            await onFilterChange({ ..._.pick(this.state, ['order', 'orderBy', 'page', 'searchText', 'rowsPerPage']), ...{ status, page: 0, filters: nextFilters } });
        } catch (error) {
            throw error;
        }
        return nextFilters;
    }

    render() {
        const {
            data,
            columns,
            options: { customToolbar },
            overflowClass,
            total,
            resolving,
            classes,
        } = this.props;
        const self = this;

        const {
            filters,
            page,
            pagination,
            searchText,
            selectableRows,
            selectedRows,
            selectedRows: { uidIndex },
            rowsPerPage,
            orderBy,
        } = this.state;

        if (columns.length <= 0) return null;

        /**
         * Render the table headers
         *
         * @return {Node}
         */
        const renderHeaders = () => (
            <TableHead>
                <TableRow>
                    {selectableRows ? (
                        <TableCell padding="checkbox">
                            {self.renderHeaderCheckbox()}
                        </TableCell>
                    ) : null}
                    {columns
                        .filter(column => !_.hasIn(column, 'options.hide'))
                        .map(column => (
                            <TableCell key={column.name}>
                                {self.renderHeaderCell(column)}
                            </TableCell>
                        ))}
                </TableRow>
            </TableHead>
        );

        /**
         * Render the table pagination
         *
         * @return {Node}
         */
        const renderPagination = count => (
            <MuiTablePagination
                rowsPerPageOptions={[10, 15, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onPageChange={self.handleChangePage}
                onRowsPerPageChange={self.handleChangeRowsPerPage}
            />
        );

        return (
            <div>
                {customToolbar ? customToolbar({
                    filters,
                    searchText,
                    selectedRows,
                    onSearchChange: this.handleSearchChange,
                    onFilterChange: this.handleFilterChange,
                    onSelectableChange: this.handleSelectableChange,
                }) : null}
                <div className={`${!resolving ? overflowClass : ''}`}>
                    <Table ref={this.tableEl}>
                        {renderHeaders()}
                        <TableBody>
                            {!resolving && data
                                .map((row) => {
                                    const isSelected = this.isSelected(row[uidIndex]);
                                    return (
                                        <TableRow
                                            key={row[uidIndex]}
                                            hover={selectableRows}
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            selected={isSelected}
                                        >
                                            {selectableRows ? (
                                                <TableCell
                                                    onClick={event => (selectableRows ? this.handleRowSelectClick(event, row[uidIndex]) : null)}
                                                    padding="checkbox"
                                                >
                                                    {this.renderBodyCheckbox(isSelected)}
                                                </TableCell>
                                            ) : null}
                                            {row.map((cell, columnIndex) => (
                                                !_.hasIn(columns[columnIndex], 'options.hide')
                                                    ? (
                                                        <TableCell
                                                            key={`${columns[columnIndex].name}_${shortid.generate()}`}
                                                            align={_.hasIn(columns[columnIndex], 'options.align') ? _.get(columns[columnIndex], 'options.align') : 'left'}
                                                            style={{
                                                                minWidth: _.hasIn(columns[columnIndex], 'options.width')
                                                                    ? `${_.get(columns[columnIndex], 'options.width')}px` : 'auto',
                                                            }}
                                                        >
                                                            {(_.get(columns[columnIndex], 'name') === orderBy)
                                                                ? (<strong>{this.renderCell(cell, columnIndex, columns, searchText, row)}</strong>)
                                                                : this.renderCell(cell, columnIndex, columns, searchText, row)
                                                            }
                                                        </TableCell>
                                                    ) : null
                                            ))}
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
                {resolving ? <CircularProgress color="secondary" className={classes.loader} /> : null}
                {(pagination ? renderPagination(total) : null)}
            </div>
        );
    }
}

const styles = theme => ({
    loader: {
        display: 'block',
        margin: `${theme.spacing(4)}px auto`,
        textAlign: 'center',
    },
});

MuiStateTable.defaultProps = {
    resolving: false,
    actionMenuOpen: false,
};

MuiStateTable.propTypes = {
    onSearchChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    resolving: PropTypes.bool,
    actionMenuOpen: PropTypes.bool,
};

export default withStyles(styles)(MuiStateTable);
