import { gql } from '@apollo/client';
import getContent from '../../content/getContent';
import graphQLCLient from '../client';
import errorHandler from '../errorHandler';

const getCompanyMembersUploadStatusQuery = async () => {
    const client = graphQLCLient();

    const query = gql`query GetCompanyMembersUploadStatus {
        getCompanyMembersUploadStatus {
            stage
            processed
            total
            isComplete
            errors {
                row
                field
                message
            }
        }
    }`;

    return new Promise((resolve, reject) => {
        client.query({
            query,
        })
            .then((result) => {
                if (!result.data || !Object.prototype.hasOwnProperty.call(result.data, 'getCompanyMembersUploadStatus')) {
                    throw new Error(getContent(['errors', 'invalid-graphQL-response']));
                }
                resolve(result.data.getCompanyMembersUploadStatus);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};

export default getCompanyMembersUploadStatusQuery;
