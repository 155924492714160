import * as Immutable from 'immutable';
import _ from 'lodash';
import {
    MEMBER_REQUESTED,
    MEMBER_RESOLVED,
    MEMBER_ERROR,
    MEMBER_UPDATE_REQUESTED,
    MEMBER_UPDATE_RESOLVED,
    MEMBER_UPDATE_ERROR,
    MEMBER_INTERESTS_REQUESTED,
    MEMBER_INTERESTS_RESOLVED,
    MEMBER_INTERESTS_ERROR,
    MEMBER_INTERESTS_UPDATE_REQUESTED,
    MEMBER_INTERESTS_UPDATE_RESOLVED,
    MEMBER_INTERESTS_UPDATE_ERROR,
    MEMBER_ALL_INTERESTS_REQUESTED,
    MEMBER_ALL_INTERESTS_RESOLVED,
    MEMBER_ALL_INTERESTS_ERROR,
    MEMBER_NOTIFICATION_PREFERENCES_REQUESTED,
    MEMBER_NOTIFICATION_PREFERENCES_RESOLVED,
    MEMBER_NOTIFICATION_PREFERENCES_ERROR,
    MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED,
    MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED,
    MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR,
    MEMBER_REGISTRATION_COMPLETE_RESOLVED,
    MEMBER_AND_PATIENT_UPDATE_REQUESTED,
    MEMBER_AND_PATIENT_UPDATE_RESOLVED,
    MEMBER_AND_PATIENT_UPDATE_ERROR,
} from '../actions/member/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    statusOf: {
        member: 'INIT',
        interests: 'INIT',
        memberInterests: 'INIT',
        notifications: 'INIT',
    },
    resolving: false,
    submitting: false,
    member: null,
    interests: [],
    memberInterests: [],
    notifications: null,
};

const initialState = Immutable.Map(schema);
/**
 * The member reducer - responsible for managing a member and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const memberReducer = (state = initialState, action) => {
    switch (action.type) {
    case MEMBER_UPDATE_REQUESTED:
    case MEMBER_AND_PATIENT_UPDATE_REQUESTED:
    case MEMBER_REQUESTED:
    case MEMBER_INTERESTS_REQUESTED:
    case MEMBER_ALL_INTERESTS_REQUESTED:
    case MEMBER_NOTIFICATION_PREFERENCES_REQUESTED:
        return state.set('resolving', true);
    case MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED:
    case MEMBER_INTERESTS_UPDATE_REQUESTED:
        return state.set('submitting', true);
    case MEMBER_UPDATE_RESOLVED:
    case MEMBER_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .setIn(['statusOf', 'member'], 'OK')
            .set('member', action.payload);
    case MEMBER_AND_PATIENT_UPDATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .setIn(['statusOf', 'member'], 'OK')
            .setIn(['member', 'mobilePhone'], action.payload.mobilePhone);
    case MEMBER_REGISTRATION_COMPLETE_RESOLVED:
        return state
            .set('member', action.payload);
    case MEMBER_INTERESTS_RESOLVED:
    case MEMBER_INTERESTS_UPDATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('submitting', false)
            .set('status', 'OK')
            .setIn(['statusOf', 'memberInterests'], 'OK')
            .set('memberInterests', action.payload.map(interest => _.pick(interest, ['id', 'name'])));

    case MEMBER_ALL_INTERESTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .setIn(['statusOf', 'interests'], 'OK')
            .set('interests', action.payload);

    case MEMBER_NOTIFICATION_PREFERENCES_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .setIn(['statusOf', 'notifications'], 'OK')
            .set('notifications', action.payload);

    case MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('submitting', false)
            .set('status', 'OK')
            .setIn(['statusOf', 'notifications'], 'OK')
            .set('notifications', action.payload);

    case MEMBER_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .setIn(['statusOf', 'member'], 'ERROR')
            .set('member', null);

    case MEMBER_INTERESTS_UPDATE_ERROR:
    case MEMBER_INTERESTS_ERROR:
        return state
            .set('submitting', false)
            .set('resolving', false)
            .setIn(['statusOf', 'memberInterests'], 'ERROR')
            .set('memberInterests', []);

    case MEMBER_ALL_INTERESTS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .setIn(['statusOf', 'interests'], 'ERROR')
            .set('interests', []);

    case MEMBER_UPDATE_ERROR:
    case MEMBER_AND_PATIENT_UPDATE_ERROR:
        return state
            .set('resolving', false)
            .setIn(['statusOf', 'member'], 'ERROR');

    case MEMBER_NOTIFICATION_PREFERENCES_ERROR:
    case MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR:
        return state
            .set('resolving', false)
            .set('submitting', false)
            .setIn(['statusOf', 'notifications'], 'ERROR')
            .set('notifications', null);

    default:
        return state;
    }
};

export { initialState };

export default memberReducer;
