import variables from '../variables';

const MuiCheckbox = {
    root: {
        '&$checked': {
            color: variables.colors.blue,
        },
    },
    colorSecondary: {
        '&$checked': {
            color: variables.colors.blue,
        },
    },
};

export default MuiCheckbox;
