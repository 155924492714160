import getCompanyContactQuery from '../../services/graphQL/company-contact/getCompanyContactQuery';
import {
    COMPANY_CONTACT_REQUESTED,
    COMPANY_CONTACT_RESOLVED,
    COMPANY_CONTACT_ERROR,
} from './types';

/**
 * Action helper which resolves the contact of a company to the redux store
 */

const getCompanyContactAction = async () => async (dispatch, getState) => {
    const { companyContact } = getState();

    if (companyContact.get('resolving')) return false;

    dispatch({
        type: COMPANY_CONTACT_REQUESTED,
    });

    try {
        const payload = await getCompanyContactQuery();
        return dispatch({
            type: COMPANY_CONTACT_RESOLVED,
            payload,
        });
    } catch (error) {
        return dispatch({
            type: COMPANY_CONTACT_ERROR,
        });
    }
};

export default getCompanyContactAction;
