import React from 'react';
import getContent from '../../services/content/getContent';
import StaticMessagePage from '../static-message-page/StaticMessagePage';

const MaintenancePage = () => (
    <StaticMessagePage
        title={getContent(['components', 'maintenancePage', 'title'])}
        subTitle={getContent(['components', 'maintenancePage', 'subtitle'])}
    />
);

export default MaintenancePage;
