import _ from 'lodash';
/**
 * Compare and order two rows
 *
 * @param  {Object} a
 * @param  {Object} b
 * @param  {String} orderBy
 * @return {Number}
 */
const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};
/**
 * Stabilize the sort and add the index to the array of data
 *
 * @param  {Array} array
 * @param  {Func} cmp
 * @return {Array}
 */
const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
};
/**
 * Get the sorting order
 *
 * @param  {String} order
 * @param  {String} orderBy
 * @return {func}
 */
const getSortingOrder = (order, orderBy) => (order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy));

/**
 * Computer the display row
 *
 * @param  {Array} columns
 * @param  {Array} row
 * @param  {Number} rowIndex
 * @param  {String} searchText
 * @param  {Object} filters
 * @return {Bool}
 */
const computeDisplayRow = (columns, row, rowIndex, searchText, filters) => {
    if (searchText.length <= 0 && _.size(filters) === 0) {
        return true;
    }
    // Does the row match any filters that are set?
    const matchFilter = row.findIndex((cell, index) => {
        const property = columns[index].name;
        // If the filter is a function then run the function
        if (filters[property] && filters[property].findIndex(item => typeof item === 'function') !== -1) {
            const func = filters[property].find(item => typeof item === 'function');
            return func(property, cell);
        }
        return filters[property] && filters[property].indexOf(cell.toLowerCase()) !== -1;
    }) !== -1 || _.size(filters) === 0;
    // Does the row contain any matches to the search text
    const matchSearch = row.findIndex((cell, index) => {
        const searchable = columns[index].options && columns[index].options.searchable;
        return cell && cell.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 && searchable;
    }) !== -1 || searchText.length <= 0;

    return matchFilter && matchSearch;
};

export {
    desc,
    stableSort,
    getSortingOrder,
    computeDisplayRow,
};
