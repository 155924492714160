import getCompanyMembersUploadStatusQuery from '../../services/graphQL/company-members/getCompanyMembersUploadStatusQuery';
import {
    ADD_COMPANY_MEMBERS_UPLOAD_STATUS_REQUESTED,
    ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED,
    ADD_COMPANY_MEMBERS_UPLOAD_STATUS_ERROR,
} from './types';

/**
 * Action helper which resolves the members upload status of a company to the redux store
 */

const getCompanyMembersUploadStatusAction = async () => async (dispatch, getState) => {
    const { companyMembers } = getState();

    if (companyMembers.getIn(['membersUploadStatus', 'resolving'])) return false;

    dispatch({
        type: ADD_COMPANY_MEMBERS_UPLOAD_STATUS_REQUESTED,
    });

    try {
        const payload = await getCompanyMembersUploadStatusQuery();
        return dispatch({
            type: ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED,
            payload,
        });
    } catch (error) {
        if (error.message === 'NO_CURRENT_UPLOADS') {
            return dispatch({
                type: ADD_COMPANY_MEMBERS_UPLOAD_STATUS_RESOLVED,
            });
        }

        return dispatch({
            type: ADD_COMPANY_MEMBERS_UPLOAD_STATUS_ERROR,
            payload: error.message,
        });
    }
};

export default getCompanyMembersUploadStatusAction;
