import {
    AUTH_DISABLE_AUTOLOGOUT,
    AUTH_ENABLE_AUTOLOGOUT,
} from './types';

/**
 * Action helper which sets the autologout flag
 * @param {Bool} active
 */
const setAutoLogoutAction = active => (dispatch) => {
    dispatch({
        type: active ? AUTH_ENABLE_AUTOLOGOUT : AUTH_DISABLE_AUTOLOGOUT,
    });
};

export default setAutoLogoutAction;
