const MuiTypography = {
    root: {

    },
    gutterBottom: {
        marginBottom: '0.50em',
    },
};

export default MuiTypography;
