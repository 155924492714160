import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '../../icon/Icon';

const AssessmentStep = ({
    classes,
    icon,
    header,
    title,
    description,
}) => (
    <Box className={`${classes.root} aut-assessmentStep`}>
        <Typography variant="overline" className={`${classes.header} aut-assessmentStep_header`}>
            {header}
        </Typography>
        <Typography variant="h3" className={`${classes.title} aut-assessmentStep_title`}>
            {title}
        </Typography>
        <Box className={`${classes.icon} aut-assessmentStep_icon`}>
            <Icon
                src={icon}
                alt={title}
            />
        </Box>
        <Typography variant="body1" align="center" color="textSecondary" className={`${classes.description} aut-assessmentStep_description`}>
            {description}
        </Typography>
    </Box>
);

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        position: 'relative',
        zIndex: 1,
    },
    header: {
        fontSize: 14,
        letterSpacing: 1,
    },
    title: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: 26,
    },
    icon: {

    },
    description: {
        flex: 1,
        marginTop: theme.spacing(2),
    },
});

AssessmentStep.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default withStyles(styles)(AssessmentStep);
