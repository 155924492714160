import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';

const MuiCardContent = ({ classes, className, children }) => (
    <CardContent className={`${classes.root} ${className || ''}`}>
        {children}
    </CardContent>
);

const styles = theme => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(3.5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
});

MuiCardContent.defaultProps = {
    className: '',
};

MuiCardContent.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default withStyles(styles)(MuiCardContent);
