import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { INTERNAL_DATE_FORMAT } from '../../../services/form/validation';

const MuiDatePicker = ({
    placeholder,
    disableFuture,
    disablePast,
    openTo,
    format,
    variant,
    views,
    input: {
        value, name, onBlur, ...inputProps
    },
}) => {
    const onChange = date => (Date.parse(date) ? inputProps.onChange(date.format(INTERNAL_DATE_FORMAT)) : inputProps.onChange(null));
    return (
        <Fragment>
            <KeyboardDatePicker
                {...inputProps}
                placeholder={placeholder}
                name={name}
                inputProps={{
                    id: name,
                }}
                value={value ? moment(value, INTERNAL_DATE_FORMAT) : null}
                disableFuture={disableFuture}
                disablePast={disablePast}
                inputVariant="outlined"
                openTo={openTo}
                format={format}
                variant={variant}
                views={views}
                onChange={onChange}
                onBlur={() => onBlur(value)}
                minDateMessage=""
                maxDateMessage=""
            />
        </Fragment>
    );
};

MuiDatePicker.defaultProps = {
    placeholder: 'dd/mm/yyyy',
    input: null,
    disableFuture: false,
    disablePast: false,
    openTo: 'date',
    format: INTERNAL_DATE_FORMAT,
    views: ['date', 'month', 'year'],
    variant: 'dialog',
};

MuiDatePicker.propTypes = {
    input: fieldPropTypes.input,
    placeholder: PropTypes.string,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    openTo: PropTypes.oneOf(['year', 'month', 'date']),
    format: PropTypes.string,
    variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
    views: PropTypes.arrayOf(PropTypes.string),
};

export default MuiDatePicker;
