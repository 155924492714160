import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '../Container/Container';
import PageTitle from '../PageTitle/PageTitle';

const PageHeader = ({
    classes,
    title,
    subTitle,
    image,
}) => (
    <Container gutterTop className={classes.container}>
        <Grid
            container
            spacing={4}
        >
            <Grid item xs={12} sm={12} md={5} lg={6}>
                <PageTitle text={title} lg />
                {subTitle && (
                    <Typography variant="body1" gutterBottom>
                        {subTitle}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={6}>
                <div className={classes.imageContainer}>
                    <img
                        src={image}
                        className={classes.image}
                        alt={title}
                    />
                </div>
            </Grid>
        </Grid>
    </Container>
);

const styles = theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
        },
    },
    imageContainer: ({ small }) => ({
        height: small ? 'auto' : 200,
        width: 463,
        float: 'right',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            height: small ? 'auto' : 230,
        },
        [theme.breakpoints.down('sm')]: {
            float: 'none',
            width: '100%',
            height: 'auto',
        },
    }),
    image: ({ small, imageMargin }) => ({
        marginBottom: imageMargin || -82,
        marginTop: small ? -30 : 0,
        width: small ? 'auto' : 463,
        height: small ? 165 : 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'calc(50% - 231px)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }),
});

PageHeader.defaultProps = {
    subTitle: null,
};

PageHeader.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    image: PropTypes.string.isRequired,
};

export default withStyles(styles)(PageHeader);
