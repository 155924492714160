export const HEALTHY_BITES_ARTICLES_REQUESTED = 'HEALTHY_BITES_ARTICLES_REQUESTED';
export const HEALTHY_BITES_ARTICLES_RESOLVED = 'HEALTHY_BITES_ARTICLES_RESOLVED';
export const HEALTHY_BITES_ARTICLES_ERROR = 'HEALTHY_BITES_ARTICLES_ERROR';

export const HEALTHY_BITES_ARTICLES_NEXT_REQUESTED = 'HEALTHY_BITES_ARTICLES_NEXT_REQUESTED';
export const HEALTHY_BITES_ARTICLES_NEXT_RESOLVED = 'HEALTHY_BITES_ARTICLES_NEXT_RESOLVED';
export const HEALTHY_BITES_ARTICLES_NEXT_ERROR = 'HEALTHY_BITES_ARTICLES_NEXT_ERROR';

export const HEALTHY_BITES_ARTICLES_FEATURED_REQUESTED = 'HEALTHY_BITES_ARTICLES_FEATURED_REQUESTED';
export const HEALTHY_BITES_ARTICLES_FEATURED_RESOLVED = 'HEALTHY_BITES_ARTICLES_FEATURED_RESOLVED';
export const HEALTHY_BITES_ARTICLES_FEATURED_ERROR = 'HEALTHY_BITES_ARTICLES_FEATURED_ERROR';

export const HEALTHY_BITES_ARTICLES_RELATED_REQUESTED = 'HEALTHY_BITES_ARTICLES_RELATED_REQUESTED';
export const HEALTHY_BITES_ARTICLES_RELATED_RESOLVED = 'HEALTHY_BITES_ARTICLES_RELATED_RESOLVED';
export const HEALTHY_BITES_ARTICLES_RELATED_ERROR = 'HEALTHY_BITES_ARTICLES_RELATED_ERROR';

export const HEALTHY_BITES_ARTICLE_REQUESTED = 'HEALTHY_BITES_ARTICLE_REQUESTED';
export const HEALTHY_BITES_ARTICLE_RESOLVED = 'HEALTHY_BITES_ARTICLE_RESOLVED';
export const HEALTHY_BITES_ARTICLE_ERROR = 'HEALTHY_BITES_ARTICLE_ERROR';

export const HEALTHY_BITES_CATEGORIES_REQUESTED = 'HEALTHY_BITES_CATEGORIES_REQUESTED';
export const HEALTHY_BITES_CATEGORIES_RESOLVED = 'HEALTHY_BITES_CATEGORIES_RESOLVED';
export const HEALTHY_BITES_CATEGORIES_ERROR = 'HEALTHY_BITES_CATEGORIES_ERROR';

export const HEALTHY_BITES_FAVOURITE_REQUESTED = 'HEALTHY_BITES_FAVOURITE_REQUESTED';
export const HEALTHY_BITES_FAVOURITE_RESOLVED = 'HEALTHY_BITES_FAVOURITE_RESOLVED';
export const HEALTHY_BITES_FAVOURITE_ERROR = 'HEALTHY_BITES_FAVOURITE_ERROR';

export const HEALTHY_BITES_UNFAVOURITE_REQUESTED = 'HEALTHY_BITES_UNFAVOURITE_REQUESTED';
export const HEALTHY_BITES_UNFAVOURITE_RESOLVED = 'HEALTHY_BITES_UNFAVOURITE_RESOLVED';
export const HEALTHY_BITES_UNFAVOURITE_ERROR = 'HEALTHY_BITES_UNFAVOURITE_ERROR';

export const HEALTHY_BITES_FAVOURITES_REQUESTED = 'HEALTHY_BITES_FAVOURITES_REQUESTED';
export const HEALTHY_BITES_FAVOURITES_RESOLVED = 'HEALTHY_BITES_FAVOURITES_RESOLVED';
export const HEALTHY_BITES_FAVOURITES_ERROR = 'HEALTHY_BITES_FAVOURITES_ERROR';

export const HEALTHY_BITES_SET_FAVOURITE_FILTER_RESOLVED = 'HEALTHY_BITES_SET_FAVOURITE_FILTER_RESOLVED';

export const HEALTHY_BITES_SPOTLIGHT_REQUESTED = 'HEALTHY_BITES_SPOTLIGHT_REQUESTED';
export const HEALTHY_BITES_SPOTLIGHT_RESOLVED = 'HEALTHY_BITES_SPOTLIGHT_RESOLVED';
export const HEALTHY_BITES_SPOTLIGHT_ERROR = 'HEALTHY_BITES_SPOTLIGHT_ERROR';

export const HEALTHY_BITES_AUTHOR_ARTICLES_REQUESTED = 'HEALTHY_BITES_AUTHOR_ARTICLES_REQUESTED';
export const HEALTHY_BITES_AUTHOR_ARTICLES_RESOLVED = 'HEALTHY_BITES_AUTHOR_ARTICLES_RESOLVED';
export const HEALTHY_BITES_AUTHOR_ARTICLES_ERROR = 'HEALTHY_BITES_AUTHOR_ARTICLES_ERROR';

export const HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_REQUESTED = 'HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_REQUESTED';
export const HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_RESOLVED = 'HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_RESOLVED';
export const HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_ERROR = 'HEALTHY_BITES_AUTHOR_ARTICLES_NEXT_ERROR';
