import _ from 'lodash';
import store from '../../services/store';
import {
    STATUS_RESOLVED,
    STATUS_UNCHANGED,
} from './types';

/**
 * onStatusCheck - updates the status
 * @return {Object}
 */
const onStatusCheck = (payload) => {
    const { status } = store.getState();
    // Check if status is equal
    const isStatusEqual = (x, y) => _(x).differenceWith(y, _.isEqual).isEmpty();
    // If the status hasn't changed then don't return the action
    if (isStatusEqual(payload, status.get('components').map(component => _.omit(component, '__typename')))) {
        return {
            type: STATUS_UNCHANGED,
        };
    }

    return {
        type: STATUS_RESOLVED,
        payload,
    };
};

export default onStatusCheck;
