import * as Immutable from 'immutable';
import {
    GRAPH_HINT_SHOW,
    GRAPH_HINT_HIDE,
} from '../actions/graph/types';

const schema = {
    showHint: false,
};

const initialState = Immutable.Map(schema);
/**
 * The member company reducer - responsible for managing a member company page data
 * @param {Object} state
 * @param {Object} action
 */
const graphReducer = (state = initialState, action) => {
    switch (action.type) {
    case GRAPH_HINT_SHOW:
        return state.set('showHint', true);

    case GRAPH_HINT_HIDE:
        return state.set('showHint', false);

    default:
        return state;
    }
};

export { initialState };

export default graphReducer;
