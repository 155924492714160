import * as Immutable from 'immutable';
import {
    MEMBER_COMPANY_ERROR,
    MEMBER_COMPANY_REQUESTED,
    MEMBER_COMPANY_RESOLVED,
} from '../actions/member/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    company: {},
};

const initialState = Immutable.Map(schema);
/**
 * The member company reducer - responsible for managing a member company page data
 * @param {Object} state
 * @param {Object} action
 */
const memberCompanyReducer = (state = initialState, action) => {
    switch (action.type) {
    case MEMBER_COMPANY_REQUESTED:
        return state.set('resolving', true);

    case MEMBER_COMPANY_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('company', action.payload);

    case MEMBER_COMPANY_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    default:
        return state;
    }
};

export { initialState };

export default memberCompanyReducer;
