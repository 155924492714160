import * as Immutable from 'immutable';
import {
    STATUS_REQUESTED,
    STATUS_RESOLVED,
    // STATUS_ERROR,
} from '../actions/status/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    components: [],
};

const initialState = Immutable.Map(schema);
/**
 * The status reducer - responsible for managing the status of the api and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const statusReducer = (state = initialState, action) => {
    switch (action.type) {
    case STATUS_REQUESTED:
        return state.set('resolving', true);

    case STATUS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('components', action.payload);

        // case STATUS_ERROR:
        //     return state
        //         .set('resolving', false)
        //         .set('status', 'ERROR')
        //         .set('components', []);

    default:
        return state;
    }
};

export { initialState };

export default statusReducer;
