export const COMPANY_CONTACTS_REQUESTED = 'COMPANY_CONTACTS_REQUESTED';
export const COMPANY_CONTACTS_RESOLVED = 'COMPANY_CONTACTS_RESOLVED';
export const COMPANY_CONTACTS_ERROR = 'COMPANY_CONTACTS_ERROR';

export const CREATE_COMPANY_CONTACT_REQUESTED = 'CREATE_COMPANY_CONTACT_REQUESTED';
export const CREATE_COMPANY_CONTACT_RESOLVED = 'CREATE_COMPANY_CONTACT_RESOLVED';
export const CREATE_COMPANY_CONTACT_ERROR = 'CREATE_COMPANY_CONTACT_ERROR';

export const DELETE_COMPANY_CONTACT_REQUESTED = 'DELETE_COMPANY_CONTACT_REQUESTED';
export const DELETE_COMPANY_CONTACT_RESOLVED = 'DELETE_COMPANY_CONTACT_RESOLVED';
export const DELETE_COMPANY_CONTACT_ERROR = 'DELETE_COMPANY_CONTACT_ERROR';

export const GET_COMPANY_CONTACT_REQUESTED = 'GET_COMPANY_CONTACT_REQUESTED';
export const GET_COMPANY_CONTACT_RESOLVED = 'GET_COMPANY_CONTACT_RESOLVED';
export const GET_COMPANY_CONTACT_ERROR = 'GET_COMPANY_CONTACT_ERROR';

export const UPDATE_COMPANY_CONTACT_REQUESTED = 'UPDATE_COMPANY_CONTACT_REQUESTED';
export const UPDATE_COMPANY_CONTACT_RESOLVED = 'UPDATE_COMPANY_CONTACT_RESOLVED';
export const UPDATE_COMPANY_CONTACT_ERROR = 'UPDATE_COMPANY_CONTACT_ERROR';

export const ACTIVATE_COMPANY_CONTACT_REQUESTED = 'ACTIVATE_COMPANY_CONTACT_REQUESTED';
export const ACTIVATE_COMPANY_CONTACT_RESOLVED = 'ACTIVATE_COMPANY_CONTACT_RESOLVED';
export const ACTIVATE_COMPANY_CONTACT_ERROR = 'ACTIVATE_COMPANY_CONTACT_ERROR';

export const COMPANY_CONTACT_SEND_INVITE_REQUESTED = 'COMPANY_CONTACT_SEND_INVITE_REQUESTED';
export const COMPANY_CONTACT_SEND_INVITE_RESOLVED = 'COMPANY_CONTACT_SEND_INVITE_RESOLVED';
export const COMPANY_CONTACT_SEND_INVITE_ERROR = 'COMPANY_CONTACT_SEND_INVITE_ERROR';

export const UPDATE_COMPANY_CONTACT_VISIBILITY_REQUESTED = 'UPDATE_COMPANY_CONTACT_VISIBILITY_REQUESTED';
export const UPDATE_COMPANY_CONTACT_VISIBILITY_RESOLVED = 'UPDATE_COMPANY_CONTACT_VISIBILITY_RESOLVED';
export const UPDATE_COMPANY_CONTACT_VISIBILITY_ERROR = 'UPDATE_COMPANY_CONTACT_VISIBILITY_ERROR';

export const COMPANY_CONTACT_RESET = 'COMPANY_CONTACT_RESET';
