import * as Immutable from 'immutable';
import {
    MEMBER_EVENTS_ERROR,
    MEMBER_EVENTS_REQUESTED,
    MEMBER_EVENTS_RESOLVED,
    MEMBER_EVENT_ERROR,
    MEMBER_EVENT_REQUESTED,
    MEMBER_EVENT_RESOLVED,
    MEMBER_DASHBOARD_RESOLVED,
} from '../actions/member/types';
import {
    MEMBER_EVENTS_REGISTER_INTEREST_REQUESTED,
    MEMBER_EVENTS_REGISTER_INTEREST_RESOLVED,
    MEMBER_EVENTS_REGISTER_INTEREST_ERROR,
    MEMBER_EVENTS_REGISTER_ATTENDING_REQUESTED,
    MEMBER_EVENTS_REGISTER_ATTENDING_RESOLVED,
    MEMBER_EVENTS_REGISTER_ATTENDING_ERROR,
    MEMBER_EVENTS_UNREGISTER_INTEREST_REQUESTED,
    MEMBER_EVENTS_UNREGISTER_INTEREST_RESOLVED,
    MEMBER_EVENTS_UNREGISTER_INTEREST_ERROR,
    MEMBER_EVENTS_UNREGISTER_ATTENDING_REQUESTED,
    MEMBER_EVENTS_UNREGISTER_ATTENDING_RESOLVED,
    MEMBER_EVENTS_UNREGISTER_ATTENDING_ERROR,
    MEMBER_EVENTS_RESET,
} from '../actions/events/types';


const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    eventsPage: null,
    event: null,
    registerEventInterest: {
        status: 'INIT',
        resolving: false,
        eventIdsResolving: [], // ids of events resolving
    },
    registerEventAttending: {
        status: 'INIT',
        resolving: false,
        eventIdsResolving: [], // ids of events resolving
    },
};

const initialState = Immutable.Map(schema);
/**
 * The member events reducer - responsible for managing a member events page data
 * @param {Object} state
 * @param {Object} action
 */
const memberEventsReducer = (state = initialState, action) => {
    switch (action.type) {
    case MEMBER_EVENT_REQUESTED:
    case MEMBER_EVENTS_REQUESTED:
        return state.set('resolving', true);

    case MEMBER_EVENTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('eventsPage', action.payload);

    case MEMBER_EVENTS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case MEMBER_EVENTS_REGISTER_INTEREST_REQUESTED:
    case MEMBER_EVENTS_UNREGISTER_INTEREST_REQUESTED:
        return state
            .setIn(['registerEventInterest', 'resolving'], true)
            .updateIn(['registerEventInterest', 'eventIdsResolving'], (eventIdsResolving) => {
                if (eventIdsResolving.indexOf(action.payload.eventId) === -1) {
                    eventIdsResolving.push(action.payload.eventId);
                    return eventIdsResolving;
                }
                return eventIdsResolving;
            });

    case MEMBER_EVENTS_REGISTER_ATTENDING_REQUESTED:
    case MEMBER_EVENTS_UNREGISTER_ATTENDING_REQUESTED:
        return state
            .setIn(['registerEventAttending', 'resolving'], true)
            .updateIn(['registerEventAttending', 'eventIdsResolving'], (eventIdsResolving) => {
                if (eventIdsResolving.indexOf(action.payload.eventId) === -1) {
                    eventIdsResolving.push(action.payload.eventId);
                    return eventIdsResolving;
                }
                return eventIdsResolving;
            });

    case MEMBER_EVENTS_REGISTER_INTEREST_RESOLVED:
    case MEMBER_EVENTS_UNREGISTER_INTEREST_RESOLVED:
        return state
            .setIn(['registerEventInterest', 'resolving'], false)
            .setIn(['registerEventInterest', 'status'], 'OK')
            .updateIn(['eventsPage', 'events'], events => events.map((event) => {
                if (event.eventId === action.payload.eventId) {
                    return action.payload;
                }
                return event;
            }))
            .updateIn(['registerEventInterest', 'eventIdsResolving'], (eventIdsResolving) => {
                const index = eventIdsResolving.indexOf(action.payload.eventId);
                if (index !== -1) {
                    eventIdsResolving.splice(index, 1);
                    return eventIdsResolving;
                }
                return eventIdsResolving;
            });

    case MEMBER_EVENTS_REGISTER_ATTENDING_RESOLVED:
    case MEMBER_EVENTS_UNREGISTER_ATTENDING_RESOLVED:
        return state
            .setIn(['registerEventAttending', 'resolving'], false)
            .setIn(['registerEventAttending', 'status'], 'OK')
            .updateIn(['eventsPage', 'events'], events => events.map((event) => {
                if (event.eventId === action.payload.eventId) {
                    return action.payload;
                }
                return event;
            }))
            .updateIn(['registerEventAttending', 'eventIdsResolving'], (eventIdsResolving) => {
                const index = eventIdsResolving.indexOf(action.payload.eventId);
                if (index !== -1) {
                    eventIdsResolving.splice(index, 1);
                    return eventIdsResolving;
                }
                return eventIdsResolving;
            });

    case MEMBER_EVENTS_REGISTER_INTEREST_ERROR:
    case MEMBER_EVENTS_UNREGISTER_INTEREST_ERROR:
        return state
            .setIn(['registerEventInterest', 'resolving'], false)
            .setIn(['registerEventInterest', 'status'], 'ERROR')
            .updateIn(['registerEventInterest', 'eventIdsResolving'], (eventIdsResolving) => {
                const index = eventIdsResolving.indexOf(action.payload.eventId);
                if (index !== -1) {
                    eventIdsResolving.splice(index, 1);
                    return eventIdsResolving;
                }
                return eventIdsResolving;
            });

    case MEMBER_EVENTS_REGISTER_ATTENDING_ERROR:
    case MEMBER_EVENTS_UNREGISTER_ATTENDING_ERROR:
        return state
            .setIn(['registerEventAttending', 'resolving'], false)
            .setIn(['registerEventAttending', 'status'], 'ERROR')
            .updateIn(['registerEventAttending', 'eventIdsResolving'], (eventIdsResolving) => {
                const index = eventIdsResolving.indexOf(action.payload.eventId);
                if (index !== -1) {
                    eventIdsResolving.splice(index, 1);
                    return eventIdsResolving;
                }
                return eventIdsResolving;
            });

    case MEMBER_DASHBOARD_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('eventsPage', { events: action.payload.events });

    case MEMBER_EVENT_RESOLVED:
        return state
            .set('resolving', false)
            .set('event', action.payload);

    case MEMBER_EVENT_ERROR:
        return state
            .set('resolving', false)
            .set('event', null);

    case MEMBER_EVENTS_RESET:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('eventsPage', null);

    default:
        return state;
    }
};

export { initialState };

export default memberEventsReducer;
