import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const MuiRoundedButton = (props) => {
    const { selected } = props;
    return (
        <Button {...props} variant={(selected ? 'contained' : 'outlined')} />
    );
};

const styles = theme => ({
    root: {
        borderRadius: 20,
        minHeight: 45,
    },
    sizeSmall: {
        minHeight: 34,
    },
    sizeLarge: {
        paddingTop: 11,
        paddingBottom: 11,
    },
    contained: {
        backgroundColor: theme.palette.common.blue,
        color: theme.palette.common.white,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: theme.palette.common.blue,
        '&:hover': {
            backgroundColor: theme.palette.common.blueDark,
        },
    },
    outlined: {
        '&:hover': {
            borderColor: theme.palette.common.blue,
        },
    },
});

MuiRoundedButton.defaultProps = {
    selected: false,
};

MuiRoundedButton.propTypes = {
    selected: PropTypes.bool,
};

export default withStyles(styles)(MuiRoundedButton);
