import * as Immutable from 'immutable';
import {
    EVENTS_REQUESTED,
    EVENTS_RESOLVED,
    EVENTS_ERROR,
    EVENT_REQUESTED,
    EVENT_RESOLVED,
    EVENT_ERROR,
    EVENT_CREATE_REQUESTED,
    EVENT_CREATE_RESOLVED,
    EVENT_CREATE_ERROR,
    EVENT_UPDATE_REQUESTED,
    EVENT_UPDATE_RESOLVED,
    EVENT_UPDATE_ERROR,
    EVENT_DELETE_REQUESTED,
    EVENT_DELETE_RESOLVED,
    EVENT_DELETE_ERROR,
    EVENT_RESET,
    EVENT_REGISTRATIONS_REQUESTED,
    EVENT_REGISTRATIONS_RESOLVED,
    EVENT_REGISTRATIONS_ERROR,
} from '../actions/events/types';

import {
    COMPANY_MEMBERS_COUNT_RESOLVED,
} from '../actions/company-members/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    eventsPage: null,
    event: null,
    totalEmployees: 0,
    eventMembersPage: null,
    eventMembersResolving: false,
    eventMembersStatus: 'INIT',
};

const initialState = Immutable.Map(schema);

/**
 * The events reducer - responsible for managing a events and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
    case EVENTS_REQUESTED:
    case EVENT_REQUESTED:
    case EVENT_CREATE_REQUESTED:
    case EVENT_UPDATE_REQUESTED:
    case EVENT_DELETE_REQUESTED:
        return state
            .set('status', 'OK')
            .set('resolving', true);

    case EVENT_CREATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK');

    case EVENTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('event', null)
            .set('eventsPage', action.payload);

    case EVENT_RESOLVED:
    case EVENT_UPDATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('event', action.payload);

    case EVENT_DELETE_RESOLVED:
        return state
            .set('resolving', false)
            .set('event', null)
            .set('status', 'OK');

    case EVENTS_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('event', null)
            .set('eventsPage', null);

    case EVENT_CREATE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case EVENT_UPDATE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case EVENT_DELETE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case EVENT_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('event', null);
    case COMPANY_MEMBERS_COUNT_RESOLVED:
        return state
            .set('totalEmployees', action.payload);

    case EVENT_REGISTRATIONS_REQUESTED:
        return state
            .set('eventMembersPage', null)
            .set('eventMembersResolving', true);

    case EVENT_REGISTRATIONS_RESOLVED:
        return state
            .set('eventMembersStatus', 'OK')
            .set('eventMembersPage', action.payload)
            .set('eventMembersResolving', false);

    case EVENT_REGISTRATIONS_ERROR:
        return state
            .set('eventMembersStatus', 'ERROR')
            .set('eventMembersPage', null)
            .set('eventMembersResolving', false);

    case EVENT_RESET:
        return state
            .set('event', null);

    default:
        return state;
    }
};

export { initialState };

export default eventsReducer;
