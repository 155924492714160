import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MuiCard from '../material-ui/MuiCard/MuiCard';
import MuiCardContent from '../material-ui/MuiCardContent/MuiCardContent';

const ServiceProviderCard = ({
    classes, logo, title, description, variant,
}) => (
    <MuiCard variant={variant}>
        <MuiCardContent className={classes.content}>
            <Grid
                container
                spacing={4}
                alignItems="center"
            >
                <Grid item xs={12} sm={5} md={4} lg={3} align="center">
                    <img
                        className={classes.logo}
                        src={logo}
                        alt={title}
                    />
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={9} align="left">
                    <Typography variant="h5" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="body2">
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </MuiCardContent>
    </MuiCard>
);

const styles = theme => ({
    content: {
        marginBottom: 0,
    },
    logo: {
        maxWidth: 150,
        maxHeight: 100,
        // maxHeight: 45,
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
});

ServiceProviderCard.defaultProps = {
    variant: 'gradientGrey',
};

ServiceProviderCard.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    logo: PropTypes.node.isRequired,
    variant: PropTypes.string,
};

export default withStyles(styles)(ServiceProviderCard);
