import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import RadialChart from '../RadialChart/RadialChart';
import RadialDial from '../RadialDial/RadialDial';
import { getThemeResult, getThemeTarget, isRadialChartType } from '../../../services/tracker/tracker';

const ThemeResult = ({
    classes,
    inViewport,
    forwardedRef,
    enterCount,
    description,
    theme,
    theme: {
        latestResultDate,
        higherIsBetter,
    },
}) => {
    const reRender = ((enterCount === 1 && inViewport) || enterCount > 0);

    if (!isRadialChartType(theme)) {
        return (
            <Box className={classes.chartContainer} ref={forwardedRef}>
                <RadialDial
                    description={description || `Last updated: ${moment(latestResultDate).format('DD/MM/YY')}`}
                    {...getThemeResult(theme, reRender)}
                    rangeReverse={!higherIsBetter}
                />
            </Box>
        );
    }

    return (
        <Box className={classes.chartContainer} ref={forwardedRef}>
            <Box className={classes.chart}><RadialChart {...getThemeResult(theme, reRender)} /></Box>
            <Box className={classes.chart}><RadialChart {...getThemeTarget(theme, reRender)} grey /></Box>
        </Box>
    );
};

const styles = () => ({
    chartContainer: ({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: isRadialChartType(theme) ? -100 : -132,
    }),
    chart: {
        marginLeft: -7,
        marginRight: -7,
    },
});

ThemeResult.defaultProps = {
    description: null,
};

ThemeResult.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    inViewport: PropTypes.bool.isRequired,
    forwardedRef: PropTypes.shape({
        current: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
    enterCount: PropTypes.number.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    theme: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        change: PropTypes.string,
        companyLatest: PropTypes.string,
        companyLatestLabel: PropTypes.string,
        companyLatestDate: PropTypes.string,
        companyPrevious: PropTypes.string,
        companyPreviousLabel: PropTypes.string,
        companyPreviousDate: PropTypes.string,
        latestResult: PropTypes.string,
        latestResultLabel: PropTypes.string,
        latestResultDate: PropTypes.string,
        name: PropTypes.string,
        previousResult: PropTypes.string,
        previousResultLabel: PropTypes.string,
        previousResultDate: PropTypes.string,
        recommendedMin: PropTypes.string,
        recommendedMax: PropTypes.string,
        recommendedLabel: PropTypes.string,
        relevantContentId: PropTypes.number,
        relevantServiceId: PropTypes.string,
        higherIsBetter: PropTypes.bool,
    }).isRequired,
};

export default withStyles(styles)(ThemeResult);
