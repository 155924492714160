import _ from 'lodash';
import MoneyQuestionIcon from '../../assets/images/icons/money-question-icon.svg'; // temp
import ConeIcon from '../../assets/images/icons/cone-icon.svg'; // temp
import TarginIcon from '../../assets/images/icons/target-icon.svg'; // temp
import WaterIcon from '../../assets/images/icons/water-icon.svg';
import TrainerIcon from '../../assets/images/icons/trainer-icon.svg';
import CoffeeIcon from '../../assets/images/icons/coffee-icon.svg';
import ScalesIcon from '../../assets/images/icons/scales-icon.svg';
import SleepingMaskIcon from '../../assets/images/icons/sleeping-mask-icon.svg';
import PlateIcon from '../../assets/images/icons/plate-icon.svg';
import ShieldIcon from '../../assets/images/icons/shield-icon.svg';
import AlcoholIcon from '../../assets/images/icons/alcohol-icon.svg';
import WeightIcon from '../../assets/images/icons/weight-icon.svg';

import getContent from '../content/getContent';
/**
 * We need to prefix the question name as redux-form has issues with
 * numbers as input names
 * @type {String}
 */
const QUESTION_PREFIX = 'question_';

const pageImages = {
    HealthQuiz: [ScalesIcon],
};

const summaryImages = {
    UNEXPECTED: ConeIcon,
    LONGTERM: TarginIcon,
    DAYTODAY: MoneyQuestionIcon,
    BMI: ScalesIcon,
    EXERCISE: TrainerIcon,
    WATER: WaterIcon,
    CAFFEINE: CoffeeIcon,
    FRUIT: PlateIcon,
    SLEEP: SleepingMaskIcon,
    RESILIENCE: ShieldIcon,
    ALCOHOL: AlcoholIcon,
    STRENGTH: WeightIcon,
    OLDEXERCISE: TrainerIcon,
};

const questionnaire = {};
/**
 * Group questions by short title
 *
 * @param  {Array}
 * @return {Array}
 */
questionnaire.groupSteps = (questions, questionnaireCode) => _.uniqBy(
    questions.map((question) => {
        const { label } = question.category;
        return {
            label,
            displayLabel: getContent(['components', 'questionnaires', questionnaireCode, label, 'title']) || label,
            active: false,
        };
    }),
    question => question.label,
);
/**
 * Group the questions by sub categories
 * @param  {Array} questions [description]
 * @return {Array}           [description]
 */
questionnaire.groupQuestionsBySubCategory = (questions) => {
    const subCategories = [];
    questions.forEach((question) => {
        if (question.subCategory) {
            const index = subCategories.findIndex(subCategory => subCategory && subCategory.id === question.subCategory.id);
            if (index === -1) {
                subCategories.push({ ...question.subCategory, questions: [question] });
            } else {
                subCategories[index].questions.push(question);
            }
        }
    });
    return subCategories;
};
/**
 * Group the questions by shortTitle
 *
 * @param  {Array}
 * @return {Object}
 */
questionnaire.groupQuestions = questions => _.groupBy(questions, question => question.category.label);
/**
 * Find the step index by label
 *
 * @param  {Array}
 * @param  {String}
 * @return {Number}
 */
questionnaire.getStepIndex = (steps, label) => steps.findIndex(step => step.label === label);
/**
 * Get the first step in the questionnaire
 *
 * @param  {Array} steps
 * @return {String}
 */
questionnaire.getFirstStep = steps => (steps[0] && steps[0].label ? steps[0].label : null);
/**
 * Get the label of the next step
 * @param  {Array}
 * @param  {String}
 * @return {String}
 */
questionnaire.getNextStep = (steps, currentStepLabel) => {
    const index = questionnaire.getStepIndex(steps, currentStepLabel);
    return (steps[index + 1] && steps[index + 1].label ? steps[index + 1].label : null);
};
/**
 * Get the label of the prev step
 * @param  {Array}
 * @param  {String}
 * @return {String}
 */
questionnaire.getPrevStep = (steps, currentStepLabel) => {
    const index = questionnaire.getStepIndex(steps, currentStepLabel);
    return (steps[index - 1] && steps[index - 1].label ? steps[index - 1].label : null);
};
/**
 * Is this the last step?
 *
 * @param  {Array} steps
 * @param  {String} currentStepLabel
 * @return {Bool}
 */
questionnaire.isLastStep = (steps, currentStepLabel) => {
    const index = questionnaire.getStepIndex(steps, currentStepLabel);
    return (index + 1) === steps.length;
};
/**
 * Take the array of questions and return an object
 * of answer values
 *
 * @param  {Array}
 * @return {Object}
 */
questionnaire.answersToMap = questions => questions.reduce((question, obj) => {
    const updatedQuestion = question;
    updatedQuestion[obj.id] = obj.value;
    return updatedQuestion;
}, {});
/**
 * Take the array of questions and return an object
 * of answer values with prefix
 *
 * @param  {Array}
 * @return {Object}
 */
questionnaire.answersToMapWithPrefix = questions => questions.reduce((question, obj) => {
    const updatedQuestion = question;
    updatedQuestion[`${QUESTION_PREFIX}${obj.id}`] = obj.value;
    return updatedQuestion;
}, {});
/**
 * Take an answer object and return it as an array
 *
 * @param  {Object}
 * @return {Array}
 */
questionnaire.answersToArray = answers => _.map(answers, (value, id) => ({ id, value }));
/**
 * Prefix the question name
 *
 * @param  {String} name
 * @return {String}
 */
questionnaire.preFixQuestionName = name => `${QUESTION_PREFIX}${name}`;
/**
 * Remove Prefix the question name
 *
 * @param  {String} name
 * @return {String}
 */
questionnaire.removePreFixQuestionName = name => name.replace(QUESTION_PREFIX, '');
/**
 * Get the validation rules for a question from an array of questions
 * by id
 *
 * @param  {Array} questions
 * @param  {String} id
 * @return {Array}
 */
questionnaire.getValidation = (questions, id) => {
    const question = _.find(questions, { id: questionnaire.removePreFixQuestionName(id) });
    if (question && question.validation) {
        return question.validation;
    }
    return [];
};
/**
 * Run the rule validation
 *
 * @param  {Mixed} value
 * @param  {Array} allValues
 * @param  {Object} props
 * @param  {String} name
 * @return {String}
 */
questionnaire.ruleValidation = (value, allValues, props, name) => {
    // Get the validation rules for the question
    const validation = questionnaire.getValidation(props.questions, name);
    // Get the first message of any failed validation rules - if no value match against empty string
    return validation.filter(rule => (value ? !value.match(rule.regex) : !''.match(rule.regex))).map(rule => rule.label).pop();
};
/**
 * Return the first validation label
 *
 * @param  {Array} question
 * @return {String}
 */
questionnaire.getValidationLabel = question => _.get(question, 'validation[0].label');
/**
 * Get page images
 * @param  {String} questionnaireId
 * @return {String}
 */
questionnaire.getPageImages = questionnaireId => pageImages[questionnaireId] || [];
/**
 * Get the icon for summary item
 * @param  {string} iconId
 * @return {string}
 */
questionnaire.getResultSummaryIcon = iconId => summaryImages[iconId] || null;

export default questionnaire;
