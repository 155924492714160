export const COMPANY_MEMBER_REQUESTED = 'COMPANY_MEMBER_REQUESTED';
export const COMPANY_MEMBER_RESOLVED = 'COMPANY_MEMBER_RESOLVED';
export const COMPANY_MEMBER_ERROR = 'COMPANY_MEMBER_ERROR';

export const COMPANY_MEMBER_CREATE_REQUESTED = 'COMPANY_MEMBER_CREATE_REQUESTED';
export const COMPANY_MEMBER_CREATE_RESOLVED = 'COMPANY_MEMBER_CREATE_RESOLVED';
export const COMPANY_MEMBER_CREATE_ERROR = 'COMPANY_MEMBER_CREATE_ERROR';
export const COMPANY_MEMBER_CREATE_RESET = 'COMPANY_MEMBER_CREATE_RESET';

export const COMPANY_MEMBER_UPDATE_REQUESTED = 'COMPANY_MEMBER_UPDATE_REQUESTED';
export const COMPANY_MEMBER_UPDATE_RESOLVED = 'COMPANY_MEMBER_UPDATE_RESOLVED';
export const COMPANY_MEMBER_UPDATE_ERROR = 'COMPANY_MEMBER_UPDATE_ERROR';

export const COMPANY_MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED = 'COMPANY_MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED';
export const COMPANY_MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED = 'COMPANY_MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED';
export const COMPANY_MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR = 'COMPANY_MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR';

export const COMPANY_MEMBER_UNREGISTERED_REQUESTED = 'COMPANY_MEMBER_UNREGISTERED_REQUESTED';
export const COMPANY_MEMBER_UNREGISTERED_RESOLVED = 'COMPANY_MEMBER_UNREGISTERED_RESOLVED';
export const COMPANY_MEMBER_UNREGISTERED_ERROR = 'COMPANY_MEMBER_UNREGISTERED_ERROR';

export const COMPANY_MEMBER_RESET = 'COMPANY_MEMBER_RESET';
