import { connectWithLifecycle } from 'react-lifecycle-component';
import getStatusAction from '../../actions/status/getStatus';
import onStatusCheckSubscription from '../../services/graphQL/status/onStatusCheckSubscription';
import MaintenancePoller from './MaintenancePoller';

const mapDispatchToProps = dispatch => ({
    getStatus: async () => dispatch(await getStatusAction()),
    componentDidMount: async () => {
        onStatusCheckSubscription();
    },
});

const mapStateToProps = state => ({
    resolving: state.status.get('resolving'),
});

export default connectWithLifecycle(mapStateToProps, mapDispatchToProps)(MaintenancePoller);
