import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const MuiLabelSwitch = ({
    classes, checked, className, disabled, labelOne, labelTwo, onChange, name,
}) => (
    <Box
        role="button"
        tabIndex={0}
        className={`${classes.switch} ${className} ${(checked ? classes.switchOn : '')} ${(disabled ? classes.switchDisabled : '')}`}
        onClick={e => (!disabled ? onChange(e) : null)}
        onKeyDown={e => (!disabled && e.key === 'Enter' ? onChange(e) : null)}
    >
        <Box
            height="120"
            width="25"
            className={`${classes.switchToggle} ${(checked ? classes.switchToggleOn : '')} ${(disabled ? classes.switchToggleDisabled : '')}`}
        />
        <Typography
            variant="body2"
            align="left"
            className={`${classes.switchLabelOne} ${(!checked && classes.switchLabelOn)}`}
        >
            {labelOne}
        </Typography>
        <Typography
            variant="body2"
            align="right"
            className={`${classes.switchLabelTwo} ${(checked && classes.switchLabelOn)}`}
        >
            {labelTwo}
        </Typography>
        <input
            type="checkbox"
            name={name}
            id={name}
            className={classes.switchCheckbox}
            defaultChecked={!!checked}
            disabled={disabled}
        />
    </Box>
);

const styles = theme => ({
    switch: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
        borderRadius: 20,
        border: `1px solid ${theme.palette.common.blue}`,
        height: 33,
        width: 200,
        minWidth: 200,
        position: 'relative',
        cursor: 'pointer',
        transition: theme.transitions.create('background', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
        margin: theme.spacing(1.5),
        '&:focus': {
            outline: 'none',
        },
    },
    switchOn: {
        backgroundColor: theme.palette.common.white,
    },
    switchDisabled: {
        border: `solid 1px ${theme.palette.common.grey}`,
        cursor: 'default',
    },
    switchToggle: {
        position: 'absolute',
        width: 'calc(50% - 2px)',
        height: 'calc(100% - 4px)',
        top: 2,
        left: 2,
        borderRadius: 20,
        backgroundColor: theme.palette.common.blue,
        transition: theme.transitions.create('left', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
        filter: 'drop-shadow(0 0 1px rgba(0, 0, 0, .1))',
        zIndex: 1,
    },
    switchToggleOn: {
        left: '50%',
    },
    switchToggleDisabled: {
        backgroundColor: theme.palette.common.grey,
    },
    switchLabelOne: {
        position: 'relative',
        zIndex: 2,
        fontWeight: 'bold',
        paddingTop: 6,
        color: theme.palette.common.grey,
        marginLeft: 15,
    },
    switchLabelTwo: {
        position: 'relative',
        zIndex: 2,
        fontWeight: 'bold',
        paddingTop: 6,
        color: theme.palette.common.grey,
        marginRight: 15,
    },
    switchLabelOn: {
        color: theme.palette.common.white,
    },
    switchCheckbox: {
        top: 0,
        left: 0,
        width: '100%',
        cursor: 'inherit',
        height: '100%',
        margin: 0,
        opacity: 0,
        padding: 0,
        position: 'absolute',
    },
});

MuiLabelSwitch.defaultProps = {
    checked: false,
    className: '',
    disabled: false,
};

MuiLabelSwitch.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    checked: PropTypes.bool,
    labelOne: PropTypes.string.isRequired,
    labelTwo: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default withStyles(styles)(MuiLabelSwitch);
