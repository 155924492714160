import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MuiPrimaryButton from '../material-ui/MuiPrimaryButton/MuiPrimaryButton';
import { URLS } from '../../config/config';
import calendarIconSVG from '../../assets/images/icons/calendar-month-empty-icon.svg';

const RetakeAssessmentReminder = ({ classes }) => (
    <Grid
        container
        spacing={4}
        justifyContent="center"
        className={classes.root}
    >
        <Grid item xs={12} sm={4} md={2}>
            <img src={calendarIconSVG} alt="Remember to retake your assessment" />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
            <Typography variant="h3" gutterBottom className={classes.header}>
                We’ll remind you when your next assessment is due. Re-taking your assessments every 3 months will enable you to monitor progress over time.
            </Typography>
            <Typography variant="body1" gutterBottom>View all of your assessment results.</Typography>
            <MuiPrimaryButton
                size="large"
                component={RouterLink}
                to={URLS.MEMBER.DASHBOARD}
                className={classes.button}
            >
                View Dashboard
            </MuiPrimaryButton>
        </Grid>
    </Grid>
);

const styles = theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    header: {
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
    },
    button: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
});

RetakeAssessmentReminder.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(RetakeAssessmentReminder);
