import variables from '../variables';

const MuiButton = {
    root: {
        boxShadow: variables.button.boxShadow,
        textTransform: 'none',
    },
    outlinedPrimary: {
        borderWidth: variables.button.borderWidth,
        borderColor: variables.themeColors.primary,
        fontSize: 16,
        fontWeight: 'bold',
        '&$disabled': {
            borderWidth: variables.button.borderWidth,
        },
        '&:hover': {
            borderWidth: variables.button.borderWidth,
        },
        '&:not($disabled)>span:first-child': {
            color: variables.colors.greyDark,
        },
        '&$outlinedSizeLarge': {
            fontWeight: 'bold',
            fontSize: '1rem',
        },
    },
    containedPrimary: {
        '&$disabled': {
            color: variables.colors.greyDark,
        },
    },
    contained: {
        fontWeight: 700,
        boxShadow: variables.button.boxShadow,
        '&:active': {
            boxShadow: variables.button.boxShadow,
        },
        '&$disabled': {
            color: variables.colors.white,
            backgroundColor: variables.button.backgroundColorDisabled,
        },
    },
    outlined: {
        paddingTop: 8,
        paddingBottom: 8,
        borderWidth: variables.button.borderWidth,
        borderColor: variables.button.borderColor,
        '&$disabled': {
            borderColor: 'rgba(0, 0, 0, 0.26)',
        },
        '&$outlinedSizeLarge': {
            fontWeight: 'bold',
            fontSize: '1rem',
        },
    },
    sizeLarge: {
        padding: '16px 24px',
        minHeight: '52px',
    },
};

export default MuiButton;
