import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import getContent from '../../services/content/getContent';
import healthshieldLogo from '../../assets/images/global/healthshield-logo.svg';
import breezeFlower from '../../assets/images/global/breeze-flower.svg';
import Container from '../layout/Container/Container';
import GooglePlaySVG from '../../assets/images/global/google-play-button.svg';
import AppleStoreSVG from '../../assets/images/global/apple-store-button.svg';
import { URLS } from '../../config/config';
import DeviceContext from '../../context/device-context';
import MobileAppService from '../../services/mobile-app/mobile-app';

const Footer = ({ classes, linkGroups = [], isAdmin }) => {
    const device = useContext(DeviceContext);
    const copyrightInfo = `${getContent(['components', 'footer', 'copyright'])}${moment().format('YYYY')}`;
    return (
        <section className={classes.footer}>
            <Container displayPrint="none">
                <div className={classes.footerLinksContainer}>
                    <Grid container alignItems="flex-start" spacing={0}>
                        {Array.isArray(linkGroups) && linkGroups.map(linkGroup => (
                            <Grid key={linkGroup.label} item xs={12} sm={4} md={3} className={classes.footerLinksGroup}>
                                <Typography variant="h5" className={classes.footerLinksLabel}>
                                    {linkGroup.label}
                                </Typography>
                                <ul className={classes.footerLinks}>
                                    {Array.isArray(linkGroup.links) && linkGroup.links.map(link => (
                                        <li key={link.label} className={classes.footerLinkContainer}>
                                            {link.to.includes('http') ? (
                                                <a href={link.to} className={classes.footerLink} rel="noreferrer noopener" target="_blank">
                                                    {link.label}
                                                </a>
                                            ) : (
                                                <Link className={classes.footerLink} component={RouterLink} to={link.to} target={link.target}>
                                                    {link.label}
                                                </Link>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={12} md={linkGroups.length > 1 ? 3 : 9} className={classes.breezeFloweContainer}>
                            <img
                                src={breezeFlower}
                                alt={getContent(['components', 'footer', 'breezeFlower', 'alt'])}
                                className={classes.icon}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.divider} />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <img
                                className={classes.footerBrand}
                                src={healthshieldLogo}
                                alt={getContent(['components', 'footer', 'healthshieldLogo', 'alt'])}
                            />
                            {!!copyrightInfo && <p className={classes.copyright}>{copyrightInfo}</p>}
                        </Grid>
                        {!MobileAppService.isMobileApp(device) && !isAdmin && (
                            <Grid item xs={12} sm={6} className={classes.appLinksContainer}>
                                <Link href={URLS.MEMBER.GOOGLE_STORE} target="_blank" rel="noopener noreferrer">
                                    <img src={GooglePlaySVG} alt={getContent(['components', 'footer', 'apps', 'google'])} height="56" />
                                </Link>
                                <Link href={URLS.MEMBER.APPLE_STORE} target="_blank" rel="noopener noreferrer">
                                    <img src={AppleStoreSVG} alt={getContent(['components', 'footer', 'apps', 'apple'])} height="56" />
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Container>
        </section>
    );
};

const styles = theme => ({
    footer: {
        position: 'relative',
        backgroundColor: theme.palette.common.greyBlueDark,
    },
    footerLinksContainer: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(10),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(8),
        },
    },
    footerLinksGroup: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            '&:last-child': {
                paddingBottom: 0,
            },
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 0,
        },
    },
    footerLinksLabel: {
        color: 'white',
    },
    footerLinks: {
        paddingTop: theme.spacing(2.5),
        paddingLeft: 0,
        listStyleType: 'none',
        marginBottom: 0,
        marginTop: 0,
    },
    footerLinkContainer: {
        paddingBottom: theme.spacing(1),
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    footerLink: {
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: 14,
        color: theme.palette.common.grey,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.common.blue,
            textDecoration: 'none',
        },
    },
    breezeFloweContainer: {
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(0),
        },
    },
    divider: {
        height: 1,
        width: '100%',
        backgroundColor: theme.palette.common.grey,
        opacity: 0.25,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    footerBrand: {
        display: 'block',
    },
    copyright: {
        width: '100%',
        marginTop: theme.spacing(1),
        color: theme.palette.common.grey,
    },
    appLinksContainer: {
        textAlign: 'right',
        '& a': {
            marginLeft: theme.spacing(2),
        },
    },
});

Footer.defaultProps = {
    linkGroups: [],
    isAdmin: false,
};

Footer.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    linkGroups: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            links: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    to: PropTypes.string.isRequired,
                }),
            ),
        }),
    ),
    isAdmin: PropTypes.bool,
};

export default withStyles(styles)(Footer);
