import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import 'intersection-observer';
import 'core-js/es';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { fromJS } from 'immutable';
import MomentUtils from '@date-io/moment';
import App from './components/app/App';
import theme from './theme/healthy-hub';
import store, { history } from './services/store';
import * as serviceWorker from './serviceWorker';
import { setEnvironmentConfig, getEnvironmentVariable } from './services/environment/environment-service';
import { initTracking } from './services/analytics/analytics';
import Cookiebot from './components/cookiebot/cookiebot';
import DeviceContext from './context/device-context';
import MobileAppService from './services/mobile-app/mobile-app';
import E2EHelper from './services/e2e/e2e-helper-service';

// Debug the store
if (process.env.REACT_APP_DEBUG_STORE) {
    const listendToStore = () => {
        const state = fromJS(store.getState());
        console.log('Redux Store Changed', state.toJS()); // eslint-disable-line no-console
    };
    store.subscribe(listendToStore);
}
fetch(process.env.REACT_APP_ENVIRONMENT_CONFIG_PATH)
    .then(res => res.json())
    .then(async (env) => {
        // Set environment vars
        setEnvironmentConfig(env);
        window.addEventListener('CookiebotOnAccept', () => {
            initTracking();
        }, false);
        
        ReactDOM.render(
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MuiThemeProvider theme={theme}>
                            <CssBaseline />
                            {getEnvironmentVariable('REACT_APP_E2E_ENABLED') === 'true' && <E2EHelper />}
                            <DeviceContext.Provider
                                value={MobileAppService.getAppType()}
                            >

                                <App />
                                <Cookiebot />
                            </DeviceContext.Provider>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </ConnectedRouter>
            </Provider>,
            document.getElementById('root'),
        );
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
