import variables from '../variables';

const MuiSelect = {
    icon: {
        color: variables.themeColors.primary,
        right: 10,
    },
    select: {
        paddingRight: 36,
        '&.MuiSelect-select': {
            paddingRight: 36,
        },
    },
    selectMenu: {
        minHeight: '1.1875em',
    },
};

export default MuiSelect;
