import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '../Container/Container';
import PageTitle from '../PageTitle/PageTitle';

const SubPageHeader = ({
    classes, title, subTitle, image,
}) => (
    <Container smallerHeight={subTitle.smallerHeight} gutterTop className={classes.container}>
        <Grid
            container
            spacing={4}
        >
            <Grid item xs={12} sm={4}>
                <div className={classes.imageContainer}>
                    {typeof image === 'string' ? (
                        <img
                            src={image}
                            className={classes.image}
                            alt={title}
                        />
                    ) : image}
                </div>
            </Grid>
            <Grid item xs={12} sm={8}>
                <PageTitle text={title} />
                <Typography variant="subtitle1" gutterBottom>
                    {subTitle.text}
                </Typography>
            </Grid>
        </Grid>
    </Container>
);

const styles = theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
        },
    },
    imageContainer: {
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {

        },
    },
    image: {
        width: 160,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'calc(50% - 231px)',
        },
    },
});

SubPageHeader.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.object.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default withStyles(styles)(SubPageHeader);
