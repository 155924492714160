import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import QuestionnaireResultsSummaryChart from '../../questionnaire/QuestionnaireResultsSummaryChart/QuestionnaireResultsSummaryChart';
import { getWellbeingThemeId, getTrackerThemeIcon } from '../../../services/tracker/tracker';
import getContent from '../../../services/content/getContent';

const MentalHealthQuestionnaireResultsSummaryCharts = ({ priorities }) => {
    const theme = useTheme();
    const displayPriorities = priorities.map((priority, key) => ({ ...priority, color: theme.palette.performance[key] })).filter(priority => priority.items.length > 0);
    return (
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="stretch"
        >
            {displayPriorities.sort((a) => {
                if (a.items.findIndex(item => item.icon === 'STRESS') !== -1) return 1;
                return -1;
            }).map(priority => priority.items.map((item) => {
                const themeId = getWellbeingThemeId(item.theme);
                const helpContent = getContent(['components', 'wellbeingTrackerTheme', 'modalContent', themeId, 'help']);
                return (
                    <Grid item xs={12} sm={6} key={item.title.replace(' ', '')}>
                        <QuestionnaireResultsSummaryChart
                            item={item}
                            title={(
                                <span style={{ color: priority.color }}>{priority.title}</span>
                            )}
                            color={priority.color}
                            icon={getTrackerThemeIcon(themeId)}
                            helpContent={helpContent && helpContent.toJS()}
                        />
                    </Grid>
                );
            }))}
        </Grid>
    );
};

MentalHealthQuestionnaireResultsSummaryCharts.propTypes = {
    priorities: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.object),
    })).isRequired,
};

export default MentalHealthQuestionnaireResultsSummaryCharts;
