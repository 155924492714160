import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MuiProgress from '../MuiProgress/MuiProgress';
import getContent from '../../../services/content/getContent';

const MuiFileDrop = ({
    classes,
    input: {
        value: omitValue, onChange, // eslint-disable-line no-unused-vars
    },
    input, // eslint-disable-line no-unused-vars
    message,
    subMessage,
    resolving,
    isComplete,
    onContinue,
    onReset,
    progress,
    errors,
    maxSize,
    uploadStatusError,
}) => {
    const theme = useTheme();
    const [invalidFile, setInvalidFile] = React.useState(false);
    const [fileError, setFileError] = React.useState(null);

    const onDropAccepted = (files) => {
        setFileError(null);
        onChange(files[0]);
    };

    const onDropRejected = (error) => {
        if (_.hasIn(error, [0, 'errors', 0, 'message'])) {
            setFileError(error[0].errors[0].message);
        }
        setInvalidFile(true);
    };

    const {
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        multiple: false,
        disabled: errors && errors.length > 0, // disable dropzone if errors
        maxSize,
        onDropAccepted,
        onDropRejected,
    });

    const style = useMemo(() => ({
        ...(isDragActive ? {
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.common.grey,
        } : {}),
        ...(isDragAccept ? {} : {}),
        ...(isDragReject ? {} : {}),
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
    ]);

    const renderUploadPanel = () => (
        <>
            <div className={classes.icon}>
                <AddIcon onClick={open} />
            </div>
            <Typography variant="subtitle1" color="textSecondary" className={classes.fileInputLabel}><strong>{message}</strong></Typography>
            <Typography variant="body1">{getContent(['components', 'MuiFileDrop', 'or'])}</Typography>
            <Button color="primary" variant="contained" size="large" className={classes.fileInputBtn} onClick={open}>
                {getContent(['components', 'MuiFileDrop', 'browse'])}
            </Button>
            <input {...getInputProps()} />
        </>
    );

    const renderLoader = () => (
        <div className={classes.spinner}>
            <div className={classes.bounce1} />
            <div className={classes.bounce2} />
            <div className={classes.bounce3} />
        </div>
    );

    const renderSuccessPanel = () => (
        <>
            <div className={classes.icon}>
                <CheckCircleIcon className={classes.iconSuccess} />
            </div>
            <div className={classes.fileInputLabel}>
                <Typography variant="subtitle1" color="textSecondary">
                    <strong>{getContent(['components', 'MuiFileDrop', 'success'])}</strong>
                </Typography>
                {subMessage && (
                    <Typography variant="body1" color="textSecondary">
                        {subMessage}
                    </Typography>
                )}
            </div>
            <Button color="primary" variant="contained" size="large" className={classes.fileInputBtn} onClick={onContinue || open}>
                {getContent(['components', 'MuiFileDrop', 'continueBtn'])}
            </Button>
            <input {...getInputProps()} />
        </>
    );

    const renderLoadingPanel = () => (
        <>
            <div className={classes.icon}>
                {renderLoader()}
            </div>
            <div className={classes.fileInputLabel}>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    <strong>{getContent(['components', 'MuiFileDrop', 'uploading'])}</strong>
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    <strong>{getContent(['components', 'MuiFileDrop', 'uploadingSub'])}</strong>
                </Typography>
                {subMessage && (
                    <Typography variant="body1" color="textSecondary">
                        {subMessage}
                    </Typography>
                )}
            </div>
            <MuiProgress className={classes.progress} variant="determinate" value={progress} />
        </>
    );

    const renderInvalidFilePanel = () => (
        <>
            <div className={classes.icon}>
                <WarningIcon />
            </div>
            <Typography variant="subtitle1" color="textSecondary" className={classes.fileInputLabel}>
                <strong>{fileError || getContent(['components', 'MuiFileDrop', 'tryAgainMessage'])}</strong>
            </Typography>
            <Button color="primary" variant="contained" size="large" className={classes.fileInputBtn} onClick={() => setInvalidFile(false)}>
                {getContent(['components', 'MuiFileDrop', 'tryAgainBtn'])}
            </Button>
        </>
    );

    const renderErrorPanel = () => (
        <>
            <div className={classes.icon}>
                <WarningIcon />
            </div>
            <Typography variant="subtitle1" color="textSecondary" className={classes.fileInputLabel}>
                <strong>{getContent(['components', 'MuiFileDrop', 'errorMessage'])}</strong>
            </Typography>
            <Button color="primary" variant="contained" size="large" className={classes.fileInputBtn} onClick={onReset || open}>
                {getContent(['components', 'MuiFileDrop', 'tryAgainBtn'])}
            </Button>
        </>
    );

    const renderInUsePanel = () => (
        <>
            <div className={classes.icon}>
                <WarningIcon />
            </div>
            <Typography variant="subtitle1" color="textSecondary" className={classes.fileInputLabel}>
                <strong>{uploadStatusError}</strong>
            </Typography>
        </>
    );

    const renderPanel = () => {
        if (errors && errors.length > 0) return renderErrorPanel();
        if (uploadStatusError && uploadStatusError.indexOf('currently uploading employees') !== -1) return renderInUsePanel();
        if (isComplete) return renderSuccessPanel();
        if (resolving) return renderLoadingPanel();
        if (invalidFile) return renderInvalidFilePanel();
        return renderUploadPanel();
    };

    return (
        <div {...getRootProps({ className: classes.fileInput, style })}>
            {renderPanel()}
        </div>
    );
};

const styles = theme => ({
    fileInput: {
        display: 'flex',
        cursor: 'pointer',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: 15,
        backgroundColor: theme.palette.common.greyLight,
        padding: theme.spacing(6),
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    fileInputHover: {
        borderColor: theme.palette.secondary.main,
    },
    fileInputLabel: {
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    fileInputBtn: {
        width: '100%',
        maxWidth: 364,
        marginTop: theme.spacing(4),
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: '50%',
        textAlign: 'center',
        width: 100,
        height: 100,
        marginBottom: theme.spacing(3),
        '& svg': {
            fontSize: 50,
        },
    },
    iconSuccess: {
        color: theme.palette.common.green,
    },
    progress: {
        margin: theme.spacing(1),
        width: '100%',
    },
    spinner: {
        '& > div': {
            width: 5,
            height: 5,
            marginLeft: 5,
            marginRight: 5,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '100%',
            display: 'inline-block',
            animation: '$mui-ripple-pulsate 1.4s infinite ease-in-out both',
        },
    },
    bounce1: {
        animationDelay: '-0.32s !important',
    },
    bounce2: {
        animationDelay: '-0.16s !important',
    },
    '@keyframes mui-ripple-pulsate': {
        '0%, 80%, 100%': {
            transform: 'scale(0)',
        },
        '40%': {
            transform: 'scale(1.0)',
        },
    },
});

MuiFileDrop.defaultProps = {
    input: null,
    resolving: false,
    message: getContent(['components', 'MuiFileDrop', 'defaultMessage']),
    subMessage: null,
    isComplete: false,
    progress: null,
    onContinue: null,
    onReset: null,
    errors: null,
    maxSize: 500000,
    uploadStatusError: null,
};

MuiFileDrop.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    input: fieldPropTypes.input,
    message: PropTypes.string,
    subMessage: PropTypes.string,
    resolving: PropTypes.bool,
    isComplete: PropTypes.bool,
    progress: PropTypes.number,
    maxSize: PropTypes.number,
    onContinue: PropTypes.func,
    onReset: PropTypes.func,
    uploadStatusError: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        message: PropTypes.string,
        row: PropTypes.number,
    })),
};

export default withStyles(styles)(MuiFileDrop);
