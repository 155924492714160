import * as Immutable from 'immutable';
import {
    COMPANY_MEMBER_CREATE_REQUESTED,
    COMPANY_MEMBER_CREATE_RESOLVED,
    COMPANY_MEMBER_CREATE_ERROR,
    COMPANY_MEMBER_CREATE_RESET,
} from '../actions/company-member/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    member: null,
};

const initialState = Immutable.Map(schema);

/**
 * The company Member reducer - responsible for managing a member of a company and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const createCompanyMemberReducer = (state = initialState, action) => {
    switch (action.type) {
    case COMPANY_MEMBER_CREATE_REQUESTED:
        return state.set('resolving', true);

    case COMPANY_MEMBER_CREATE_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('member', action.payload);

    case COMPANY_MEMBER_CREATE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('member', null);

    case COMPANY_MEMBER_CREATE_RESET:
        return initialState;

    default:
        return state;
    }
};

export { initialState };

export default createCompanyMemberReducer;
