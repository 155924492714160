import { gql } from '@apollo/client';
import _ from 'lodash';
import getContent from '../../content/getContent';
import graphQLCLient from '../client';
import errorHandler from '../errorHandler';

const updateMemberNotificationPreferencesQuery = async (notifications) => {
    const client = graphQLCLient();
    const notificationsInput = [];

    // Loop through the member properties and create the gql string
    _.forIn(notifications, (value, key) => {
        notificationsInput.push(`${key}: ${value}`);
    });

    const mutation = gql`mutation {
        updateMemberNotificationPreferences(
            notifications: {${notificationsInput.join(',')}}
        ) {
            email
            profilingConsent
        }
    }`;

    return new Promise((resolve, reject) => {
        client.mutate({ mutation })
            .then((result) => {
                if (!result.data || !Object.prototype.hasOwnProperty.call(result.data, 'updateMemberNotificationPreferences')) {
                    throw new Error(getContent(['errors', 'invalid-graphQL-response']));
                }
                resolve(result.data.updateMemberNotificationPreferences);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};

export default updateMemberNotificationPreferencesQuery;
