import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import StaticLoader from '../../loader/StaticLoader/StaticLoader';
import SubPageHeader from '../SubPageHeader/SubPageHeader';
import Container from '../Container/Container';

const SubPage = ({
    classes, resolving, header, children,
}) => (
    <Box className={classes.box}>
        <SubPageHeader {...header} />
        {resolving && <StaticLoader />}
        <Container>
            <Grid
                container
                spacing={4}
                justifyContent="flex-end"
            >
                <Grid item xs={12} sm={8}>
                    {children}
                </Grid>
            </Grid>
        </Container>
    </Box>
);

const styles = theme => ({
    box: {
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
        },
    },
});

SubPage.defaultProps = {
    resolving: false,
};

SubPage.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    resolving: PropTypes.bool,
    header: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.object.isRequired,
        image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        imageMargin: PropTypes.number,
    }).isRequired,
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(SubPage);
