import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Avatar } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { URLS } from '../../config/config';
import getContent from '../../services/content/getContent';
import breezeLogoSvg from '../../assets/images/global/breeze-logo.svg';
import StarIcon from '../../assets/images/icons/star-icon.svg';
import hsLogoSvg from '../../assets/images/global/healthshield-logo-black.svg';
import DrawerMenu from './DrawerMenu/DrawerMenu';
import PlaceHolderAvatar from '../../assets/images/avatar/placeholderAvatar.svg';
import DeviceContext from '../../context/device-context';
import MobileAppService from '../../services/mobile-app/mobile-app';

const MenuBar = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const device = useContext(DeviceContext);
    const open = Boolean(anchorEl);
    const {
        classes,
        title,
        isAuthenticated,
        isRegistered,
        isRegistering,
        role,
        companyContact,
        member,
        logout,
        // highlightWhatsNew,
    } = props;

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const MenuButtonLink = React.forwardRef((navProps, ref) => (
        <NavLink {...navProps} innerRef={ref} isActive={(match, location) => (match && match.url === location.pathname)} />
    ));

    const Link = React.forwardRef((navProps, ref) => (
        <NavLink {...navProps} innerRef={ref} />
    ));

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const sm = useMediaQuery(theme.breakpoints.down('md')); // useMediaQuery(theme.breakpoints.down('sm'));

    const renderAccountMenu = () => (
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
        >
            <MenuItem
                onClick={handleClose}
                component={Link}
                to={role === 'admin' ? URLS.CONTACT.ACCOUNT.PERSONAL_DETAILS : URLS.MEMBER.ACCOUNT.PERSONAL_DETAILS}
                className={classes.accountMenuItem}
            >
                {getContent(['appBar', 'myAccount'])}
            </MenuItem>
            {role !== 'admin' && !member.isCashPlanMember && (
                <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to={URLS.MEMBER.ACCOUNT.COMPANY}
                    className={classes.accountMenuItem}
                >
                    {getContent(['appBar', 'companyDetails'])}
                </MenuItem>
            )}
            <MenuItem onClick={logout} className={classes.accountMenuItem}>Logout</MenuItem>
        </Menu>
    );

    const renderMobileAccountMenu = () => (
        <div className={classes.mobileAccountMenu}>
            <Button
                color="inherit"
                className={classes.menuItem}
                to={role === 'admin' ? URLS.CONTACT.ACCOUNT.PERSONAL_DETAILS : URLS.MEMBER.ACCOUNT.PERSONAL_DETAILS}
                component={MenuButtonLink}
            >
                {getContent(['appBar', 'myAccount'])}
            </Button>
            {role !== 'admin' && !member.isCashPlanMember && (
                <Button
                    color="inherit"
                    className={classes.menuItem}
                    to={URLS.MEMBER.ACCOUNT.COMPANY}
                    component={MenuButtonLink}
                >
                    {getContent(['appBar', 'myCompany'])}
                </Button>
            )}
            <Button
                color="inherit"
                onClick={logout}
                aria-label="logout"
                className={classes.menuItem}
            >
                Logout
            </Button>
        </div>
    );
    const renderAdminProfileLink = () => {
        const fullName = `${companyContact.firstName} ${companyContact.lastName}`;
        return (
            <Button
                color="inherit"
                className={`${classes.profileNameContainer}  aut-navBarMenu`}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
            >
                <Avatar src={PlaceHolderAvatar} className={classes.profileAvatar} />
                {(md === false || sm === true)
                    && (
                        <div className={`${classes.menuItem} ${classes.profileName} ${classes.profileNameText}`}>
                            {fullName && fullName.length > 20 ? `${fullName.substring(0, 20)}...` : fullName}
                        </div>
                    )
                }
                {sm === false && <MoreVertIcon />}
            </Button>
        );
    };

    const renderMemberProfile = () => {
        const fullName = `${member.firstName} ${member.lastName}`;
        return (
            <Button
                color="inherit"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                className={`${classes.profileNameContainer}  aut-navBarMenu`}
            >
                <Avatar src={PlaceHolderAvatar} className={classes.profileAvatar} />
                <div className={`${classes.menuItem} ${classes.profileName}`}>
                    <Typography variant="body2" className={classes.profileNameText}>
                        {fullName && fullName.length > 20 ? `${fullName.substring(0, 20)}...` : fullName}
                    </Typography>
                </div>
                {sm === false && <MoreVertIcon />}
            </Button>
        );
    };

    const getMenuItems = () => {
        const menuItems = [
            { to: URLS.MEMBER.HOME, label: getContent(['appBar', 'dashboardButtonText']) },
            { to: URLS.MEMBER.DASHBOARD, label: getContent(['appBar', 'wellbeingTrackerButtonText']) },
            { to: URLS.MEMBER.SERVICES, label: getContent(['appBar', 'healthServicesButtonText']) },
            { to: URLS.MEMBER.ARTICLES, label: getContent(['appBar', 'healthyBitesButtonText']) },
            { to: URLS.MEMBER.EVENTS, label: getContent(['appBar', 'memberEventsButtonText']) },
            { to: URLS.MEMBER.HELP, label: getContent(['appBar', 'helpButtonText']) },
        ];

        const mobileMenuItems = [
            { to: URLS.MEMBER.TERMS, label: getContent(['appBar', 'mobile', 'termsButtonText']) },
            { href: URLS.MEMBER.PRIVACY_EXTERNAL, label: getContent(['appBar', 'mobile', 'privacyButtonText']) },
            { href: URLS.MEMBER.COOKIES, label: getContent(['appBar', 'mobile', 'cookiesText']) },
        ];

        return (
            <div>
                {menuItems.map(menuItem => (
                    <Button
                        key={menuItem.to}
                        color="inherit"
                        className={classes.menuItem}
                        activeClassName="active"
                        to={menuItem.to}
                        component={MenuButtonLink}
                    >
                        {menuItem.label}
                        {menuItem.highlight && (
                            <span className={classes.highlight}>
                                <span />
                                <span />
                                <span />
                            </span>
                        )}
                    </Button>
                ))}
                {MobileAppService.isMobileApp(device) && mobileMenuItems.map((menuItem) => {
                    if (menuItem.href) {
                        return (
                            <Button
                                href="#"
                                key={menuItem.href}
                                color="inherit"
                                className={`${classes.menuItem} ${classes.externalLink} ${classes.buttonLink}`}
                                activeClassName="active"
                                onClick={() => window.open(`${menuItem.href}`, 'linkpop', 'popup')}
                            >
                                {menuItem.label}
                            </Button>
                        );
                    }
                    return (
                        <Button
                            key={menuItem.to}
                            color="inherit"
                            className={classes.menuItem}
                            activeClassName="active"
                            {..._.omit(menuItem, ['label'])}
                            component={MenuButtonLink}
                        >
                            {menuItem.label}
                        </Button>
                    );
                })}
                {MobileAppService.isMobileApp(device) && <img src={hsLogoSvg} alt={getContent(['appBar', 'mobile', 'logoAlt'])} className={classes.hsLogo} width="150" />}
            </div>
        );
    };

    const getAdminMenuItems = () => {
        const menuItems = [
            { to: URLS.CONTACT.HOME, label: getContent(['appBar', 'admin', 'dashboardButtonText']) },
            { to: URLS.CONTACT.DASHBOARD, label: getContent(['appBar', 'admin', 'wellbeingTrackerButtonText']) },
            { to: URLS.CONTACT.SERVICES, label: getContent(['appBar', 'admin', 'healthServicesButtonText']) },
            { to: URLS.CONTACT.ARTICLES, label: getContent(['appBar', 'healthyBitesButtonText']) },
            { to: URLS.CONTACT.EMPLOYEES, label: getContent(['appBar', 'admin', 'membersButtonText']) },
            { to: URLS.CONTACT.EVENTS, label: getContent(['appBar', 'admin', 'eventsButtonText']) },
            { to: URLS.CONTACT.ADMIN.ADMINISTRATORS, label: getContent(['appBar', 'admin', 'adminButtonText']) },
            { to: URLS.CONTACT.HELP, label: getContent(['appBar', 'admin', 'helpButtonText']) },
            // { to: URLS.CONTACT.WHATS_NEW, label: getContent(['appBar', 'admin', 'whatsNewButtonText']), highlight: highlightWhatsNew },
        ];

        return (
            <div>
                {menuItems.map(menuItem => (
                    <Button
                        key={menuItem.to}
                        color="inherit"
                        className={classes.menuItem}
                        activeClassName="active"
                        to={menuItem.to}
                        component={MenuButtonLink}
                    >
                        {menuItem.label}
                        {menuItem.highlight && (
                            <span className={classes.highlight}>
                                <span />
                                <span />
                                <span />
                            </span>
                        )}
                    </Button>
                ))}
            </div>
        );
    };

    const renderLogo = () => (<img src={breezeLogoSvg} alt={getContent(['appBar', 'title'])} />);

    return (
        <Box className={classes.root} displayPrint="none">
            <AppBar position="fixed" color="default">
                <Toolbar className={classes.toolbar}>
                    {isAuthenticated
                        ? <Link to={role === 'admin' ? URLS.CONTACT.HOME : URLS.MEMBER.HOME}>{renderLogo()}</Link>
                        : renderLogo()
                    }
                    <div className={classes.grow} />
                    {(sm === false && !MobileAppService.isMobileApp(device)) && isRegistered && isAuthenticated && !isRegistering && role === 'member' && (
                        <div className={classes.menuTopBar}>
                            {getMenuItems()}
                            {renderMemberProfile()}
                            {renderAccountMenu()}
                        </div>
                    )
                    }
                    {(sm === true || MobileAppService.isMobileApp(device)) && isRegistered && isAuthenticated && !isRegistering && role === 'member' && (
                        <DrawerMenu>
                            {getMenuItems()}
                            <div className={classes.bottomContent}>
                                {renderMemberProfile()}
                                {renderMobileAccountMenu()}
                            </div>
                        </DrawerMenu>
                    )
                    }
                    {sm === false && isRegistered && isAuthenticated && !isRegistering && role === 'admin' && companyContact && (
                        <div className={classes.menuTopBar}>
                            {getAdminMenuItems()}
                            {renderAdminProfileLink()}
                            {renderAccountMenu()}
                        </div>
                    )
                    }
                    {sm === true && isRegistered && isAuthenticated && !isRegistering && role === 'admin' && companyContact && (
                        <DrawerMenu>
                            {getAdminMenuItems()}
                            <div className={classes.bottomContent}>
                                {renderAdminProfileLink()}
                                {renderMobileAccountMenu()}
                            </div>
                        </DrawerMenu>
                    )
                    }
                    {isRegistering
                        && (
                            <Button
                                color="inherit"
                                className={`${classes.menuItem} ${classes.accountMenuItem} ${classes.logoutLink}`}
                                onClick={logout}
                            >
                                Logout
                            </Button>
                        )
                    }
                    {title ? <Typography variant="body1" color="inherit" className={classes.title}>{title}</Typography> : null}
                </Toolbar>
            </AppBar>
        </Box>
    );
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        [theme.breakpoints.down('xs')]: {
            minHeight: 56,
        },
    },
    grow: {
        flexGrow: 1,
    },
    menuItem: {
        fontWeight: 'bold',
        fontSize: 15,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        '&.active': {
            color: theme.palette.secondary.main,
        },
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
        },
        // [theme.breakpoints.down('md')]: {
        //     paddingLeft: theme.spacing(1),
        //     paddingRight: theme.spacing(1),
        //     fontSize: 14,
        // },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    menuTopBar: {
        display: 'flex',
        alignItems: 'center',
    },
    profileNameContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
        },
    },
    profileAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    profileName: {
        fontSize: 13,
        color: theme.palette.common.black,
        display: 'block',
        textAlign: 'left',
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    profileNameText: {
        fontSize: 13,
        fontWeight: 'bold',
        color: theme.palette.common.orange,
    },
    companyName: {
        fontSize: 12,
        color: theme.palette.common.greyDark,
        fontWeight: 400,
    },
    accountMenuItem: {
        fontSize: 12,
    },
    logoutLink: {
        fontSize: 14,
    },
    bottomContent: {
        marginTop: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.common.greyLight,
        borderTop: `1px solid ${theme.palette.common.grey}`,
    },
    mobileAccountMenu: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& a:first-child': {
            paddingTop: theme.spacing(0.5),
        },
    },
    hsLogo: {
        textAlign: 'revert',
        float: 'right',
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    externalLink: {
        color: theme.palette.common.greyDark,
        textDecoration: 'none',
    },
    highlight: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        [theme.breakpoints.down('md')]: {
            left: 40,
        },
        '& > span': {
            position: 'absolute',
            bottom: 0,
            left: 'calc(50% - 6px)',
            display: 'block',
            background: `url(${StarIcon}) no-repeat 100%`,
            backgroundSize: 'contain',
            width: 12,
            height: 12,
        },
        '& > span:first-child': {
            top: 3,
            left: 3,
            bottom: 'auto',
            width: 12,
            height: 12,
        },
        '& > span:last-child': {
            top: 0,
            right: 0,
            left: 'auto',
            bottom: 'auto',
            width: 18,
            height: 18,
        },
    },
});

MenuBar.defaultProps = {
    title: null,
    isAuthenticated: false,
    isRegistered: false,
    isRegistering: false,
    role: null,
    companyContact: null,
    member: {
        firstName: null,
        lastName: null,
        companyName: null,
        isCashPlanMember: false,
    },
    logout: null,
    // highlightWhatsNew: false,
};

MenuBar.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isRegistering: PropTypes.bool,
    role: PropTypes.oneOf(['member', 'admin']),
    companyContact: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    member: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        companyName: PropTypes.string,
        isCashPlanMember: PropTypes.bool,
    }),
    logout: PropTypes.func,
    // highlightWhatsNew: PropTypes.bool,

};

export default withStyles(styles)(MenuBar);
