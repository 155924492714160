import { LogLevel } from '@azure/msal-browser';
import { getEnvironmentVariable } from '../../../services/environment/environment-service';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const ua = window.navigator.userAgent;
// const msie = ua.indexOf('MSIE ');
// const msie11 = ua.indexOf('Trident/');
// const msedge = ua.indexOf('Edge/');
// const firefox = ua.indexOf('Firefox');
// const isIE = msie > 0 || msie11 > 0;
// const isEdge = msedge > 0;
// const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const config = {
    cache: {
        storeAuthStateInCookie: false, // isIE || isEdge || isFirefox,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii || process.env.REACT_APP_DEBUG_STORE) {
                    return;
                }
                switch (level) {
                case LogLevel.Error:
                case LogLevel.Info:
                case LogLevel.Verbose:
                case LogLevel.Warning: {
                    console.warn(message);// eslint-disable-line no-console
                    break;
                }
                default:
                    break;
                }
            },
        },
    },
};

// Config object to be passed to Msal on creation
export const getMsalSignInConfig = () => {
    const url = new URL(getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_AUTHORITY_SIGNIN'));
    return {
        auth: {
            clientId: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_CLIENTID'),
            authority: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_AUTHORITY_SIGNIN'),
            redirectUri: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_REDIRECT_URI'),
            postLogoutRedirectUri: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_REDIRECT_URI'),
            knownAuthorities: [url.host],
        },
        ...config,
    };
};

export const getMsalSignUpConfig = () => {
    const url = new URL(getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_AUTHORITY_SIGNIN'));
    return {
        auth: {
            clientId: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_CLIENTID'),
            authority: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_AUTHORITY_SIGNUP'),
            redirectUri: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_REDIRECT_URI'),
            postLogoutRedirectUri: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_REDIRECT_URI'),
            knownAuthorities: [url.host],
        },
        ...config,
    };
};

export const getMsalPasswordResetConfig = () => {
    const url = new URL(getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_AUTHORITY_SIGNIN'));
    return {
        auth: {
            authority: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_AUTHORITY_RESET_PASSWORD'),
            clientId: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_CLIENTID'),
            redirectUri: getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_REDIRECT_URI'),
            knownAuthorities: [url.host],
        },
        ...config,
    };
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const getMsalRequest = (platform, registrationHash) => {
    const { navigator: { userAgent } } = window;
    const extraQueryParameters = registrationHash ? { registration_hash: registrationHash, platform, userAgent } : { platform, userAgent };
    return {
        scopes: [`https://${getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_TENANT')}/api/user_impersonation`, 'openid', 'offline_access profile'],
        extraQueryParameters,
    };
};
