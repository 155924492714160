import {
    createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { createBrowserHistory } from 'history';

// Import reducers
import companyMembersReducer from '../reducers/company-members';
import companyMemberReducer from '../reducers/company-member';
import companyContactReducer from '../reducers/company-contact';
import addCompanyMembersReducer from '../reducers/add-company-members';
import createCompanyMemberReducer from '../reducers/create-company-member';
import questionnaireReducer from '../reducers/questionnaire';
import gpBookingReducer from '../reducers/gp-booking';
import authReducer from '../reducers/auth';
import perksReducer from '../reducers/perks';
import servicesReducer from '../reducers/services';
import healthyBitesReducer from '../reducers/healthy-bites';
import eventsReducer from '../reducers/events';
import memberReducer from '../reducers/member';
import memberDashboardReducer from '../reducers/member-dashboard';
import memberTrackerReducer from '../reducers/member-tracker';
import addressSearchReducer from '../reducers/address-search';
import employerDashboardReducer from '../reducers/employer-dashboard';
import employerTrackerReducer from '../reducers/employer-tracker';
import companyContactsReducer from '../reducers/company-contacts';
import createCompanyContactReducer from '../reducers/create-company-contact';
import locationHistoryReducer from '../reducers/location-history';
import contentCacheReducer from '../reducers/content-cache-reducer';
import memberEventsReducer from '../reducers/member-events';
import memberCompanyReducer from '../reducers/member-company';
import notificationsReducer from '../reducers/notifications';
import statusReducer from '../reducers/status';
import memberThemeTrendReducer from '../reducers/member-theme-trend';
import whatsNewReducer from '../reducers/whats-new';
import graphReducer from '../reducers/graph';

// Get middleware for history
const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Build middleware
const middleware = composeEnhancers(
    applyMiddleware(
        routerMiddleware(history),
        thunk,
    ),
);

// Combine the reducers
const reducers = combineReducers({
    auth: authReducer,
    addressSearch: addressSearchReducer,
    addCompanyMembers: addCompanyMembersReducer,
    companyMembers: companyMembersReducer,
    companyMember: companyMemberReducer,
    companyContact: companyContactReducer,
    contentCache: contentCacheReducer,
    createCompanyMember: createCompanyMemberReducer,
    form: formReducer,
    gpBooking: gpBookingReducer,
    questionnaire: questionnaireReducer,
    perks: perksReducer,
    member: memberReducer,
    memberCompany: memberCompanyReducer,
    memberDashboard: memberDashboardReducer,
    memberEvents: memberEventsReducer,
    memberTracker: memberTrackerReducer,
    memberThemeTrend: memberThemeTrendReducer,
    notifications: notificationsReducer,
    services: servicesReducer,
    healthyBites: healthyBitesReducer,
    events: eventsReducer,
    employerDashboard: employerDashboardReducer,
    employerTracker: employerTrackerReducer,
    companyContacts: companyContactsReducer,
    createCompanyContact: createCompanyContactReducer,
    locationHistory: locationHistoryReducer,
    router: connectRouter(history),
    status: statusReducer,
    whatsNew: whatsNewReducer,
    graph: graphReducer,
});

// Make our store with our reducers and middleware
const store = createStore(
    reducers,
    middleware,
);

export { history };

export default store;
