export const MEMBER_REQUESTED = 'MEMBER_REQUESTED';
export const MEMBER_RESOLVED = 'MEMBER_RESOLVED';
export const MEMBER_ERROR = 'MEMBER_ERROR';

export const MEMBER_UPDATE_REQUESTED = 'MEMBER_UPDATE_REQUESTED';
export const MEMBER_UPDATE_RESOLVED = 'MEMBER_UPDATE_RESOLVED';
export const MEMBER_UPDATE_ERROR = 'MEMBER_UPDATE_ERROR';

export const MEMBER_AND_PATIENT_UPDATE_REQUESTED = 'MEMBER_AND_PATIENT_UPDATE_REQUESTED';
export const MEMBER_AND_PATIENT_UPDATE_RESOLVED = 'MEMBER_AND_PATIENT_UPDATE_RESOLVED';
export const MEMBER_AND_PATIENT_UPDATE_ERROR = 'MEMBER_AND_PATIENT_UPDATE_ERROR';

export const MEMBER_INTERESTS_REQUESTED = 'MEMBER_INTERESTS_REQUESTED';
export const MEMBER_INTERESTS_RESOLVED = 'MEMBER_INTERESTS_RESOLVED';
export const MEMBER_INTERESTS_ERROR = 'MEMBER_INTERESTS_ERROR';

export const MEMBER_INTERESTS_UPDATE_REQUESTED = 'MEMBER_INTERESTS_UPDATE_REQUESTED';
export const MEMBER_INTERESTS_UPDATE_RESOLVED = 'MEMBER_INTERESTS_UPDATE_RESOLVED';
export const MEMBER_INTERESTS_UPDATE_ERROR = 'MEMBER_INTERESTS_UPDATE_ERROR';

export const MEMBER_NOTIFICATION_PREFERENCES_REQUESTED = 'MEMBER_NOTIFICATION_PREFERENCES_REQUESTED';
export const MEMBER_NOTIFICATION_PREFERENCES_RESOLVED = 'MEMBER_NOTIFICATION_PREFERENCES_RESOLVED';
export const MEMBER_NOTIFICATION_PREFERENCES_ERROR = 'MEMBER_NOTIFICATION_PREFERENCES_ERROR';

export const MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED = 'MEMBER_NOTIFICATION_PREFERENCES_UPDATE_REQUESTED';
export const MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED = 'MEMBER_NOTIFICATION_PREFERENCES_UPDATE_RESOLVED';
export const MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR = 'MEMBER_NOTIFICATION_PREFERENCES_UPDATE_ERROR';

export const MEMBER_ALL_INTERESTS_REQUESTED = 'MEMBER_ALL_INTERESTS_REQUESTED';
export const MEMBER_ALL_INTERESTS_RESOLVED = 'MEMBER_ALL_INTERESTS_RESOLVED';
export const MEMBER_ALL_INTERESTS_ERROR = 'MEMBER_ALL_INTERESTS_ERROR';

export const MEMBER_DASHBOARD_REQUESTED = 'MEMBER_DASHBOARD_REQUESTED';
export const MEMBER_DASHBOARD_RESOLVED = 'MEMBER_DASHBOARD_RESOLVED';
export const MEMBER_DASHBOARD_ERROR = 'MEMBER_DASHBOARD_ERROR';

export const MEMBER_TRACKER_REQUESTED = 'MEMBER_TRACKER_REQUESTED';
export const MEMBER_TRACKER_RESOLVED = 'MEMBER_TRACKER_RESOLVED';
export const MEMBER_TRACKER_ERROR = 'MEMBER_TRACKER_ERROR';

export const MEMBER_REGISTRATION_COMPLETE_REQUESTED = 'MEMBER_REGISTRATION_COMPLETE_REQUESTED';
export const MEMBER_REGISTRATION_COMPLETE_RESOLVED = 'MEMBER_REGISTRATION_COMPLETE_RESOLVED';
export const MEMBER_REGISTRATION_COMPLETE_ERROR = 'MEMBER_REGISTRATION_COMPLETE_ERROR';

export const MEMBER_EVENTS_REQUESTED = 'MEMBER_EVENTS_REQUESTED';
export const MEMBER_EVENTS_RESOLVED = 'MEMBER_EVENTS_RESOLVED';
export const MEMBER_EVENTS_ERROR = 'MEMBER_EVENTS_ERROR';

export const MEMBER_EVENT_REQUESTED = 'MEMBER_EVENT_REQUESTED';
export const MEMBER_EVENT_RESOLVED = 'MEMBER_EVENT_RESOLVED';
export const MEMBER_EVENT_ERROR = 'MEMBER_EVENT_ERROR';

export const MEMBER_COMPANY_REQUESTED = 'MEMBER_COMPANY_REQUESTED';
export const MEMBER_COMPANY_RESOLVED = 'MEMBER_COMPANY_RESOLVED';
export const MEMBER_COMPANY_ERROR = 'MEMBER_COMPANY_ERROR';

export const MEMBER_THEME_TREND_REQUESTED = 'MEMBER_THEME_TREND_REQUESTED';
export const MEMBER_THEME_TREND_RESOLVED = 'MEMBER_THEME_TREND_RESOLVED';
export const MEMBER_THEME_TREND_ERROR = 'MEMBER_THEME_TREND_ERROR';

export const MEMBER_CLEAR_THEME_TREND = 'MEMBER_CLEAR_THEME_TREND';

export const MEMBER_DASHBOARD_HIDE_CONSENT = 'MEMBER_DASHBOARD_HIDE_CONSENT';
