import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MuiTablePagination = ({
    classes, count, rowsPerPage, rowsPerPageOptions, page, onPageChange, onRowsPerPageChange,
}) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <TablePagination
            classes={{ selectRoot: classes.selectRoot }}
            rowsPerPageOptions={rowsPerPageOptions}
            labelRowsPerPage={sm ? 'Rows' : 'Rows per page'}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
                'aria-label': 'Next Page',
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
        />
    );
};

const styles = theme => ({
    selectRoot: {
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1),
        },
    },
});

MuiTablePagination.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(MuiTablePagination);
