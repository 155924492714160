import variables from '../variables';
import background from '../palette/background';

const MuiSlider = {
    thumb: {
        height: 40,
        width: 40,
        marginTop: -16,
        marginLeft: -15,
        boxShadow: '0px 0px 3px 3px rgba(255,255,255,0.2)',
        backgroundColor: variables.themeColors.primary,
        '&$active': {
            boxShadow: '0px 0px 3px 3px rgba(255,255,255,0.2)',
        },
        '& > span': {
            height: 12,
            width: 2,
            backgroundColor: variables.colors.white,
            marginLeft: 1.5,
            marginRight: 1.5,
        },
    },
    rail: {
        backgroundColor: variables.colors.white,
        border: `2px solid ${variables.colors.grey}`,
        height: 9,
        opacity: 1,
        borderRadius: 10,
    },
    track: {
        background: background.gradient,
        marginTop: 2,
        height: 9,
        borderRadius: 10,
    },
    mark: {
        backgroundColor: variables.colors.grey,
        height: 32,
        width: 1,
        top: 0,
        zIndex: -1,
    },
};

export default MuiSlider;
