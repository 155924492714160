import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import StaticLoader from '../../loader/StaticLoader/StaticLoader';
import PageHeader from '../PageHeader/PageHeader';

const Page = ({
    classes, resolving, header, children,
}) => (
    <Box className={classes.box}>
        <PageHeader {...header} />
        {resolving && <StaticLoader />}
        {children}
    </Box>
);

const styles = theme => ({
    box: {
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
        },
    },
});

Page.defaultProps = {
    resolving: false,
};

Page.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    resolving: PropTypes.bool,
    header: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string,
        image: PropTypes.string.isRequired,
        imageMargin: PropTypes.number,
    }).isRequired,
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Page);
