import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import setAutoLogoutAction from '../../../actions/auth/setAutoLogout';

const HealthyBitesVideo = ({
    url,
    width,
    height,
    allowFullScreen,
}) => {
    const dispatch = useDispatch();

    useEffect(() => () => {
        // resume auto logout
        dispatch(setAutoLogoutAction(true));
    }, []);

    return (
        <ReactPlayer
            url={url}
            width={width}
            height={height}
            controls
            playsinline
            config={{
                vimeo: {
                    allowfullscreen: allowFullScreen,
                },
            }}
            onPlay={() => {
                // stop autologout
                dispatch(setAutoLogoutAction(false));
            }}
            onStart={() => {
                // stop autologout
                dispatch(setAutoLogoutAction(false));
            }}
            onEnded={() => {
                // resume autologout
                dispatch(setAutoLogoutAction(true));
            }}
            onPause={() => {
                // resume autologout
                dispatch(setAutoLogoutAction(true));
            }}
        />
    );
};

HealthyBitesVideo.defaultProps = {
    allowFullScreen: false,
};

HealthyBitesVideo.propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    allowFullScreen: PropTypes.bool,
};

export default HealthyBitesVideo;
