import * as Immutable from 'immutable';
import {
    EMPLOYER_TRACKER_REQUESTED,
    EMPLOYER_TRACKER_RESOLVED,
    EMPLOYER_TRACKER_ERROR,
    EMPLOYER_TRACKER_ASSESSMENT_REQUESTED,
    EMPLOYER_TRACKER_ASSESSMENT_RESOLVED,
    EMPLOYER_TRACKER_ASSESSMENT_ERROR,
    EMPLOYER_ASSESSMENT_THEME_TREND_REQUESTED,
    EMPLOYER_ASSESSMENT_THEME_TREND_RESOLVED,
    EMPLOYER_ASSESSMENT_THEME_TREND_ERROR,
    EMPLOYER_ASSESSMENT_CLEAR_THEME_TREND,
    EMPLOYER_LOGIN_TREND_REQUESTED,
    EMPLOYER_LOGIN_TREND_RESOLVED,
    EMPLOYER_LOGIN_TREND_ERROR,
    EMPLOYER_LOGIN_CLEAR_TREND,
} from '../actions/employer/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    assessmentResolving: false,
    activeCode: null,
    tracker: [],
    themeTrend: {
        status: 'INIT', // INIT/OK/ERROR
        resolving: false,
        themeCode: null,
        dataPoints: null,
        chart: null,
        title: null,
    },
};

const initialState = Immutable.Map(schema);
/**
 * The employer tracker reducer - responsible for managing the state of the employer data
 * @param {Object} state
 * @param {Object} action
 */
const employerTrackerReducer = (state = initialState, action) => {
    switch (action.type) {
    case EMPLOYER_TRACKER_REQUESTED:
        return state
            .set('resolving', true)
            .set('activeCode', action.payload.code);

    case EMPLOYER_TRACKER_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .update('tracker', (tracker) => {
                const assessmentIndex = tracker.findIndex(assessment => assessment.code === action.payload.code);
                if (assessmentIndex === -1) {
                    tracker.push({ code: action.payload.code, assessments: action.payload.tracker });
                    return tracker;
                }
                return tracker.map((assessment, index) => {
                    if (index === assessmentIndex) {
                        return { code: action.payload.code, assessments: action.payload.tracker };
                    }
                    return assessment;
                });
            });

    case EMPLOYER_TRACKER_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .update('tracker', (tracker) => {
                const assessmentIndex = tracker.findIndex(assessment => assessment.code === action.payload.code);
                if (assessmentIndex === -1) {
                    tracker.push({ code: action.payload.code, assessments: null });
                    return tracker;
                }
                return tracker.map((assessment, index) => {
                    if (index === assessmentIndex) {
                        return { code: action.payload.code, assessments: null };
                    }
                    return assessment;
                });
            });

    case EMPLOYER_TRACKER_ASSESSMENT_REQUESTED:
        return state
            .set('assessmentResolving', true);

    case EMPLOYER_TRACKER_ASSESSMENT_RESOLVED: {
        const assessmentIndex = state.get('tracker').findIndex(assessment => assessment.code === action.payload.code);
        return state
            .setIn(['tracker', assessmentIndex, 'assessments', action.payload.assessmentName], action.payload.assessment)
            .set('assessmentResolving', false);
    }

    case EMPLOYER_TRACKER_ASSESSMENT_ERROR: {
        const assessmentIndex = state.get('tracker').findIndex(assessment => assessment.code === action.payload.code);
        return state
            .setIn(['tracker', assessmentIndex, 'assessments', action.payload.assessmentName], null)
            .set('assessmentResolving', false);
    }

    case EMPLOYER_LOGIN_TREND_REQUESTED:
    case EMPLOYER_ASSESSMENT_THEME_TREND_REQUESTED:
        return state
            .setIn(['themeTrend', 'resolving'], true)
            .setIn(['themeTrend', 'chart'], null)
            .setIn(['themeTrend', 'title'], action.payload.title)
            .setIn(['themeTrend', 'themeCode'], action.payload.themeCode)
            .setIn(['themeTrend', 'dataPoints'], action.payload.dataPoints);

    case EMPLOYER_LOGIN_TREND_RESOLVED:
    case EMPLOYER_ASSESSMENT_THEME_TREND_RESOLVED: {
        return state
            .setIn(['themeTrend', 'resolving'], false)
            .setIn(['themeTrend', 'status'], 'OK')
            .setIn(['themeTrend', 'title'], action.payload.title)
            .setIn(['themeTrend', 'chart'], action.payload.themeTrend)
            .setIn(['themeTrend', 'themeCode'], action.payload.themeCode)
            .setIn(['themeTrend', 'dataPoints'], action.payload.dataPoints);
    }

    case EMPLOYER_LOGIN_TREND_ERROR:
    case EMPLOYER_ASSESSMENT_THEME_TREND_ERROR: {
        return state
            .setIn(['themeTrend', 'chart'], null)
            .setIn(['themeTrend', 'status'], 'ERROR')
            .setIn(['themeTrend', 'title'], action.payload.title)
            .setIn(['themeTrend', 'themeCode'], action.payload.themeCode)
            .setIn(['themeTrend', 'dataPoints'], action.payload.dataPoints);
    }

    case EMPLOYER_LOGIN_CLEAR_TREND:
    case EMPLOYER_ASSESSMENT_CLEAR_THEME_TREND: {
        return state
            .setIn(['themeTrend', 'resolving'], false)
            .setIn(['themeTrend', 'chart'], null)
            .setIn(['themeTrend', 'status'], 'OK')
            .setIn(['themeTrend', 'title'], null)
            .setIn(['themeTrend', 'themeCode'], null)
            .setIn(['themeTrend', 'dataPoints'], null);
    }


    default:
        return state;
    }
};

export { initialState };

export default employerTrackerReducer;
