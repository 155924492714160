import * as Immutable from 'immutable';
import _ from 'lodash';
import {
    COMPANY_CONTACTS_REQUESTED,
    COMPANY_CONTACTS_RESOLVED,
    COMPANY_CONTACTS_ERROR,
    DELETE_COMPANY_CONTACT_REQUESTED,
    DELETE_COMPANY_CONTACT_RESOLVED,
    DELETE_COMPANY_CONTACT_ERROR,
    ACTIVATE_COMPANY_CONTACT_REQUESTED,
    ACTIVATE_COMPANY_CONTACT_RESOLVED,
    ACTIVATE_COMPANY_CONTACT_ERROR,
    GET_COMPANY_CONTACT_REQUESTED,
    GET_COMPANY_CONTACT_RESOLVED,
    GET_COMPANY_CONTACT_ERROR,
    UPDATE_COMPANY_CONTACT_REQUESTED,
    UPDATE_COMPANY_CONTACT_RESOLVED,
    UPDATE_COMPANY_CONTACT_ERROR,
    UPDATE_COMPANY_CONTACT_VISIBILITY_REQUESTED,
    UPDATE_COMPANY_CONTACT_VISIBILITY_RESOLVED,
    UPDATE_COMPANY_CONTACT_VISIBILITY_ERROR,
    COMPANY_CONTACT_RESET,
} from '../actions/company-contacts/types';
import getContent from '../services/content/getContent';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    contacts: null,
    contactStatus: 'INIT',
    contactResolving: false,
    contactVisibilityResolving: false,
    contact: null,
};

const mapStatus = status => getContent(['members', 'status', status]);

const initialState = Immutable.Map(schema);
/**
 * The company contacts reducer - responsible for managing the state of the company contacts data
 * @param {Object} state
 * @param {Object} action
 */
const companyContactsReducer = (state = initialState, action) => {
    switch (action.type) {
    case COMPANY_CONTACTS_REQUESTED:
    case DELETE_COMPANY_CONTACT_REQUESTED:
    case ACTIVATE_COMPANY_CONTACT_REQUESTED:
        return state.set('resolving', true);

    case DELETE_COMPANY_CONTACT_RESOLVED:
    case ACTIVATE_COMPANY_CONTACT_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('contacts', action.payload.map(contact => ({
                ...contact,
                statusLabel: mapStatus(contact.status),
            })));

    case COMPANY_CONTACTS_RESOLVED:
        return state
            .set('resolving', false)
            .set('contactVisibilityResolving', false)
            .set('status', 'OK')
            .set('contacts', action.payload.map(contact => ({
                ...contact,
                statusLabel: mapStatus(contact.status),
            })));

    case COMPANY_CONTACTS_ERROR:
    case DELETE_COMPANY_CONTACT_ERROR:
    case ACTIVATE_COMPANY_CONTACT_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case GET_COMPANY_CONTACT_REQUESTED:
    case UPDATE_COMPANY_CONTACT_REQUESTED:
        return state
            .set('contactResolving', true);

    case GET_COMPANY_CONTACT_RESOLVED:
    case UPDATE_COMPANY_CONTACT_RESOLVED:
        return state
            .set('contactResolving', false)
            .set('contactStatus', 'OK')
            .set('contact', action.payload);

    case GET_COMPANY_CONTACT_ERROR:
        return state
            .set('contactResolving', false)
            .set('contactStatus', 'ERROR')
            .set('contact', null);

    case UPDATE_COMPANY_CONTACT_ERROR:
        return state
            .set('contactResolving', false)
            .set('contactVisibilityResolving', false)
            .set('contactStatus', 'ERROR');

    case COMPANY_CONTACT_RESET:
        return state
            .set('contact', null)
            .set('contactResolving', false)
            .set('contactStatus', 'INIT');

    case UPDATE_COMPANY_CONTACT_VISIBILITY_RESOLVED:
        return state
            .set('contactVisibilityResolving', false)
            .set('status', 'OK')
            .update('contacts', contacts => contacts.map((contact) => {
                if (action.payload.contactId === contact.contactId) {
                    return _.merge(contact, { visibility: action.payload.visibility });
                }
                return contact;
            }));

    case UPDATE_COMPANY_CONTACT_VISIBILITY_REQUESTED:
        return state.set('contactVisibilityResolving', true);

    case UPDATE_COMPANY_CONTACT_VISIBILITY_ERROR:
        return state.set('contactVisibilityResolving', false);

    default:
        return state;
    }
};

export { initialState, mapStatus };

export default companyContactsReducer;
