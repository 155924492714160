import URLS from './urls';
import PILLARS from './pillars';
import MOBILE_APP from './mobile-app';

export { URLS };
export { PILLARS };
export { MOBILE_APP };

export default {
    URLS,
    PILLARS,
    MOBILE_APP,
};
