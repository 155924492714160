import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MuiButtonLink from '../../material-ui/MuiButtonLink/MuiButtonLink';
import MuiCard from '../../material-ui/MuiCard/MuiCard';
import MuiCardContent from '../../material-ui/MuiCardContent/MuiCardContent';
import getContent from '../../../services/content/getContent';
import piggyBankSVG from '../../../assets/images/icons/piggy-bank-icon.svg';
import cebrPNG from '../../../assets/images/services/financial-wellbeing/cebr.png';
import ServiceProviderCard from '../../service-provider-card/ServiceProviderCard';

const FinancialWellbeingAssessmentIntro = ({
    classes,
    onStartQuiz,
    onCancelQuiz,
}) => (
    <>
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} sm={12} md={11}>
                <Typography variant="h1" align="center" gutterBottom>
                    {getContent(['components', 'financialWellbeingAssessment', 'title'])}
                </Typography>
                <Typography variant="subtitle1" align="center" gutterBottom>
                    {getContent(['components', 'financialWellbeingAssessment', 'description'])}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={11}>
                <MuiCard variant="gradient">
                    <MuiCardContent className={classes.infoContent}>

                        <Typography variant="h3" color="textSecondary" align="center" gutterBottom className={classes.infoContentTitle}>
                            {getContent(['components', 'financialWellbeingAssessment', 'introPanel', 'title'])}
                        </Typography>

                        <img
                            src={piggyBankSVG}
                            className={classes.infoContentLogo}
                            alt={getContent(['components', 'financialWellbeingAssessment', 'introPanel', 'title'])}
                        />

                        <Typography variant="body1" align="center" gutterBottom>
                            {getContent(['components', 'financialWellbeingAssessment', 'introPanel', 'description', 0])}
                        </Typography>
                        <Typography variant="body1" align="center" gutterBottom>
                            {getContent(['components', 'financialWellbeingAssessment', 'introPanel', 'description', 1])}
                        </Typography>
                        <Typography variant="body1" align="center" gutterBottom>
                            {getContent(['components', 'financialWellbeingAssessment', 'introPanel', 'description', 2])}
                        </Typography>
                    </MuiCardContent>
                </MuiCard>
            </Grid>
        </Grid>
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            align="center"
        >
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <Button color="primary" variant="contained" size="large" fullWidth onClick={onStartQuiz} className={classes.questionnaireBtn}>
                    {getContent(['components', 'financialWellbeingAssessment', 'startQuestionnaireBtnLabel'])}
                </Button>
            </Grid>
        </Grid>
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            align="center"
        >
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <MuiButtonLink onClick={onCancelQuiz}>
                    {getContent(['components', 'financialWellbeingAssessment', 'tryAgainBtnLabel'])}
                </MuiButtonLink>
            </Grid>
        </Grid>
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            align="center"
            className={classes.providerCard}
        >
            <Grid item xs={12} sm={12} md={11}>
                <ServiceProviderCard
                    logo={cebrPNG}
                    title={getContent(['components', 'financialWellbeingAssessment', 'serviceProvider', 'serviceMessageTitle'])}
                    description={getContent(['components', 'financialWellbeingAssessment', 'serviceProvider', 'serviceMessageDescription'])}
                />
            </Grid>

        </Grid>
    </>
);

const styles = theme => ({
    intro: {
        marginTop: theme.spacing(4),
        '&:last-child': {
            marginTop: 0,
            marginBottom: theme.spacing(4),
        },
    },
    infoContent: {
        padding: theme.spacing(4),
        marginBottom: 0,
    },
    infoContentPrimary: {
        padding: theme.spacing(6, 3),
    },
    infoContentLogo: {
        display: 'block',
        margin: `0 auto ${theme.spacing(3)}px`,
    },
    infoContentTitle: {
        marginBottom: theme.spacing(3),
    },
    infoSecondaryContent: {
        backgroundColor: theme.palette.common.greyLight,
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    infoButton: {
        textTransform: 'uppercase',
        margin: `0 ${theme.spacing(1)}px`,
    },
    questionnaireBtn: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    providerCard: {
        marginTop: theme.spacing(4),
    },
    backButton: {
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
});

FinancialWellbeingAssessmentIntro.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onCancelQuiz: PropTypes.func.isRequired,
    onStartQuiz: PropTypes.func.isRequired,
};

export default withStyles(styles)(FinancialWellbeingAssessmentIntro);
