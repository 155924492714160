import getArticleFavouritesQuery from '../../services/graphQL/healthy-bites/getArticleFavouritesQuery';
import {
    HEALTHY_BITES_FAVOURITES_REQUESTED,
    HEALTHY_BITES_FAVOURITES_RESOLVED,
    HEALTHY_BITES_FAVOURITES_ERROR,
} from './types';

/**
 * Action helper which resolves the favourite articles to the redux store
 * @param {String} articleId
 */

const getArticleFavouritesAction = async () => async (dispatch, getState) => {
    const { healthyBites } = getState();

    if (healthyBites.getIn(['favouriteArticles', 'resolving'])) return false;

    dispatch({
        type: HEALTHY_BITES_FAVOURITES_REQUESTED,
    });

    try {
        const payload = await getArticleFavouritesQuery();
        return dispatch({
            type: HEALTHY_BITES_FAVOURITES_RESOLVED,
            payload,
        });
    } catch (error) {
        return dispatch({
            type: HEALTHY_BITES_FAVOURITES_ERROR,
        });
    }
};

export default getArticleFavouritesAction;
