import { gql } from '@apollo/client';
import getContent from '../../content/getContent';
import graphQLCLient from '../client';
import errorHandler from '../errorHandler';

const getMemberQuery = async () => {
    const client = graphQLCLient();

    const query = gql`{
        getMember {
            contactId
            createdAt
            breeze {
                lastLogin
                registered
            }
            status
            lastModified
            emailAddress
            salutation
            firstName
            lastName
            birthDate
            gender
            mobilePhone
            telephone
            address {
                address1
                address2
                address3
                address4
                city
                region
                postcode
            }
            companyName
            consent {
                profiling
                marketing
                showOverlay
            }
            isCashPlanMember
        }
    }`;

    return new Promise((resolve, reject) => {
        client.query({ query })
            .then((result) => {
                if (!result.data || !Object.prototype.hasOwnProperty.call(result.data, 'getMember')) {
                    throw new Error(getContent(['errors', 'invalid-graphQL-response']));
                }

                resolve(result.data.getMember);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};

export default getMemberQuery;
