export const COMPANY_MEMBERS_REQUESTED = 'COMPANY_MEMBERS_REQUESTED';
export const COMPANY_MEMBERS_RESOLVED = 'COMPANY_MEMBERS_RESOLVED';
export const COMPANY_MEMBERS_ERROR = 'COMPANY_MEMBERS_ERROR';

export const COMPANY_MEMBERS_COUNT_REQUESTED = 'COMPANY_MEMBERS_COUNT_REQUESTED';
export const COMPANY_MEMBERS_COUNT_RESOLVED = 'COMPANY_MEMBERS_COUNT_RESOLVED';
export const COMPANY_MEMBERS_COUNT_ERROR = 'COMPANY_MEMBERS_COUNT_ERROR';

export const COMPANY_MEMBERS_STATUS_UPDATE_REQUESTED = 'COMPANY_MEMBERS_STATUS_UPDATE_REQUESTED';
export const COMPANY_MEMBERS_STATUS_UPDATE_RESOLVED = 'COMPANY_MEMBERS_STATUS_UPDATE_RESOLVED';
export const COMPANY_MEMBERS_STATUS_UPDATE_ERROR = 'COMPANY_MEMBERS_STATUS_UPDATE_ERROR';

export const COMPANY_MEMBERS_SEND_INVITE_REQUESTED = 'COMPANY_MEMBERS_SEND_INVITE_REQUESTED';
export const COMPANY_MEMBERS_SEND_INVITE_RESOLVED = 'COMPANY_MEMBERS_SEND_INVITE_RESOLVED';
export const COMPANY_MEMBERS_SEND_INVITE_ERROR = 'COMPANY_MEMBERS_SEND_INVITE_ERROR';

export const COMPANY_MEMBERS_STATUS_REQUESTED = 'COMPANY_MEMBERS_STATUS_REQUESTED';
export const COMPANY_MEMBERS_STATUS_RESOLVED = 'COMPANY_MEMBERS_STATUS_RESOLVED';
export const COMPANY_MEMBERS_STATUS_ERROR = 'COMPANY_MEMBERS_STATUS_ERROR';

export const COMPANY_MEMBERS_FILTERED_REQUESTED = 'COMPANY_MEMBERS_FILTERED_REQUESTED';
export const COMPANY_MEMBERS_FILTERED_RESOLVED = 'COMPANY_MEMBERS_FILTERED_RESOLVED';
export const COMPANY_MEMBERS_FILTERED_ERROR = 'COMPANY_MEMBERS_FILTERED_ERROR';
