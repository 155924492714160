import moment from 'moment';
import _ from 'lodash';
import getContent from '../content/getContent';

export const INTERNAL_DATE_FORMAT = 'DD/MM/YYYY';
export const INTERNAL_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const INCOMING_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const OUTGOING_DATE_FORMAT = 'YYYY-MM-DD';
export const OUTGOING_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const OUTGOING_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**
 * @param  {mixed} value
 * @return {String}
 */
const required = value => (value && value.toString().match(/^\s*\S+.*/) ? undefined : getContent(['form', 'validation', 'requiredLabel']));
/**
 * @param  {mixed} value
 * @return {String}
 */
// eslint-disable-next-line no-control-regex, max-len, no-useless-escape
const validEmail = value => (value && value.toLowerCase().match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)
    ? undefined : getContent(['form', 'validation', 'invalidEmailLabel']));
/**
 * @param  {Number} min
 * @return {String}
 */
const minValue = min => value => ((value && parseFloat(value) <= min) || value === 0 ? `Must be more than ${min}` : undefined);
/**
 * @param  {Number} max
 * @return {String}
 */
const maxValue = max => value => (value && parseFloat(value) > max ? `Must be less than ${max}` : undefined);
/**
 * @param  {Moment} value
 * @return {String}
 */
const validDate = value => (!(value && typeof value.isValid === 'function' && value.isValid())
    || !value ? getContent(['form', 'validation', 'invalidDateLabel']) : undefined);

/**
 * Valid dob = >= 16 && <= 100
 * @param  {String} value
 * @return {String}
 */
const validDOB = (value) => {
    const dob = moment(value, INTERNAL_DATE_FORMAT);
    const age = moment().diff(dob, 'years');
    return (!dob.isValid() || age < 16 || age > 100 ? getContent(['form', 'validation', 'invalidDOBLabel']) : undefined);
};

/**
 * Valid future date
 * @param  {String} value
 * @return {String}
 */
// eslint-disable-next-line max-len
const validFutureDate = (value) => {
    const date = moment(value, INTERNAL_DATE_FORMAT);
    return (!date.isValid() || date.isBefore(moment().startOf('day')) ? getContent(['form', 'validation', 'invalidFutureDateLabel']) : undefined);
};

/**
 * @param  {String} value
 * @return {String}
 */
const validDateString = value => (!moment(value, INTERNAL_DATE_FORMAT).isValid() ? getContent(['form', 'validation', 'invalidDateLabel']) : undefined);
/**
 * @param  {Number} min
 * @return {String}
 */
const minLength = min => value => ((value && value.length < min) || !value ? `A minimum of ${min} characters` : undefined);
/**
 * @param  {Number} max
 * @return {String}
 */
const maxLength = max => value => (value && value.length > max ? `A maximum of ${max} characters` : undefined);
/**
 * @param  {mixed} value
 * @return {String}
 */
const containsUppercaseCharacter = value => (!/[A-Z]/.test(value) ? 'At least one upper case letter' : undefined);
/**
 * @param  {mixed} value
 * @return {String}
 */
const containsNumber = value => (!/[0-9]/.test(value) ? 'At least one number' : undefined);
/**
 * @param  {String} name
 * @return {String}
 */
const doesNotContainField = name => (value, allValues) => ((allValues && allValues[name] && value && value.indexOf(allValues[name]) !== -1)
    ? `Must not contain your ${name}` : undefined);
/**
 * @param  {String} name
 * @return {String}
 */
const matchesField = name => (value, allValues) => ((allValues && allValues[name] && allValues[name] !== value) ? `${name} must match` : undefined);

/**
 * @param  {Array} fields { name, validation }
 * @return {String}
 */
const requiresValidField = (fields, message) => (value, allValues) => {
    const emptyFields = fields.filter((field) => {
        const { name } = field;
        if ((!allValues || !_.get(allValues, name)) || (allValues && _.get(allValues, name) && !_.get(allValues, name).toString().match(/^\s*\S+.*/))) {
            return true;
        }
        return false;
    });
    const result = fields.filter((field) => {
        const { name, validation } = field;
        // If the value is empty or if the value is not empty and valid
        if (allValues && _.get(allValues, name) && validation(_.get(allValues, name)) === undefined) {
            return false;
        } if (allValues && _.get(allValues, name) && _.get(allValues, name).toString().match(/^\s*\S+.*/)) {
            return true;
        }
        return false;
    });
    // return [emptyFields.length, fields.length];
    return (result.length > 0 || emptyFields.length === fields.length) ? (message || 'Required') : undefined;
};

/**
 * Is the value empty or passes validation
 *
 * @param  {Array} validations
 * @return {String}
 */
const emptyOrValidate = validations => (value) => {
    if (value && value.toString().match(/^\s*\S+.*/)) {
        const invalidMessages = validations
            .filter(validation => validation(value) !== undefined)
            .map(validation => validation(value));
        return invalidMessages.length > 0 ? invalidMessages[0] : undefined;
    }
    return undefined;
};

/**
 * @param  {String} value
 * @return {String]}
 */
// eslint-disable-next-line max-len
const validMobile = value => (!/(^(\+44\s?(?:\s*\d\s*){9,10}|0044\s?(?:\s*\d\s*){9,10}|0\s?(?:\s*\d\s*){9,10}){1,}$)/.test(value) ? 'A valid mobile number' : undefined);
/**
 * @param  {String} value
 * @return {String]}
 */
// eslint-disable-next-line max-len
const validPhone = value => (!/(^(\+44\s?(?:\s*\d\s*){9,10}|0044\s?(?:\s*\d\s*){9,10}|0\s?(?:\s*\d\s*){9,10}){1,}$)/.test(value) ? 'A valid phone number' : undefined);
/**
 * @param  {String} value
 * @return {String}
 */
// eslint-disable-next-line max-len
const validPostcode = value => (!/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/.test(value) ? 'A valid postcode' : undefined);
/**
 * @param  {String} value
 * @return {String}
 */
// eslint-disable-next-line max-len
const validCompanyRegistration = value => (!/^$|^(AC|FC|GE|GN|GS|IC|IP|LP|NA|NF|NI|NL|NO|NP|NR|NZ|OC|RC|SA|SC|SF|SI|SL|SO|SP|SR|SZ|ZC)\d{6}$|^R\d{7}$|^\d{8}$/.test(value) ? 'Invalid registration number' : undefined);
/**
 * @param  {mixed} value
 * @return {String}
 */
const requiredYesNo = value => (value !== undefined ? undefined : getContent(['form', 'validation', 'requiredLabel']));

/**
 * @param  {mixed} value
 * @return {String}
 */
const requiredTrueFalse = value => (typeof value === 'boolean' ? undefined : getContent(['form', 'validation', 'requiredLabel']));

/**
 * Is the time after another time
 * @param  {String} name
 * @return {String}
 */
const isTimeAfter = name => (value, allValues) => {
    const errorMsg = 'End Time must be after Start Time';

    if (allValues && allValues[name]) {
        // Split the start time and end time
        const endTime = moment(new Date(value)).format('HH:mm').split(':');
        const startTime = moment(new Date(allValues[name])).format('HH:mm').split(':');

        if (startTime.length === 2 && endTime.length === 2) {
            const result = moment().startOf('day').add(endTime[0], 'hours').add(endTime[1], 'minutes')
                .isAfter(moment().startOf('day').add(startTime[0], 'hours').add(startTime[1], 'minutes'));

            return result ? undefined : errorMsg;
        }
        return errorMsg;
    }
    return errorMsg;
};

const isTimeInFuture = (value) => {
    const errorMsg = 'The selected time must be in the future';
    const time = value.split(':');
    if (time.length === 2) {
        const result = moment()
            .hour(time[0])
            .minute(time[1])
            .second(0)
            .isAfter(moment());
        return result ? undefined : errorMsg;
    }
    return errorMsg;
};

const isDateBefore = name => (value, allValues) => {
    const errorMsg = 'Date must be before start date';

    if (allValues && allValues[name]) {
        const endDate = moment(value, INTERNAL_DATE_FORMAT);
        const startDate = moment(allValues[name], INTERNAL_DATE_FORMAT);
        return (!endDate.isValid() || startDate.isBefore(endDate.startOf('day')) ? getContent(['form', 'validation', 'invalidDateBeforeLabel']) : undefined);
    }
    return errorMsg;
};

// eslint-disable-next-line no-control-regex, max-len, no-useless-escape
const validUrl = value => (!/^$|^(\b([Hh][Tt][Tt][Pp][Ss]?|[Hh][Tt][Tt][Pp]):\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/.test(value) ? 'Invalid company Url' : undefined);

export {
    required,
    minValue,
    maxValue,
    validEmail,
    validDate,
    minLength,
    maxLength,
    containsUppercaseCharacter,
    containsNumber,
    doesNotContainField,
    matchesField,
    requiresValidField,
    validMobile,
    validPhone,
    validPostcode,
    requiredYesNo,
    validDateString,
    validDOB,
    validFutureDate,
    validCompanyRegistration,
    isTimeAfter,
    isTimeInFuture,
    isDateBefore,
    emptyOrValidate,
    validUrl,
    requiredTrueFalse,
};
