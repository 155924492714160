import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication, InteractionType, EventType } from '@azure/msal-browser';
import { AUTH_SET_ACCOUNT } from '../../actions/auth/types';
import StaticLoader from '../loader/StaticLoader/StaticLoader';
import AutomaticLogout from '../automatic-logout/AutomaticLogout';
import Page from '../app/Page/Page';

const UserMsal = ({
    authProvider,
    children,
    config,
    resetConfig,
    request,
    UserWrapper,
}) => {
    const [msalInstance, setMsalInstance] = useState();
    const [resolving, setResolving] = useState(true);
    const [passwordReset, setPasswordReset] = useState(false);
    const dispatch = useDispatch();

    const getToken = () => {
        // If we don't have a token (e.g. from a refresh we need to get one)
        msalInstance.acquireTokenSilent(request).then((response) => {
            const { account } = response;
            msalInstance.setActiveAccount(account);
            dispatch({ type: AUTH_SET_ACCOUNT, payload: response });
        }).catch(() => {
            // If there's an error with auth then clear session and try and re login
            // Happens when user goes from admin to member idm
            sessionStorage.clear();
            msalInstance.acquireTokenRedirect(request);
        });
    };

    const logout = async () => {
        const currentAccount = msalInstance.getActiveAccount();
        await msalInstance.logoutRedirect({
            account: currentAccount,
        });
    };

    useEffect(() => {
        // check if password reset redirect
        authProvider.setAuthProviderConfig(config);
        const authConfig = new PublicClientApplication(config);
        setMsalInstance(authConfig);
    }, []);

    useEffect(() => {
        if (msalInstance) {
            msalInstance.addEventCallback((event) => {
                if (event.eventType === EventType.LOGIN_FAILURE && event.interactionType === InteractionType.Redirect) {
                    if (event.error.errorMessage && event.error.errorMessage.indexOf('AADB2C90118') !== -1) {
                        setPasswordReset(true);
                    }
                }
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    const { payload: { account } } = event;
                    msalInstance.setActiveAccount(account);
                }
                if (event.eventType === EventType.HANDLE_REDIRECT_END) {
                    setResolving(false);
                }
                dispatch({ type: event.eventType, payload: event.payload });
            });
        }
    }, [msalInstance]);

    useEffect(() => {
        if (passwordReset) {
            const newMsalInstance = new PublicClientApplication(resetConfig);
            setMsalInstance(newMsalInstance);
        }
    }, [passwordReset]);

    if (!msalInstance) return null;

    return (
        <MsalProvider instance={msalInstance}>
            <UnauthenticatedTemplate>
                <StaticLoader />
            </UnauthenticatedTemplate>
            <UserWrapper resolving={resolving} getToken={getToken}>
                <Page logout={logout}>
                    <AutomaticLogout logout={logout}>
                        {children}
                    </AutomaticLogout>
                </Page>
            </UserWrapper>
        </MsalProvider>
    );
};

UserMsal.propTypes = {
    authProvider: PropTypes.shape({
        setAuthProviderConfig: PropTypes.func,
    }).isRequired,
    config: PropTypes.shape().isRequired,
    resetConfig: PropTypes.shape().isRequired,
    request: PropTypes.shape().isRequired,
    children: PropTypes.node.isRequired,
    UserWrapper: PropTypes.func.isRequired,
};

export default UserMsal;
