import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { SectionDescription, Container } from '../../layout';
import Icon from '../../icon/Icon';
import { getWellbeingThemeId, getTrackerThemeIcon, getColorBanding } from '../../../services/tracker/tracker';
import getContent from '../../../services/content/getContent';
import QuestionnaireResultsRecommendation from '../QuestionnaireResultsRecommendation/QuestionnaireResultsRecommendation';
import QuestionnaireArticlesLink from '../QuestionnaireArticlesLink/QuestionnaireArticlesLink';
import { MuiButtonLink } from '../../material-ui';

const QuestionnaireResultsRecommendations = ({
    classes,
    recommendations,
    moreRecommendations,
}) => {
    const [showMoreArticles, setShowMoreArticles] = useState(false);

    const renderList = (listItems, hasBackground) => listItems.map((recommendation, index) => {
        const { category, banding } = recommendation;
        const icon = getTrackerThemeIcon(getWellbeingThemeId({ name: category }));
        const performance = {
            color: getColorBanding(banding),
            title: getContent(['components', 'wellbeingTracker', 'banding', banding]),
        };
        return (
            <Box key={recommendation.article.id} className={`${index > 0 || hasBackground ? classes.containerFill : ''} ${classes.container}`}>
                <Container>
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <Grid item xs={12} sm={4} md={3}>
                            <Box className={classes.box}>
                                <Typography variant="h4" className={classes.title} align="center">
                                    {category}
                                </Typography>
                                <Typography variant="body2" gutterBottom className={classes.performance} style={{ color: performance.color }} align="center">
                                    {performance.title}
                                </Typography>
                                {!!icon && <Box className={classes.iconContainer}><Icon src={icon} alt={category} height={85} /></Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9}>
                            <QuestionnaireResultsRecommendation recommendation={recommendation} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        );
    });

    return (
        <Box className={`${classes.root} ${classes.container}`}>
            <Container>
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <SectionDescription>Recommendations for you.</SectionDescription>
                    </Grid>
                </Grid>
            </Container>
            {((recommendations && recommendations.length <= 1) || !recommendations) && <Container><QuestionnaireArticlesLink /></Container>}
            {recommendations && recommendations.length > 0 && renderList(recommendations)}
            {moreRecommendations && moreRecommendations.length > 0 && !showMoreArticles && (
                <Container>
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} className={classes.btnContainer}>
                            <MuiButtonLink onClick={() => setShowMoreArticles(true)} size="large" color="primary">Show more +</MuiButtonLink>
                        </Grid>
                    </Grid>
                </Container>
            )}
            {showMoreArticles && renderList(moreRecommendations, true)}
        </Box>
    );
};

const styles = theme => ({
    root: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 'calc(50% - 3.5px)',
            top: -100,
            width: 1,
            height: 100,
            border: `1px dashed ${theme.palette.common.green}`,
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            left: 'calc(50% - 10px)',
            top: -7.5,
            width: 15,
            height: 15,
            backgroundColor: theme.palette.common.green,
            borderRadius: '50%',
        },
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    containerFill: {
        background: theme.palette.background.greyLightFadePinTop,
        backgroundPosition: '0 0',
    },
    box: {
        alignItems: 'center',
    },
    title: {
        marginBottom: theme.spacing(0.5),
        fontFamily: theme.typography.h1.fontFamily,
        fontWeight: 400,
    },
    performance: {
        fontWeight: 'bold',
    },
    iconContainer: {
        width: '100%',
        '& img': {
            minHeight: 60,
            maxWidth: 100,
            maxHeight: 60,
        },
    },
    btnContainer: {
        textAlign: 'center',
        '& button': {
            fontSize: theme.typography.h4.fontSize,
        },
    },
});

QuestionnaireResultsRecommendations.defaultProps = {
    moreRecommendations: [],
};

QuestionnaireResultsRecommendations.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    recommendations: PropTypes.arrayOf(PropTypes.shape({
        article: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            introduction: PropTypes.string,
            thumbnailImage: PropTypes.string,
            videoUrl: PropTypes.string,
            authors: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string,
            })),
        }),
        category: PropTypes.string,
        banding: PropTypes.string,
    })).isRequired,
    moreRecommendations: PropTypes.arrayOf(PropTypes.shape({
        article: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            introduction: PropTypes.string,
            thumbnailImage: PropTypes.string,
            videoUrl: PropTypes.string,
            authors: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string,
            })),
        }),
        category: PropTypes.string,
        banding: PropTypes.string,
    })),
};

export default withStyles(styles)(QuestionnaireResultsRecommendations);
