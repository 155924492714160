import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * This service should add ids to buttons and links so it makes it easier to target
 * using the external e2e development tools
 * Every interval it will run the script to add ids
 */
const setIdAttribute = (e, index) => {
    e.setAttribute('id', `${e.textContent.toLowerCase().replace(/[\W_]+/g, '')}_${index}`);
};

const setIdAttributes = () => {
    document.querySelectorAll('button, a').forEach((e, index) => {
        if (!e.hasAttribute('id') && e.textContent) {
            setIdAttribute(e, index);
        }
    });
};

export const addIdAttributes = () => {
    setInterval(() => {
        setIdAttributes();
    }, 10000);
};

/**
 * When the location changes we want to run the add ids to elements
 */
const e2eHelper = () => {
    const history = useHistory();
    useEffect(() => history.listen(() => {
        addIdAttributes();
    }), [history]);

    setTimeout(() => setIdAttributes(), 10000);

    return null;
};

export default e2eHelper;
