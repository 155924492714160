import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';

const DrawerMenu = ({
    classes,
    children,
}) => {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (side, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            {children}
        </div>
    );

    return (
        <div>
            <Button onClick={toggleDrawer('right', true)}>
                <MenuIcon className={classes.menuIcon} />
            </Button>
            <Drawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer('right', false)}
                SlideProps={{ timeout: 400 }}
                className={classes.drawer}
            >
                {sideList('right')}
            </Drawer>
        </div>
    );
};

const styles = theme => ({
    list: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        '& a': {
            width: '100%',
            display: 'block',
            textAlign: 'right',
        },
    },
    menuIcon: {
        color: theme.palette.common.black,
    },
});

DrawerMenu.defaultProps = {
    children: null,
};

DrawerMenu.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node,
};

export default withStyles(styles)(DrawerMenu);
