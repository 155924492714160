import common from './common';
import primary from './primary';
import secondary from './secondary';
import error from './error';
import text from './text';
import background from './background';
import performance from './performance';

export default {
    common,
    primary,
    secondary,
    error,
    text,
    background,
    performance,
};
