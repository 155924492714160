import * as Immutable from 'immutable';
import {
    COMPANY_MEMBER_REQUESTED,
    COMPANY_MEMBER_RESOLVED,
    COMPANY_MEMBER_ERROR,
    COMPANY_MEMBER_UPDATE_REQUESTED,
    COMPANY_MEMBER_UPDATE_RESOLVED,
    COMPANY_MEMBER_UPDATE_ERROR,
    COMPANY_MEMBER_UNREGISTERED_REQUESTED,
    COMPANY_MEMBER_UNREGISTERED_RESOLVED,
    COMPANY_MEMBER_UNREGISTERED_ERROR,
    COMPANY_MEMBER_RESET,
} from '../actions/company-member/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    member: null,
    unregisteredMember: null,
};

const initialState = Immutable.Map(schema);
/**
 * The company Member reducer - responsible for managing a member of a company and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const companyMemberReducer = (state = initialState, action) => {
    switch (action.type) {
    case COMPANY_MEMBER_UPDATE_REQUESTED:
    case COMPANY_MEMBER_REQUESTED:
    case COMPANY_MEMBER_UNREGISTERED_REQUESTED:
        return state.set('resolving', true);

    case COMPANY_MEMBER_UPDATE_RESOLVED:
    case COMPANY_MEMBER_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('member', action.payload);

    case COMPANY_MEMBER_UNREGISTERED_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('unregisteredMember', action.payload);

    case COMPANY_MEMBER_ERROR:
    case COMPANY_MEMBER_UNREGISTERED_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('member', null)
            .set('unregisteredMember', null);

    case COMPANY_MEMBER_UPDATE_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    case COMPANY_MEMBER_RESET:
        return initialState;

    default:
        return state;
    }
};

export { initialState };

export default companyMemberReducer;
