import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import shortid from 'shortid';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import {
    stableSort,
    getSortingOrder,
    computeDisplayRow,
} from '../../../services/table/table';
import MuiTablePagination from '../MuiTablePagination/MuiTablePagination';

/**
 * Set a pagination prototype to the array object
 *
 * @param  {Bool} paginate
 * @param  {Number} page
 * @param  {Number} rowsPerPage
 * @return {Array}
 */
function arrayPaginate(paginate, page, rowsPerPage) {
    return paginate ? this.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : this;
}
// Set the array prototype
// eslint-disable-next-line no-extend-native
Array.prototype.paginate = arrayPaginate;

class MuiTable extends React.Component {
    constructor(props) {
        super(props);
        /**
         * The default options for the table
         * @type {Object}
         */
        this.defaultOptions = {
            filters: {},
            page: 0,
            order: 'asc',
            orderBy: '',
            rowsPerPage: 10,
            searchText: '',
            pagination: true,
            selectableRows: false,
            selectAllRows: false,
            selectedRows: {
                selected: [],
                uidKey: null,
                uidIndex: 0,
            },
        };
        // Set the state by merging the default options with props
        this.state = {
            ...this.defaultOptions,
        };
        // Bind the handler functions
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.tableEl = React.createRef();
    }

    componentDidMount() {
        const { columns, options, options: { selectAllRows, selectableRows } } = this.props;
        const { order, orderBy, filters, selectedRows: { selected } } = this.state;
        const currentState = this.state;
        // Find the uid index from the columns collection
        const uidIndex = columns.findIndex(column => column.options.uid);
        // Set the seleted rows object
        const selectedRows = {
            selected,
            uidIndex,
            uidKey: columns[uidIndex].name,
        };
        // Find and set the default sort column
        const defaultSortColumn = columns.find(column => column.options && column.options.sortDirection);
        // Set the defaults
        let nextOrder = order;
        let nextOrderBy = orderBy;
        // If theres a default sort column
        if (defaultSortColumn) {
            nextOrder = defaultSortColumn.options.sortDirection;
            nextOrderBy = defaultSortColumn.name;
        }
        // Set the state with the updated props
        this.setState({
            ...currentState, // add current state
            ..._.pick(options, _.keys(this.defaultOptions)), // merge in the defaults
            ...{
                selectedRows,
                order: nextOrder,
                orderBy: nextOrderBy,
                filters, 
            },
        });
        // If the option for select all rows is set the trigger the select action
        if (selectAllRows && selectableRows) {
            this.handleRowSelectAllClick({ target: { checked: true } });
        }
    }

    shouldComponentUpdate = nextProps => nextProps.actionMenuOpen !== true;

    componentDidUpdate(prevProps, prevState) {
        const { selectedRows: { selected }, selectAllRows } = this.state;
        const { selectedRows: { selected: prevSelected } } = prevState;
        const { onSelectChange, data } = this.props;
        const { data: prevData } = prevProps;
        // If the selected has changed then call the on select change func
        if (onSelectChange && selected !== prevSelected) {
            onSelectChange(selected);
        }
        if (selectAllRows && data.length > 0 && data.length !== prevData.length) {
            this.handleRowSelectAllClick({ target: { checked: true } });
        }
    }

    /**
     * Handle the toggle to make rows selectable
     *
     * @param  {Bool} selectableRows
     */
    handleSelectableChange = (selectableRows) => {
        this.setState({ selectableRows });
    }

    /**
     * Handle the search term input
     *
     * @param  {String} searchText
     */
    handleSearchChange = (searchText) => {
        const { selectedRows } = this.state;
        this.setState({ searchText, selectedRows: { ...selectedRows, selected: [] }, page: 0 });
    }

    /**
     * Handle the page change
     *
     * @param  {Event} event
     * @param  {Number} page
     */
    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    /**
     * Handle the change of number of rows
     *
     * @param  {Event} event
     */
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
        // Scroll to the top of the table
        setTimeout(() => {
            window.scrollTo(0, this.tableEl.current.offsetTop);
        }, 100);
    };

    /**
     * Handle the change of sort column
     *
     * @param  {Event} event
     * @param  {String} nextOrderBy
     */
    handleSortChange = (event, nextOrderBy) => {
        const { orderBy, order } = this.state;
        let setOrder = order;
        if (orderBy === nextOrderBy) {
            setOrder = order === 'asc' ? 'desc' : 'asc';
        }
        this.setState({
            order: setOrder,
            orderBy: nextOrderBy,
        });
    };

    /**
     * Handle the filter change
     *
     * @param  {String} property
     * @param  {String} value
     * @param  {Bool} reset
     * @return {Object}
     */
    handleFilterChange = (property, value, reset) => {
        const { selectedRows, filters } = this.state;
        // Set the nextFitlers to current filters
        let nextFilters = filters;
        // Check if the filter already exists and remove it
        if (nextFilters[property] && nextFilters[property].indexOf(value) !== -1) {
            nextFilters[property].splice(nextFilters[property].indexOf(value), 1);
            if (nextFilters[property].length <= 0) delete nextFilters[property];
            // Set the state nextFilters and reset the selected array
            this.setState({ nextFilters, selectedRows: { ...selectedRows, selected: [] }, page: 0 });
        } else {
            // If the reset flag is set then clear nextFilters
            if (reset) {
                nextFilters = {};
            }
            // If the filter property exists then push the new filter into its array
            // Else add the new propery and push the initial filter
            if (nextFilters[property]) {
                nextFilters[property].push(value);
            } else {
                nextFilters[property] = [value];
            }
            // Set the state filters and reset the selected array
            this.setState({ filters: nextFilters, selectedRows: { ...selectedRows, selected: [] }, page: 0 });
        }
        return nextFilters;
    };

    /**
     * Handle when a row is clicked on
     *
     * @param  {Event} event
     * @param  {String} id
     */
    handleRowSelectClick = (event, id) => {
        const { selectedRows, selectedRows: { selected } } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selectedRows: { ...selectedRows, selected: newSelected } });
    };

    /**
     * Handle the select all rows click
     *
     * @param  {Event} event
     */
    handleRowSelectAllClick = (event) => {
        const { columns } = this.props;
        const { selectedRows, selectedRows: { uidIndex } } = this.state;
        if (event.target.checked) {
            const { data } = this.props;
            // Apply all the filters to the rows
            const processedRows = this.processRows(data, columns);
            // Add all of the selected row uids to the selected array
            this.setState({ selectedRows: { ...selectedRows, selected: processedRows.map(row => row[uidIndex]) } });
        } else {
            // Clear the selected array
            this.setState({ selectedRows: { ...selectedRows, selected: [] } });
        }
    };

    /**
     * Is the row id in the state selected array?
     *
     * @param  {String} id
     * @return {Bool}
     */
    isSelected = (id) => {
        const { selectedRows } = this.state;
        return selectedRows.selected.indexOf(id) !== -1;
    };

    /**
     * Process the rows and filter / paginate
     *
     * @param  {Array} data
     * @param  {Array} columns
     * @return {Array}
     */
    processRows = (data, columns) => {
        const {
            order, orderBy, searchText, filters,
        } = this.state;
        return stableSort(data, getSortingOrder(order, _.findIndex(columns, { name: orderBy })))
            .filter((row, rowIndex) => computeDisplayRow(columns, row, rowIndex, searchText, filters));
    };

    /**
     * Render the cell
     *
     * @param  {String} cell
     * @param  {Number} columnIndex
     * @param  {String} searchText
     * @return {Mixed}
     */
    renderCell = (cell, columnIndex, columns, searchText, row) => {
        if (_.hasIn(columns[columnIndex], 'options.customBodyRender')) {
            return columns[columnIndex].options.customBodyRender(
                cell,
                columns,
                row,
                _.pick(this.state, ['order', 'orderBy', 'page', 'searchText', 'rowsPerPage', 'filters']),
            );
        } if (searchText.length > 0 && _.hasIn(columns[columnIndex], 'options.searchable')) {
            const re = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');
            // eslint-disable-next-line react/no-danger
            return <span dangerouslySetInnerHTML={{ __html: cell.replace(re, a => `<strong>${a}</strong>`) }} />;
        }
        return cell;
    };

    /**
     * Render the header cell
     *
     * @param  {Object} column
     * @return {Node}
     */
    renderHeaderCell = (column) => {
        const { orderBy, order } = this.state;

        if (column.options.sort) {
            return (
                <Tooltip
                    title="Sort"
                    placement="bottom-start"
                    enterDelay={300}
                >
                    <TableSortLabel
                        active={orderBy === column.name}
                        direction={order}
                        onClick={e => this.handleSortChange(e, column.name)}
                    >
                        {column.label}
                    </TableSortLabel>
                </Tooltip>
            );
        }
        return column.label;
    };

    /**
     * Render the header checkbox
     *
     * @return {Node}
     */
    renderHeaderCheckbox = () => {
        const { selectedRows: { selected } } = this.state;
        const { data, columns } = this.props;
        // Apply all the filters to the rows
        const processedRows = this.processRows(data, columns);
        return (
            <Checkbox
                indeterminate={selected.length > 0 && selected.length < processedRows.length}
                checked={selected.length > 0 && selected.length === processedRows.length}
                onChange={e => this.handleRowSelectAllClick(e)}
            />
        );
    }

    /**
     * Render the body checkbox
     *
     * @param  {Boolean} isSelected
     * @return {Node}
     */
    renderBodyCheckbox = isSelected => (<Checkbox checked={isSelected} />);

    render() {
        const {
            data, columns, options: { customToolbar }, overflowClass,
        } = this.props;
        const {
            filters,
            page,
            pagination,
            searchText,
            selectableRows,
            selectedRows,
            selectedRows: { uidIndex },
            rowsPerPage,
            orderBy,
        } = this.state;

        if (columns.length <= 0) return null;

        /**
         * Render the table headers
         *
         * @return {Node}
         */
        const renderHeaders = () => (
            <TableHead>
                <TableRow>
                    {selectableRows ? (
                        <TableCell padding="checkbox">
                            {this.renderHeaderCheckbox()}
                        </TableCell>
                    ) : null}
                    {columns
                        .filter(column => !_.hasIn(column, 'options.hide'))
                        .map(column => (
                            <TableCell key={column.name}>
                                {this.renderHeaderCell(column)}
                            </TableCell>
                        ))}
                </TableRow>
            </TableHead>
        );

        /**
         * Render the table pagination
         *
         * @return {Node}
         */
        const renderPagination = count => (
            <MuiTablePagination
                rowsPerPageOptions={[10, 15, 100]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
        );

        /**
         * Process the rows for display in the table
         *
         * @type {Array}
         */
        const processedRows = this.processRows(data, columns);

        return (
            <div>
                {customToolbar ? customToolbar({
                    filters,
                    searchText,
                    selectedRows,
                    onSearchChange: this.handleSearchChange,
                    onFilterChange: this.handleFilterChange,
                    onSelectableChange: this.handleSelectableChange,
                }) : null}
                <div className={overflowClass}>
                    <Table ref={this.tableEl}>
                        {renderHeaders()}
                        <TableBody>
                            {processedRows
                                .paginate(pagination, page, rowsPerPage)
                                .map((row) => {
                                    const isSelected = this.isSelected(row[uidIndex]);
                                    return (
                                        <TableRow
                                            key={row[uidIndex]}
                                            hover={selectableRows}
                                            onClick={event => (selectableRows ? this.handleRowSelectClick(event, row[uidIndex]) : null)}
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            selected={isSelected}
                                        >
                                            {selectableRows ? (<TableCell padding="checkbox">{this.renderBodyCheckbox(isSelected)}</TableCell>) : null}
                                            {row.map((cell, columnIndex) => (
                                                !_.hasIn(columns[columnIndex], 'options.hide')
                                                    ? (
                                                        <TableCell
                                                            key={`${columns[columnIndex].name}_${shortid.generate()}`}
                                                            align={_.hasIn(columns[columnIndex], 'options.align') ? _.get(columns[columnIndex], 'options.align') : 'left'}
                                                        >
                                                            {(_.get(columns[columnIndex], 'name') === orderBy)
                                                                ? (<strong>{this.renderCell(cell, columnIndex, columns, searchText, row)}</strong>)
                                                                : this.renderCell(cell, columnIndex, columns, searchText, row)
                                                            }
                                                        </TableCell>
                                                    ) : null
                                            ))}
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
                {(pagination ? renderPagination(processedRows.length) : null)}
            </div>
        );
    }
}

MuiTable.defaultProps = {
    columns: null,
    data: [],
    options: {
        pagination: true,
        selectableRows: false,
        selectAllRows: false,
        customToolbar: null,
    },
    overflowClass: '',
    onSelectChange: null,
    actionMenuOpen: false,
};

MuiTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
    data: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
        ),
    ),
    options: PropTypes.shape({
        pagination: PropTypes.bool,
        selectableRows: PropTypes.bool,
        selectAllRows: PropTypes.bool,
        customToolbar: PropTypes.ReactNode, // eslint-disable-line react/no-typos
    }),
    overflowClass: PropTypes.string,
    onSelectChange: PropTypes.func,
    actionMenuOpen: PropTypes.bool,
};

export default MuiTable;
