import * as Immutable from 'immutable';
import {
    CONTENT_CACHE_INVALIDATE,
    CONTENT_CACHE_INVALIDATE_ALL,
} from '../actions/content-cache/types';
import {
    MEMBER_DASHBOARD_RESOLVED,
    MEMBER_TRACKER_RESOLVED,
} from '../actions/member/types';
import {
    HEALTHY_BITES_CATEGORIES_RESOLVED,
    HEALTHY_BITES_ARTICLES_FEATURED_RESOLVED,
    HEALTHY_BITES_ARTICLES_RESOLVED,
} from '../actions/healthy-bites/types';
import {
    GP_FUTURE_APPOINTMENTS_RESOLVED,
} from '../actions/gp-booking/types';

export const contentCacheSchema = {
    [MEMBER_DASHBOARD_RESOLVED]: 0,
    [MEMBER_TRACKER_RESOLVED]: 0,
    [HEALTHY_BITES_CATEGORIES_RESOLVED]: 0,
    [HEALTHY_BITES_ARTICLES_FEATURED_RESOLVED]: 0,
    [HEALTHY_BITES_ARTICLES_RESOLVED]: 0,
    [GP_FUTURE_APPOINTMENTS_RESOLVED]: 0,
};

export const initialState = Immutable.Map(contentCacheSchema);

/**
 * The content cache reducer - responsible for managing content resolution time
 * @param {Object} state
 * @param {Object} action
 */
const contentCacheReducer = (state = initialState, action) => {
    switch (action.type) {
    case MEMBER_DASHBOARD_RESOLVED:
    case MEMBER_TRACKER_RESOLVED:
    case HEALTHY_BITES_CATEGORIES_RESOLVED:
    case HEALTHY_BITES_ARTICLES_FEATURED_RESOLVED:
    case HEALTHY_BITES_ARTICLES_RESOLVED:
    case GP_FUTURE_APPOINTMENTS_RESOLVED:
        return state.set(action.type, action.meta.resolveTime);
    case CONTENT_CACHE_INVALIDATE:
        return state.set(action.payload, 0);
    case CONTENT_CACHE_INVALIDATE_ALL:
        return initialState;
    default:
        return state;
    }
};

export default contentCacheReducer;
