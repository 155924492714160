import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import shortid from 'shortid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import getContent from '../../../services/content/getContent';

const QuestionnaireResultsPriorityPanel = ({ classes, answer }) => (
    <Card elevation={0} className={classes.card}>
        <CardHeader
            titleTypographyProps={{
                variant: 'h2',
                align: 'center',
                className: classes.header,
            }}
            title={answer.title}
        />
        <CardContent className={classes.content}>
            <List dense disablePadding>
                {answer.items && answer.items.map(item => (
                    <ListItem disableGutters className={classes.listItem} key={`answer-list-${shortid.generate()}`}>
                        <ListItemIcon className={classes.listIcon}>
                            <FiberManualRecordIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                            primary={item}
                            primaryTypographyProps={{
                                variant: 'body2',
                                color: 'textSecondary',
                                className: classes.listText,
                            }}
                        />
                    </ListItem>
                ))}
                {answer.items && answer.items.length === 0 && (
                    <ListItem className={`${classes.listItem} ${classes.listItemEmpty}`}>
                        <ListItemText
                            secondary={getContent(['components', 'QuestionnaireResultsSummaryPanel', 'emptyPanel', answer.title.toLowerCase().replace(/ /g, '')])}
                            secondaryTypographyProps={{
                                variant: 'body2',
                                align: 'center',
                            }}
                        />
                    </ListItem>
                )}
            </List>
        </CardContent>
    </Card>
);

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 15,
    },
    header: props => ({
        color: props.color,
        fontWeight: 400,
        fontFamily: theme.typography.h1.fontFamily,
    }),
    listIcon: {
        minWidth: 18,
        marginTop: 8,
    },
    listItem: {
        alignItems: 'flex-start',
    },
    icon: props => ({
        color: props.color,
        fontSize: 12,
    }),
    content: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.common.greyLight,
        flex: 1,
    },
    listText: {
        fontWeight: 'bold',
    },
    listItemEmpty: {
        width: '100%',
        paddingBottom: 0,
        minHeight: 0,
    },
});

styles.propTypes = {
    color: PropTypes.string.isRequired,
};

QuestionnaireResultsPriorityPanel.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    answer: PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export default withStyles(styles)(QuestionnaireResultsPriorityPanel);
