const MuiLink = {
    root: {

    },
    underlineHover: {
        textDecoration: 'underline',
    },
};

export default MuiLink;
