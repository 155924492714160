import { gql } from '@apollo/client';
import getWebSocketClient from '../getWebSocketClient';
import onCompanyMembersUploadStatus from '../../../actions/add-company-members/onCompanyMembersUploadStatus';
import store from '../../store';

export const handleSubscription = ({ data }) => {
    if (!data.onCompanyMembersUploadStatusCheck) return;
    store.dispatch(onCompanyMembersUploadStatus(data.onCompanyMembersUploadStatusCheck));
};

const onCompanyMembersUploadStatusSubscription = async () => {
    const client = await getWebSocketClient({ resetInactivityTimer: false });
    const subscription = gql`subscription OnCompanyMembersUploadStatusCheck  {
        onCompanyMembersUploadStatusCheck {
            stage
            processed
            total
            isComplete
            errors {
                row
                field
                message
            }
        }
    }`;

    const observable = await client.subscribe({
        query: gql`${subscription}`,
    });

    observable.subscribe({
        next: handleSubscription,
        // complete: console.log,
        // error: console.log,
    });
};

export default onCompanyMembersUploadStatusSubscription;
