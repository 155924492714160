import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import LaunchIcon from '@material-ui/icons/Launch';

const HelpfulResources = ({ classes, items }) => (
    <Box className={classes.box}>
        <Grid
            container
            spacing={6}
        >
            {items.map((item) => {
                const {
                    title, description, telephone, link,
                } = item;
                return (
                    <Grid item xs={12} sm={4} key={title.replace(' ', '')}>
                        <Card className={classes.card} elevation={0}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h3" gutterBottom>{title}</Typography>
                                <Typography variant="body1" className={classes.description}>{description}</Typography>
                            </CardContent>
                            {(telephone || link) && (
                                <CardActions className={classes.cardActions}>
                                    {telephone && (
                                        <Typography variant="caption" className={classes.telephone}>
                                            <PhoneIcon />
                                            {' '}
                                            <a href={`tel:${telephone}`} target="_blank" rel="noopener noreferrer">{telephone}</a>
                                        </Typography>

                                    )}
                                    {link && (
                                        <Typography variant="caption" className={classes.source}>
                                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                <LaunchIcon />
                                                {link.label}
                                            </a>
                                        </Typography>
                                    )}
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    </Box>
);

const styles = theme => ({
    box: {
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(4),
    },
    description: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flex: 1,
        paddingTop: 0,
        paddingBottom: 0,
    },
    cardActions: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    source: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 5,
        '& > a': {
            color: theme.palette.common.black,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        '& svg': {
            fontSize: 16,
            marginRight: theme.spacing(0.5),
            color: theme.palette.primary.main,
            textDecoration: 'none',
        },
    },
    telephone: {
        fontSize: 20,
        display: 'flex',
        alignItems: 'center',
        '& > a': {
            color: theme.palette.common.black,
            textDecoration: 'none',
            fontWeight: 'bold',
        },
    },
});

HelpfulResources.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.string,
        }),
    })).isRequired,
};

export default withStyles(styles)(HelpfulResources);
