import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const Radial = ({ classes, children }) => (
    <Box className={classes.circleOuter}>
        <Box className={classes.circle}>
            <Box className={classes.circleInner}>
                {children}
            </Box>
        </Box>
    </Box>
);

const styles = theme => ({
    circleOuter: ({ size }) => {
        const getPadding = () => {
            switch (size) {
            case 'large': return 14;
            case 'medium': return 12;
            case 'small': return 10;
            case 'xsmall': return 8;
            default: return 14;
            }
        };
        return {
            borderRadius: '50%',
            backgroundColor: theme.palette.common.white,
            padding: getPadding(),
            display: 'inline-block',
        };
    },
    circleInner: ({ color }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.common.white,
        '& > *': {
            fontWeight: 400,
            fontFamily: theme.typography.h1.fontFamily,
            transition: 'color 2s',
            color,
            fontSize: 30,
            lineHeight: 1,
        },
    }),
    circle: ({ background, grey, size }) => {
        const getSize = () => {
            switch (size) {
            case 'large': return 128;
            case 'medium': return 96;
            case 'small': return 64;
            case 'xsmall': return 24;
            default: return 128;
            }
        };
        return {
            width: getSize(),
            height: getSize(),
            borderRadius: '50%',
            transition: 'background 2s',
            background: grey ? theme.palette.common.greyDark : background,
            padding: 5,
            display: 'inline-block',
        };
    },
});

Radial.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Radial);
