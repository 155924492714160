import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MuiButtonLink = (props) => {
    const {
        children,
        classes: { root },
    } = props;

    return (
        <Button
            variant="text"
            disableRipple
            classes={{ root }}
            {..._.omit(props, ['inline'])}
        >
            {children}
        </Button>
    );
};

const styles = theme => ({
    root: (props) => {
        const inline = props.inline ? {
            backgroundColor: 'transparent',
            border: 'none',
            display: 'inline',
            margin: 0,
            marginTop: -2,
            padding: 0,
            fontWeight: 'inherit',
            '&:hover,&:focus': {
                color: theme.palette.common.orange,
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            },
        } : {};
        return {
            fontWeight: 'bold',
            cursor: 'pointer',
            textDecoration: 'underline',
            '&:hover,&:focus': {
                color: theme.palette.primary.main,
                textDecoration: 'underline',
                backgroundColor: 'transparent',
            },
            ...inline,
        };
    },
});

MuiButtonLink.defaultProps = {
    inline: false,
    className: null,
    color: 'inherit',
    disabled: false,
};

MuiButtonLink.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    // onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    inline: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

export default withStyles(styles)(MuiButtonLink);
