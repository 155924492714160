import { connectWithLifecycle } from 'react-lifecycle-component';
import getMember from '../../actions/member/getMember';
import updateMemberNotificationPreferences from '../../actions/member/updateMemberNotificationPreferences';
import { openSnackbarSuccess, openSnackbarError } from '../notifier/Notifier';
import getContent from '../../services/content/getContent';
import MemberConsentNotice from './MemberConsentNotice';

const mapDispatchToProps = dispatch => ({
    onClose: async (reason, consent) => {
        if (reason !== 'backdropClick') {
            const { marketing } = consent;
            try {
                await dispatch(await updateMemberNotificationPreferences({ profilingConsent: false, email: marketing }));
                await dispatch(await getMember());
            } catch (error) {
                openSnackbarError(error.message || error);
            }
        }
    },
    onConfirm: async (consent) => {
        const { marketing } = consent;
        try {
            await dispatch(await updateMemberNotificationPreferences({ profilingConsent: true, email: marketing }));
            await dispatch(await getMember());
            openSnackbarSuccess(getContent(['components', 'memberAccount', 'notifications', 'successMsg']));
        } catch (error) {
            openSnackbarError(error.message || error);
        }
    },
});

const mapStateToProps = state => ({
    open: state.member.getIn(['member', 'consent', 'profiling']) === null,
    name: state.member.getIn(['member', 'firstName']),
    consent: state.member.getIn(['member', 'consent']),
    resolving: state.member.get('resolving') || state.member.get('submitting'),
});

export default connectWithLifecycle(mapStateToProps, mapDispatchToProps)(MemberConsentNotice);
