import * as Immutable from 'immutable';
import {
    CREATE_COMPANY_CONTACT_REQUESTED,
    CREATE_COMPANY_CONTACT_RESOLVED,
    CREATE_COMPANY_CONTACT_ERROR,
} from '../actions/company-contacts/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    contact: null,
};

const initialState = Immutable.Map(schema);
/**
 * The company contacts reducer - responsible for managing the state of the company-contacts data
 * @param {Object} state
 * @param {Object} action
 */
const createCompanyContactReducer = (state = initialState, action) => {
    switch (action.type) {
    case CREATE_COMPANY_CONTACT_REQUESTED:
        return state.set('resolving', true);

    case CREATE_COMPANY_CONTACT_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK');

    case CREATE_COMPANY_CONTACT_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR');

    default:
        return state;
    }
};

export { initialState };

export default createCompanyContactReducer;
