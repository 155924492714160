import { MOBILE_APP } from '../../config/config';

const MobileAppService = {};

/**
 * Check if the device is the mobile app
 * @param  {String} device
 * @return {Bool}
 */
MobileAppService.isMobileApp = device => device === MOBILE_APP.DEVICE.APP;

/**
 * Check if the window var the mobile app sets is there
 * @return {Bool}
 */
MobileAppService.checkIfMobileApp = () => window[MOBILE_APP.WINDOW_VARS.IS_APP] === true;

/**
 * Get the app type
 * @return {String}
 */
MobileAppService.getAppType = () => (MobileAppService.checkIfMobileApp() ? MOBILE_APP.DEVICE.APP : MOBILE_APP.DEVICE.WEB);

/**
 * Should we show the starter guide for mobile app users
 * @param  {String} device
 * @param  {Object} cookies
 * @return {Bool}
 */
MobileAppService.canShowGuide = (device, cookies) => (MobileAppService.isMobileApp(device)
        && window[MOBILE_APP.WINDOW_VARS.SHOW_GUIDE] === true
        && cookies.get(MOBILE_APP.COOKIES.BREEZE_GUIDE_READ) !== true);
/**
 * Is the react native view window object present
 * @return {Bool}
 */
MobileAppService.isReactNativeView = () => window[MOBILE_APP.WINDOW_VARS.REACT_NATIVE_WEB_VIEW] !== undefined;

/**
 * Post a message to the react native webview
 * @param  {String} message
 */
MobileAppService.postMessage = (message) => {
    if (MobileAppService.isReactNativeView()) {
        window[MOBILE_APP.WINDOW_VARS.REACT_NATIVE_WEB_VIEW].postMessage(message);
    }
};

/**
 * Set the guide as been viewed by user
 * @param  {Object} cookies
 */
MobileAppService.setGuideViewed = (cookies) => {
    MobileAppService.postMessage(MOBILE_APP.EVENTS.BREEZE_GUIDE_READ);
    cookies.set(MOBILE_APP.COOKIES.BREEZE_GUIDE_READ, true, { path: '/' });
};

/**
 * Set the mobile app menu to be shown
 */
MobileAppService.setShowMenu = () => {
    MobileAppService.postMessage(MOBILE_APP.EVENTS.MENU_SHOW);
};


export default MobileAppService;
