import * as Immutable from 'immutable';
import { EventType } from '@azure/msal-browser';
import { getEnvironmentVariable } from '../services/environment/environment-service';
import {
    AUTH_DISABLE_AUTOLOGOUT,
    AUTH_ENABLE_AUTOLOGOUT,
    AUTH_SET_ACCOUNT,
} from '../actions/auth/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    authenticated: false,
    authentication: {
        accessToken: null,
        idToken: null,
        role: null,
    },
    error: null,
    autologout: true,
};

const getRole = (clientId) => {
    if (clientId === getEnvironmentVariable('REACT_APP_AZURE_B2C_MEMBER_CLIENTID')) {
        return 'member';
    } if (clientId === getEnvironmentVariable('REACT_APP_AZURE_B2C_CONTACT_CLIENTID')) {
        return 'admin';
    }
    return null;
};

const initialState = Immutable.Map(schema);
/**
 * The auth reducer - responsible for managing the auth and the state of the data
 * @param {Object} state
 * @param {Object} action
 */
const authReducer = (state = initialState, action) => {
    // if (action && action.type && action.type.indexOf('AAD_') !== -1) {
    //     console.log('type:', action.type);
    //     console.log('payload:', action.payload);
    // }
    switch (action.type) {
    case EventType.HANDLE_REDIRECT_END:
        return state
            .set('status', 'OK')
            .set('resolving', false)
            .set('authenticated', true);

    case AUTH_SET_ACCOUNT:
        return state
            .set('status', 'OK')
            .setIn(['authentication', 'role'], getRole(action.payload.idTokenClaims.aud))
            .setIn(['authentication', 'idToken'], action.payload.idToken)
            .set('authenticated', true);

    case EventType.LOGIN_SUCCESS:
        return state
            .set('status', 'OK')
            .setIn(['authentication', 'role'], getRole(action.payload.account.idTokenClaims.aud))
            .setIn(['authentication', 'idToken'], action.payload.idToken)
            .set('authenticated', true);

    case EventType.ACQUIRE_TOKEN_SUCCESS:
        return state
            .set('status', 'OK')
            .setIn(['authentication', 'role'], getRole(action.payload.account.idTokenClaims.aud))
            .setIn(['authentication', 'idToken'], action.payload.idToken)
            .set('authenticated', true);

    case EventType.LOGIN_FAILURE:
        return state
            .set('status', 'OK')
            .set('authenticated', false)
            .set('error', action.payload)
            .setIn(['authentication', 'role'], null)
            .setIn(['authentication', 'accessToken'], null)
            .setIn(['authentication', 'idToken'], null);

    case EventType.LOGOUT_END:
        return state
            .set('status', 'OK')
            .set('authenticated', false)
            .setIn(['authentication', 'role'], null)
            .setIn(['authentication', 'accessToken'], null)
            .setIn(['authentication', 'idToken'], null);

    case AUTH_ENABLE_AUTOLOGOUT:
        return state.set('autologout', true);

    case AUTH_DISABLE_AUTOLOGOUT:
        return state.set('autologout', false);

    default:
        return state;
    }
};

export { initialState };

export default authReducer;
