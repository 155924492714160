import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const getVariantClass = (variant, classes) => {
    switch (variant) {
    case 'gradient': return classes.gradient;
    case 'gradientGrey': return classes.gradientGrey;
    case 'shadow': return classes.shadow;
    case 'error': return classes.error;
    default: return '';
    }
};

const getTransparentClass = (classes, transparent) => (transparent ? classes.transparent : '');

const getSelectedClass = (classes, selected) => (selected ? classes.selected : '');

const setClass = (classes, variant, transparent, selected) => {
    const className = classes.root;
    return className.concat(` ${getVariantClass(variant, classes)} ${getTransparentClass(classes, transparent)} ${getSelectedClass(classes, selected)}`);
};

const MuiCard = ({
    classes, children, selected, variant, transparent, className,
}) => (
    <Card
        className={`${setClass(classes, variant, transparent, selected)} ${className}`}
    >
        {children}
    </Card>
);

const gradient = (theme, background) => ({
    background: theme.palette.common.white,
    backgroundClip: 'padding-box',
    border: 'solid 2px transparent',
    position: 'relative',
    overflow: 'visible',
    marginBottom: 0,
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 0,
        margin: -2,
        background,
        borderRadius: theme.shape.borderRadius,
    },
    '&:last-child': {
        paddingBottom: 0,
    },
    '& > div': {
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        zIndex: 1,
        marginBottom: 0,
        borderRadius: 3,
    },
});

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        border: `2px solid ${theme.palette.common.grey}`,
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
        height: '100%',
        '&transparent': {
            backgroundColor: 'transparent',
        },
    },
    gradient: gradient(theme, theme.palette.background.gradient),
    gradientGrey: {
        ...gradient(theme, theme.palette.background.gradientGrey),
    },
    selected: {
        borderColor: theme.palette.common.blue,
        backgroundColor: theme.palette.common.blueLight,
    },
    transparent: {
        backgroundColor: 'transparent',
    },
    shadow: {
        border: 'none',
        margin: 0,
        height: 'auto',
        boxShadow: '0px 1px 7px 0px rgba(0,0,0,0.2)',
    },
    error: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
});

MuiCard.defaultProps = {
    selected: false,
    variant: null,
    transparent: false,
    className: '',
};

MuiCard.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    selected: PropTypes.bool,
    variant: PropTypes.string,
    transparent: PropTypes.bool,
};

export default withStyles(styles)(MuiCard);
