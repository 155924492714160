import React from 'react';
import PrivacyPanel from '../../privacy-panel/PrivacyPanel';
import Footer from '../Footer';
import { URLS } from '../../../config/config';
import { contactFooterLinkGroups } from '../footer.config';

const ContactFooter = () => (
    <>
        <PrivacyPanel link={URLS.CONTACT.PRIVACY_EXTERNAL} isAdmin />
        <Footer linkGroups={contactFooterLinkGroups} isAdmin />
    </>
);

export default ContactFooter;
